/** @format */

import React, { useEffect, useState } from "react";
import { Table, notification, Modal } from "antd";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  apisecondstage,
  apiGetQuizz,
  apiPostQuiz,
} from "../../../../services/secondstage";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrency, path } from "../../../../utils/constant";
import { Popover } from "antd";
import QuizModal from "../../../../components/Modal/ModalQuiz";
import { Icon } from "@iconify/react";
// import { CardSession, CardLevel_2 } from "../../../../components";
import Levels from "./Sections/Levels";
import { withTranslation, useTranslation } from "react-i18next";
import CardSession from "../../../../components/Card/CardSessionV2";

const SwimmingLesson = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));
  const [quizResult, setQuizResult] = useState({
    answers: [],
    name: "",
    color: "",
    detail: "",
    pictureUrl: "",
    targetId: "1",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal_complete_quiz = () => {
  //   setIsModalOpen(true);
  // };
  // const handleOk_complete_quiz = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel_complete_quiz = () => {
  //   setIsModalOpen(false);
  // };
  // define modal quizz
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    // if (user) {
    setModalVisible(true);
    // } else {
    //   notification.info({
    //     message: "Please log in to continue",
    //     placement: "topRight",
    //     duration: 2,
    //   });
    //   navigate("../" + path.LOGIN);
    // }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const handleCompleteQuiz = async (userAnswers) => {
    // let answers = [];
    // for (let i = 0; i < userAnswers.length; i++) {
    //   const element = userAnswers[i];
    //   if (element === "Yes") {
    //     answers.push(1);
    //   } else if (element === "No") {
    //     answers.push(0);
    //   } else if (i === userAnswers.length - 1) {
    //     if (element) {
    //       answers.push(Number(element));
    //     } else {
    //       answers.push(0);
    //     }
    //   } else {
    //     answers.push(element);
    //   }
    // }

    await apiPostQuiz({
      answers: userAnswers,
      quizName: dataQuiz?.description,
    })
      .then((res) => {
        if (res.status === 200) {
          setQuizResult({
            ...quizResult,
            answers: userAnswers,
            name: res.data.data.name,
            color: res.data.data.color,
            pictureUrl: res.data.data.pictureUrl,
            detail: res.data.data.detail,
            targetId: res.data.data.index,
          });
          setIsModalOpen(true);
          setModalVisible(false);
          sessionStorage.setItem("resultQuiz", JSON.stringify(res.data.data));
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error evaluating user",

          placement: "topRight",
          duration: 2,
        });
      });
  };

  // const dataState = location.state.data;
  // const handleClick = () => {
  //   navigate("../" + path.SWIMMINGLESSONS, {
  //     state: {
  //       data: { ...dataState, ...quizResult },
  //       type: "SOF",
  //     },
  //   });
  // };
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState({
    description: "",
    semester: [
      {
        classPrice: "",
        code: "",
        description: "",
        duration: "",
        endDate: "",
        endRegisterDate: "",
        isCurrentSemester: "",
        startDate: "",
        startRegisterDate: "",
        status: "",
        type: "",
        year: "",
      },
    ],
    img: "",
    level: {
      level1: {
        name: "",
        color: "",
        detail: "",
      },
      level2: {
        name: "",
        color: "",
        detail: "",
      },
      level3: {
        name: "",
        color: "",
        detail: "",
      },
      level4: {
        name: "",
        color: "",
        detail: "",
      },
      level5: {
        name: "",
        color: "",
        detail: "",
      },
      level6: {
        name: "",
        color: "",
        detail: "",
      },
      level7: {
        name: "",
        color: "",
        detail: "",
      },
    },
    nowClass: [
      {
        title: "",
        detail: "",
        pictureUrl: "",
        estCost: "",
      },
    ],
  });

  const [dataQuiz, setDataQuiz] = useState({
    code: "",
    description: "",
    type: "",
    quizSet: [
      {
        question: "",
        options: [],
      },
    ],
  });
  const getSize = () => {
    setWidth(window.innerWidth);
  };

  // break point resize sidebar
  useEffect(() => {
    const fetchSecondStage = async () => {
      const response = await apisecondstage("SOF");

      if (response?.status === 201 || response?.status === 200) {
        setData(response.data.data);
      }
    };
    fetchSecondStage();
    const fetchQuiz = async () => {
      const response = await apiGetQuizz("SOF");

      if (response?.status === 201 || response?.status === 200) {
        setDataQuiz(response?.data.data);
      }
    };
    fetchQuiz();
    window.addEventListener("resize", getSize);
    if (width <= 400) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => {
      window.removeEventListener("resize", getSize);
    };
  }, [window.innerWidth]);
  const columns = [
    // {
    //   title: <p className="text-xl font-roboto_bold text-white">No</p>,
    //   dataIndex: "code",
    //   key: "code",
    //   fixed: "left",
    //   width: 100,
    //   render: (no) => <p className={`text-lg`}>{no}</p>,
    // },
    {
      title: (
        <p className="text-xl pv:max-sm:text-lg font-roboto_bold text-white">
          Lesson
        </p>
      ),
      dataIndex: "description",
      key: "description",
      render: (lesson) => (
        <p className={`text-lg pv:max-sm:text-[14px]`}>{lesson}</p>
      ),
      fixed: "left",
      width: `${isMobile ? "50px" : "200px"} `,
    },
    {
      title: (
        <p className="text-xl pv:max-sm:text-lg font-roboto_bold text-white ">
          Price
        </p>
      ),
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <p className={`text-lg pv:max-sm:text-[14px]`}>
          {formatCurrency(price)}
        </p>
      ),
    },
    {
      title: (
        <p className="text-xl pv:max-sm:text-lg font-roboto_bold text-white">
          Type
        </p>
      ),
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <p className={`text-lg pv:max-sm:text-[14px]`}>{type}</p>
      ),
    },
    {
      title: (
        <p className="text-xl pv:max-sm:text-lg font-roboto_bold text-white">
          Level
        </p>
      ),
      dataIndex: "level",
      key: "level",
      render: (level) => (
        <p
          className={` text-lg pv:max-sm:text-[14px] font-roboto_bold`}
          style={{
            color: `${
              level === "1"
                ? "#c02942"
                : level === "2"
                ? "#d95b43"
                : level === "3"
                ? "#fecc23"
                : level === "4"
                ? "#467a3c"
                : level === "5"
                ? "#4e8ddb"
                : level === "6"
                ? "#c252c9"
                : level === "7"
                ? "#542437"
                : ""
            }`,
          }}
        >
          {level === "1"
            ? `${data.level.level1.name}`
            : level === "2"
            ? `${data.level.level2.name}`
            : level === "3"
            ? `${data.level.level3.name}`
            : level === "4"
            ? `${data.level.level4.name}`
            : level === "5"
            ? `${data.level.level5.name}`
            : level === "6"
            ? `${data.level.level6.name}`
            : level === "7"
            ? `${data.level.level7.name}`
            : ""}
        </p>
      ),
    },
  ];
  // Update Here
  const [selectedCard, setSelectedCard] = useState(null);

  const sessions = [
    {
      startDate: "26 AUGUST 2024",
      finishDate: "20 OCTOBER 2024",
      session: "1",
      time: "8 WEEK SESSION",
      price: "VND 2,000,000",
      available: true,
      // datasection: dataState,
      dataquizSection: quizResult,
      path: path.SWIMMINGLESSONS,
    },
    {
      startDate: "28 OCTOBER 2024",
      finishDate: "22 DECEMBER 2024​",
      session: "2",
      time: "8 WEEK SESSION",
      price: "VND 2,000,000",
    },
    {
      startDate: "6 JANUARY 2025",
      finishDate: "23 MARCH 2025",
      session: "3",
      time: "10 WEEK SESSION",
      price: "VND 2,500,000 ​",
      available: false,
    },
    {
      startDate: "31 MARCH​ 2025",
      finishDate: "1 JUNE 2​025",
      session: "4",
      time: "10 WEEK SESSION",
      price: "VND 2,500,000 ​",
      available: false,
    },
  ];
  return (
    <div>
      {modalVisible ? (
        <QuizModal
          visible={modalVisible}
          onCancel={handleCloseModal}
          questions={dataQuiz?.quizSet}
          onComplete={handleCompleteQuiz}
        />
      ) : null}

      {isModalOpen ? (
        <Modal
          width={800}
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <button
              className="px-4 py-2 bg-secondary1 rounded-2xl text-white font-roboto_bold"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              <p className="text-black">OK</p>
            </button>,
          ]}
        >
          <div className="">
            <div className="flex  justify-center items-center">
              <p className="text-[20px] font-roboto_bold">
                {" "}
                {t("SwimmingCourse_CourseforU")}
              </p>
            </div>
            <div className="">
              <img
                className="object-cover"
                src={
                  quizResult.pictureUrl !== ""
                    ? quizResult.pictureUrl
                    : "http://103.157.218.115/AquaticPicture/Badges/starfish.png"
                }
              ></img>
              <div className="flex justify-center items-center">
                <p className="text-[24px] text-[#55c1ff]">
                  {quizResult.name !== "" ? quizResult.name : "Starfish"}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
      {/* <div className="w-full   bg-no-repeat bg-contain ">
       
        <div className="grid grid-cols-5 w-[95%] mx-auto py-8 pv:max-lg:grid-cols-1 pv:max-md:w-[95%] pt-[180px] pv:max-md:pt-0">
          <div className="flex flex-col justify-center pv:max-lg:p-4 gap-10 pv:max-lg:gap-4 col-span-2 2xl:w-[80%] mx-auto lg:max-2xl:p-0">
            <p className="text-[#1b4235]  text-6xl  text-start pv:max-lg:text-start pv:max-md:text-2xl">
              {dataState.title}
            </p>
            <p className="text-3xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start">
              {data?.description}
            </p>
          </div>
          <img
            src={dataState.img}
            className="w-[100%] h-[500px] 2xl:h-[600px] object-cover pv:max-md:h-[50vh] col-span-3 mx-auto 2xl:w-[80%]"
          />
        </div>
      </div> */}
      <div className="w-full bg-no-repeat bg-contain ">
        <div className="grid grid-cols-5 w-[80%] mx-auto py-8 pv:max-md:grid-cols-1 pv:max-md:w-[95%] pt-[180px] pv:max-md:pt-0">
          <div className="flex flex-col justify-center p-10 pv:max-lg:p-4 gap-10 pv:max-lg:gap-4 col-span-2">
            <p className="text-[#1b4235] text-5xl font-roboto_bold text-start pv:max-lg:text-start pv:max-md:text-2xl md:max-xl:text-4xl">
              {/* {dataState.title} */}
              CHILDREN ​SWIMMING LESSONS
            </p>
            <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
              {data?.description}
            </p>
            {/*<div className=" ">
               <button className="bg-[#dcd3d1] px-10 py-4 rounded-full hover:text-white hover:bg-secondary1 pv:max-ph:px-10 pv:max-ph:py-4">
                <a
                  className=" text-2xl underline md:max-xl:text-lg pv:max-md:text-lg "
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc16wK8SzqIjmjkMpUxTYCMcSjqN1ql_FNtn7loM0nuxlEAJQ/viewform"
                >
                  {t("AdultCourse_register")}
                </a>
              </button>
            </div> */}
          </div>
          <img
            src={data?.pictureUrl}
            className="w-full h-[500px] object-cover col-span-3 pv:max-ph:h-[50vh]"
          />
        </div>
      </div>

      <div className="pt-20">
        <div className="bg-white w-[100%] mx-auto">
          <div className="py-10 bg-black">
            <div className="w-[80%] mx-auto pv:max-ph:w-[90%]">
              <div className="py-5 flex  ">
                <p className="text-5xl  font-roboto_bold pv:max-md:text-2xl text-white  ">
                  {t("SwimmingCourse_School")}
                </p>
              </div>

              <div className=" w-[80%] mx-auto grid grid-cols-3 text-white gap-10 pv:max-md:grid-cols-1 md:max-lg:grid-cols-2 md:max-2xl:w-[100%]">
                {[...Array(6)]?.map((item, index) => {
                  const element = data?.level[`level${index + 1}`];

                  return (
                    <div className=" ">
                      <img
                        className="h-[300px] pv:max-ph:h-[200px] "
                        src={element?.pictureUrl}
                      ></img>
                      <p className="text-3xl uppercase pv:max-ph:text-2xl">
                        {element?.name}
                      </p>
                      <p className="text-xl pv:max-ph:text-lg">
                        {element?.detail}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify-center pb-4 pt-10">
                <button
                  className="bg-[#dcd3d1] px-14 py-4 rounded-full pv:max-md:px-10 pv:max-md:py-3 text-[20px]  hover:bg-[#dcd3d1]"
                  onClick={() => {
                    handleOpenModal();
                  }}
                >
                  <p className="underline text-2xl pv:max-ph:text-[16px]">
                    {t("SwimmingCourse_Level")}
                  </p>
                </button>
              </div>
            </div>
          </div>

          <div className="py-10 bg-[#4e858b]">
            <div className=" w-[95%] mx-auto mt-4 pv:max-sm:w-[100%]  flex flex-col items-center">
              <div className="w-[65%] pv:max-ph:w-[70%] ph:max-sm:w-[60%]  lg:max-xl:w-[80%] ph:max-md:w-[60%] md:max-lg:w-[60%]    ">
                <p className="text-7xl pv:max-sm:text-3xl font-roboto_regular pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#dcd3d1]">
                  {t("AdultCourse_Session")}
                </p>

                <div className="mt-4 mx-auto  grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-4   ">
                  {sessions.map((session, index) => (
                    <CardSession
                      key={index}
                      startDate={session.startDate}
                      finishDate={session.finishDate}
                      session={session.session}
                      time={session.time}
                      price={session.price}
                      isSelected={selectedCard === index}
                      available={session.available}
                      onSelect={() => setSelectedCard(index)}
                      // data={session.datasection}
                      quizResult={session.dataquizSection}
                      path={session.path}
                    />
                  ))}
                  {/* <button
                    className=" uppercase bg-[#dcd3d1] w-full py-6 rounded-full pv:max-ph:rounded-md"
                    onClick={() =>
                      navigate("../" + path.SWIMMINGLESSONS, {
                        state: {
                          data: { ...dataState, ...quizResult },
                          type: "SOF",
                        },
                      })
                    }
                  >
                    <p className="underline lg:text-[1.2vw] md:text-sm sm:text-base">
                      BOOK NOW
                    </p>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="flex justify-center py-8 gap-10 flex-col w-[25%] pv:max-md:w-[90%] md:max-xl:w-[40%] mx-auto">
              <button className="uppercase bg-[#dcd3d1] w-full py-6 rounded-full pv:max-ph:rounded-full">
                <p
                  className="underline text-2xl pv:max-ph:text-lg"
                  onClick={() => navigate("../" + path.HOME)}
                >
                  {t("AdultCourse_Home")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SwimmingLesson);
