/** @format */

import { Icon } from "@iconify/react";
const CardLevel = ({
  pictureUrl = "https://thepatiocompanyma.com/wp-content/uploads/2021/07/fiberglass-poool-sizes-1024x688.png",
  color = "#ffffff",
  detail,
  name,
  badge,
  additionalInfo = [],
  customStyle = "",
}) => {
  return (
    <div
      className={`min-h-[100px] h-[300px] w-[300px] pv:max-md:h-[350px] p-1.5 relative hover:scale-105 duration-700 shadow-lg rounded-lg flex flex-col items-center ${customStyle}`}
    >
      {/* {badge ? null
        : <img
          className="w-full object-cover h-[200px] rounded-t-lg"
          alt=""
          src={pictureUrl}
        />
      } */}
      {badge ? (
        <img
          alt="badge"
          src={badge}
          // className="absolute top-[140px] right-[10px] object-contain w-[50px] aspect-square"
          className="w-[120px] aspect-square object-cover"
        />
      ) : (
        <></>
      )}
      <div className="p-[15px] flex flex-col gap-[15px]">
        <div className={`font-roboto_bold text-center text-lg`}>{name}</div>
        <div className={`line-clamp-4`}>{detail}</div>
        {additionalInfo.length > 0 ? (
          additionalInfo.map((item, index) => {
            return (
              <div className="flex gap-[10px] items-center ">
                <div className="font-roboto_bold">{item.title}</div>
                <Icon icon={"octicon:dash-24"} />
                <div className="text-primary">{item.description}</div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CardLevel;
