import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import CardLevel from "../../../../../components/Card/CardLevel";

const Levels = ({
  data,
  numberofElement = 7,
  slidesPerView = "1",
  customStyle,
}) => {
  return (
    <>
      <Swiper
        slidesPerView={slidesPerView}
        navigation
        pagination={{ clickable: true }}
        modules={[Navigation]}
      >
        {[...Array(numberofElement)].map((item, index) => {
          const element = data?.level[`level${index + 1}`];

          return (
            <SwiperSlide className="p-[10px] !flex justify-center">
              <CardLevel
                customStyle={customStyle}
                badge={element?.pictureUrl}
                color={element?.color}
                detail={element?.detail}
                name={element?.name}
                additionalInfo={element?.additionalInfo}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Levels;
