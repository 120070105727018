/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { path } from "../../../../utils/constant";
import imgPoolParty from "../../../../assets/Final_PIctures/poolparty_1.png";
import imgPoolParty_2 from "../../../../assets/Final_PIctures/1.SchoolofFish.jpg";
import { withTranslation, useTranslation } from "react-i18next";

const PoolParty = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="grid grid-cols-5 w-[65%] mx-auto pt-[120px] gap-10 pv:max-dv:w-[95%] pv:max-dv:gap-1 pv:max-dv:pt-4 md:max-dv:pt-[100px] ">
        <div className="col-span-3">
          <div className="pb-4">
            <p className="text-[#1b4235] text-5xl w-[70%] pv:max-dv:text-[3vw] pv:max-dv:w-[100%] ">
              POOL PARTY
            </p>
          </div>
          <div className=" text-[#391b0b]   w-[100%] text-[24px] flex flex-col gap-10 pv:max-dv:text-[2vw]  pv:max-dv:gap-2 pv:max-md:font-semibold">
            <p className=" ">
              A new concept in Hanoi, ‘The birthday pool party’! Book out a two
              hour slot at the Aquatics Hub on a weekend throughout the year. A
              dedicated Party Planner will help organise your perfect paty with
              an hour of organised pool games followed by a meal at Aqua Cafe’s
              long Party Table. Decorations are included and there is plenty of
              room to further customize the experience.
            </p>
            <div className="grid grid-cols-5  items-center">
              <img className="col-span-2 object-cover" src={imgPoolParty}></img>
              <div className="col-span-3 text-[#391b0b]   w-[80%] mx-auto text-[24px]  pv:max-dv:text-[2vw]  pv:max-dv:gap-2 pv:max-md:font-semibold">
                <p className="">
                  Please note that there are only two slots available each
                  weekend:
                </p>
                <div className="">
                  <ul className="list-disc pl-10 font-bold ">
                    <li>Saturday 11:00-13:00</li>
                    <li>Sunday 11:00-13:00</li>
                  </ul>
                </div>
                <p className="">...so book up to avoid disappointment.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full  object-cover col-span-2 flex flex-col gap-10 pv:max-dv:gap-4 ">
          <img className="object-cover h-full" src={imgPoolParty_2}></img>
        </div>
      </div>

      <div className="pt-[20px] pv:max-ph:pt-[20px] md:max-lg:pt-[65px] lg:max-dh:pt-[75px]  ">
        <div className="">
          <div className="bg-[#4e858b] w-full  py-10 ">
            <div className="w-[60%] mx-auto flex flex-col gap-10  pv:max-xl:w-[90%]">
              <p className="uppercase text-white font-bold text-5xl underline pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl   ">
                PRICE
              </p>
              <div className="">
                <ul className="list-disc pl-20 uppercase text-white text-5xl   pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                  <li>PARTY PLANNER</li>
                  <li>AQUATICS GAMES ORGANISED BY THE AQUATICS TEAM</li>
                  <li>CHOICE OF MEAL & DRINK</li>
                  <li>SOUVENIR BIRTHDAY MENU</li>
                  <li>TABLE DECORATIONS INCLUDING BALLOONS AND CONFETTI</li>
                </ul>
              </div>
              <p className="uppercase text-white text-5xl  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                VND 7,000,000 FOR UP TO 10 CHILDREN
              </p>
              <p className="uppercase text-white text-5xl  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                VND 500,000FORADDITIONAL CHILDREN
              </p>
              <p className="uppercase font-bold text-white text-5xl underline pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl   ">
                PERSONALISED EXTRAS
              </p>
              <div className="">
                <ul className="list-disc uppercase pl-20 text-white text-5xl   pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                  <li>FROM VND 800,000- CHOICE OF CAKE</li>
                  <li>VND 4,000,000- CHOICE OF BACKDROP</li>
                  <li>VND 2,000,000- PHOTOGRAPHER</li>
                  <li>VND 4,000,000-VIDEO OF THE DAY</li>
                </ul>
              </div>
            </div>

            <div className="flex flex-col gap-6 w-[400px] mx-auto pt-20 pv:max-ph:w-[300px]">
              <button
                className="bg-[#dcd3d1] py-4 rounded-3xl"
                onClick={() =>
                  (window.location = "mailto:contact@aquaticshub.vn")
                }
              >
                <p className="text-2xl pv:max-ph:text-lg">
                  CONTACT TO RESERVE A DATE
                </p>
              </button>
              <button
                className="bg-[#dcd3d1] py-4 rounded-3xl"
                onClick={() => navigate("../" + path.HOME)}
              >
                <p className="text-2xl pv:max-ph:text-lg">HOME</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PoolParty);
