/** @format */
import { formatCurrency } from "../../utils/constant";
import { Icon } from "@iconify/react";
import { Empty } from "antd";
const organizeData = (data, mainAttribute, columnAttribute) => {
  const organized = {};
  data.forEach((item) => {
    if (!organized[item[mainAttribute]]) {
      organized[item[mainAttribute]] = {};
    }
    organized[item[mainAttribute]][item[columnAttribute]] = item;
  });

  return organized;
};

const TableComponent_v2 = ({
  data = [],
  columns,
  mainAttribute,
  columnAttribute,
  handleClick,
  handleClickPlay,
  Weekday,
  type = "",
  displayColumnsName = false,
}) => {
  const organizedData = organizeData(data, mainAttribute, columnAttribute);
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const renderHeader = () => {
    return (
      <tr className="w-full">
        <th className="w-[10%] px-6 pv:max-md:px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base pv:max-md:text-[14px] text-gray-700 uppercase tracking-wider text-secondary1 font-roboto_bold">
          <p className="w-full text-center">{Weekday}</p>
        </th>

        {columns.map((column) => (
          <th
            key={column}
            className="px-6 pv:max-md:px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base pv:max-md:text-[14px] font-semibold text-gray-700 uppercase tracking-wider"
          >
            <p className="">{displayColumnsName ? column : ""}</p>
          </th>
        ))}
      </tr>
    );
  };

  const renderRows = () => {
    return Object.keys(organizedData).map((time) => (
      <tr
        key={time}
        className="border-b-4 border-gray-300 w-[150px] font-roboto_bold  "
      >
        <td className="w-[150px] font-roboto_bold pv:max-md:w-[30px]">
          <div className="xl:hidden pv:max-sm:text-[8px] sm:max-lg:text-[12px] p-[10px]">
            {<p className="text-end">{time.split("-")[0]}</p>}
            {<p className="text-end">{time.split("-")[1]}</p>}
            {<p className="text-end">{time.split("-")[2]}</p>}
          </div>
          <div className="pv:max-xl:hidden flex justify-end ">
            {<p className="text-end">{time}</p>}
          </div>
        </td>
        {columns.map((column, index) => {
          const element = organizedData[time][column];
          const isFull = element?.data.enrolled / element?.data.capacity == 1;
          return (
            <td className="w-[30%] max-w-[30%]" key={column}>
              {element ? (
                <div
                  className={`shadow-md rounded-xl m-4 pv:max-lg:m-1 flex justify-between items-center pv:max-md:flex-col pv:max-md:flex-col-reverse pv:max-md:items-start`}
                  style={
                    isFull
                      ? { backgroundColor: "#F3F4F6" }
                      : { backgroundColor: "#bcbefa" }
                  }
                >
                  <div
                    className={`font-bold text-xl pv:max-md:text-[12px] ${
                      isFull ? "cursor-not-allowed" : "cursor-pointer"
                    } text-center p-6 pv:max-md:p-2 w-[30%] border-r-[2px] pv:max-md:border-t-[2px] pv:max-md:border-r-0 pv:max-md:w-full border-gray-300
                  hover:scale-105
                  `}
                    onClick={() => {
                      if (element && type != "clubs") {
                        if (!isFull) {
                          handleClick(element);
                        }
                      }
                    }}
                  >
                    REGISTER
                  </div>
                  <div className="p-6 w-full flex items-center justify-around pv:max-md:text-[12px] pv:max-sm:flex-col pv:max-sm:items-start pv:max-sm:gap-2 pv:max-md:p-[16px]">
                    {isFull ? (
                      <p>Class is full</p>
                    ) : (
                      <>
                        <p>PRICE: {formatCurrency(element?.data.price)}</p>
                        <p>MAX: {element?.data.capacity} swimmers </p>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <>
      {data.length > 0 ? (
        <div className="inline-block min-w-full  shadow-md rounded-lg overflow-hidden mb-10 mt-4">
          <table className="w-full shadow-2xl ">
            <thead>{renderHeader()}</thead>
            <tbody>{renderRows()}</tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="py-10 bg-blue-50 w-full flex justify-center items-center">
            <Empty />
          </div>
        </>
      )}
    </>
  );
};

export default TableComponent_v2;
