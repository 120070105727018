/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { apisecondstage } from "../../../../services/secondstage";
import {
  formatCurrency,
  path,
  numberWithCommas,
} from "../../../../utils/constant";
import { Table } from "antd";
import { Loading } from "../../../../components";
import { Icon } from "@iconify/react";
import Levels from "./Sections/Levels";
import { withTranslation, useTranslation } from "react-i18next";

import masterpic from "../../../../assets/adultswimmingpc3.png";
import beginpic from "../../../../assets/adultswimmingpc1.png";
import ImgpsAdult from "../../../../assets/Final_PIctures/ps_adultclass.jpg";
import techniquepic from "../../../../assets/adultswimmingpc2.jpg";
import ImgopenSwim from "../../../../assets/Final_PIctures/ps_openswimming.jpg";
const PublicSwimming = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    description: "",
    cost: {
      age: [],
      level: [],
      pricePlural: [],
      priceSingle: [],
    },
    newClass: [
      {
        detail: "",
        estCost: "",
        pictureUrl: "",
        title: "",
      },
    ],
    nowClass: [
      {
        detail: "",
        estCost: "",
        pictureUrl: "",
        title: "",
      },
    ],
    level: {},

    policy: [],
    pictureUrl: "",
  });

  const handleOnclick = () => {
    navigate("../" + path.PUBLICSWIMMINGS);
  };

  useEffect(() => {
    const fetchSecondStage = async () => {
      const response = await apisecondstage("publicSwimming");

      if (response?.status === 201 || response?.status === 200) {
        setData({
          description: response.data.data.description,
          policy: response.data.data.policy,
          cost: response.data.data.cost,
          newClass: response.data.data.newClass,
          nowClass: response.data.data.nowClass,
          pictureUrl: response.data.data.pictureUrl,
        });
        // setData(response.data.data)
        setIsLoading(false);
      }
    };
    fetchSecondStage();
  }, []);

  return (
    <div className="">
      {/* <div className="w-full bg-no-repeat bg-contain ">
        <div className="grid grid-cols-5 w-[80%] mx-auto py-8 pv:max-md:grid-cols-1 pv:max-md:w-[95%] pt-[180px] pv:max-md:pt-0">
          <div className="flex flex-col justify-center p-10 pv:max-lg:p-4 gap-10 pv:max-lg:gap-4 col-span-2 ">
            <p className="text-[#1b4235] text-6xl font-roboto_bold text-start pv:max-lg:text-start pv:max-md:text-2xl md:max-xl:text-4xl">
              {DataState.title}
            </p>
            <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
              {data?.description}
            </p>
            <div className="">
              <button
                className="bg-[#dcd3d1] px-10 py-4 rounded-full hover:text-white hover:bg-secondary1 pv:max-ph:px-10 pv:max-ph:py-4"
              // onClick={() => {
              //   handleClick();
              // }}
              >
                <a
                  className="text-2xl underline md:max-xl:text-lg pv:max-md:text-lg"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc16wK8SzqIjmjkMpUxTYCMcSjqN1ql_FNtn7loM0nuxlEAJQ/viewform"
                >
                  {t("AdultCourse_register")}
                </a>
              </button>
            </div>
          </div>
          <img
            src={data.pictureUrl}
            className="w-full h-[500px] object-cover col-span-3 pv:max-ph:h-[50vh]"
          />
        </div>
      </div> */}
      {/* update here */}
      <div className="w-full relative pv:max-sm:pt-0 mt-20 ">
        <div className="w-full bg-no-repeat bg-contain ">
          <div className="items-center grid grid-cols-11 w-[65%] mx-auto  gap-y-20 pv:max-md:gap-y-2  pv:max-md:grid-cols-1 pv:max-md:w-[95%] pv:max-md:pt-0">
            {/* Begin */}
            <div className="flex flex-col justify-center py-20 pv:max-lg:p-4 gap-5 pv:max-lg:gap-4 col-span-4">
              <p className="text-7xl pv:max-sm:text-2xl pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#1b4235]">
                {t("PublicSwiming_Public")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("PublicSwiming_Public_Description")}
              </p>
            </div>
            <div className="col-span-1"></div>

            <div className="col-span-6 flex pv:justify-center">
              <img src={ImgpsAdult} className="w-full h-auto object-cover" />
            </div>
            {/* Lane */}
            <div className="col-span-6 flex pv:max-md:justify-center py-10">
              <img src={techniquepic} className="w-full h-auto object-cover" />
            </div>
            <div className="col-span-1"></div>

            <div className="flex flex-col justify-center py-20 pv:max-lg:p-4 gap-5 pv:max-lg:gap-4 col-span-4 ">
              <p className="text-7xl pv:max-sm:text-2xl font-roboto_regular pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#1b4235]">
                {t("PublicSwiming_Lane")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("PublicSwiming_Lane_Description")}
              </p>
            </div>
            {/* Open */}
            <div className="flex flex-col justify-center py-20 pv:max-lg:p-4 gap-5 pv:max-lg:gap-4 col-span-4 ">
              <p className="text-7xl pv:max-sm:text-2xl font-roboto_regular pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#1b4235]">
                {t("PublicSwiming_Open")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("PublicSwiming_Open_Description")}
              </p>
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-6 flex justify-center">
              <img
                src={ImgopenSwim}
                className="w-full h-auto object-cover mt-14"
              />
            </div>
          </div>
        </div>
      </div>
      {/* end update */}
      <div className="pt-20 pv:max-sm:pt-0 md:max-lg:pt-[95px] lg:max-dh:pt-[95px] ">
        <div className="bg-[#4e858b] w-full py-20 mx-auto flex flex-col gap-10 pv:max-md:gap-6 pv:max-md:py-8">
          <div className=" w-[80%] pl-40 pv:max-md:pl-10">
            <p className="font-roboto_regular text-[#e8d0c3] text-[2.5vw] p-4 pv:max-sm:text-[16px]">
              {t("PublicSwiming_Price")}
            </p>
            <p className="font-roboto_regular text-[#e8d0c3] text-[2.5vw] p-4 pv:max-sm:text-[16px]">
              1X {t("PublicSwiming_ticket")} - VND 150,000 -{" "}
              {t("PublicSwiming_Adult")}
            </p>
            <p className="font-roboto_regular text-[#e8d0c3] text-[2.5vw] p-4 pv:max-sm:text-[16px]">
              1X {t("PublicSwiming_ticket")} - VND 120,000 -{" "}
              {t("PublicSwiming_Child")}
            </p>
            <p className="font-roboto_regular text-[#e8d0c3] text-[2.5vw] p-4 pv:max-sm:text-[16px]">
              10X {t("PublicSwiming_ticket")} - VND 1,200,000 -{" "}
              {t("PublicSwiming_Adult")}
            </p>
            <p className="font-roboto_regular text-[#e8d0c3] text-[2.5vw] p-4 pv:max-sm:text-[16px]">
              10X {t("PublicSwiming_ticket")} - VND 1,000,000 -{" "}
              {t("PublicSwiming_Child")}
            </p>
          </div>
          <div className="w-[90%] mx-auto flex justify-center pb-10 pv:max-md:pb-2">
            <button
              className=" uppercase bg-[#dcd3d1] w-[35vw]  py-4 rounded-full pv:max-ph:rounded-md pv:max-md:w-[70vw]"
              onClick={() => {
                window.location = "mailto:contact@aquaticshub.vn";
                // handleOnclick();
              }}
            >
              <p className="underline text-[2vw] pv:max-md:text-lg">
                {t("PublicSwiming_purchasex10")}
              </p>
            </button>
          </div>

          <div className="w-[90%] mx-auto flex justify-center pb-10 ">
            <button
              className=" uppercase bg-[#dcd3d1] w-[20vw]  py-4 rounded-full pv:max-ph:rounded-md pv:max-md:w-[70vw]"
              onClick={() => {
                navigate("../" + path.HOME);
              }}
            >
              <p className="underline text-[2vw] pv:max-md:text-lg">
                {t("AdultCourse_Home")}
              </p>
            </button>
          </div>
          {/* <div className="">
            <div className="w-[70%] mx-auto md:max-lg:w-[40%] lg:max-xl:w-[80%] py-4">
              <p className="text-5xl pv:max-md:text-3xl text-[#edd3c5]">
                {t("PublicSwiming_OnOffer")}
              </p>
            </div>
            <div className=" w-[70%] mx-auto grid grid-cols-3 gap-10 pv:max-lg:grid-cols-1 md:max-lg:w-[40%] lg:max-xl:w-[80%]">
              {data.nowClass.map((item, index) => {
                return (
                  <>
                    <div className=" bg-white">
                      <img
                        className="h-[350px] w-full object-cover pv:max-ph:h-[250px]"
                        src={item.pictureUrl}
                      ></img>
                      <div className="flex flex-col gap-4 m-4 ">
                        <p className="uppercase text-2xl font-bold">
                          {item.title}
                        </p>
                        <p className="text-lg line-clamp-4">{item.detail}</p>
                        <p className="text-xl">Open In June</p>
                      </div>
                    </div>
                  </>
                );
              })}
              {data.newClass.map((item, index) => {
                return (
                  <>
                    <div className=" bg-white">
                      <img
                        className="h-[350px] w-full object-cover pv:max-ph:h-[250px]"
                        src={item.pictureUrl}
                      ></img>
                      <div className="flex flex-col gap-4 m-4 ">
                        <p className="uppercase text-2xl font-bold">
                          {item.title}
                        </p>
                        <p className="text-lg line-clamp-4">{item.detail}</p>
                        <p className="text-xl uppercase">{t("PublicSwiming_CommingLater")}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="w-[70%] mx-auto flex flex-col gap-10 pt-20 pv:max-md:w-[95%] ">
              <p className=" text-5xl pv:max-md:text-3xl text-[#edd3c5]">
                {t("PublicSwiming_OpenPrice")}
              </p>

              <div className="grid grid-cols-4 w-[100%] mx-auto gap-2">
                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]"></p>
                  </div>
                  {data.cost.level.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        <p className="text-[2vw]">{item}</p>
                      </div>
                    );
                  })}
                </div>

                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]">{t("PublicSwiming_Age")}</p>
                  </div>
                  {data.cost.age.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        {item === "18 - 50" ? (
                          <p className="text-[2vw]">18+</p>
                        ) : (
                          <p className="text-[2vw]">{item}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]">1X {t("PublicSwiming_ticket")}</p>
                  </div>
                  {data.cost.priceSingle.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        {item === 0 ? (
                          <p className="text-[2vw]">{t("PublicSwiming_Free")}</p>
                        ) : (
                          <p className="text-[2vw]">
                            VND {numberWithCommas(item)}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]">10X {t("PublicSwiming_ticket")}</p>
                  </div>
                  {data.cost.pricePlural.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        {item === 0 ? (
                          <p className="text-[2vw]">N/A</p>
                        ) : (
                          <p className="text-[2vw]">
                            VND {numberWithCommas(item)}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-[70%] mx-auto flex flex-col gap-10 pt-20 pv:max-md:w-[95%] ">
              <p className=" text-5xl pv:max-md:text-3xl text-[#edd3c5]">
                {t("PublicSwiming_LanePrice")}
              </p>

              <div className="grid grid-cols-4 w-[100%] mx-auto gap-2">
                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]"></p>
                  </div>
                  {data.cost.level.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        <p className="text-[2vw]">{item}</p>
                      </div>
                    );
                  })}
                </div>

                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]">{t("PublicSwiming_Age")}</p>
                  </div>
                  {data.cost.age.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        {item === "18 - 50" ? (
                          <p className="text-[2vw]">18+</p>
                        ) : (
                          <p className="text-[2vw]">{item}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]">1X {t("PublicSwiming_ticket")}</p>
                  </div>
                  {data.cost.priceSingle.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        {item === 0 ? (
                          <p className="text-[2vw]">{t("PublicSwiming_Free")}</p>
                        ) : (
                          <p className="text-[2vw]">
                            VND {numberWithCommas(item)}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-2">
                  <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                    <p className="text-[2vw]">10X {t("PublicSwiming_ticket")}</p>
                  </div>
                  {data.cost.pricePlural.map((item, index) => {
                    return (
                      <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                        {item === 0 ? (
                          <p className="text-[2vw]">N/A</p>
                        ) : (
                          <p className="text-[2vw]">
                            VND {numberWithCommas(item)}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="w-[90%] mx-auto flex justify-center py-20 pv:max-sm:py-4">
              <button
                className=" uppercase bg-[#dcd3d1] w-[30vw] py-4 rounded-full pv:max-ph:rounded-md"
                onClick={() => handleOnclick()}
              >
                <p className="text-[2vw]">{t("PublicSwiming_Schedule")}</p>
              </button>
            </div>
            <div className="w-[90%] mx-auto flex justify-center pb-10">
              <button
                className=" uppercase bg-[#dcd3d1] w-[30vw]  py-4 rounded-full pv:max-ph:rounded-md"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <p className="underline text-[2vw]">{t("AdultCourse_Home")}</p>
              </button>
            </div>

            <div className="pb-10 w-[90%] mx-auto flex flex-col gap-[10px] items-start ">
              <p className="font-roboto_bold text-white text-[1.5vw] pv:max-sm:text-[16px]">
                {t("PublicSwiming_Policy")}
              </p>
              {isLoading ? (
                <Loading />
              ) : (
                <div className="flex flex-col gap-[5px]">
                  {data?.policy.map((item, index) => {
                    return (
                      <ul className="flex gap-4 items-center list-disc text-[1.2vw] text-white pv:max-sm:w-[90%] mx-auto">
                        <li className="pv:max-sm:text-[12px]">{item}</li>
                      </ul>
                    );
                  })}
                </div>
              )}
            </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PublicSwimming);
