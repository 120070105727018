/** @format */

import React, { useState, useEffect } from "react";
import imgfasttrack_1 from "../../../../assets/week_fasttrack_1.png";
import imgfasttrack_2 from "../../../../assets/week_fasttrack_2.png";
import imgfasttrack_3 from "../../../../assets/week_fasttrack_3.png";
import { Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { apiThirdStage } from "../../../../services/stageDetails";
import {
  path,
  encryptString,
  formatCurrency,
  courseObject,
} from "../../../../utils/constant";
import { Loading, TimeTable } from "../../../../components";
import BreakTimeImg from "../../../../assets/breaktime.png";

const organizeData = (data, mainAttribute, columnAttribute) => {
  const organized = {};
  data.forEach((item) => {
    if (!organized[item[mainAttribute]]) {
      organized[item[mainAttribute]] = {};
    }
    organized[item[mainAttribute]][item[columnAttribute]] = item;
  });

  return organized;
};

const TableComponent = ({
  data = [],
  columns,
  mainAttribute,
  columnAttribute,
  handleClick,
  Weekday,
  className,
}) => {
  const organizedData = organizeData(data, mainAttribute, columnAttribute);

  const renderHeader = () => {
    return (
      <tr>
        <th className="px-6 pv:max-md:px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base pv:max-md:text-[14px] text-gray-700 uppercase tracking-wider text-secondary1 font-roboto_bold">
          {Weekday}
        </th>
        {columns.map((column) => (
          <th
            className="px-6 pv:max-md:px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base pv:max-md:text-[14px] font-semibold text-gray-700 uppercase tracking-wider"
            key={column}
          ></th>
        ))}
      </tr>
    );
  };

  const renderRows = () => {
    return Object.keys(organizedData).map((time) => (
      <tr
        key={time}
        className="border-b-4 border-gray-300 w-[150px] font-roboto_bold"
      >
        <td className="w-[150px] font-roboto_bold pv:max-md:w-[30px]">
          <div className="md:hidden pv:max-sm:text-[8px] sm:max-lg:text-[12px]">
            {
              <p className="text-center  pv:max-md:text-[12px]">
                {time.split("-")[0]}
              </p>
            }
            {
              <p className="text-center pv:max-md:text-[12px]">
                {time.split("-")[1]}
              </p>
            }
          </div>
          <div className="pv:max-md:hidden ">
            {<p className="text-center pv:max-md:text-[10px]">{time}</p>}
          </div>
        </td>
        {columns.map((column) => {
          const element = organizedData[time][column];
          return (
            <td
              key={column}
              onClick={() => {
                if (element) {
                  handleClick(element);
                }
              }}
              className="w-[30%] max-w-[30%]"
            >
              {element ? (
                <div className="border-[1px] border-gray-200 rounded-xl m-4 pv:max-lg:m-1 cursor-pointer">
                  {/* <img className="" src={element?.data.pictureUrl}></img> */}
                  <div className="relative ">
                    <label className="bg-blue-300 px-4 py-2 absolute bottom-4  right-4 rounded-md ">
                      {formatCurrency(element?.data.price)}
                    </label>
                    <img
                      className="w-full h-[200px] object-cover rounded-t-xl pv:max-md:hidden"
                      src={element?.data.pictureUrl}
                    ></img>
                  </div>

                  <div className="p-2">
                    <div className="flex justify-around">
                      <p className="text-[16px] font-roboto_bold pv:max-lg:text-[10px]">
                        {element?.data.description}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <>
      {data.length > 0 ? (
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden mb-10 mt-4">
          <table className="w-full">
            <thead>{renderHeader()}</thead>
            <tbody>{renderRows()}</tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const FastTracks = () => {
  const [week, setWeek] = useState("1");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };

  const location = useLocation();
  const dataState = location.state.data;

  const handleNavigate = (record) => {
    navigate(
      "../" + path.COURSEDETAIL + `/${encryptString({ str: record.key })}`,
      {
        state: {
          // UserID: dataState.UserID ?? "",
          // UserType: dataState.UserType ?? "",
          EnrollmentType: "Fasttrack",

          enrollment: {
            isFasttrack: true,
            Number: record.key,
            selectedUserInAttendanceList:
              record.data.selectedUserInAttendanceList,
            StartDate:
              record.data.timeTable.length > 0
                ? record.data.timeTable[0].timeTableDay
                : "-",
            EndDate:
              record.data.timeTable.length > 0
                ? record.data.timeTable[record.data.timeTable.length - 1]
                    .timeTableDay
                : "-",
            Enrolled: record.data.enrolled,
            PictureUrl: record.data.pictureUrl,
            Description: record.data.description,
            Price: record.data.price,
            Timetable: record.data.timeTable,

            Capacity: record.data.capacity,
            Lecture: {
              Code: record.data.lectureInformation.code,
              Description: record.data.lectureInformation.description,
              PictureUrl: record.data.lectureInformation.pictureUrl,
            },
            Duration: record.data.duration,
          },
        },
      }
    );
  };

  const [dataTimetable, setDataTimeTable] = useState({
    dailyAM: [],
    dailyPM: [],
  });

  useEffect(() => {
    apiThirdStage("Fasttrack")
      .then((response) => {
        let AMArr = [];
        let PMArr = [];

        if (response.data.data.class.length > 0) {
          for (
            let index = 0;
            index < response.data.data.class.length;
            index++
          ) {
            const element = response.data.data.class[index];

            if (element.timeTable.length > 0) {
              let Lane = "";
              switch (element.area[0].description.slice(-1)) {
                case "1":
                  Lane = "Lane_1&2";
                  break;
                case "3":
                  Lane = "Lane_3&4";
                  break;
                case "5":
                  Lane = "Lane_5&6";
                  break;

                default:
                  break;
              }

              switch (element.startTime.trim().slice(-2)) {
                case "AM":
                  AMArr = [
                    ...AMArr,
                    {
                      key: element.number,
                      //concat startTime/ endTime string from "8:00:00 AM" to "8:00"
                      time: `${element.startTime
                        .trim()
                        .split(" ")[0]
                        .slice(0, -3)} - ${element.endTime
                        .trim()
                        .split(" ")[0]
                        .slice(0, -3)}`,
                      Description: element.description,
                      Lane: Lane,
                      level: `${
                        response.data.data.level[`level${element.level}`].name
                      }`,
                      data: element,
                    },
                  ];
                  break;
                case "PM":
                  PMArr = [
                    ...PMArr,
                    {
                      key: element.number,
                      //concat startTime/ endTime string from "8:00:00 AM" to "8:00"
                      time: `${element.startTime
                        .trim()
                        .split(" ")[0]
                        .slice(0, -3)} - ${element.endTime
                        .trim()
                        .split(" ")[0]
                        .slice(0, -3)}`,
                      Description: element.description,
                      Lane: Lane,
                      level: `${
                        response.data.data.level[`level${element.level}`].name
                      }`,
                      data: element,
                    },
                  ];
                  break;

                default:
                  break;
              }
            }
          }
        }

        setDataTimeTable({
          dailyAM: AMArr,
          dailyPM: PMArr,
        });

        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(true);
      });
  }, []);

  const columns = ["Lane_1&2", "Lane_3&4", "Lane_5&6"];
  const mockDataFasttrackAM = [
    {
      time: "09:00 - 09:40",
      Description: "STARFISH ",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(1).jpg",
        description: "STARFISH",
      },
    },
    {
      time: "09:00 - 09:40",
      Description: "SEAHORSE",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(8).jpg",
        description: "SEAHORSE",
      },
    },
    {
      time: "09:40 - 10:20",
      Description: "STARFISH ",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(2).jpg",
        description: "STARFISH",
      },
    },
    {
      time: "09:40 - 10:20",
      Description: "SEAHORSE",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(28).jpg",
        description: "SEAHORSE",
      },
    },
    {
      time: "09:40 - 10:20",
      Description: "SEAL",
      Lane: "Lane_5&6",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(29).jpg",
        description: "SEAL",
      },
    },
    {
      time: "10:20 - 11:00",
      Description: "SEAL ",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(1).jpg",
        description: "SEAL",
      },
    },
    {
      time: "10:20 - 11:00",
      Description: "BARRACUDA",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(13).jpg",
        description: "BARRACUDA",
      },
    },
    {
      time: "11:00 - 11:40",
      Description: "BARRACUDA",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(19).jpg",
        description: "BARRACUDA",
      },
    },
    {
      time: "11:00 - 11:40",
      Description: "SWORDFISH",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(8).jpg",
        description: "SWORDFISH",
      },
    },
    {
      time: "11:00 - 11:40",
      Description: "DOLPHIN",
      Lane: "Lane_5&6",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(19).jpg",
        description: "DOLPHIN",
      },
    },
    {
      time: "11:40 - 12:20",
      Description: "STARFISH",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(1).jpg",
        description: "STARFISH",
      },
    },
    {
      time: "11:40 - 12:20",
      Description: "SEAHORSE",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(18).jpg",
        description: "SEAHORSE",
      },
    },
    {
      time: "11:40 - 12:20",
      Description: "SEAL",
      Lane: "Lane_5&6",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(1).jpg",
        description: "SEAL",
      },
    },
  ];

  const mockDataFasttrackPM = [
    {
      time: "13:20 - 14:00",
      Description: "BARRACUDA",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(2).jpg",
        description: "BARRACUDA",
      },
    },
    {
      time: "13:20 - 14:00",
      Description: "SWORDFISH",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(1).jpg",
        description: "SWORDFISH",
      },
    },
    {
      time: "13:20 - 14:00",
      Description: "DOLPHIN & SHARK",
      Lane: "Lane_5&6",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(8).jpg",
        description: "DOLPHIN & SHARK",
      },
    },
    {
      time: "14:00 - 14:40",
      Description: "STARFISH",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(2).jpg",
        description: "STARFISH",
      },
    },
    {
      time: "14:00 - 14:40",
      Description: "SEAHORSE",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(18).jpg",
        description: "SEAHORSE",
      },
    },
    {
      time: "14:40 - 15:20",
      Description: "STARFISH",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(1).jpg",
        description: "STARFISH",
      },
    },
    {
      time: "14:40 - 15:20",
      Description: "SEAHORSE",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(18).jpg",
        description: "SEAHORSE",
      },
    },
    {
      time: "14:40 - 15:20",
      Description: "SEAL",
      Lane: "Lane_5&6",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(2).jpg",
        description: "SEAL",
      },
    },
    {
      time: "15:20 - 16:00",
      Description: "SEAL",
      Lane: "Lane_1&2",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(18).jpg",
        description: "SEAL",
      },
    },
    {
      time: "15:20 - 16:00",
      Description: "BARRACUDA",
      Lane: "Lane_3&4",
      data: {
        pictureUrl:
          "http://103.157.218.115/AquaticPicture/v3.0.0/swimming%20(8).jpg",
        description: "BARRACUDA",
      },
    },
  ];

  return (
    <div>
      <div className="w-full relative">
        <img
          alt=""
          className="w-full object-cover h-[70vh]"
          src={dataState.img}
        ></img>
        <div className="z-10 absolute top-[30%] left-[5%]  w-[400px] pv:max-ph:w-[300px] ">
          <p className=" text-start uppercase font-bold text-white text-5xl   pv:max-md:left-[-10%] w-[100%]  pv:max-md:text-xl  pv:max-md:top-[50%] pb-8">
            {dataState.title}
          </p>
        </div>

        <div className="w-full h-full bg-gradient-to-t from-slate-800 absolute top-0 left-0  "></div>
      </div>

      <div className="pt-10">
        <div className="bg-white w-[90%] mx-auto">
          <div className="pb-10 pv:max-sm:pt-0">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold">Timetable</p>
            </div>
            <div className="w-[100%] mx-auto flex flex-row justify-start items-start relative">
              {isLoading ? (
                <Loading />
              ) : (
                <div className="w-full h-fit mx-auto flex-cols justify-around sticky top-24">
                  <TableComponent
                    data={dataTimetable.dailyAM}
                    columns={columns}
                    mainAttribute="time"
                    columnAttribute="Lane"
                    Weekday={"Daily AM"}
                    handleClick={handleNavigate}
                  />
                  <TableComponent
                    data={dataTimetable.dailyPM}
                    columns={columns}
                    mainAttribute="time"
                    columnAttribute="Lane"
                    Weekday={"Daily PM"}
                    handleClick={handleNavigate}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="py-20">
        <div className="flex justify-center">
          <button
            className="text-2xl hover:text-white text-[#5f5be4]  rounded-2xl px-6 py-4 border-2 border-[#5f5be4] hover:bg-[#5f5be4]"
            onClick={() => {
              goback();
            }}
          >
            <p className=" font-roboto_bold ">Previous page</p>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default FastTracks;
