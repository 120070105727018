/** @format */

import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "../components";
import PublicLayout from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";
import PublicRoutes from "../utils/routes/public";
import { routes as PrivateRoutes, allowedRoles } from "../utils/routes/private";
import { path } from "../utils/constant";
import { NotAuthorizePage, Login } from "../pages";
import { useState } from "react";

const DefaultLayout = () => {
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserInformation"))
  );
  const handeSetUser = (userJson) => {
    setUser(userJson);
  };

  //Routes
  const showPublicMenu = (routes) => {
    var result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={route.main()}
            exact={route.exact}
          ></Route>
        );
      });
    }
    return result;
  };

  const showPrivateMenu = (routes) => {
    var result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <ProtectedRoute
                isAllowed={
                  user
                    ? route.role
                      ? route.role.includes(user.role)
                      : false
                    : false
                }
                redirectPath={`../${path.NOTAUTH}`}
              >
                {route.main()}
              </ProtectedRoute>
            }
            exact={route.exact}
          ></Route>
        );
      });
    }
    return result;
  };

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        {showPublicMenu(PublicRoutes)}
        <Route
          path={path.LOGIN}
          element={<Login onSetUser={handeSetUser} />}
          exact={true}
        />
      </Route>

      <Route
        element={
          <ProtectedRoute
            isAllowed={user ? allowedRoles.includes(user.role) : false}
          >
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path={`${path.NOTAUTH}`} element={<NotAuthorizePage />} />
        {showPrivateMenu(PrivateRoutes)}
      </Route>
    </Routes>
  );
};

export default DefaultLayout;
