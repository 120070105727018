import React, { useState } from "react";
import { Modal, Input, Button, notification, DatePicker, Select } from "antd";
import { withTranslation } from "react-i18next";
import { apiEditProfile } from "../../services/usermember";
import { useNavigate } from "react-router-dom";
import { path, convertDateString } from "../../utils/constant";
import { date } from "yup";
import dayjs from "dayjs";

const ModalEditUserProfile = ({
  t,
  open,
  onOk,
  onCancel,
  userData,
  months,
  userType,
}) => {
  const [formData, setFormData] = useState({
    userId: userData?.code,
    userType: userType,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    address: userData?.address,
    gender: userData?.gender,
    dateOfBirth: convertDateString(userData?.dateOfBirth),
  });

  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={open}
        title={
          <div className="flex justify-center ">
            <p className="text-blue-500 font-bold text-3xl">Edit Information</p>
          </div>
        }
        onOk={onOk}
        onCancel={onCancel}
        footer={[
          <Button
            type="primary"
            onClick={async () => {
              await apiEditProfile(formData)
                .then((res) => {
                  if (res.status == 200) {
                    notification.success({
                      message: "Edit user information successfully",
                      placement: "topRight",
                      duration: 2,
                    });
                    onOk();
                  }
                })
                .catch((err) => {
                  notification.error({
                    message:
                      "Can't Edit user information, please contact admin",
                    placement: "topRight",
                    duration: 2,
                  });
                });
            }}
          >
            Change
          </Button>,
        ]}
      >
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 items-center">
            <label>
              <span className="block mb-1">Gender</span>
              <Select
                value={formData.gender}
                size="large"
                className="w-[100%] md:w-[120px] h-10 "
                options={[
                  {
                    value: "Male",
                    label: (
                      <div className="flex gap-[10px] items-center">Male</div>
                    ),
                  },
                  {
                    value: "Female",
                    label: (
                      <div className="flex gap-[10px] items-center">Female</div>
                    ),
                  },
                  {
                    value: "Other",
                    label: (
                      <div className="flex gap-[10px] items-center">Other</div>
                    ),
                  },
                ]}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    gender: e,
                  })
                }
              />
            </label>
            <label>
              <span className="block mb-1">Date Of birth</span>
              <DatePicker
                className="h-auto"
                value={dayjs(formData?.dateOfBirth, "YYYYMMDD")}
                onChange={(e) => {
                  if (e != null) {
                    if (e.$D < 10) {
                      setFormData({
                        ...formData,
                        dateOfBirth: `${e.$y}${months[String(e.$M)].MonthNum}0${
                          e.$D
                        }`,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        dateOfBirth: `${e.$y}${months[String(e.$M)].MonthNum}${
                          e.$D
                        }`,
                      });
                    }
                  }
                }}
              />
            </label>
          </div>
          <label>
            <span className="block mb-1">First Name</span>
            <Input
              className=""
              placeholder="Enter Full Name"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  firstName: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span className="block mb-1">Last Name</span>
            <Input
              className=""
              placeholder="Enter Full Name"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  lastName: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span className="block mb-1">Address</span>
            <Input
              className=""
              placeholder="Enter Address"
              value={formData.address}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  address: e.target.value,
                })
              }
            />
          </label>
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ModalEditUserProfile);
