/** @format */

import { Outlet } from "react-router-dom";
import {
  PrivateHeader,
  PrivateSidebar,
  Footer,
  // BackToTopButton,
  PublicHeader,
} from "../components";

const PrivateLayout = () => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  return (
    <>
      {user.role === "user" ? (
        <div className="w-full mx-auto h-full bg-primary  ">
          <div className="w-full  sticky top-0 z-10  ">
            <PublicHeader />
            {/* <Navigation /> */}
          </div>
          <div className="w-full mx-auto">
            <Outlet />
          </div>
          <Footer />
          {/* <BackToTopButton /> */}
        </div>
      ) : (
        <>
          <div className="w-full">
            <PrivateHeader />
          </div>
          <div className={`flex `}>
            <PrivateSidebar />
            <Outlet />
          </div>
          <div className="">
            <Footer />
          </div>
          {/* <BackToTopButton /> */}
        </>
      )}
    </>
  );
};

export default PrivateLayout;
