/** @format */

import React, { useState, useEffect } from "react";
import Imgbanner from "../../../assets/MainBanner_v1.png";
import ImgAquarium from "../../../assets/home_aquarium.png";
import ImgScheduleMaster from "../../../assets/Final_PIctures/Schedule_master.png";
import ImgCommingSoon from "../../../assets/CommingSoon.png";
import ImageSchoolGroup01 from "../../../assets/schoolgroup_01.webp";
import ImageSchoolGroup02 from "../../../assets/schoolgroup_02.webp";
import ImageSchoolGroup03 from "../../../assets/schoolgroup_03.webp";
// import

import {
  FlashCardList,
  CardPool,
  CardLecture,
  CardSchoolGroup,
  Loading,
} from "../../../components";
import ImageQS03 from "../../../assets/qs3.jpg";
import ImageQS02 from "../../../assets/qs2.jpg";
import { ChooseLesson, JoinToDay } from "./Sections";
import { useNavigate } from "react-router-dom";
import { path } from "../../../utils/constant";

//Multi-lang
import { withTranslation, useTranslation } from "react-i18next";
import { apigetMetadata } from "../../../services/metadata";

const HomePage = (props) => {
  //Multi Lang
  const { t } = props;
  const { i18n } = useTranslation();

  // Define Navigation
  const navigate = useNavigate();
  const handlefullListPool = () => {
    navigate("../" + path.RENTALPOOLLANE);
  };

  // Define Data List
  const [data, setData] = useState({});
  const [isLoadData, setIsLoadData] = useState({});

  useEffect(() => {
    const fetchMetaData = async () => {
      const response = await apigetMetadata();

      if (response?.status === 200) {
        setData(response.data.data);
        setIsLoadData(false);
      }
    };
    fetchMetaData();
  }, []);
  const handleCardSchoolGroupClick = (price, title, type, imageUrl) => {
    navigate("../" + path.RENTALPOOLLANE, {
      state: {
        img: imageUrl,
        title: title,
        description:
          "Aquatics Hub offers more than just a pool; it's you aquatic canvas for events, parties, and even personal training. Rent our versatile 8-lane, 25m pool for everything from celebrations to year-around lane rentals.",
        price: price,
        type: type,
      },
    });
  };

  return (
    <>
      <div className="h-auto bg-[#F7F7F7] pv:max:max-sm:pt-[90px]">
        <div
          className={`relative bg-coverh-full h-[85vh] w-full  bg-no-repeat  text-black flex flex-col items-center justify-center gap-6  pv:max-pvmax:h-[20vh]  sm:max-md:bg-cover sm:max-md:bg-center sm:max-md:bg-local sm:max-md:h-[380px]  md:max-lg:bg-cover md:max-lg:bg-center lg:max-xl:bg-cover  xl:max-2xl:bg-cover xl:max-2xl:h-[700px] `}
        >
          <img className="h-full w-full  object-cover" src={Imgbanner}></img>
        </div>
        <div className="bg-black py-10 ">
          <div className=" flex justify-center items-center mx-auto pv:max-md:grid-cols-1 pv:max-md:grid pv:max-md:w-[90%] xl:max-2xl:w-[90%] 2xl:w-[65%] lg:max-xl:w-[90%] md:max-lg:flex-col md:max-lg:items-start md:max-lg:w-[90%] md:max-lg:gap-6">
            <div className="pv:max-md:py-4">
              <p className=" text-start pv:max-md:text-start pv:max-ph:text-2xl sm:max-md:text-5xl sm:max-md:w-[80%]  text-white font-roboto_bold text-[48px]  md:max-lg:text-white    ">
                {t("HomePage_BannerTitle")}
              </p>
              <p className="text-start pv:max-md:text-start text-white text-3xl pv:max-ph:text-xl">
                QUANG AN, TAY HO, HA NOI
              </p>
            </div>
            <p className="text-start pv:max-md:text-start text-white text-[28px] w-full mx-auto pv:max-ph:text-lg ">
              {t("Homepage_WelcomeBanner")}
            </p>
          </div>
        </div>
        {/* Schedule Master  */}

        <div className="py-10 flex flex-col gap-6 pv:max-md:py-0 pv:max-md:gap-0">
          <div className="w-[65%] pv:max-md:w-[90%] xl:max-2xl:w-[90%] 2xl:w-[65%] lg:max-xl:w-[90%] md:max-lg:w-[90%] mx-auto">
            <p className="uppercase text-5xl text-[#1b4235] pv:max-md:text-xl md:max-lg:text-2xl">
              {/* Schedule - from monday 19th august 2024 */}
              {t("HomePage_schedule")}
            </p>
          </div>
          <div className="w-full">
            <img
              className="w-[65%] pv:max-md:w-[90%] xl:max-2xl:w-[90%] 2xl:w-[65%] md:max-lg:w-[90%] lg:max-xl:w-[90%] mx-auto"
              src={ImgScheduleMaster}
            ></img>
          </div>
        </div>
        {isLoadData ? (
          <Loading />
        ) : (
          <ChooseLesson
            dataColor={data.color}
            dataLessonChoose={data.metadata}
          />
        )}
      </div>
    </>
  );
};

export default withTranslation()(HomePage);
