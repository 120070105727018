/** @format */
import axiosConfig from "../axiosConfig";
import { apiPost } from "./";

export const apiusermember = async (payload) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/v1/user-member",
      data: payload,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const apiAddChildMember = async (payload) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/v1/admin-create-child",
      data: payload,
    });

    return response;
  } catch (error) {
    return error;
  }
};
export const apiAddadultMember = async (payload) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/v1/admin-create-adult",
      data: payload,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const apiGetUserEnrollment = async () => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const response = await apiPost({
    endpoint: "/v1/user-get-enrollment-history",
    payload: {
      uuid: user ? user.uuid : "",
    },
  });

  return response;
};

export const apienrollmentRefresh = async () => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const response = await apiPost({
    endpoint: "/v1/user-enrollment-refresh",
    payload: {
      uuid: user ? user.uuid : "",
    },
  });

  return response;
};

export const apiusermemberEnrollment = async () => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const response = await apiPost({
    endpoint: "/v1/user-member-enrollment",
    payload: {
      uuid: user ? user.uuid : "",
    },
  });

  return response;
};

export const apiEditProfile = async (payload) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/v1/user-edit-profile",
      data: payload,
    });

    return response;
  } catch (error) {
    return error;
  }
};
