/** @format */

import React, { useEffect, useState } from "react";
import { Table, Drawer, Checkbox } from "antd";
import imgdiamondmaster from "../../../../assets/Diamond_master.png";
import imgdiamondbeginner from "../../../../assets/Diamond_beginer.png";
import imgdiamondtechnique from "../../../../assets/Diamond_technique.png";
import imageFutureCourse01 from "../../../../assets/img_1.jpg";
import { apisecondstage } from "../../../../services/secondstage";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrency, path } from "../../../../utils/constant";
import Levels from "./Sections/Levels";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { CardFutureCourse } from "../../../../components";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { withTranslation, useTranslation } from "react-i18next";
import masterpic from "../../../../assets/adultswimmingpc3.png";
import beginpic from "../../../../assets/adultswimmingpc1.png";
import techniquepic from "../../../../assets/adultswimmingpc2.jpg";
import CardSession from "../../../../components/Card/CardSessionV2";
const AdultCourse = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();

  const location = useLocation();
  const dataState = location.state.data;
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const handleClick = () => {
    const newDataState = {
      ...dataState,
      UserID: user?.code,
      UserType: "main",
    };
    navigate("../" + path.ADULTCOURSES, {
      state: {
        data: newDataState,
      },
    });
  };

  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  function truncateText(text, maxLength = 230, suffix = "...") {
    return text.length > maxLength
      ? text.substring(0, maxLength) + suffix
      : text;
  }
  const [data, setData] = useState({
    description: "",
    class: [
      {
        code: "",
        description: "",
        detail: "",
        lessonUrl: "",
        level: "",
        pictureUrl: "",
        lessonUrl: "",
        price: "",
        type: "",
      },
    ],
    level: {
      level1: {
        name: "",
        color: "",
        detail: "",
      },
      level2: {
        name: "",
        color: "",
        detail: "",
      },
      level3: {
        name: "",
        color: "",
        detail: "",
      },
      level4: {
        name: "",
        color: "",
        detail: "",
      },
    },
    nowClass: [
      {
        title: " ",
        detail: "",
        pictureUrl: "",
      },
    ],
    upcomming: [],
    pictureUrl: "",
  });

  const getSize = () => {
    setWidth(window.innerWidth);
  };

  // break point resize sidebar   // service
  useEffect(() => {
    const fetchSecondStage = async () => {
      const response = await apisecondstage("Adult");

      if (response?.status === 201 || response?.status === 200) {
        setData(response.data.data);
      }
    };
    fetchSecondStage();
    window.addEventListener("resize", getSize);
    if (width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => {
      window.removeEventListener("resize", getSize);
    };
  }, [window.innerWidth]);

  const futureCoursesData = [
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
    {
      img: imageFutureCourse01,
      title: "COMMING SOON JAN 2025",
      subTitle: "SCHOOL OF FISH",
      description:
        "Our swimming lessons follow the School of Fish method, focusing on stroke technique. Developed by expert instructors, this method breaks learning into manageable steps, offering a structured curriculum for accelerated skill development.",
      price: 500000,
    },
  ];

  // Update Here
  const [selectedCard, setSelectedCard] = useState(null);

  const sessions = [
    {
      startDate: "26 AUGUST 2024",
      finishDate: "20 OCTOBER 2024",
      session: "1",
      time: "8 WEEK SESSION",
      price: "VND 2,400,000",
      available: true,
      data: dataState,
      path: path.ADULTCOURSES,
    },
    {
      startDate: "28 OCTOBER 2024",
      finishDate: "22 DECEMBER 2024​",
      session: "2",
      time: "8 WEEK SESSION",
      price: "VND 2,400,000",
      available: false,
    },
    {
      startDate: "6 JANUARY 2025",
      finishDate: "23 MARCH 2025",
      session: "3",
      time: "10 WEEK SESSION",
      price: "VND 3,000,000 ​",
      available: false,
    },
    {
      startDate: "31 MARCH​ 2025",
      finishDate: "1 JUNE 2​025",
      session: "4",
      time: "10 WEEK SESSION",
      price: "VND 3,000,000 ​",
      available: false,
    },
  ];
  return (
    <div>
      {/* <div className="w-full relative pv:max-sm:pt-0 ">
        <div className="w-full bg-no-repeat bg-contain ">
          <div className="grid grid-cols-5 w-[80%] mx-auto py-8 pv:max-md:grid-cols-1 pv:max-md:w-[95%] pt-[180px] pv:max-md:pt-0">
            <div className="flex flex-col justify-center p-10 pv:max-lg:p-4 gap-10 pv:max-lg:gap-4 col-span-2 ">
              <p className="text-[#1b4235] text-6xl font-roboto_bold text-start pv:max-lg:text-start pv:max-md:text-2xl md:max-xl:text-4xl">
                {dataState.title}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {data?.description}
              </p>
              <div className="">
                <button className="bg-[#dcd3d1] px-10 py-4  md:max-lg:px-0 md:max-lg:w-[200px]  rounded-full hover:text-white hover:bg-secondary1 pv:max-ph:px-10 pv:max-ph:py-4">
                  <a
                    className="text-2xl underline md:max-xl:text-lg pv:max-md:text-lg"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc16wK8SzqIjmjkMpUxTYCMcSjqN1ql_FNtn7loM0nuxlEAJQ/viewform"
                  >
                    {t("AdultCourse_register")}
                  </a>
                </button>
              </div>
            </div>
            <img
              src={data.pictureUrl}
              className="w-full h-[500px] object-cover col-span-3 pv:max-ph:h-[50vh] md:max-lg:h-[400px]"
            />
          </div>
        </div>
      </div> */}
      {/* new update */}

      <div className="w-full relative pv:max-sm:pt-0 mt-20 ">
        <div className="w-full bg-no-repeat bg-contain ">
          <div className="items-center grid grid-cols-11 w-[75%] mx-auto py-8 pv:max-md:grid-cols-1 pv:max-md:w-[95%] pv:max-md:pt-0">
            {/* Begin */}
            <div className="flex flex-col justify-center py-20 pv:max-lg:p-4 gap-5 pv:max-lg:gap-4 col-span-4">
              <p className="text-7xl pv:max-sm:text-2xl pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#1b4235]">
                {t("AdultCourse_beginner")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_beginner_Description")}
              </p>
              <p className="text-2xl font-bold pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_weekly")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_timely")}
              </p>
            </div>
            <div className="col-span-1"></div>

            <div className="col-span-6 flex pv:justify-center">
              <img src={beginpic} className="w-full h-auto object-cover" />
            </div>
            {/* technique */}
            <div className="col-span-6 flex pv:max-md:justify-center py-10">
              <img src={techniquepic} className="w-full h-auto object-cover" />
            </div>
            <div className="col-span-1"></div>

            <div className="flex flex-col justify-center py-20 pv:max-lg:p-4 gap-5 pv:max-lg:gap-4 col-span-4 ">
              <p className="text-7xl pv:max-sm:text-2xl font-roboto_regular pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#1b4235]">
                {t("AdultCourse_Technique")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_Technique_Description")}
              </p>
              <p className="text-2xl font-bold pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_weekly")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_timely")}
              </p>
            </div>
            {/* Master */}
            <div className="flex flex-col justify-center py-20 pv:max-lg:p-4 gap-5 pv:max-lg:gap-4 col-span-4 ">
              <p className="text-7xl pv:max-sm:text-2xl font-roboto_regular pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#1b4235]">
                {t("AdultCourse_Master")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_Master_Description")}
              </p>
              <p className="text-2xl font-bold pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_weekly")}
              </p>
              <p className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
                {t("AdultCourse_timely")}
              </p>
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-6 flex justify-center">
              <img src={masterpic} className="w-full h-auto object-cover " />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#4e858b] py-10 pv:max-ph:py-0">
        <div className=" w-[95%] mx-auto mt-4 pv:max-sm:w-[100%]  flex flex-col items-center">
          <div className="w-[65%] pv:max-ph:w-[70%] ph:max-sm:w-[60%]  lg:max-xl:w-[80%] ph:max-md:w-[60%] md:max-lg:w-[60%]    ">
            <p className="text-7xl pv:max-sm:text-3xl font-roboto_regular pv:max-lg:text-start pv:max-md:text-4xl md:max-xl:text-4xl text-[#dcd3d1]">
              {t("Swimming_Session")}
            </p>

            <div className="mt-4 mx-auto  grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-4   ">
              {sessions.map((session, index) => (
                <CardSession
                  key={index}
                  startDate={session.startDate}
                  finishDate={session.finishDate}
                  session={session.session}
                  time={session.time}
                  price={session.price}
                  isSelected={selectedCard === index}
                  onSelect={() => setSelectedCard(index)}
                  available={session.available}
                  data={session.data}
                  path={session.path}
                />
              ))}
              {/* <button
                className=" uppercase bg-[#dcd3d1] w-full py-6 rounded-full pv:max-ph:rounded-md"
                onClick={() =>
                  navigate("../" + path.ADULTCOURSES, {
                    state: {
                      data: dataState,
                    },
                  })
                }
              >
                <p className="underline lg:text-[1.2vw] md:text-sm sm:text-base">
                  BOOK NOW
                </p>
              </button> */}
            </div>
          </div>
          {/* <div className="py-5">
            <div className="py-10 flex justify-start w-[70%] lg:max-xl:w-[80%] ph:max-md:w-[60%]  mx-auto pv:max-ph:w-[80%]">
              <p className="text-6xl pv:max-sm:text-2xl font-roboto_regular text-[#edd3c5]">
                {t("AdultCourse_CominginJune")}
              </p>
            </div>

            <div className="w-[70%] mx-auto grid grid-cols-1 lg:max-xl:w-[80%] ph:max-md:w-[60%] md:max-lg:w-[90%]    md:grid-cols-2 lg:grid-cols-3 gap-10 pv:max-ph:w-[80%]">
              {data.nowClass.map((item, index) => {
                return (
                  <div className=" justify-center bg-white  cursor-pointer">
                    <img
                      className="h-[300px] w-full object-cover pv:max-ph:h-[250px]"
                      src={item.pictureUrl}
                    ></img>
                    <div className="m-4">
                      <p className="text-[#1b4235] font-bold text-3xl pv:max-md:text-2xl md:max-lg:text-2xl ">
                        {item.title}
                      </p>
                      <p className="text-[#391b0b] text-2xl line-clamp-6 pv:max-md:text-[16px] md:max-lg:text-lg lg:max-xl:text-xl">
                        {item.detail}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
          {/* 
          <div className="py-5">
            <div className="py-10 flex justify-start w-[70%] lg:max-xl:w-[80%] ph:max-md:w-[60%] mx-auto pv:max-ph:w-[80%]">
              <p className="text-6xl pv:max-sm:text-2xl font-roboto_regular text-[#edd3c5]">
                {t("AdultCourse_FutureCourse")}
              </p>
            </div>

            <div className="w-[70%] mx-auto grid grid-cols-1 lg:max-xl:w-[80%] ph:max-md:w-[60%]   md:max-lg:w-[90%]    md:grid-cols-2 lg:grid-cols-3 gap-10 pv:max-ph:w-[80%]">
              {data.upcomming?.map((futureCourse, index) => {
                return (
                  <CardFutureCourse
                    key={index}
                    img={futureCourse.pictureUrl}
                    subTitle={futureCourse.title}
                    description={futureCourse.detail}
                  />
                );
              })}
            </div>
          </div> */}
        </div>
        {/* <div className="py-20">
          <div className="w-[90%] mx-auto flex  gap-10 justify-center pb-10 pv:max-ph:gap-4">
            <button
              className="w-[150px] ph:max-lg:w-[45%] pv:max-ph:w-[50%] p-4 px-2 uppercase bg-[#dcd3d1] rounded-lg text-black hover:bg-secondary2 hover:text-white underline pv:max-ph:text-[13px]"
              onClick={() => {
                navigate("../" + path.HOME);
              }}
            >
              {t("AdultCourse_Home")}
            </button>
            <button
              className="w-[150px] ph:max-lg:w-[45%] pv:max-ph:w-[50%] p-4 px-2 uppercase bg-[#dcd3d1] rounded-lg  text-black hover:bg-secondary2 hover:text-white underline pv:max-ph:text-[13px]"
              onClick={() => {
                handleClick();
              }}
            >
              {t("AdultCourse_register")}
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default withTranslation()(AdultCourse);
