/** @format */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as CryptoJS from "crypto-js";
import { Icon } from "@iconify/react";
// import { differenceInYears, parse } from "date-fns";
import { Tabs } from "antd";

export const path = {
  // public
  HOME: "/",
  ERROR: "*",
  NOTAUTH: "authorize",
  LOGIN: "login",
  PUBLICALLCOURSE: "public-all-course",
  POOLALL: "pool-all",
  LISTCOURSESEARCH: "list-course-search",
  CONTACTUS: "contact-us",
  POLICY: "policy",
  // student web
  MYLEARNING: "my-learning",
  USERPROFILE: "user-profile",
  CHECKOUT: "checkout",
  POOLDETAIL: "pool-detail",
  TEACHERPROFILE: "teacher-profile",
  COURSEDETAIL: "course-detail",
  CHECKOUTEMBLED: "checkout-embled",
  COURSEENROLLDETAIL: "course-enroll-detail",
  QRWALLET: "my-qr-wallet",
  QRWALLETDETAIL: "my-qr-walletdetail",
  CHECKOUTSUCCESSVNPAY: "checkout-vnpay",
  SERVICECOURSE: "service-course",
  // Instructor web
  INSTRUCTORPROFILE: "instructor-profile",
  STUDENTLIST: "student-list",
  STUDENTPROFILE: "student-profile",
  ALLCOURSE: "all-courses",
  INSTRUCTORCALENDAR: "instructor-calendar",
  INSTRUCTORCOURSEDETAIL: "instructor-course-detail",
  INSTRUCTORHOME: "home-instructor",

  // Employee Web
  HOMEPAGEEMPLOYEE: "employee-homepage",
  CAFETERIA: "employee-cafeteria",

  // slectmembership

  SELECTMEMBERSGIP: "select-membership",

  // second stage
  SWIMMINGLESSON: "swimming-lesson",
  ADULTCOURSE: "adult-course",
  CLUB: "club",
  FASTTRACK: "fast-track",
  TRAINING: "training",
  PUBLICSWIMMING: "public-swimming",
  RENTALPOOLLANE: "rental",
  AQUARIUMPRICE: "aquarium-price",
  LANERENTAL: "lane-rental",
  POOLPARTY: "pool-party",

  // third stage
  ADULTCOURSES: "adult-courses",
  SWIMMINGLESSONS: "swimming-lessons",
  FASTTRACKS: "fast-tracks",
  CLUBS: "clubs",
  PUBLICSWIMMINGS: "public-swimmings",

  //private:
  PAYMENTSTATUS: "payment-status",

  // StepsFrom

  STEPFORM: "step-form",
};
export const formatVietnameseToString = (keyword) => {
  return keyword
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .split(" ")
    .join("-");
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const encryptString = ({ str, secretKey }) => {
  const keyHex = CryptoJS.enc.Utf8.parse(secretKey ? secretKey : " _ ");
  const encrypted = CryptoJS.AES.encrypt(str, keyHex.toString());
  const encryptedStr = CryptoJS.enc.Base64.parse(encrypted.toString());

  return CryptoJS.enc.Hex.stringify(encryptedStr);
};

export const decryptString = ({ encryptedStr, secretKey }) => {
  const keyHex = CryptoJS.enc.Utf8.parse(secretKey ? secretKey : " _ ");
  const wordArray = CryptoJS.enc.Hex.parse(encryptedStr);
  const toDecrypt = CryptoJS.enc.Base64.stringify(wordArray);
  const decryptedStr = CryptoJS.AES.decrypt(toDecrypt, keyHex.toString());

  return decryptedStr.toString(CryptoJS.enc.Utf8);
};
export const formatCurrency = (price) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "VND",
  }).format(price);
};

export const displayStar = (number) => {
  for (let index = 0; index < number; index++) {
    return (
      <Icon icon={"material-symbols:star"} className="text-yellow-500"></Icon>
    );
  }
};

export const numberWithCommas = (x) => {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
};

export const useOnKeyPress = (callback, targetkey, searchKey) => {
  useEffect(() => {
    if (searchKey !== "") {
      const keyPressHandler = (event) => {
        if (event.key === targetkey) {
          callback();
        }
      };
      window.addEventListener("keydown", keyPressHandler);
      return () => {
        window.removeEventListener("keydown", keyPressHandler);
      };
    }
  }, [callback, targetkey]);
};

export const SliceString = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const yearOld = (date, currentYear) => {
  // const dateParsed = parse(date, "dd.MM.yyyy", new Date());
  // const year_old = differenceInYears(new Date(currentYear, 1, 1), dateParsed);
  // return year_old;
  return 0;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

// userProfile

export const itemstypeMember = (datachild, dataAdult) => [
  {
    key: "1",
    label: (
      <div className="flex items-center justify-between gap-4">
        <p className="">Child</p>
      </div>
    ),
    children: (
      <>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          items={datachild}
          className="bg-white px-2"
        />
      </>
    ),
  },
  {
    key: "2",
    label: (
      <div className="flex items-center justify-between gap-4">
        <p className="">Adult</p>
      </div>
    ),
    children: (
      <>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          items={dataAdult}
          onTabClick={(e) => {}}
          className="bg-white px-2"
        />
      </>
    ),
  },
];

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const courseObject = (element, response, Lane) => {
  return {
    key: element?.number,
    //concat startTime/ endTime string from "8:00:00 AM" to "8:00"
    time: `${
      weekDays[element?.timeTable[0].timeTableWeekDay - 1]
    } - ${element?.startTime
      .trim()
      .split(" ")[0]
      .slice(0, -3)} - ${element?.endTime.trim().split(" ")[0].slice(0, -3)}`,
    Lane: Lane,
    // level: `${response.data.data.level[`level${element.level}`].name}`,
    bgColor: `#${response.data.data.level[`level${element?.level}`]?.color}`,
    data: element,
  };
};

export function daysBetween(dateStr) {
  // Parse the date from DD.MM.YYYY format
  function parseDate(dateStr) {
    const parts = dateStr.split(".");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  // Target date from the string
  const targetDate = parseDate(dateStr);

  // Current date with time set to start of the day
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds
  const diffTime = targetDate - currentDate;

  // Convert milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

export const months = [
  { MonthNum: "01", Description: "January", ShortDescription: "Jan" },
  { MonthNum: "02", Description: "February", ShortDescription: "Feb" },
  { MonthNum: "03", Description: "March", ShortDescription: "Mar" },
  { MonthNum: "04", Description: "April", ShortDescription: "Apr" },
  { MonthNum: "05", Description: "May", ShortDescription: "May" },
  { MonthNum: "06", Description: "June", ShortDescription: "Jun" },
  { MonthNum: "07", Description: "July", ShortDescription: "Jul" },
  { MonthNum: "08", Description: "August", ShortDescription: "Aug" },
  { MonthNum: "09", Description: "September", ShortDescription: "Sep" },
  { MonthNum: "10", Description: "October", ShortDescription: "Oct" },
  { MonthNum: "11", Description: "November", ShortDescription: "Nov" },
  { MonthNum: "12", Description: "December", ShortDescription: "Dec" },
];

export const formatDateToYYYYMMDD = (date) => {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return `${year}${month}${day}`;
};

function parseTime(timeString) {
  // Split the time string into parts
  const [time, modifier] = timeString.split(" "); // "16:00:00" and "PM"
  let [hours, minutes, seconds] = time.split(":").map(Number); // Convert to numbers

  // Convert 12-hour format to 24-hour format
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  } else if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a Date object with the specified time (date doesn't matter)
  return new Date(1970, 0, 1, hours, minutes, seconds);
}

export const getMinutesBetween = (
  startTime = "06:00:00 AM",
  endTime = "07:00:00 AM"
) => {
  // Parse the start and end times
  const start = parseTime(startTime);
  const end = parseTime(endTime);

  // Calculate the difference in milliseconds and convert to minutes
  const diffInMs = end - start;
  const diffInMinutes = diffInMs / (1000 * 60);

  return diffInMinutes;
};

export const convertDateString = (dateStr) => {
  // Split the input date string by the dot separator
  const parts = dateStr.split(".");

  // Rearrange the parts in the desired format: YYYYMMDD
  const formattedDate = `${parts[2]}${parts[1]}${parts[0]}`;

  return formattedDate;
};
