/** @format */

import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { path } from "../../../utils/constant";
const PrivateSidebar = ({ t }) => {
  //Multi Lang
  // Navigate
  const navigate = useNavigate();

  const lectureEnrollment = JSON.parse(
    sessionStorage.getItem("LectureEnrollment")
  );
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const [width, setWidth] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  // get size screen
  const getSize = () => {
    setWidth(window.innerWidth);
  };

  // break point resize sidebar
  useEffect(() => {
    window.addEventListener("resize", getSize);
    if (width < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    return () => {
      window.removeEventListener("resize", getSize);
    };
  }, [window.innerWidth]);

  const handleclick = () => {
    setCollapsed(!collapsed);
  };
  const handleCollapsed = () => {
    if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Sidebar
      collapsed={collapsed}
      collapsedWidth="50px"
      width="220px"
      transitionDuration={500}
      rootStyles={{}}
      closeOnClick={true}
      className="_private_sidebar"
    >
      <Menu
        transitionDuration={500}
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree

            if (level === 0)
              return {
                color: disabled ? "#ffffff" : "#000000",
                backgroundColor: active ? "#0D9AFF24" : undefined,
                color: active ? "#0D99FF" : "#000000",
              };
          },
        }}
      >
        <MenuItem
          className={`_private_sidebar_toggleButton flex justify-end ${
            collapsed ? "justify-center" : ""
          }`}
        >
          <button
            className={`p-2 ${handleCollapsed() ? "hidden" : ""}`}
            onClick={() => {
              handleclick();
            }}
          >
            <Icon icon={"bx-menu"} className="w-6 h-6 "></Icon>
          </button>
        </MenuItem>
        {user.Role === "Lecture" ? (
          <MenuItem
            icon={<Icon icon={"carbon:home"} className="w-6 h-6"></Icon>}
            onClick={() => {
              navigate("../" + path.INSTRUCTORHOME);
            }}
          >
            {t("pv_sidebar_dashboard")}
          </MenuItem>
        ) : (
          <></>
        )}
        {user.Role === "Lecture" ? (
          <MenuItem
            icon={<Icon icon={"gridicons:calendar"} className="w-6 h-6"></Icon>}
            onClick={() => {
              navigate("../" + path.INSTRUCTORCALENDAR);
            }}
          >
            {t("pv_sidebar_timetable")}
          </MenuItem>
        ) : (
          <></>
        )}
        {user.Role === "Lecture" ? (
          <SubMenu
            label={t("pv_sidebar_manage_student")}
            icon={
              <Icon
                icon={"material-symbols-light:person-outline"}
                className="w-8 h-8"
              />
            }
          >
            {lectureEnrollment.map((item, index) => {
              return (
                <>
                  <MenuItem
                    className=""
                    onClick={() => {
                      navigate("../" + path.STUDENTLIST, {
                        state: {
                          EnrollmentCode: item.Number,
                        },
                      });
                    }}
                  >
                    <p className="w-auto ml-auto">
                      {item?.Lesson?.Description}
                    </p>
                  </MenuItem>
                </>
              );
            })}
          </SubMenu>
        ) : (
          <></>
        )}
        {user.Role === "Lecture" ? (
          <SubMenu
            label={t("pv_sidebar_managecourse")}
            icon={
              <Icon
                icon={"eos-icons:subscription-management"}
                className="w-6 h-6"
              />
            }
          >
            {lectureEnrollment.map((item, index) => {
              return (
                <>
                  <MenuItem
                    className=""
                    onClick={() => {
                      navigate("../" + path.ALLCOURSE, {
                        state: {
                          EnrollmentCode: item.Number,
                        },
                      });
                    }}
                  >
                    <p className="w-auto ml-auto">
                      {item?.Lesson?.Description}
                    </p>
                  </MenuItem>
                </>
              );
            })}
            {/* <MenuItem
              className=""
              onClick={() => {
                navigate("../" + path.ALLCOURSE);
              }}
            >
              <p className="w-auto ml-auto">{t("pv_sidebar_courselist")}</p>
            </MenuItem> */}
          </SubMenu>
        ) : (
          <></>
        )}
        {user.Role === "Lecture" ? (
          <SubMenu
            defaultOpen
            label={t("pv_sidebar_instructor")}
            icon={<Icon icon={"mdi:lecture"} className={`w-6 h-6 `} />}
          >
            <MenuItem
              className=""
              onClick={() => {
                navigate("../" + path.INSTRUCTORPROFILE);
              }}
            >
              <p className="w-auto ml-auto">{t("pv_sidebar_personprofile")}</p>
            </MenuItem>
          </SubMenu>
        ) : (
          <></>
        )}
        {user.Role === "Employee" ? (
          <MenuItem
            icon={<Icon icon={"carbon:home"} className="w-6 h-6"></Icon>}
            onClick={() => {
              navigate("../" + path.HOMEPAGEEMPLOYEE);
            }}
          >
            {t("pv_sidebar_dashboard")}
          </MenuItem>
        ) : (
          <></>
        )}
        {user.Role === "Employee" ? (
          <MenuItem
            icon={
              <Icon icon={"grommet-icons:cafeteria"} className="w-6 h-6"></Icon>
            }
            onClick={() => {
              navigate("../" + path.CAFETERIA);
            }}
          >
            {t("pv_sidebar_cafeteria")}
          </MenuItem>
        ) : (
          <></>
        )}
      </Menu>
    </Sidebar>
  );
};

export default withTranslation()(PrivateSidebar);
