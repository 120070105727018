import React, { useState } from "react";
import { Modal, Input, Button, notification } from "antd";
import { withTranslation } from "react-i18next";
import { apiChangePass, apiusermember } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { path } from "../../utils/constant";
import { Icon } from "@iconify/react/dist/iconify.js";
const ModalChangePassword = ({ t, open, onOk, onCancel, userId }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [formData, setFormData] = useState({
    userId: userId,
    oldPassword: "",
    newPassword: "",
  });
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={open}
        title={
          <div className="flex justify-center ">
            <p className="text-blue-500 font-bold text-3xl">Change Password</p>
          </div>
        }
        onOk={onOk}
        onCancel={onCancel}
        footer={[
          <Button
            type="primary"
            onClick={async () => {
              await apiChangePass(formData)
                .then((res) => {
                  if (res.status == 200) {
                    notification.success({
                      message: "Password change successfully",
                      placement: "topRight",
                      duration: 2,
                    });
                    onOk();
                    navigate("../" + path.HOME);
                  }
                })
                .catch((err) => {
                  notification.error({
                    message: "Can't Change password, please contact admin",
                    placement: "topRight",
                    duration: 2,
                  });
                });
            }}
          >
            Change
          </Button>,
        ]}
      >
        <div className="flex flex-col gap-4">
          <div className="relative">
            <Input
              type={showOldPassword ? "text" : "password"}
              placeholder="Enter Old Password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  oldPassword: e.target.value,
                });
              }}
            />
            <button
              type="button"
              onClick={() => setShowOldPassword(!showOldPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              {showOldPassword ? (
                <Icon icon={"mdi:hide-outline"} className="h-6 w-6"></Icon>
              ) : (
                <Icon icon={"mdi:show-outline"} className="h-6 w-6"></Icon>
              )}
            </button>
          </div>

          <div className="relative">
            <Input
              type={showNewPassword ? "text" : "password"}
              placeholder="Enter New Password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  newPassword: e.target.value,
                });
              }}
            />
            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              {showNewPassword ? (
                <Icon icon={"mdi:hide-outline"} className="h-6 w-6"></Icon>
              ) : (
                <Icon icon={"mdi:show-outline"} className="h-6 w-6"></Icon>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ModalChangePassword);
