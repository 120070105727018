/** @format */

import React, { useState } from "react";
import QRCode from "qrcode.react";

const CardFmMember = ({ img, name, gender, dob, relationship, age }) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  return (
    <div className="flex gap-10 items-center justify-between  bg-blue-100 w-[600px] rounded-r-2xl">
      <div className="flex-col items-center p-2 text-lg">
        <div className="flex items-center gap-2">
          <p className="font-roboto_bold">Name:</p>
          <p className="">{name}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="font-roboto_bold">Gender:</p>
          <p className="">{gender}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="font-roboto_bold">Date of birth:</p>
          <p className="">{dob}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="font-roboto_bold">Age:</p>
          <p className="">{age} year old</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="font-roboto_bold">Relationship :</p>
          <p className="">{relationship}</p>
        </div>
      </div>
      <div className="" id="qr" onClick={() => showModal()}>
        <QRCode
          id="qrcode"
          size={50}
          bgColor="white"
          fgColor="black"
          value={name}
        />
      </div>
      <div className="rounded-r-2xl">
        <img
          className="object-cover w-[200px] h-[200px] rounded-r-2xl"
          src={img}
        ></img>
      </div>
    </div>
  );
};

export default CardFmMember;
