import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import imgadultswimmingclubs from "../../../../assets/Final_PIctures/adult_swimming_club.png";
import imgyouthswimmingclubs from "../../../../assets/Final_PIctures/youth_swimming_club.png";
import { path } from "../../../../utils/constant";
const LaneRental = ({ t }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const mockDataTable = [
    {
      key: "1",
      row_1: (
        <p className="text-center">
          PER LANE
          <br /> (VND)
        </p>
      ),
      row_2: "1 HOUR",
      row_3: "2 HOUR",
      row_4: "3 HOUR",
      row_5: "4 HOUR",
    },
    {
      key: "2",
      row_1: "1 LANE",
      row_2: "1,000,000",
      row_3: "900,000",
      row_4: "800,000",
      row_5: "750,000",
    },
    {
      key: "3",
      row_1: "2 LANE",
      row_2: "900,000",
      row_3: "800,000",
      row_4: "750,000",
      row_5: "700,000",
    },
    {
      key: "4",
      row_1: "3 LANE",
      row_2: "800,000",
      row_3: "750,000",
      row_4: "700,000",
      row_5: "700,000",
    },
    {
      key: "5",
      row_1: "4 LANE",
      row_2: "750,000",
      row_3: "700,000",
      row_4: "700,000",
      row_5: "650,000",
    },
    {
      key: "6",
      row_1: "5 LANE",
      row_2: "700,000",
      row_3: "700,000",
      row_4: "650,000",
      row_5: "650,000",
    },
    {
      key: "7",
      row_1: "6 LANE",
      row_2: "700,000",
      row_3: "650,000",
      row_4: "600,000",
      row_5: "600,000",
    },
    {
      key: "8",
      row_1: "7-8 LANE",
      row_2: "650,000",
      row_3: "600,000",
      row_4: "600,000",
      row_5: "600,000",
    },
  ];
  const mockDataTablePool = [
    {
      key: "1",
      row_1: (
        <p className="text-center">
          LANE HIRE
          <br /> (VND)
        </p>
      ),
      row_2: "1 HOUR",
      row_3: "2 HOUR",
      row_4: "3 HOUR",
      row_5: "4 HOUR",
    },
    {
      key: "2",
      row_1: "1 LANE",
      row_2: "1.0M",
      row_3: "1.8M",
      row_4: "2.4M",
      row_5: "2.4M",
    },
    {
      key: "3",
      row_1: "2 LANE",
      row_2: "1.8M",
      row_3: "2.4M",
      row_4: "4.5M",
      row_5: "5.6M",
    },
    {
      key: "4",
      row_1: "3 LANE",
      row_2: "2.4M",
      row_3: "4.5M",
      row_4: "6.3M",
      row_5: "7.0M",
    },
    {
      key: "5",
      row_1: "4 LANE",
      row_2: "3.0M",
      row_3: "5.6M",
      row_4: "7.0M",
      row_5: "10.4M",
    },
    {
      key: "6",
      row_1: "5 LANE",
      row_2: "3.5M",
      row_3: "6.0M",
      row_4: "7.75M",
      row_5: "13.0M",
    },
    {
      key: "7",
      row_1: "6 LANE",
      row_2: "4.2M",
      row_3: "7.8M",
      row_4: "10.8M",
      row_5: "14.4M",
    },
    {
      key: "8",
      row_1: "7-8 LANE",
      row_2: "5.0M",
      row_3: "9.0M",
      row_4: "13.0M",
      row_5: "17.0M",
    },
  ];

  return (
    <div className="flex bg-[#4e858b] flex-col items-center">
      {/* <div className="w-full bg-white bg-contain ">
        <div className="grid grid-cols-5 w-[65%] mx-auto py-8 pv:max-md:grid-cols-1 pv:max-md:w-[95%] pt-[180px] pv:max-md:pt-0">
          <div className="flex flex-col col-span-2 justify-between w-[90%] p-10 pv:max-lg:p-4 gap-10 pv:max-lg:gap-4">
            <p className="text-[#1b4235] text-6xl text-start pv:max-lg:text-start pv:max-md:text-2xl md:max-xl:text-4xl">
              {t("lane_rental_title")}
            </p>
            <div className="text-2xl pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-lg">
              <p className="">Lane hire for swim clubs is available:</p>
              <ul className="list-disc pl-10">
                <li>Every morning 06:00-09:00</li>
                <li>Wednesdays 18:00-20:30</li>
                <li>Saturdays 15:30-20:30</li>
                <li>Sundays 15:30-18:00</li>
              </ul>
            </div>

            <div className="">
              <button className="bg-[#dcd3d1] px-14 py-4 rounded-full hover:text-white hover:bg-secondary1 pv:max-ph:py-3 pv:max-ph:px-10 ">
                <a className="w-full text-2xl underline  md:max-xl:text-lg pv:max-ph:text-lg">
                  <p
                    className="  "
                    onClick={() => {
                      window.location = "mailto:contact@aquaticshub.vn";
                    }}
                  >
                    {t("lane_rental_contact")}
                  </p>
                </a>
              </button>
            </div>
          </div>
          <img
            src={
              "https://aquaticshub.com/lane-pool-rental/images/7ed5c7a95dd0a5af3aed9ca74a9e99bb.jpg"
            }
            className="w-full h-[500px] col-span-3 object-cover pv:max-ph:h-[50vh] "
          />
        </div>
      </div> */}
      <div className=" w-full bg-white bg-contain  py-[100px]  pv:max-md:pt-4">
        <div className="w-[60%]  mx-auto gap-10  grid grid-cols-3 pv:max-sm:w-[95%] pv:max-lg:grid-cols-1 pv:max-sm:gap-6 sm:max-md:w-[95%] sm:max-md:mx-auto lg:max-dv:w-[90%] md:max-lg:w-[70%] dv:max-dvmax:w-[80%] lg:max-dv:mx-auto  ">
          <div className="col-span-1 flex flex-col  justify-between pv:max-sm:gap-4 pv:max-sm:w-[95%] pv:max-sm:mx-auto sm:max-lg:w-[70%] sm:max-lg:mx-auto sm:max-md:gap-4">
            <div className="">
              <p className="uppercase text-[#1b4235] font-medium text-5xl pv:max-sm:text-5xl sm:max-md:text-5xl md:max-lg:text-4xl lg:max-dv:text-[3.2vw] ">
                LANE & POOL
              </p>
              <p className="uppercase text-[#1b4235] font-medium text-5xl pv:max-sm:text-5xl sm:max-md:text-5xl md:max-lg:text-4xl lg:max-dv:text-[3.2vw] ">
                ​RENTAL
              </p>
            </div>
            <div className="">
              <div className="text-[26px] pv:max-lg:text-xl text-[#391b0b] text-start pv:max-lg:text-start md:max-xl:text-xl">
                <p className="">Lane hire for swim clubs is available:</p>
                <ul className="list-disc pl-10">
                  <li>Every morning 06:00-09:00</li>
                  <li>Wednesdays 18:00-20:30</li>
                  <li>Saturdays 15:30-20:30</li>
                  <li>Sundays 15:30-18:00</li>
                </ul>
              </div>
            </div>
            <div className="">
              <button
                className="w-full rounded-full bg-[#ece7e3] py-4"
                // onClick={() => {
                //   navigate("../" + path.AQUARIUMPRICE);
                // }}
                onClick={() => {
                  window.location = "mailto:contact@aquaticshub.vn";
                }}
              >
                <p className="uppercase text-2xl  hover:font-bold hover:underline pv:max-sm:text-xl md:max-lg:text-lg">
                  contact
                </p>
              </button>
            </div>
          </div>
          <div className="col-span-2 pv:max-ph:col-span-1 w-full  md:max-lg:mx-auto   ">
            <img
              className="w-full h-[450px]  mx-auto   object-cover pv:max-md:h-[55vw] lg:max-xl:h-[350px]"
              src={
                "https://aquaticshub.com/lane-pool-rental/images/7ed5c7a95dd0a5af3aed9ca74a9e99bb.jpg"
              }
            ></img>
          </div>
        </div>
      </div>

      <div className="w-[65%] pv:max-md:w-[90%] grid grid-cols-2 gap-12 py-10 pv:max-lg:grid-cols-1">
        <div className="">
          <img className="" src={imgadultswimmingclubs}></img>
        </div>
        <div className="">
          <img className="" src={imgadultswimmingclubs}></img>
        </div>
        {/* <p className="text-4xl mt-8 pt-10 pb-5 text pv:max-sm:text-lg font-roboto_bold text-[#c3bfb6]">
          {t("lane_rental_lane_hire")}
        </p>
        <div className="grid grid-cols-8 w-[100%] mx-auto gap-3">
          {mockDataTablePool.map((item, index) => {
            return (
              <div className="flex flex-col gap-3">
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_1}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_2}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_3}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_4}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_5}</p>
                </div>
              </div>
            );
          })}
        </div>
        <p className="text-4xl mt-8 pt-10 pb-5 text pv:max-sm:text-lg font-roboto_bold text-[#c3bfb6]">
          {t("lane_rental_pool_price")}
        </p>
        <div className="grid grid-cols-8 w-[100%] mx-auto gap-3 pb-10">
          {mockDataTable.map((item, index) => {
            return (
              <div className="flex flex-col gap-3">
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_1}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_2}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_3}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_4}</p>
                </div>
                <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-white flex justify-center items-center">
                  <p className="text-[1.2vw]">{item.row_5}</p>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>

      <div className="flex justify-center pb-10 pt-5 pv:max-lg:pt-0">
        <button
          className="w-[350px] text-2xl   p-4 px-2 uppercase bg-[#dcd3d1] rounded-full text-black hover:bg-secondary2 hover:text-white underline md:max-lg:w-[100%] pv:max-lg:w-[300px]  pv:max-md:p-2 "
          onClick={() => {
            navigate("../" + path.HOME);
          }}
        >
          {t("lane_rental_home")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(LaneRental);
