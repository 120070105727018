/** @format */

import React, { useState, useEffect, useCallback } from "react";
import logo from "../../../assets/Logo_AquaticHub.png";
import logo_white from "../../../assets/LogoAquatic_white.png";
import { path, useOnKeyPress } from "../../../utils/constant";
import { Icon } from "@iconify/react";
import { useTranslation, withTranslation } from "react-i18next";
import { Select, Dropdown, Space, Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import ImageAvatar from "../../../assets/teach_1.jpg";
import { apiSearchCourse } from "../../../services/course";
import { apiGetCart } from "../../../services/secondstage";
import { apiThirdStage } from "../../../services/stageDetails";

const PublicHeader = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const currentLanguage = localStorage.getItem("lang");
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const width = window.innerWidth;
  const [isMobile, setIsMobile] = useState(false);
  const [click, setClick] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);
  const [clickSearchDesktop, setClickSearchDesktop] = useState(false);
  const [cartInfo, setCartInfo] = useState([]);

  const itemsCart = (data = []) => {
    let membersArray = [];

    if (data.length > 0) {
      data.forEach((element) => {
        membersArray.push({
          label: (
            <div className="flex flex-col gap-2 p-2  ">
              <div className="grid grid-cols-3 w-[400px] gap-2">
                <div className="col-span-2 flex flex-col justify-center w-full">
                  <p className="text-xl font-bold line-clamp-1">
                    {element.description}
                  </p>
                  <p className="text-sm text-gray-500">{element.student}</p>
                  <p className="text-sm text-gray-500">
                    Start date: {element.startDate}
                  </p>
                  <p className="text-sm text-gray-500">
                    End date: {element.endDate}
                  </p>
                </div>
              </div>
              <div className="w-full bg-slate-500 h-[1px]"></div>
            </div>
          ),
          key: element.id,
          type: "item", // Assuming each element has a unique id
        });
      });
    } else {
      membersArray.push({
        label: (
          <div className="w-[300px]">
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={
                <p className="text-xl font-bold">Your shopping cart is empty</p>
              }
              className="w-[300px] flex flex-col justify-center items-center"
            ></Empty>
          </div>
        ),
        type: "empty", // Assuming each element has a unique id
      });
    }
    // membersArray.push({
    //   label: (

    //   ),
    //   type: "button",
    // });
    return membersArray;
  };
  useEffect(() => {
    const storedCart = JSON.parse(sessionStorage.getItem("CartInfo"));
    if (storedCart) {
      setCartInfo(storedCart);
    }
  }, []);

  useEffect(() => {
    if (user?.code) {
      // Check if user.code exists
      apiGetCart({ userId: user.code })
        .then((res) => {
          if (res.status === 200) {
            setCartInfo(res.data.data);
          }
        })
        .catch((err) => console.error(err.message));
    } else {
      setCartInfo([]);
    }
  }, [user?.code]);

  useEffect(() => {
    apiThirdStage("SOF")
      .then((response) => {})
      .catch((err) => {});
  }, []);

  const handleClickDetail = (section, uuid) => {};

  const handleClickSearch = () => {
    setClick(false);
    setClickSearch(!clickSearch);
  };
  const handleClickSearchDesktop = () => {
    setClickSearchDesktop(!clickSearchDesktop);
  };
  const handleClick = () => {
    setClick(!click);
    setClickSearch(false);
  };
  const closeMenu = () => setClick(false);

  //Change nav color when scroll
  const [headerColor, setHeaderColor] = useState(false);
  const changeHeaderColor = () => {
    //90 is header height (first div in Return)
    if (window.scrollY >= 90) {
      setHeaderColor(true);
    } else {
      setHeaderColor(false);
    }
  };
  window.addEventListener("scroll", changeHeaderColor);

  useEffect(() => {
    if (width < 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const handleNavigate = useCallback((flag) => {
    navigate("../" + path.LOGIN, { state: { flag } });
  }, []);

  const itemsUser = [
    {
      label: (
        <div
          className="flex gap-2 hover:text-secondary2"
          onClick={() => handleclickViewProfile()}
        >
          <Icon icon={"iconamoon:profile"} className="w-6 h-6"></Icon>
          <p className="">{t("nav_view_profile")}</p>
        </div>
      ),
      key: "0",
    },
    // {
    //   label: (
    //     <div
    //       className="flex gap-2 hover:text-secondary2"
    //       onClick={() => handleclickMylearning()}
    //     >
    //       <Icon icon={"fluent-mdl2:publish-course"} className="w-6 h-6"></Icon>
    //       <p className="">{t("nav_my_learning")}</p>
    //     </div>
    //   ),
    //   key: "1",
    // },
    // {
    //   label: (
    //     <div
    //       className="flex gap-2 hover:text-secondary2"
    //       onClick={() => handleclickQRWallet()}
    //     >
    //       <Icon icon={"material-symbols:wallet"} className="w-6 h-6"></Icon>
    //       <p className="">{t("nav_qrwallet")}</p>
    //     </div>
    //   ),
    //   key: "2",
    // },
    // {
    //   label: (
    //     <div
    //       className="flex gap-2 hover:text-secondary2"
    //       // onClick={() => handleclickQRWallet()}
    //     >
    //       <Icon
    //         icon={"fluent:person-support-20-filled"}
    //         className="w-6 h-6"
    //       ></Icon>
    //       <p className="">Send Support Ticket</p>
    //     </div>
    //   ),
    //   key: "3",
    // },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex gap-2 hover:text-secondary2"
          onClick={() => {
            sessionStorage.clear();

            navigate("/");
          }}
        >
          <Icon icon={"clarity:logout-solid"} className="h-6 w-6"></Icon>
          <p className="">{t("nav_sign_out")}</p>
        </div>
      ),
      key: "4",
    },
  ];

  const itemsNotification = [
    {
      label: (
        <div className="flex gap-2 hover:text-secondary2 w-[400px] ">
          <img
            className="w-6 h-6 rounded-full object-cover"
            // src={` ${user}:?${ImageAvatar} :`}
            src={user ? ImageAvatar : ""}
          ></img>
          <p className="line-clamp-1">
            On Monday we will have a test so prepare carefully to get the best
            score
          </p>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="flex gap-2 hover:text-secondary2 w-[400px]">
          <img
            className="w-6 h-6 rounded-full object-cover"
            src={ImageAvatar}
          ></img>
          <p className="line-clamp-1">
            On Monday we will have a test so prepare carefully to get the best
            score
          </p>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="flex gap-2 hover:text-secondary2 w-[400px]">
          <img
            className="w-6 h-6 rounded-full object-cover"
            src={ImageAvatar}
          ></img>
          <p className="line-clamp-1">
            On Monday we will have a test so prepare carefully to get the best
            score
          </p>
        </div>
      ),
      key: "2",
    },
  ];

  // {

  // },

  const [notificationCount, setNotificationCount] = useState(
    itemsNotification.length
  );

  const handleclickMylearning = () => {
    navigate("../" + path.MYLEARNING);
  };
  const handleclickQRWallet = () => {
    navigate("../" + path.QRWALLET);
  };
  const handleclickViewProfile = () => {
    navigate("../" + path.USERPROFILE, { state: "1" });
  };
  const handleNavbarNavigate = (pathToNavigate) => {
    closeMenu();
    navigate(pathToNavigate);
  };

  const [searchkey, setSearchkey] = useState("");
  const handleSearchChange = (event) => {
    setSearchkey(event.target.value);
  };
  const handleClickSearchIcon = async () => {
    setClickSearch(false);
    setClickSearchDesktop(false);
    const response = await apiSearchCourse(searchkey);
    try {
      if (response?.status === 200 || response?.status === 204) {
        navigate("../" + path.LISTCOURSESEARCH, {
          state: {
            listDataSearch: response.data.Result,
          },
        });
      }
    } catch (error) {}
  };

  useOnKeyPress(handleClickSearchIcon, "Enter", searchkey);

  return (
    <div
      className={`c_header_public_header  h-[180px] w-full top-0 left-0 z-50 pv:max-md:h-[100px]
            ${headerColor ? "bg-black" : "bg-[black]"}`}
      style={{ transition: ".3s ease-in" }}
    >
      <nav className="c_header_public_navbar flex justify-between items-center max-w-[1240px] m-auto h-full p-0 px-[1rem] z-50 relative">
        {/* desktop */}
        <a
          onClick={() => {
            navigate("../" + path.HOME);
          }}
          className="c_header_public_logo  pv:max-md:hidden  p-[10px] cursor-pointer absolute -bottom-20 -left-40 h-[300px] w-[1000px] md:max-xl:w-[600px] md:max-xl:h-[200px] md:max-xl:-left-14 md:max-xl:-bottom-14"
        >
          <img
            className="w-full h-full object-cover "
            src={logo_white}
            alt="logo"
          />
        </a>

        {/* Mobile */}

        <a
          onClick={() => {
            navigate("../" + path.HOME);
          }}
          className=" md:hidden w-full  h-[80px]"
        >
          <img
            className="w-full h-full object-cover "
            src={logo_white}
            alt="logo"
          />
        </a>
        <ul
          className={
            click
              ? "c_header_public_nav-menu c_header_public_nav-menu_active flex"
              : clickSearchDesktop
              ? "c_header_public_nav-menu flex hidden"
              : "c_header_public_nav-menu flex"
          }
        >
          {/* <li className="c_header_public_nav-item p-[1rem] font-medium">
            <a
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                        ${
                          headerColor
                            ? isMobile
                              ? "text-white"
                              : "text-black hover:border-sky-400"
                            : "text-white"
                        }`}
              onClick={() => handleNavbarNavigate("../" + path.HOME)}
            >
              {t("nav_Home")}
            </a>
          </li>
          <li className="c_header_public_nav-item p-[1rem] font-medium">
            <a
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                        ${
                          headerColor
                            ? isMobile
                              ? "text-white"
                              : "text-black hover:border-sky-400"
                            : "text-white"
                        }`}
              onClick={() => handleNavbarNavigate("../" + path.PUBLICALLCOURSE)}
            >
              {t("subnav_allCourse")}
            </a>
          </li>
          <li className="c_header_public_nav-item p-[1rem] font-medium">
            <a
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                        ${
                          headerColor
                            ? isMobile
                              ? "text-white"
                              : "text-black hover:border-sky-400"
                            : "text-white"
                        }`}
              onClick={() => handleNavbarNavigate("../" + path.POOLALL)}
            >
              {t("subnav_allpoollane")}
            </a>
          </li> */}

          {/* <li className="c_header_public_nav-item c_header_public_profile p-[1rem] font-medium hidden">
            <div className="border-t-[2px] border-white"></div>
          </li> */}
          {user ? (
            <li className="c_header_public_nav-item c_header_public_profile p-[1rem] font-roboto_regular hidden">
              <a
                className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                                ${
                                  headerColor
                                    ? isMobile
                                      ? "text-white"
                                      : "text-white hover:border-sky-400"
                                    : "text-white"
                                }`}
                onClick={() => handleNavbarNavigate("../" + path.USERPROFILE)}
              >
                {t("nav_view_profile")}
              </a>
            </li>
          ) : (
            <></>
          )}
          {user ? (
            <li
              className="c_header_public_nav-item c_header_public_profile p-[1rem] font-roboto_regular hidden"
              onClick={() => {
                navigate("../" + path.USERPROFILE, { state: "4" });
                closeMenu();
              }}
            >
              <a
                className={` relative hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                                ${
                                  headerColor
                                    ? isMobile
                                      ? "text-white"
                                      : "text-white hover:border-sky-400"
                                    : "text-white"
                                }`}
              >
                CART
                {cartInfo.length > 0 ? (
                  <span className="absolute top-[-10px] right-[-15px] h-4 w-4 bg-red-600 text-white text-xs flex items-center justify-center rounded-full">
                    {cartInfo.length}
                  </span>
                ) : (
                  ""
                )}
              </a>
            </li>
          ) : (
            <></>
          )}
          {/* {user ? (
            <li className="c_header_public_nav-item c_header_public_profile p-[1rem] font-roboto_regular hidden">
              <a
                className={` relative hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                                ${
                                  headerColor
                                    ? isMobile
                                      ? "text-white"
                                      : "text-black hover:border-sky-400"
                                    : "text-white"
                                }`}
              >
                NOTIFICATION
                {notificationCount > 0 && (
                  <span className="absolute top-[-10px] right-[-15px] h-4 w-4 bg-red-600 text-white text-xs flex items-center justify-center rounded-full">
                    {notificationCount}
                  </span>
                )}
              </a>
            </li>
          ) : (
            <></>
          )} */}
          {user ? (
            <li className="c_header_public_nav-item c_header_public_profile p-[1rem] font-roboto_regular hidden">
              <a
                className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                                ${
                                  headerColor
                                    ? isMobile
                                      ? "text-white"
                                      : "text-black hover:border-sky-400"
                                    : "text-white"
                                }`}
                onClick={() => {
                  sessionStorage.clear();
                  handleClick();
                  navigate("/");
                }}
              >
                {t("nav_sign_out")}
              </a>
            </li>
          ) : (
            <></>
          )}
          {user ? (
            <></>
          ) : (
            <li className="c_header_public_nav-item c_header_public_profile p-[1rem] font-roboto_regular hidden">
              <a
                className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                            ${
                              headerColor
                                ? isMobile
                                  ? "text-white"
                                  : "text-black hover:border-sky-400"
                                : "text-white"
                            }`}
                onClick={() => {
                  handleNavigate(false);
                  closeMenu();
                }}
              >
                {t("h_pu_signin")}
              </a>
            </li>
          )}
          {user ? (
            <></>
          ) : (
            <li className="c_header_public_nav-item c_header_public_profile p-[1rem] font-roboto_regular hidden">
              <a
                className={`hover:pb-[12px] hover:border-b-[3px] hover:border-secondary1 cursor-pointer
                            ${
                              headerColor
                                ? isMobile
                                  ? "text-white"
                                  : "text-black hover:border-sky-400"
                                : "text-white"
                            }`}
                onClick={() => {
                  handleNavigate(true);
                  closeMenu();
                }}
              >
                {t("h_pu_signup")}
              </a>
            </li>
          )}
        </ul>
        <div className="flex gap-[5px] c_header_public_userInteraction">
          {/* <div className="flex items-center relative">
            <input
              type="text"
              id="simple-search"
              className={`w-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5 dark:bg-blue-100 dark:border-blue-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                clickSearchDesktop
                  ? "absolute top-0 right-[35px] w-[300px]"
                  : "hidden"
              }`}
              style={{ transition: "width 0.5s ease-in-out 0.5s" }}
              placeholder={t("h_pu_placeholder_search")}
              required
              onChange={handleSearchChange}
            />
            <Icon
              className={`w-[30px] h-[30px] cursor-pointer ${
                headerColor ? "text-white" : "text-white"
              }`}
              icon={clickSearchDesktop ? "la:times" : "ic:twotone-search"}
              onClick={() => handleClickSearchDesktop()}
            />
          </div> */}
          <button
            className={`c_header_public_signIn hover:bg-secondary1 font-roboto_regular px-4 py-1 rounded-lg border-2 border-[#0B2470] bg-white text-black
                        ${
                          headerColor
                            ? "text-secondary1 hover:text-white"
                            : "text-secondary1 hover:text-white"
                        }
                        ${user ? "hidden" : ""}
                        `}
            onClick={() => handleNavigate(false)}
          >
            <p className="">{t("h_pu_signin")}</p>
          </button>
          <button
            className={`c_header_public_signUp hover:bg-secondary1 font-roboto_regular px-4 py-1 rounded-lg border-2 border-[#0B2470] bg-white text-black
                        ${
                          headerColor
                            ? "text-secondary1 hover:text-white"
                            : "text-secondary1 hover:text-white"
                        }
                        ${user ? "hidden" : ""}
                        `}
            onClick={() => handleNavigate(true)}
          >
            <p className="">{t("h_pu_signup")}</p>
          </button>
          {user ? null : (
            <button
              className=" relative p-2  border-[1px] border-white rounded-full "
              onClick={() => {
                navigate("../" + path.LOGIN);
              }}
            >
              <Icon
                icon={"mdi:cart-outline"}
                className={`w-[24px] h-[24px]
                                ${headerColor ? "text-white" : "text-white"}
                                `}
              ></Icon>
              {cartInfo.length > 0 && (
                <span className="absolute top-[-5px] right-[-5px] h-4 w-4 bg-red-600 text-white text-xs flex items-center justify-center rounded-full">
                  {cartInfo.length}
                </span>
              )}
            </button>
          )}
          <div
            className={`c_header_public_userInfo flex gap-4 px-[6px] pl-[16px] ${
              user ? "" : "hidden"
            }`}
          >
            {/* <button className="p-2  border-[1px] border-white rounded-full ">
              <Icon
                icon={"mdi:cart"}
                className={`w-[24px] h-[24px]
                            ${headerColor
                    ? "text-black"
                    // : "text-white"
                    : "text-black"
                  }
                            `}
              ></Icon>
            </button> */}

            <Dropdown
              menu={{
                items: itemsUser,
                style: { marginTop: "10px" },
              }}
              trigger={["click"]}
              placement="bottom"
              className=""
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              {user ? (
                <button className="w-10 h-10 rounded-full border-[1px]">
                  <img
                    className="w-10 h-10 rounded-full object-cover"
                    src={user?.pictureUrl}
                  ></img>
                </button>
              ) : (
                <button className="p-2  border-[1px] border-white rounded-full ">
                  <Icon
                    icon={"iconamoon:profile"}
                    className={`w-[24px] h-[24px]
                                        ${
                                          headerColor
                                            ? "text-black"
                                            : "text-white"
                                        }
                                        `}
                  ></Icon>
                </button>
              )}
            </Dropdown>

            {/* <Dropdown
              menu={{
                items: itemsNotification,
                style: { marginTop: "10px" },
              }}
              trigger={["hover"]}
              placement="bottomLeft"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <button className="relative p-2  border-[1px] border-white rounded-full ">
                <Icon
                  icon={"iconamoon:notification"}
                  className={`w-[24px] h-[24px]
                                    ${headerColor ? "text-black" : "text-white"}
                                    `}
                ></Icon>
                {notificationCount > 0 && (
                  <span className="absolute top-[-5px] right-[-5px] h-4 w-4 bg-red-600 text-white text-xs flex items-center justify-center rounded-full">
                    {notificationCount}
                  </span>
                )}
              </button>
            </Dropdown> */}
            <Dropdown
              menu={{
                items: itemsCart(cartInfo),
                style: { marginTop: "10px" },
              }}
              className=""
              trigger={["click"]}
              placement="bottom"
              getPopupContainer={(trigger) => trigger.parentElement}
              dropdownRender={(menu) => (
                <div className="">
                  <div className="h-auto overflow-y-auto s  rounded-xl no-scrollbar">
                    {React.cloneElement(menu, {})}
                  </div>
                  <button
                    className={` w-full p-2 mt-4 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out ${
                      cartInfo.length <= 0 ? "hidden" : ""
                    }`}
                    onClick={() => {
                      navigate("../" + path.USERPROFILE, { state: "4" });
                    }}
                  >
                    <p className="text-lg"> {t("Go_to_my_cart")}</p>
                  </button>
                </div>
              )}
            >
              <button
                className=" relative p-2  border-[1px] border-white rounded-full "
                // onClick={() => {
                //   navigate("../" + path.USERPROFILE);
                // }}
              >
                <Icon
                  icon={"mdi:cart-outline"}
                  className={`w-[24px] h-[24px]
                                    ${headerColor ? "text-white" : "text-white"}
                                    `}
                ></Icon>
                {cartInfo.length > 0 && (
                  <span className="absolute top-[-5px] right-[-5px] h-4 w-4 bg-red-600 text-white text-xs flex items-center justify-center rounded-full">
                    {cartInfo.length}
                  </span>
                )}
              </button>
            </Dropdown>
          </div>

          <Select
            defaultValue={currentLanguage}
            size="large"
            placement="bottomLeft"
            className="c_header_public_langselect w-[50px]"
            suffixIcon={null}
            onChange={(e) => changeLanguage(e)}
            // getPopupContainer={(trigger) => trigger.parentElement}
            options={[
              {
                value: "vi",
                label: (
                  <div className="flex items-center justify-center">
                    <Icon icon={"twemoji:flag-vietnam"} className="w-5 h-5" />
                  </div>
                ),
              },
              {
                value: "en",
                label: (
                  <div className="flex items-center justify-center">
                    <Icon icon={"flagpack:gb-ukm"} className="w-5 h-5" />
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="c_header_public_hamburger hidden">
          <Select
            defaultValue={currentLanguage}
            size="large"
            placement="bottomLeft"
            className="c_header_public_langselect c_header_public_langselect_mobile w-[50px]"
            suffixIcon={null}
            onChange={(e) => changeLanguage(e)}
            getPopupContainer={(trigger) => trigger.parentElement}
            options={[
              {
                value: "vi",
                label: (
                  <div className="flex items-center justify-center">
                    <Icon icon={"twemoji:flag-vietnam"} className="w-5 h-5" />
                  </div>
                ),
              },
              {
                value: "en",
                label: (
                  <div className="flex items-center justify-center">
                    <Icon icon={"flagpack:gb-ukm"} className="w-5 h-5" />
                  </div>
                ),
              },
            ]}
          />
          {/* <Icon
            className="text-white w-[30px] h-[30px]"
            icon={clickSearch ? "la:times" : "ic:twotone-search"}
            onClick={() => handleClickSearch()}
          /> */}
          <Icon
            className="text-white w-[30px] h-[30px]"
            icon={click ? "la:times" : "la:bars"}
            onClick={() => handleClick()}
          />
        </div>
      </nav>
      {/* <input
        type="text"
        id="simple-search"
        className={`w-[80%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5  dark:bg-blue-100 dark:border-blue-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 absolute top-[100px] left-[50%] ${
          clickSearch ? "" : "hidden"
        }`}
        style={{ translate: "-50%" }}
        placeholder={t("h_pu_placeholder_search")}
        required
        onChange={handleSearchChange}
      /> */}
    </div>
  );
};

export default withTranslation()(PublicHeader);
