/** @format */

import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import imgSchedule from "../../../../assets/Final_PIctures/club_schedule.jpg";
import imgSchedule2 from "../../../../assets/Final_PIctures/club_schedule_2.png";
import {
  Radio,
  notification,
  Checkbox,
  Input,
  Modal,
  Result,
  Button,
} from "antd";
import { isSwimmerName } from "../../../../utils/common/validators";
import { apiClubs } from "../../../../services/stageDetails";
import { path } from "../../../../utils/constant";
import { ModalChooseLevel, CardMemberShip } from "../../../../components";
import { apiusermember } from "../../../../services/usermember";
const Clubs = () => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));
  const [data, setData] = useState([
    {
      value: "Monday 18:00-19:30",
      label: "Monday 18:00-19:30",
    },
    {
      value: "Tuesday 18:00-19:30",
      label: "Tuesday 18:00-19:30",
    },
    {
      value: "Thursday 18:00-19:30",
      label: "Thursday 18:00-19:30",
    },
    {
      value: "Friday 18:00-19:30",
      label: "Friday 18:00-19:30",
    },
    {
      value: "Saturday 14:00-15:30",
      label: "Saturday 14:00-15:30",
    },
    {
      value: "Sunday 14:00-15:30",
      label: "Sunday 14:00-15:30",
    },
  ]);
  const [selectedMember, setSelectedMember] = useState({
    UserID: user?.code,
    UserType: "main",
  });
  const [dataMember, setDataMember] = useState({
    adultMembers: [
      {
        code: "",
        dateOfBirth: "",
        description: "",
        gender: "",
        pictureUrl: "",
      },
    ],
    childMembers: [
      {
        code: "",
        dateOfBirth: "",
        description: "",
        gender: "",
        pictureUrl: "",
      },
    ],
  });
  const handleChangeMember = (e) => {
    setSelectedMember({
      UserID: e.value,
      UserType: e.userType,
    });
  };
  const [loading, setLoading] = useState(false);
  const [loadingResgister, setLoadingRegister] = useState(false); // Trạng thái loading
  const [formData, setFormData] = useState({
    userId: selectedMember.UserID,
    userType: selectedMember.UserType,
    squad: "EliteSquad",
    timeString: data[0].value,
  });
  const handleOpenModalSelectMember = () => {
    setOpenModalMemberClub(true);
  };
  useEffect(() => {
    apiusermember({
      mainUserCode: user?.code,
    })
      .then((response) => {
        if (response?.status === 201 || response?.status === 200) {
          setDataMember(response?.data.data);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);
  const dataLevel = [
    {
      key: "EliteSquad",
      title: "Elite Squad",
    },
    {
      key: "GoldSquad",
      title: "Gold Squad",
    },
    {
      key: "SilverSquad",
      title: "Silver Squad",
    },
    {
      key: "BronzeSquad",
      title: "Bronze Squad",
    },
    {
      key: "PurpleSquad",
      title: "Purple Squad",
    },
    {
      key: "DevelopmentSquad",
      title: "Development Squad",
    },
  ];
  const [responseData, setresponseData] = useState({});

  const [openReqModal, setOpenReqModal] = useState(false);
  const [openModalMemberClub, setOpenModalMemberClub] = useState(false);
  const handleShowDetail = () => {
    setOpenModalMemberClub(!openModalMemberClub);
  };
  const hanleOnchage = (e) => {
    switch (e) {
      case "EliteSquad":
        setData([
          {
            value: "Monday 18:00-19:30",
            label: "Monday 18:00-19:30",
          },
          {
            label: "Tuesday 18:00-19:30",
            value: "Tuesday 18:00-19:30",
          },
          {
            value: "Thursday 18:00-19:30",
            label: "Thursday 18:00-19:30",
          },
          {
            value: "Friday 18:00-19:30",
            label: "Friday 18:00-19:30",
          },
          {
            value: "Saturday 14:00-15:30",
            label: "Saturday 14:00-15:30",
          },
          {
            value: "Sunday 14:00-15:30",
            label: "Sunday 14:00-15:30",
          },
        ]);
        break;
      case "GoldSquad":
        setData([
          {
            value: "Monday 18:00-19:30 ",
            label: "Monday 18:00-19:30 ",
          },
          {
            value: "Tuesday 18:00-19:30 ",
            label: "Tuesday 18:00-19:30 ",
          },
          {
            label: "Friday 18:00-19:30",
            value: "Friday 18:00-19:30",
          },
          {
            value: "Saturday 14:00-15:30",
            label: "Saturday 14:00-15:30",
          },
          {
            value: "Sunday 14:00-15:30",
            label: "Sunday 14:00-15:30",
          },
        ]);
        break;
      case "SilverSquad":
        setData([
          {
            value: "Tuesday 18:00-19:30",
            label: "Tuesday 18:00-19:30",
          },
          {
            value: "Thursday 18:00-19:30",
            label: "Thursday 18:00-19:30",
          },
          {
            value: "Friday 18:00-19:30",
            label: "Friday 18:00-19:30",
          },
          {
            label: "Saturday 12:30-14:00",
            value: "Saturday 12:30-14:00",
          },
          {
            value: "Sunday 12:30-14:00",
            label: "Sunday 12:30-14:00",
          },
        ]);
        break;
      case "BronzeSquad":
        setData([
          { label: "Monday 18:00-19:30", value: "Monday 18:00-19:30" },
          { label: "Thursday 18:00-19:30", value: "Thursday 18:00-19:30" },
          { label: "Saturday 11:00-12:30", value: "Saturday 11:00-12:30" },
          { label: "Sunday 11:00-12:30", value: "Sunday 11:00-12:30" },
        ]);
        break;
      case "PurpleSquad":
        setData([
          { label: "Tuesday 17:00-18:00", value: "Tuesday 17:00-18:00" },
          { label: "Friday 17:00-18:00", value: "Friday 17:00-18:00" },
          { label: "Saturday 10:00-11:00", value: "Saturday 10:00-11:00" },
          { label: "Sunday 10:00-11:00 ", value: "Sunday 10:00-11:00 " },
        ]);
        break;
      case "DevelopmentSquad":
        setData([
          { label: "Monday 17:00-18:00", value: "Monday 17:00-18:00" },
          { label: "Thursday 17:00-18:00", value: "Thursday 17:00-18:00" },
          { label: "Saturday 09:00-10:00", value: "Saturday 09:00-10:00" },
          { label: "Saturday 09:00-10:00 ", value: "Saturday 09:00-10:00 " },
        ]);
        break;
      default:
        break;
    }
  };
  const onOkHandler = async () => {
    setLoading(true); // Bắt đầu loading
    try {
      await handleSave(formData); // Gọi hàm lưu dữ liệu
      setFormData({
        ...formData,
        userId: selectedMember.UserID,
        userType: selectedMember.UserType,
        swimmerName: selectedMember.swimmerName,
      });
      // Thực hiện điều hướng hoặc các hành động khác nếu cần
    } catch (error) {
      console.error("Lỗi khi lưu dữ liệu:", error);
    } finally {
      setLoadingRegister(false); // Kết thúc loading
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      timeString: [data[0].value],
    });
  }, [data]);

  const handleSave = (data) => {
    setLoading(true);

    apiClubs(data)
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          setOpenReqModal(true);
          setresponseData(response.data.data);
          setOpenModalMemberClub(false);
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="pt-[120px] flex flex-col gap-6">
      <div className="w-[65%] mx-auto pv:max-md:w-[90%] flex flex-col gap-2">
        <p className="text-4xl">Flying Fish BOOKING </p>
        <ul className="list-disc pl-10 text-2xl flex flex-col gap-2">
          <li>Competitive swimming training for 7-18yr olds</li>
          <li>VND 2,500,000 per month</li>
          <li>Train between 2-5 times per week</li>
        </ul>
      </div>
      <div className="w-[65%] mt-[10px] mx-auto flex flex-col gap-10  pv:max-md:w-[90%] text-xl  md:max-dv:text-lg">
        <div className="flex flex-col gap-2">
          {/* <p className=" text-black text-xl md:max-dv:text-lg">Squad Times</p> */}
          <img className="" src={imgSchedule2}></img>
        </div>
      </div>

      <div className="w-[65%] mx-auto flex flex-col gap-10  pv:max-md:w-[90%] md:max-dv:text-lg">
        <div className="bg-white p-10 flex flex-col gap-2 pv:max-md:px-0">
          <img className="" src={imgSchedule}></img>
        </div>
        <p className=" text-black text-xl md:max-dv:text-lg">
          CHOOSE YOUR SQUAD AND TRAINING DAYS
        </p>
      </div>

      <div className="w-[65%] mx-auto flex flex-col gap-10  pv:max-md:w-[90%] md:max-dv:text-lg">
        <Radio.Group
          name="radiogroup"
          // defaultValue={"Gold Squad"}
          value={formData.squad}
          buttonStyle="solid"
          size="large"
          className="flex flex-col gap-4"
          onChange={(e) => {
            setFormData({
              ...formData,
              squad: e.target.value,
            });
            hanleOnchage(e.target.value);
          }}
        >
          {dataLevel.map((item, index) => {
            return (
              <Radio value={item.key} key={index}>
                <p className="text-xl md:max-dv:text-lg">{item.title}</p>
              </Radio>
            );
          })}
        </Radio.Group>
      </div>

      <div className="w-[60%] mx-auto flex flex-col gap-10  pv:max-md:w-[90%] bg-blue-50 my-10 p-4 text-2xl">
        <Checkbox.Group
          options={data}
          defaultValue={data[0].value}
          value={formData.timeString}
          onChange={(e) => {
            setFormData({
              ...formData,
              timeString: e,
            });
          }}
          className="flex flex-col gap-4 text-xl"
        />
      </div>
      <div className="w-[60%] mx-auto ">
        {/* <p className="">Swimmer Name *</p>
        <Input
          className="p-4 h-4"
          placeholder="Nguyen Van A"
          onChange={(e) => {
            setFormData({
              ...formData,
              swimmerName: e.target.value,
            });
          }}
        ></Input> */}
      </div>

      <div className="flex justify-center pb-10 pt-5 pv:max-lg:pt-0">
        <button
          disabled={loading}
          className="w-[350px] text-xl md:max-dv:text-lg   p-4 px-2 uppercase bg-[#dcd3d1] rounded-full text-black hover:bg-secondary2 hover:text-white underline md:max-lg:w-[100%] pv:max-lg:w-[300px]  pv:max-md:p-2 "
          onClick={() => {
            handleOpenModalSelectMember();
          }}
        >
          {loading ? "Saving..." : "REGISTER SWIMMER"}
        </button>
      </div>

      {openReqModal ? (
        <Modal
          open={openReqModal}
          onCancel={() => {
            setOpenReqModal(!openReqModal);
            setFormData({ ...formData, swimmerName: "" });
          }}
          onOk={() => {
            setOpenReqModal(!openReqModal);
            setFormData({ ...formData, swimmerName: "" });
            navigate("../" + path.HOME);
          }}
          okText={"DONE"}
          cancelText={"Register for another member ?"}
        >
          <Result
            status="success"
            title="THANK YOU FOR REGISTRATION TO THE FLYING FISH PROGRAMME. WE WILL CONTACT YOU SOON REGARDING THE DETAILS OF THE CLASS"
          />
        </Modal>
      ) : null}

      {openModalMemberClub ? (
        <Modal
          open={openModalMemberClub}
          onCancel={() => {
            setOpenModalMemberClub(!openModalMemberClub);
            // setFormData({ ...formData, swimmerName: "" });
          }}
          onOk={onOkHandler}
          okText={"DONE"}
          width={"60%"}
          confirmLoading={loadingResgister} // Hiển thị trạng thái loading khi onOk được nhấn
        >
          <div className="w-[95%] mx-auto my-10 rounded-xl">
            <div className="flex justify-center gap-8 pv:max-md:flex-col pv:max-md:items-center flex-wrap pv:max-md:flex-nowrap w-full">
              <div className="w-[250px] pv:max-md:w-[220px]">
                <CardMemberShip
                  code={user.code}
                  img={user.pictureUrl}
                  name={user.description}
                  year={user.dateOfBirth}
                  icon={"el:adult"}
                  selectedMember={selectedMember}
                  UserType="main"
                  handleChangeMember={handleChangeMember}
                  gender={user.gender}
                />
              </div>

              {dataMember?.childMembers.length !== 0
                ? dataMember?.childMembers.map((item, index) => (
                    <div className="w-[250px] pv:max-md:w-[220px]" key={index}>
                      <CardMemberShip
                        code={item.code}
                        img={item.pictureUrl}
                        name={item.description}
                        year={item.dateOfBirth}
                        icon={"bx:child"}
                        selectedMember={selectedMember}
                        UserType="sub-child"
                        handleChangeMember={handleChangeMember}
                        gender={item.gender}
                      />
                    </div>
                  ))
                : null}

              {dataMember?.adultMembers.length !== 0
                ? dataMember?.adultMembers.map((item, index) => (
                    <div className="w-[250px] pv:max-md:w-[220px]" key={index}>
                      <CardMemberShip
                        code={item.code}
                        img={item.pictureUrl}
                        name={item.description}
                        year={item.dateOfBirth}
                        icon={"el:adult"}
                        selectedMember={selectedMember}
                        UserType="sub-adult"
                        handleChangeMember={handleChangeMember}
                        gender={item.gender}
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Clubs;
