/** @format */

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { path } from "../../utils/constant";

const CardChooseLesscon = ({
  img,
  title,
  pathUrl,
  textButton,
  desc,
  order,
}) => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const navigate = useNavigate();
  const handleClick = (key) => {
    navigate("../" + pathUrl, {
      state: {
        data: {
          title: title,
          img: img,
          pathUrl: pathUrl,
        },
      },
    });
  };

  return (
    <div
      className={`w-full     ${
        order ? "bg-[#c7c2b6]" : ""
      }  py-24 pv:max-lg:py-4`}
    >
      <div
        className={`w-[65%]  mx-auto gap-10  grid grid-cols-3 pv:max-sm:w-[95%] pv:max-lg:grid-cols-1 pv:max-sm:gap-6 sm:max-md:w-[95%] sm:max-md:mx-auto lg:max-dv:w-[90%] lg:max-dv:mx-auto `}
      >
        <div
          className={`col-span-1 flex flex-col justify-between pv:max-sm:gap-4 pv:max-sm:w-[95%] pv:max-sm:mx-auto sm:max-lg:w-[60%] sm:max-lg:mx-auto sm:max-md:gap-4
          ${order ? "" : "order-1"} 
          `}
        >
          <div className="pv:max-lg:w-[70%]  ">
            <p className="uppercase text-[#1b4235] font-medium text-5xl pv:max-sm:text-5xl sm:max-md:text-5xl md:max-lg:text-2xl lg:max-dv:text-[3.2vw]">
              {title}
            </p>
          </div>
          <div className="">
            <p className="text-[#391b0b] text-2xl leading-10 pv:max-sm:text-[20px] pv:max-sm:leading-8 md:max-lg:text-lg lg:max-dv:text-[2vw]">
              {desc}
            </p>
          </div>
          <div className="">
            <button
              className="w-full rounded-full bg-[#ece7e3] py-4"
              onClick={() => {
                // pathURL data fake
                if (pathUrl === true) {
                  window.location = "mailto:contact@aquaticshub.vn";
                } else {
                  handleClick();
                }
              }}
            >
              <p className="uppercase text-2xl  hover:font-bold hover:underline pv:max-sm:text-xl md:max-lg:text-lg ">
                {textButton}
              </p>
            </button>
          </div>
        </div>
        <div
          className={`col-span-2 pv:max-ph:col-span-1 w-[100%]  md:max-lg:mx-auto  `}
        >
          <img
            className="w-full lg:max-dv:h-[400px] h-[450px] mx-auto   object-cover pv:max-md:h-[55vw] md:max-lg:h-[350px]"
            src={img}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default CardChooseLesscon;
