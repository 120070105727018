import { useNavigate, useLocation } from "react-router-dom";
import { formatCurrency } from "../../../../utils/constant";

import PriceTable from "./Sections/PriceTable";

const RentalPool = () => {
  const location = useLocation();
  const DataState = location.state;

  const columnsLaneHire = [
    { title: "" },
    { title: "1 Lane" },
    { title: "2 Lane" },
    { title: "3 Lane" },
    { title: "4 Lane" },
    { title: "5 Lane" },
    { title: "6 Lane" },
    { title: "7 Lane" },
  ];

  const dataPerLane = [
    {
      laneHire: "1 Hour",
      lane01: 1000000,
      lane02: 900000,
      lane03: 800000,
      lane04: 750000,
      lane05: 700000,
      lane06: 700000,
      lane07: 650000,
    },
    {
      laneHire: "2 Hours",
      lane01: 900000,
      lane02: 800000,
      lane03: 750000,
      lane04: 700000,
      lane05: 700000,
      lane06: 650000,
      lane07: 600000,
    },
    {
      laneHire: "3 Hours",
      lane01: 900000,
      lane02: 800000,
      lane03: 750000,
      lane04: 700000,
      lane05: 700000,
      lane06: 650000,
      lane07: 600000,
    },
    {
      laneHire: "4 Hours",
      lane01: 750000,
      lane02: 700000,
      lane03: 700000,
      lane04: 650000,
      lane05: 650000,
      lane06: 600000,
      lane07: 600000,
    },
  ];

  return (
    <div>
      <div className="w-full relative pv:max-sm:pt-[90px] ">
        <img
          alt=""
          className="w-full h-[70vh] object-cover"
          src={DataState?.img}
        ></img>
        <div className="z-10 absolute top-[30%] left-[5%] w-[500px] pv:max-ph:w-[300px] pv:max-md:w-[94%]">
          <p className="text-start uppercase font-bold text-white text-5xl pv:max-md:left-[-10%] w-[100%] pv:max-md:text-xl pv:max-md:top-[50%] pb-8">
            {DataState?.title}
          </p>
          <p className="line-clamp-5 text-lg text-white pv:max-sm:text-[16px]">
            {DataState?.description}
          </p>
        </div>

        <div className="w-full h-full bg-gradient-to-t from-slate-800 absolute top-0 left-0"></div>
      </div>
      {DataState?.type !== "schoolRental" ? (
        <>
          <p className="text-4xl mt-8 text-center pv:max-sm:text-lg font-roboto_bold">
            Lane Prices
          </p>
          <div className="bg-white mx-auto my-8 overflow-x-auto no-scrollbar lg:w-[90%]">
            <PriceTable
              columnsLaneHire={columnsLaneHire}
              dataPerLane={dataPerLane}
            />
          </div>
          <p className="my-4 text-4xl text-center pv:max-sm:text-lg font-roboto_bold">
            FROM VND {formatCurrency(DataState?.price)}/ CLASS
          </p>
        </>
      ) : (
        <div className="font-bold text-3xl flex items-center justify-center p-10">
          Weekly morning and early afternoon slots are available during term
          time. {formatCurrency(DataState?.price)} per hour
        </div>
      )}

      <div className="flex justify-center pb-10 pt-5">
        <button
          className="w-[150px] pv:max-lg:w-[45%] p-4 px-2 bg-black rounded-lg text-primary hover:bg-secondary hover:text-black"
          onClick={() => {}}
        >
          <p
            className=" font-roboto_bold "
            onClick={() => {
              window.location = "mailto:contact@aquaticshub.vn";
            }}
          >
            Contact
          </p>
        </button>
      </div>
    </div>
  );
};

export default RentalPool;
