/** @format */

import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { path, encryptString } from "../../utils/constant";
import { formatCurrency } from "../../utils/constant";
const CardPool = ({ PictureURL, Description, Detail, Code, t }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("../" + path.POOLDETAIL + `/${encryptString({ str: Code })}`);
  };

  //Multi Lang
  const { i18n } = useTranslation();
  return (
    <>
      <div className="h-[500px] relative bg-white rounded-md cursor-pointer hover:shadow-2xl  border-2 border-white  hover:border-2 hover:border-[#9623F0] pv:max-pvmax:h-[650px]">
        <div
          className="h-full"
          // onClick={() => {
          //   handleNavigate();
          // }}
        >
          <div
            className="bg-no-repeat bg-cover bg-center h-[40%] rounded-t-md flex items-end justify-start"
            style={{ backgroundImage: `url("${PictureURL}")` }}
          ></div>
          <div className="flex gap-2 w-[90%] mx-auto py-2">
            <p className="text-[16px] font-roboto_bold">{`${Description}`}</p>
          </div>

          <div className="mx-auto w-[90%] py-2 flex gap-2">
            <Icon
              icon={"tabler:clock-filled"}
              width={24}
              height={24}
              color="#9623F0"
            ></Icon>
            <p className="text-black font-roboto_bold">{`3 hours`}</p>
          </div>

          <div className="mx-auto w-[90%] py-2  h-[120px]  ">
            <p className="text-black opacity-70 line-clamp-5">{Detail}</p>
          </div>
        </div>
        <button
          className="p-[10px] px-[15px] rounded-lg mx-auto bg-secondary1 text-white z-[2] absolute left-[50%] bottom-[10px]"
          style={{ transform: "translate(-50%)" }}
          onClick={() => {
            navigate("../" + path.CONTACTUS);
          }}
        >
          Contact us
        </button>
      </div>
    </>
  );
};

export default withTranslation()(CardPool);
