// /** @format */

// import React, { useState } from "react";
// import { Modal, Button, Alert, Steps, message } from "antd";
// import { Radio } from 'antd';

// const ModalQuiz = ({ visible, onCancel, questions = [], onComplete }) => {

//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [userAnswers, setUserAnswers] = useState(
//     Array(questions.length).fill("0")
//   );

//   const handleCancel = () => {
//     setUserAnswers(Array(questions.length).fill("0"));
//     onCancel();
//   };

//   const isLastQuestion = currentQuestionIndex === questions.length - 1;
//   const isFirstQuestion = currentQuestionIndex === 0;
//   const isQuizCompleted = currentQuestionIndex === questions.length;
//   const steps = [
//     {
//       // title: 'Starfish',
//       content: questions.slice(0, 2).map((item, index) => {
//         return (
//           <>
//             <p className="text-2xl font-bold my-2">
//               Question {index + 1}:{" "}
//               {item?.question}
//             </p>
//             <Radio.Group
//               name="Q1_2"
//               onChange={(e) => {
//                 userAnswers[index] = e.target.value
//               }}
//             >
//               {item?.options.map((option, i) => {
//                 if (i < 2) {
//                   const isSelected = userAnswers[index] == "1" ? "Yes" : "No"
//                   return (
//                     <Radio value={(option == "Yes" ? "1" : "0")}
//                       checked={isSelected == option}
//                     >{option}</Radio>
//                   )
//                 }
//               }
//               )}
//             </Radio.Group>
//           </>
//         )
//       })
//     },
//     {
//       // title: 'Seahorse',
//       content: questions.slice(2, 4).map((item, index) => {
//         return (
//           <>
//             <p className="text-2xl font-bold my-2">
//               Question {index + 2 + 1}:{" "}
//               {item?.question}
//             </p>
//             <Radio.Group
//               name="Q3_4"
//               onChange={(e) => {
//                 userAnswers[index + 2] = e.target.value
//               }}
//             >
//               {item?.options.map((option, i) => {
//                 if (i < 2) {
//                   const isSelected = userAnswers[index + 2] == "1" ? "Yes" : "No"
//                   return (
//                     <Radio value={(option == "Yes" ? "1" : "0")}
//                       checked={isSelected == option}
//                     >{option}</Radio>
//                   )
//                 }
//               }
//               )}
//             </Radio.Group>
//           </>
//         )
//       })
//     },
//     {
//       // title: '3',
//       content: questions.slice(4, 6).map((item, index) => {
//         return (
//           <>
//             <p className="text-2xl font-bold my-2">
//               Question {index + 4 + 1}:{" "}
//               {item?.question}
//             </p>
//             <Radio.Group
//               name="Q5_6"
//               onChange={(e) => {
//                 userAnswers[index + 4] = e.target.value
//               }}
//             >
//               {item?.options.map((option, i) => {
//                 if (i < 2) {
//                   const isSelected = userAnswers[index + 4] == "1" ? "Yes" : "No"
//                   return (
//                     <Radio value={(option == "Yes" ? "1" : "0")}
//                       checked={isSelected == option}
//                     >{option}</Radio>
//                   )
//                 }
//               }
//               )}
//             </Radio.Group>
//           </>
//         )
//       })
//     },
//     {
//       // title: '4',
//       content: questions.slice(6, 7).map((item, index) => {
//         return (
//           <>
//             <p className="text-2xl font-bold my-2">
//               Question {index + 6 + 1}:{" "}
//               {item?.question}
//             </p>
//             <Radio.Group
//               name="Q7"
//               onChange={(e) => {
//                 userAnswers[index + 6] = e.target.value
//               }}
//             >
//               {item?.options.map((option, i) => {
//                 if (i < 2) {
//                   const isSelected = userAnswers[index + 6] == "1" ? "Yes" : "No"
//                   return (
//                     <Radio value={(option == "Yes" ? "1" : "0")}
//                       checked={isSelected == option}
//                     >{option}</Radio>
//                   )
//                 }
//               }
//               )}
//             </Radio.Group>
//           </>
//         )
//       })
//     },
//     {
//       // title: '5',
//       content: questions.slice(7, 10).map((item, index) => {
//         return (
//           <>
//             <p className="text-2xl font-bold my-2">
//               Question {index + 7 + 1}:{" "}
//               {item?.question}
//             </p>
//             <Radio.Group
//               name="Q8_10"
//               onChange={(e) => {
//                 userAnswers[index + 7] = e.target.value
//               }}
//             >
//               {item?.options.map((option, i) => {
//                 if (i < 2) {
//                   const isSelected = userAnswers[index + 7] == "1" ? "Yes" : "No"
//                   return (
//                     <Radio value={(option == "Yes" ? "1" : "0")}
//                       checked={isSelected == option}
//                     >{option}</Radio>
//                   )
//                 }
//               }
//               )}
//             </Radio.Group>
//           </>
//         )
//       })
//     },
//     {
//       // title: '6',
//       content: questions.slice(10, 12).map((item, index) => {
//         return (
//           <>
//             <p className="text-2xl font-bold my-2">
//               Question {index + 9 + 1}:{" "}
//               {item?.question}
//             </p>
//             <Radio.Group
//               name="Q11_12"
//               onChange={(e) => {
//                 userAnswers[index + 9] = e.target.value
//               }}
//             >
//               {item?.options.map((option, i) => {
//                 if (i < 2) {
//                   const isSelected = userAnswers[index + 9] == "1" ? "Yes" : "No"
//                   return (
//                     <Radio value={(option == "Yes" ? "1" : "0")}
//                       checked={isSelected == option}
//                     >{option}</Radio>
//                   )
//                 }
//               }
//               )}
//             </Radio.Group>
//           </>
//         )
//       })
//     },
//     {
//       // title: '7',
//       content: <>
//         <p className="text-2xl font-bold my-2">
//           Question {12 + 1}:{" "}
//           {questions[12]?.question}
//         </p>
//         <Radio.Group
//           name="Q13"
//           onChange={(e) => {
//             userAnswers[12] = e.target.value
//           }}
//         >
//           {questions[12]?.options.map((option, i) => {
//             if (i < 2) {
//               const isSelected = userAnswers[12] == "1" ? "Yes" : "No"
//               return (
//                 <Radio value={(option == "Yes" ? "1" : "0")}
//                   checked={isSelected == option}
//                 >{option}</Radio>
//               )
//             }
//           }
//           )}
//         </Radio.Group>
//       </>
//     },
//   ];

//   const [current, setCurrent] = useState(0);
//   const next = () => {
//     switch (current) {
//       case 0:
//         if (userAnswers[0] !== "0" && userAnswers[1] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;
//       case 1:
//         if (userAnswers[2] !== "0" || userAnswers[3] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;
//       case 2:
//         if (userAnswers[4] !== "0" || userAnswers[5] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;
//       case 3:
//         if (userAnswers[6] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;
//       case 4:
//         if (userAnswers[7] !== "0" || userAnswers[8] !== "0" || userAnswers[9] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;
//       case 5:
//         if (userAnswers[10] !== "0" || userAnswers[11] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;
//       case 6:
//         if (userAnswers[12] !== "0") {
//           setCurrent(current + 1);
//         } else {
//           onComplete(userAnswers)
//         }
//         break;

//       default:
//         break;
//     }
//   };
//   const prev = () => {
//     setCurrent(current - 1);
//   };
//   const items = steps.map((item) => ({
//     // key: item.title,
//     title: item.title,
//   }));
//   const contentStyle = {};

//   return (
//     <div>
//       <Modal
//         width={800}
//         visible={visible}
//         onOk={handleCancel}
//         onCancel={handleCancel}
//         footer={null}
//       >
//         <Steps current={current} items={items} />
//         <div style={contentStyle}>{steps[current].content}</div>
//         <div
//           style={{
//             marginTop: 24,
//           }}
//         >
//           {current < steps.length - 1 && (
//             <Button type="primary" onClick={() => next()}>
//               Next
//             </Button>
//           )}
//           {current === steps.length - 1 && (
//             <Button type="primary" onClick={() => onComplete(userAnswers)}>
//               Done
//             </Button>
//           )}
//           {current > 0 && (
//             <Button
//               style={{
//                 margin: '0 8px',
//               }}
//               onClick={() => prev()}
//             >
//               Previous
//             </Button>
//           )}
//         </div>
//       </Modal >
//     </div >
//   );
// };

// export default ModalQuiz;

/** @format */

import React, { useState } from "react";
import { Modal, Button, Alert } from "antd";

const ModalQuiz = ({ visible, onCancel, questions = [], onComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    Array(questions.length).fill("0")
  );

  const [showAlert, setShowAlert] = useState(false);

  const handleAnswerSelect = (index, answer) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = answer;
      return newAnswers;
    });
  };

  const handleNextQuestion = () => {
    if (!userAnswers[currentQuestionIndex]) {
      setShowAlert(true);
    } else {
      if (userAnswers[currentQuestionIndex] == "0") {
        onComplete(userAnswers);
      } else {
        setShowAlert(false);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleCancel = () => {
    // Clear user answers when modal is canceled
    setUserAnswers(Array(questions.length).fill("0"));
    setCurrentQuestionIndex(0);
    onCancel();
  };

  const isLastQuestion = currentQuestionIndex === questions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;
  const isQuizCompleted = currentQuestionIndex === questions.length;
  const splitStringByDelimiter = (inputString, delimiter) => {
    return inputString.split(delimiter).map((substring) => substring.trim());
  };
  return (
    <div>
      <Modal
        width={800}
        visible={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button
            key="previous"
            disabled={isFirstQuestion}
            onClick={handlePreviousQuestion}
          >
            Previous
          </Button>,
          isQuizCompleted ? (
            <Button
              className="bg-blue-500 text-white"
              key="complete"
              type="primary"
              onClick={() => {
                onComplete(userAnswers);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className="bg-blue-500 text-white"
              key="next"
              type="primary"
              onClick={() => {
                isLastQuestion ? onComplete(userAnswers) : handleNextQuestion();
              }}
            >
              {isLastQuestion ? "Complete" : "Next"}
            </Button>
          ),
        ]}
      >
        {showAlert && (
          <Alert
            message="Please select an answer before proceeding"
            type="warning"
            showIcon
            closable
            onClose={() => setShowAlert(false)}
            className="my-4 w-[80%] mx-auto"
          />
        )}
        <div>
          <div className="absolute bottom-6 left-6">
            <span className="text-sm">
              {currentQuestionIndex + 1}/{questions.length}
            </span>
          </div>
          <div>
            <p className="text-2xl font-bold my-2">
              Question {currentQuestionIndex + 1}:
            </p>
            <ul>
              {splitStringByDelimiter(
                questions[currentQuestionIndex]?.question,
                "/"
              ).map((item, index) => {
                return <li className="text-xl">{item}</li>;
              })}
            </ul>
            <ul>
              {questions[currentQuestionIndex]?.options.map(
                (option, index) =>
                  option !== "" && (
                    <li key={index}>
                      <label className="block cursor-pointer py-1 px-2 rounded-md transition-colors hover:bg-blue-300">
                        <input
                          type="checkbox"
                          className="mr-2 cursor-pointer appearance-none w-4 h-4 rounded-full border border-gray-300 checked:bg-purple-500 checked:border-transparent focus:outline-none"
                          checked={
                            userAnswers[currentQuestionIndex] ===
                            (option == "Yes" ? "1" : "0")
                          }
                          onChange={(e) => {
                            handleAnswerSelect(
                              currentQuestionIndex,
                              option == "Yes" ? "1" : "0"
                            );
                          }}
                        />
                        <span className="text-xl">{option}</span>
                      </label>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
        {/* <iframe
          src="https://quiz.tryinteract.com/#/5ea00138d8d33f001453fb68"
          className="w-full h-[550px] pv:max-lg:h-[400px] lg:max-xl:h-[470px]"
        /> */}
      </Modal>
    </div>
  );
};

export default ModalQuiz;
