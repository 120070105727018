import { Modal, Form, Input, notification } from "antd";
import { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";

const { TextArea } = Input;

const ModalRequestTrial = (props) => {
  const { open, onCancel, onOk, t } = props;
  const [data, setData] = useState({
    email: "",
    phone: "",
    name: "",
    request: "",
  });

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={() => {
        onOk(data);
        setData({
          email: "",
          phone: "",
          name: "",
          request: "",
        });
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
    >
      <Form labelCol={{ span: 3 }} labelAlign="left">
        <Form.Item label={t("modal_request_trial_label_email")}>
          <Input
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t("modal_request_trial_label_phone")}>
          <Input
            value={data.phone}
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t("modal_request_trial_label_name")}>
          <Input
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t("modal_request_trial_label_request")}>
          <TextArea
            rows={4}
            value={data.request}
            onChange={(e) => setData({ ...data, request: e.target.value })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ModalRequestTrial);
