import React, { useState, useEffect } from "react";
import { Table, Modal, DatePicker, Select, notification } from "antd";
import { withTranslation, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { apiAddadultMember } from "../../services/usermember";

const ModalOTP = ({ t, openOTPform, handleCancel }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Assuming OTP is 4 digits
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsExpired(true);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input field
      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otpValue = otp.join("");
    if (otpValue.length === otp.length) {
      // Handle OTP submission here
    } else {
      setError("Please enter all OTP digits.");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <div>
      <Modal
        open={openOTPform}
        title={
          <div className="flex justify-center items-center">
            <p className="text-[24px] font-roboto_bold">Verify OTP</p>
          </div>
        }
        onCancel={handleCancel}
        footer={[]}
      >
        <div className="flex flex-col items-center mt-6 gap-2">
          <div className="">
            <p className="text-lg text-blue-500">
              Please enter the OTP code just sent to your email
            </p>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <div className="flex space-x-2 mb-4">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  maxLength="1"
                  className="w-12 h-12 text-center text-2xl border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isExpired}
                />
              ))}
            </div>
            {error && <div className="text-red-500 mb-2">{error}</div>}
            <button
              type="submit"
              className={`px-6 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                isExpired ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isExpired}
            >
              Submit
            </button>
            <div className="mt-4 text-gray-700">
              {isExpired ? (
                <span className="text-red-500">Time Expired</span>
              ) : (
                <span>Time Left: {formatTime(timeLeft)}</span>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ModalOTP);
