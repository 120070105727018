import React, { useEffect } from "react"
import {path} from "../../utils/constant"
import { Outlet, useNavigate } from "react-router-dom"

const ProtectedRoute = ({ isAllowed, children, redirectPath }) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAllowed) {
            return navigate(redirectPath ? redirectPath : `${path.LOGIN}`)
        }
    }, []);

    return (<>
        {isAllowed
            ? children ? children : <Outlet />
            : <></>}
    </>)
}

export default ProtectedRoute