import { Calendar } from "antd";
// import { Loading, CommonCard } from "../../components";
import { Loading } from "../../components";
import { useState, useEffect } from "react";
// import "./styles.css";
import { Icon } from "@iconify/react";
import { apiGetLectureTimetable } from "../../services/lecture";
import { path, encryptString } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SidebarAdmin, HeaderAdmin } from "../../components";

const InstructorCalendar = ({ t }) => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));
  const newDate = new Date();
  const months = [
    {
      MonthNum: "01",
      Description: t("in_calendar_January"),
      ShortDescription: "Jan",
    },
    {
      MonthNum: "02",
      Description: t("in_calendar_February"),
      ShortDescription: "Feb",
    },
    {
      MonthNum: "03",
      Description: t("in_calendar_March"),
      ShortDescription: "Mar",
    },
    {
      MonthNum: "04",
      Description: t("in_calendar_April"),
      ShortDescription: "Apr",
    },
    {
      MonthNum: "05",
      Description: t("in_calendar_May"),
      ShortDescription: "May",
    },
    {
      MonthNum: "06",
      Description: t("in_calendar_June"),
      ShortDescription: "Jun",
    },
    {
      MonthNum: "07",
      Description: t("in_calendar_July"),
      ShortDescription: "Jul",
    },
    {
      MonthNum: "08",
      Description: t("in_calendar_August"),
      ShortDescription: "Aug",
    },
    {
      MonthNum: "09",
      Description: t("in_calendar_September"),
      ShortDescription: "Sep",
    },
    {
      MonthNum: "10",
      Description: t("in_calendar_October"),
      ShortDescription: "Oct",
    },
    {
      MonthNum: "11",
      Description: t("in_calendar_November"),
      ShortDescription: "Nov",
    },
    {
      MonthNum: "12",
      Description: t("in_calendar_December"),
      ShortDescription: "Dec",
    },
  ];
  const [selectedDate, setSelectedDate] = useState(
    newDate.getFullYear() +
      "" +
      (Number(newDate.getMonth()) + 1) +
      "" +
      (newDate.getDate() < 10
        ? `0${newDate.getDate()}`
        : `${newDate.getDate()}`)
  );
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isLoadingCount, setIsLoadingCount] = useState(true);

  const [lectureTimeTable, setLectureTimeTable] = useState({
    Timetable: [],
    Today: [
      {
        Number: "",
        StartDate: "",
        EndDate: "",
        ViceLecture: "",
        Lecture: "",
        Enrolled: 0,
        Capacity: 0,
        SelectedStudentInAttendanceList: 0,
        SelectedStudentInPendingList: 0,
        Lesson: {
          Code: "",
          Description: "",
          Detail: "",
          Level: "",
          ModuleNumber: 0,
          Modules: [],
          PictureURL: "",
          Price: 1,
        },
      },
    ],
  });

  //   useEffect(() => {
  //     const fetchTimetable = async () => {
  //       const response = await apiGetLectureTimetable({
  //         LectureCode: "000000001",
  //         Date: selectedDate,
  //       });

  //       if (response?.status === 200) {
  //         setLectureTimeTable(response.data.LectureTimetable);
  //         setIsLoadingTable(false);
  //         setIsLoadingCount(false);
  //       }
  //     };
  //     fetchTimetable();
  //   }, [selectedDate]);

  const getCountEnrollment = (dateStr) => {
    const countObj = lectureTimeTable.Timetable.find((obj) => {
      return obj.FullDay === dateStr;
    });
    return countObj;
  };

  const handleNavigate = (courseCode) => {
    navigate(
      "../" +
        path.INSTRUCTORCOURSEDETAIL +
        `/${encryptString({ str: courseCode })}`
    );
  };
  const [resize, setResize] = useState(false);
  const handleclick = (value) => {
    setResize(value);
  };
  return (
    <>
      <div className="pt-[100px]">
        <div className="w-full ">
          <HeaderAdmin />
        </div>
        <div className={`w-full grid grid-cols-4`}>
          <div className={`col-span-1`}>
            <SidebarAdmin handleclick={handleclick} />
          </div>
          <div className="col-span-3 my-10 grid-cols-2 grid">
            <div className=" col-span-1">
              {/* {isLoadingTable ? (
            <Loading />
          ) : (
            <div>
              <Calendar
                className="instructor_calendar  "
                cellRender={(e) => {
                  const countObj = getCountEnrollment(
                    e.$y +
                      "" +
                      months[String(e.$M)].MonthNum +
                      "" +
                      (e.$D < 10 ? "0" + e.$D : e.$D)
                  );
                  if (countObj !== undefined) {
                    if (countObj.EnrollmentCount > 0) {
                      return (
                        <div className="_instructor-calendar_enrollmentCount font-bold text-white text-[12px] absolute top-[-30%] right-[-45%] rounded-md w-6 h-4 bg-red-400 flex items-center justify-center">
                          {countObj.EnrollmentCount}
                        </div>
                      );
                    }
                  }
                }}
                headerRender={(value) => {
                  return (
                    <div className="mb-[10px] flex justify-between items-center w-full ">
                      <div
                        onClick={() => {
                          value.onChange(value.value.add(-1, "months"));
                        }}
                      >
                        <Icon
                          icon="mingcute:left-fill"
                          className="w-[24px] h-[24px] cursor-pointer"
                        />
                      </div>
                      <div className="text-lg font-bold">
                        {months[String(value.value.$M)].Description} -{" "}
                        {value.value.$y}
                      </div>
                      <div
                        onClick={() => {
                          value.onChange(value.value.add(1, "months"));
                        }}
                      >
                        <Icon
                          icon="mingcute:right-fill"
                          className="w-[24px] h-[24px] cursor-pointer"
                        />
                      </div>
                    </div>
                  );
                }}
                fullscreen={false}
                onSelect={(e) => {
                  setIsLoadingCount(true);
                  setSelectedDate(
                    e.$y +
                      "" +
                      months[String(e.$M)].MonthNum +
                      "" +
                      (e.$D < 10 ? "0" + e.$D : e.$D)
                  );
                }}
              />
            </div>
          )} */}
              <div>
                <Calendar
                  className="instructor_calendar  "
                  cellRender={(e) => {
                    const countObj = getCountEnrollment(
                      e.$y +
                        "" +
                        months[String(e.$M)].MonthNum +
                        "" +
                        (e.$D < 10 ? "0" + e.$D : e.$D)
                    );
                    if (countObj !== undefined) {
                      if (countObj.EnrollmentCount > 0) {
                        return (
                          <div className="_instructor-calendar_enrollmentCount font-bold text-white text-[12px] absolute top-[-30%] right-[-45%] rounded-md w-6 h-4 bg-red-400 flex items-center justify-center">
                            {countObj.EnrollmentCount}
                          </div>
                        );
                      }
                    }
                  }}
                  headerRender={(value) => {
                    return (
                      <div className="mb-[10px] flex justify-between items-center w-full ">
                        <div
                          onClick={() => {
                            value.onChange(value.value.add(-1, "months"));
                          }}
                        >
                          <Icon
                            icon="mingcute:left-fill"
                            className="w-[24px] h-[24px] cursor-pointer"
                          />
                        </div>
                        <div className="text-lg font-bold">
                          {months[String(value.value.$M)].Description} -{" "}
                          {value.value.$y}
                        </div>
                        <div
                          onClick={() => {
                            value.onChange(value.value.add(1, "months"));
                          }}
                        >
                          <Icon
                            icon="mingcute:right-fill"
                            className="w-[24px] h-[24px] cursor-pointer"
                          />
                        </div>
                      </div>
                    );
                  }}
                  fullscreen={false}
                  onSelect={(e) => {
                    setIsLoadingCount(true);
                    setSelectedDate(
                      e.$y +
                        "" +
                        months[String(e.$M)].MonthNum +
                        "" +
                        (e.$D < 10 ? "0" + e.$D : e.$D)
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-span-1">
              <div className="bg-white w-[95%] p-4 rounded-xl mx-auto pv:max-pvmax:w-[90%]  ">
                <p className="font-bold">
                  {t("in_calendar_number_class_today")}:{" "}
                  {/* {lectureTimeTable.Today.length} */}5
                </p>
              </div>
              {/* {isLoadingCount ? (
            <Loading />
          ) : (
            <div className="overflow-y-scroll w-[95%] no-scrollbar flex flex-col gap-[1rem] flex-1 h-[85%] flex-nowrap mx-auto  pv:max-pvmax:w-[90%]  ">
              {lectureTimeTable.Today.length !== 0 ? (
                lectureTimeTable.Today.map((item, index) => {
                  return (
                    <div
                      className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                      onClick={() => handleNavigate(item.Number)}
                    >
                      <div
                        className={`w-[20px] ${
                          item.Lesson.Level === "Beginner"
                            ? "bg-blue-400"
                            : item.Lesson.Level === "Immediate"
                            ? "bg-yellow-400"
                            : "bg-red-400"
                        } rounded-l-xl`}
                      ></div>
                      <div className="flex flex-col justify-center">
                        <div className="font-bold pv:max-pvmax:text-lg">
                          {item.Lesson.Description}
                        </div>
                        <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                          <div className="flex gap-[10px] items-center">
                            <div>
                              <Icon icon="mingcute:time-line" />
                            </div>
                            <div className="text-slate-400">8:00 - 8:30</div>
                          </div>
                          <div className="flex gap-[10px] items-center">
                            <div>
                              <Icon icon="ic:outline-people" />
                            </div>
                            <div className="text-slate-400">
                              {item.Enrolled} students
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          )} */}
              <div className="overflow-y-scroll w-[95%] no-scrollbar flex flex-col gap-[1rem] flex-1 h-[85%] flex-nowrap mx-auto  pv:max-pvmax:w-[90%]  ">
                {/* {lectureTimeTable.Today.length !== 0 ? (
              lectureTimeTable.Today.map((item, index) => {
                return (
                  <div
                    className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                    onClick={() => handleNavigate(item.Number)}
                  >
                    <div
                      className={`w-[20px] ${
                        item.Lesson.Level === "Beginner"
                          ? "bg-blue-400"
                          : item.Lesson.Level === "Immediate"
                          ? "bg-yellow-400"
                          : "bg-red-400"
                      } rounded-l-xl`}
                    ></div>
                    <div className="flex flex-col justify-center">
                      <div className="font-bold pv:max-pvmax:text-lg">
                        {item.Lesson.Description}
                      </div>
                      <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                        <div className="flex gap-[10px] items-center">
                          <div>
                            <Icon icon="mingcute:time-line" />
                          </div>
                          <div className="text-slate-400">8:00 - 8:30</div>
                        </div>
                        <div className="flex gap-[10px] items-center">
                          <div>
                            <Icon icon="ic:outline-people" />
                          </div>
                          <div className="text-slate-400">
                            {item.Enrolled} students
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )} */}

                <div
                  className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                  //   onClick={() => handleNavigate(item.Number)}
                >
                  <div
                    // className={`w-[20px] ${
                    //   item.Lesson.Level === "Beginner"
                    //     ? "bg-blue-400"
                    //     : item.Lesson.Level === "Immediate"
                    //     ? "bg-yellow-400"
                    //     : "bg-red-400"
                    // } rounded-l-xl`}

                    className="w-[20px] rounded-l-xl bg-red-400"
                  ></div>
                  <div className="flex flex-col justify-center">
                    <div className="font-bold pv:max-pvmax:text-lg">
                      {/* {item.Lesson.Description} */}
                      Fast track
                    </div>
                    <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="mingcute:time-line" />
                        </div>
                        <div className="text-slate-400">8:00 - 8:30</div>
                      </div>
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="ic:outline-people" />
                        </div>
                        <div className="text-slate-400">
                          {/* {item.Enrolled} students */}
                          20 students
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                  //   onClick={() => handleNavigate(item.Number)}
                >
                  <div
                    // className={`w-[20px] ${
                    //   item.Lesson.Level === "Beginner"
                    //     ? "bg-blue-400"
                    //     : item.Lesson.Level === "Immediate"
                    //     ? "bg-yellow-400"
                    //     : "bg-red-400"
                    // } rounded-l-xl`}

                    className="w-[20px] rounded-l-xl bg-red-400"
                  ></div>
                  <div className="flex flex-col justify-center">
                    <div className="font-bold pv:max-pvmax:text-lg">
                      {/* {item.Lesson.Description} */}
                      Fast track
                    </div>
                    <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="mingcute:time-line" />
                        </div>
                        <div className="text-slate-400">10:00 - 10:30</div>
                      </div>
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="ic:outline-people" />
                        </div>
                        <div className="text-slate-400">
                          {/* {item.Enrolled} students */}
                          20 students
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                  //   onClick={() => handleNavigate(item.Number)}
                >
                  <div
                    // className={`w-[20px] ${
                    //   item.Lesson.Level === "Beginner"
                    //     ? "bg-blue-400"
                    //     : item.Lesson.Level === "Immediate"
                    //     ? "bg-yellow-400"
                    //     : "bg-red-400"
                    // } rounded-l-xl`}

                    className="w-[20px] rounded-l-xl bg-yellow-300"
                  ></div>
                  <div className="flex flex-col justify-center">
                    <div className="font-bold pv:max-pvmax:text-lg">
                      {/* {item.Lesson.Description} */}
                      Fast track
                    </div>
                    <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="mingcute:time-line" />
                        </div>
                        <div className="text-slate-400">14:00 - 14:30</div>
                      </div>
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="ic:outline-people" />
                        </div>
                        <div className="text-slate-400">
                          {/* {item.Enrolled} students */}
                          20 students
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                  //   onClick={() => handleNavigate(item.Number)}
                >
                  <div
                    // className={`w-[20px] ${
                    //   item.Lesson.Level === "Beginner"
                    //     ? "bg-blue-400"
                    //     : item.Lesson.Level === "Immediate"
                    //     ? "bg-yellow-400"
                    //     : "bg-red-400"
                    // } rounded-l-xl`}

                    className="w-[20px] rounded-l-xl bg-yellow-400"
                  ></div>
                  <div className="flex flex-col justify-center">
                    <div className="font-bold pv:max-pvmax:text-lg">
                      {/* {item.Lesson.Description} */}
                      Fast track
                    </div>
                    <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="mingcute:time-line" />
                        </div>
                        <div className="text-slate-400">15:00 - 15:30</div>
                      </div>
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="ic:outline-people" />
                        </div>
                        <div className="text-slate-400">
                          {/* {item.Enrolled} students */}
                          20 students
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=" pv:max-xl:max-h-full rounded-xl shadow-md bg-white w-full h-full min-w-fit max-h-[22%] flex gap-[20px] p-[0px] cursor-pointer"
                  //   onClick={() => handleNavigate(item.Number)}
                >
                  <div
                    // className={`w-[20px] ${
                    //   item.Lesson.Level === "Beginner"
                    //     ? "bg-blue-400"
                    //     : item.Lesson.Level === "Immediate"
                    //     ? "bg-yellow-400"
                    //     : "bg-red-400"
                    // } rounded-l-xl`}

                    className="w-[20px] rounded-l-xl bg-blue-400"
                  ></div>
                  <div className="flex flex-col justify-center">
                    <div className="font-bold pv:max-pvmax:text-lg">
                      {/* {item.Lesson.Description} */}
                      Fast track
                    </div>
                    <div className="flex gap-[30px] pv:max-ph:flex-col pv:max-ph:gap-2">
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="mingcute:time-line" />
                        </div>
                        <div className="text-slate-400">16:00 - 16:30</div>
                      </div>
                      <div className="flex gap-[10px] items-center">
                        <div>
                          <Icon icon="ic:outline-people" />
                        </div>
                        <div className="text-slate-400">
                          {/* {item.Enrolled} students */}
                          20 students
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(InstructorCalendar);
