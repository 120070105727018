import { useState } from "react";
import { path } from "../../utils/constant";
import { useNavigate } from "react-router-dom";

const CardFasttrack = ({
  dataState,
  index,
  name,
  startDate,
  endDate,
  status,
  onClick
}) => {
  const navigate = useNavigate();

  const [isCardHover, setCardHover] = useState(false);

  return (
    <div
      className={`p-[15px] flex gap-[20px] items-center
        ${
          status == "Open"
            ? "hover:scale-110 duration-700 cursor-pointer border-t-4 border-red-800"
            : "cursor-not-allowed"
        }
        `}
      onClick={() => {
        if (status == "Open" && onClick) {
          onClick()
        }
      }}
      onMouseOver={() => {
        setCardHover(true);
      }}
      onMouseLeave={() => {
        setCardHover(false);
      }}
    >
      <div className="text-4xl text-primary font-roboto_bold">0{index}</div>
      <div className="flex flex-col gap-[10px]">
        <div className="font-roboto_bold text-primary text-xl">{name}</div>
        <div className="text-primary">
          {startDate} - {endDate}
        </div>
        <button
          className={`px-4 py-2 rounded-xl text-xs text-red-800 font-roboto_bold ${
            isCardHover ? "bg-red-800 text-white" : "border border-red-800"
          }`}
        >
          <p className="">Learn more</p>
        </button>
      </div>
    </div>
  );
};

export default CardFasttrack;
