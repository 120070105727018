/** @format */

import React, { useState, useEffect } from "react";
import "./style.css";
import {
  path,
  encryptString,
  formatCurrency,
  courseObject,
} from "../../../../utils/constant";
import { apiThirdStage } from "../../../../services/stageDetails";
import { Table, Empty } from "antd";
import BreakTimeImg from "../../../../assets/breaktime.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Loading,
  TimeTable,
  TimeTable_v2,
  ModalChooseLevel,
} from "../../../../components";
import { withTranslation, useTranslation } from "react-i18next";
const AdultCourses = ({ t }) => {
  const location = useLocation();
  const dataState = location.state.data;

  const [isLoading, setIsLoading] = useState(true);
  const [dataTimetable, setDataTimetable] = useState({
    MonCourse: [],
    TueCourse: [],
    WedCourse: [],
    ThurCourse: [],
    FriCourse: [],
    SatCourse: [],
    SunCourse: [],
  });
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const [record, setRecord] = useState();
  const [currentSemester, setCurrentSemester] = useState("");
  const [isOpenChooseLevel, setIsOpenChooseLevel] = useState(false);
  const handleNavigate = (recordRow) => {
    setIsOpenChooseLevel(true);
    setRecord(recordRow);
  };
  const handleShowDetail = (e) => {
    setIsOpenChooseLevel(!isOpenChooseLevel);
    if (e) {
      navigate(
        "../" + path.COURSEDETAIL + `/${encryptString({ str: record.key })}`,
        {
          state: {
            EnrollmentType: "Adult",
            enrollment: {
              quiz: {
                instructorLevel: "VietnameseOnly",
                targetID: record.data.type,
              },
              Number: record.key,
              selectedUserInAttendanceList:
                record.data.selectedUserInAttendanceList,
              StartDate:
                record.data.timeTable.length > 0
                  ? record.data.timeTable[0].timeTableDay
                  : "-",
              EndDate:
                record.data.timeTable.length > 0
                  ? record.data.timeTable[record.data.timeTable.length - 1]
                      .timeTableDay
                  : "-",
              Enrolled: record.data.enrolled,
              PictureUrl:
                record?.data.pictureUrl == ""
                  ? dataState.img
                  : record?.data.pictureUrl,
              Description: record.data.description,
              Price: record.data.price,
              Timetable: record.data.timeTable,

              Capacity: record.data.capacity,
              Lecture: {
                Code: record.data.lectureInformation.code,
                Description: record.data.lectureInformation.description,
                PictureUrl: record.data.lectureInformation.pictureUrl,
              },
              Duration: record.data.duration,
            },
            userInfo: e.userInfo,
          },
        }
      );
    }
  };

  useEffect(() => {
    apiThirdStage("Adult")
      .then((response) => {
        let MorningCoursesMonday = [];
        let AfternoonCoursesMonday = [];
        let MorningCoursesTuesday = [];
        let AfternoonCoursesTuesday = [];
        let MorningCoursesWednesday = [];
        let AfternoonCoursesWednesday = [];
        let MorningCoursesThurday = [];
        let AfternoonCoursesThurday = [];
        let MorningCoursesFriday = [];
        let AfternoonCoursesFriday = [];
        let MorningCoursesSaturday = [];
        let AfternoonCoursesSaturday = [];
        let MorningCoursesSunday = [];
        let AfternoonCoursesSunday = [];

        if (response.data.data.class.length > 0) {
          setCurrentSemester(response.data.data.class[0].semester);
          for (
            let index = 0;
            index < response.data.data.class.length;
            index++
          ) {
            const element = response.data.data.class[index];

            if (element.timeTable.length > 0) {
              let Lane = "";
              switch (element.area[0].description.slice(-1)) {
                case "1":
                  Lane = "Lane_1&2";
                  break;
                case "3":
                  Lane = "Lane_3&4";
                  break;
                case "5":
                  Lane = "Lane_5&6";
                  break;

                default:
                  break;
              }

              // switch (element.timeTable[0]?.timeTableWeekDay) {
              switch (element.type) {
                case "Beginner":
                  switch (element.startTime.trim().slice(-2)) {
                    case "AM":
                      MorningCoursesMonday = [
                        ...MorningCoursesMonday,
                        courseObject(element, response, Lane),
                      ];
                      break;
                    case "PM":
                      AfternoonCoursesMonday = [
                        ...AfternoonCoursesMonday,
                        courseObject(element, response, Lane),
                      ];
                      break;

                    default:
                      break;
                  }
                  break;

                case "Technique":
                  switch (element.startTime.trim().slice(-2)) {
                    case "AM":
                      MorningCoursesTuesday = [
                        ...MorningCoursesTuesday,
                        courseObject(element, response, Lane),
                      ];
                      break;
                    case "PM":
                      AfternoonCoursesTuesday = [
                        ...AfternoonCoursesTuesday,
                        courseObject(element, response, Lane),
                      ];
                      break;

                    default:
                      break;
                  }
                  break;

                case "Master":
                  switch (element.startTime.trim().slice(-2)) {
                    case "AM":
                      MorningCoursesWednesday = [
                        ...MorningCoursesWednesday,
                        courseObject(element, response, Lane),
                      ];
                      break;
                    case "PM":
                      AfternoonCoursesWednesday = [
                        ...AfternoonCoursesWednesday,
                        courseObject(element, response, Lane),
                      ];
                      break;

                    default:
                      break;
                  }
                  break;

                // case 4:
                //   switch (element.startTime.trim().slice(-2)) {
                //     case "AM":
                //       MorningCoursesThurday = [
                //         ...MorningCoursesThurday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;
                //     case "PM":
                //       AfternoonCoursesThurday = [
                //         ...AfternoonCoursesThurday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;

                //     default:
                //       break;
                //   }
                //   break;

                // case 5:
                //   switch (element.startTime.trim().slice(-2)) {
                //     case "AM":
                //       MorningCoursesFriday = [
                //         ...MorningCoursesFriday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;
                //     case "PM":
                //       AfternoonCoursesFriday = [
                //         ...AfternoonCoursesFriday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;

                //     default:
                //       break;
                //   }
                //   break;

                // case 6:
                //   switch (element.startTime.trim().slice(-2)) {
                //     case "AM":
                //       MorningCoursesSaturday = [
                //         ...MorningCoursesSaturday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;
                //     case "PM":
                //       AfternoonCoursesSaturday = [
                //         ...AfternoonCoursesSaturday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;

                //     default:
                //       break;
                //   }
                //   break;

                // case 7:
                //   switch (element.startTime.trim().slice(-2)) {
                //     case "AM":
                //       MorningCoursesSunday = [
                //         ...MorningCoursesSunday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;
                //     case "PM":
                //       AfternoonCoursesSunday = [
                //         ...AfternoonCoursesSunday,
                //         courseObject(element, response, Lane),
                //       ];
                //       break;

                //     default:
                //       break;
                //   }
                //   break;

                default:
                  break;
              }
            }
          }
        }

        setDataTimetable({
          MonCourse: MorningCoursesMonday.concat(AfternoonCoursesMonday),
          TueCourse: MorningCoursesTuesday.concat(AfternoonCoursesTuesday),
          WedCourse: MorningCoursesWednesday.concat(AfternoonCoursesWednesday),
          // ThurCourse: MorningCoursesThurday.concat(AfternoonCoursesThurday),
          // FriCourse: MorningCoursesFriday.concat(AfternoonCoursesFriday),
          // SatCourse: MorningCoursesSaturday.concat(AfternoonCoursesSaturday),
          // SunCourse: MorningCoursesSunday.concat(AfternoonCoursesSunday),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(true);
      });
  }, []);

  // const columns = ["Lane_1&2", "Lane_3&4", "Lane_5&6"];
  const columns = ["Lane_1&2"];

  return (
    <div className="w-full">
      <div className="relative flex flex-col gap-8 w-[90%] mx-auto mt-[100px]">
        {/* <img
          alt=""
          className="w-full h-[70vh] object-cover"
          src={dataState.img}
        ></img>
        <div className="z-10 absolute top-[30%] left-[5%]  w-[400px] pv:max-ph:w-[300px] ">
          <p className=" text-start uppercase font-bold text-white text-5xl   pv:max-md:left-[-10%] w-[100%]  pv:max-md:text-xl  pv:max-md:top-[50%] pb-8">
            {dataState.title}
          </p>
        </div>

        <div className="w-full h-full bg-gradient-to-t from-slate-800 absolute top-0 left-0  "></div> */}
        <p className="font-bold text-xl">{t("adult_course_title_1")}</p>
        <p className="font-bold text-xl">{t("adult_course_title_2")}</p>
      </div>

      <div className="pt-10 pv:max-sm:pt-0">
        <div className="bg-white w-[90%] mx-auto pv:max-xl:w-[95%]">
          <div className="py-10">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold text-center">Timetable</p>
            </div>
            {/* <div className="py-5 flex justify-start pl-[15%]">
              <p className="text-2xl text-start">
                Current semester:{" "}
                <span className="font-roboto_bold">{currentSemester}</span>
              </p>
            </div> */}
            <div className=" flex flex-col">
              {isLoading ? (
                <Loading />
              ) : (
                <div className="w-[60%] pv:max-md:w-[90%] md:max-xl:w-[75%] h-fit mx-auto flex flex-col justify-around items-center sticky top-24">
                  {dataTimetable.MonCourse.length > 0 ? (
                    <TimeTable_v2
                      data={dataTimetable.MonCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"BEGINNER SWIMMING"}
                    />
                  ) : null}
                  {dataTimetable.TueCourse.length > 0 ? (
                    <TimeTable_v2
                      data={dataTimetable.TueCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"TECHNIQUE SWIMMING"}
                    />
                  ) : null}
                  {dataTimetable.WedCourse.length > 0 ? (
                    <TimeTable_v2
                      data={dataTimetable.WedCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"MASTERS SWIMMING"}
                    />
                  ) : null}
                  {/* {dataTimetable.ThurCourse.length > 0 ? (
                    <TimeTable
                      data={dataTimetable.ThurCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"Thursday"}
                    />
                  ) : null}
                  {dataTimetable.FriCourse.length > 0 ? (
                    <TimeTable
                      data={dataTimetable.FriCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"Friday"}
                    />
                  ) : null}
                  {dataTimetable.SatCourse.length > 0 ? (
                    <TimeTable
                      data={dataTimetable.SatCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"Saturday"}
                    />
                  ) : null}
                  {dataTimetable.SunCourse.length > 0 ? (
                    <TimeTable
                      data={dataTimetable.SunCourse}
                      columns={columns}
                      mainAttribute="time"
                      columnAttribute="Lane"
                      handleClick={handleNavigate}
                      Weekday={"Sunday"}
                    />
                  ) : null} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpenChooseLevel ? (
        <ModalChooseLevel
          isShowModal={isOpenChooseLevel}
          handleShowDetail={handleShowDetail}
          type={"Adult"}
          currentLevel={dataState}
        />
      ) : null}
    </div>
  );
};

export default withTranslation()(AdultCourses);
