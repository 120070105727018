/** @format */
import axiosConfig from "../axiosConfig";
import { notification } from "antd";

export const apigetMetadata = async () => {
  try {
    const response = await axiosConfig({
      method: "GET",
      url: "/v1/user-metadata",
    });

    return response;
  } catch (error) {
    notification.error({
      message: "Something has occurred, please try again",
      placement: "topRight",
      duration: 2,
    });
    console.error(error.message);
  }
};

export const apiContactUs = async ({ email, phone, name, request }) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/v1/user-contact-club",
      data: {
        email: email,
        phone: phone,
        name: name,
        request: request,
      },
    });

    return response;
  } catch (error) {
    notification.error({
      message: "Something has occurred, please try again",
      placement: "topRight",
      duration: 2,
    });
    console.error(error.message);
  }
};
