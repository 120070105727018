/** @format */
import img1 from "../assets/img_1.jpg";
import img2 from "../assets/img_2.jpg";
import img3 from "../assets/img_3.jpg";
import img4 from "../assets/img_4.png";
import imgqs1 from "../assets/qs1.jpg";
import imgqs2 from "../assets/qs2.jpg";
import imgqs3 from "../assets/qs3.jpg";
import imgqs4 from "../assets/qs4.png";
import imgqs5 from "../assets/qs5.png";
import imgqs6 from "../assets/qs6.png";
import imgqs7 from "../assets/qs7.png";
import imgqs8 from "../assets/qs8.jpg";
import imgqs9 from "../assets/qs9.jpg";
import imgPool from "../assets/mainpool.png";
import imgteach1 from "../assets/teach_1.jpg";
import imgteach2 from "../assets/teach_2.jpg";
import imgteach3 from "../assets/teach_3.jpg";
import imgteach4 from "../assets/teach_4.jpg";
import imgdiamondmaster from "../assets/Diamond_master.png";
import imgdiamondbeginner from "../assets/Diamond_beginer.png";
import imgdiamondtechnique from "../assets/Diamond_technique.png";
// img membership
import membership_1 from "../assets/imgmembership_1.jpg";
import membership_2 from "../assets/imgmembersgip_2.jpg";
import membership_3 from "../assets/imgmembership_3.jpeg";
import membership_4 from "../assets/imgmembership4.jpg";

import { Icon } from "@iconify/react";
import imgswimminglesson from "../assets/img_swimminglesson.png";
import imgadultcourse from "../assets/img_adultcourse.png";
import imgclub from "../assets/img_club.png";
import imgfasttrack from "../assets/img_fasttrack.png";
import imgtraining from "../assets/img_training.png";

import imgclubtype_1 from "../assets/typeclub_1.png";
import imgclubtype_2 from "../assets/typeclub_2.png";
import imgclubtype_3 from "../assets/typeclub_3.png";

import imgweekfasttrack_1 from "../assets/week_fasttrack_1.png";
import imgweekfasttrack_2 from "../assets/week_fasttrack_2.png";
import imgweekfasttrack_3 from "../assets/week_fasttrack_3.png";
import { Divider, Dropdown, Progress, Select, Table } from "antd";

import imgChildrendSwimmingLesson from "../assets/Final_PIctures/childrendswimminglesson.jpg";
import imgPublicSwimming from "../assets/Final_PIctures/public_swimming.jpg";
import imgFlyingFish from "../assets/Final_PIctures/FlyingFish.jpg";
import imgAdultclass from "../assets/Final_PIctures/adultclass.jpg";
import imgSwimmingRental from "../assets/Final_PIctures/swimminglanerental.jpg";
import imgSchoolSwimming from "../assets/Final_PIctures/schoolswimming.jpg";
import imgPoolparty from "../assets/Final_PIctures/pool-party.jpeg";
import imgAquarium from "../assets/Final_PIctures/aquarium.jpg";
import imgAquarium_1 from "../assets/Final_PIctures/aqua_1.jpg";
import imgAquarium_2 from "../assets/Final_PIctures/aqua_2.jpg";
import { path } from "./constant";
export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 5,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
// test

export const productData = [
  {
    id: 1,
    img: img1,
    Title: "Swimming course",
    nameCourse: "Little Frogs : 18 -36 Months",
    description:
      "Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities",
    countLesson: "32",
    countEnrolled: "5",
    price: "300",
  },
  {
    id: 2,
    img: img2,
    Title: "Swimming course",
    nameCourse: "Little Frogs : 18 -36 Months",
    description:
      "Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities",
    countLesson: "32",
    countEnrolled: "5",
    price: "300",
  },
  {
    id: 3,
    img: img3,
    Title: "Swimming course",
    nameCourse: "Little Frogs : 18 -36 Months",
    description:
      "Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities",
    countLesson: "32",
    countEnrolled: "5",
    price: "300",
  },
  {
    id: 4,
    img: img4,
    Title: "Swimming course",
    nameCourse: "Little Frogs : 18 -36 Months",
    description:
      "Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities",
    countLesson: "32",
    countEnrolled: "5",
    price: "300",
  },
];

export const poolData = [
  {
    id: 1,
    hours: "3 hours",
    img: imgPool,
    Title: "Swimming course",
    description:
      "Dive into our main pool at Swimmerpool and enjoy a refreshing swim that will invigorate your body and mind. Our pool is perfect for swimmers of all levels, whether you’re looking to improve your technique or just want to cool off on a hot day",
    price: "5",
  },
  {
    id: 2,
    hours: "3 hours",
    img: imgPool,
    Title: "Swimming course",
    description:
      "Dive into our main pool at Swimmerpool and enjoy a refreshing swim that will invigorate your body and mind. Our pool is perfect for swimmers of all levels, whether you’re looking to improve your technique or just want to cool off on a hot day",
    price: "5",
  },
  {
    id: 3,
    hours: "3 hours",
    img: imgPool,
    Title: "Swimming course",
    description:
      "Dive into our main pool at Swimmerpool and enjoy a refreshing swim that will invigorate your body and mind. Our pool is perfect for swimmers of all levels, whether you’re looking to improve your technique or just want to cool off on a hot day",
    price: "5",
  },
];
export const lectureData = [
  {
    id: 1,
    position: "Swimming instructor",
    img: imgteach1,
    fullname: "Deborah Fanning",
  },
  {
    id: 2,
    position: "Swimming instructor",
    img: imgteach2,
    fullname: "Deborah Fanning",
  },
  {
    id: 3,
    position: "Swimming instructor",
    img: imgteach3,
    fullname: "Deborah Fanning",
  },
  {
    id: 4,
    position: "Swimming instructor",
    img: imgteach4,
    fullname: "Deborah Fanning",
  },
];

export const questions_list = [
  {
    question:
      "Our teachers are in the water with their students, figuring out what each kid needs to learn",
    number: "01.",
    url: imgqs1,
  },
  {
    question:
      "Friendly amenities like our hair drying station and private changing rooms",
    number: "02.",
    url: imgqs2,
  },
  {
    question:
      "Each teacher is truly engaged in finding each kid's motivation to achieve progress",
    number: "03.",
    url: imgqs3,
  },
  {
    question: "An emotionally and intellectually stimulating environment",
    number: "04.",
    url: imgqs4,
  },
  {
    question:
      "A wide variety of available program options, including infant and toddler swim lessons",
    number: "05.",
    url: imgqs5,
  },
  {
    question:
      "We offer a wide range of comprehensive pool management services.",
    number: "06.",
    url: imgqs6,
  },
  {
    question:
      "Srict pool safety rules in place to ensure that all guests can enjoy the pool without any accidents or injuries.",
    number: "07.",
    url: imgqs7,
  },
  {
    question: "Come and visit us to enjoy a safe and fun day at the pool!",
    number: "08.",
    url: imgqs8,
  },
  {
    question:
      "Each teacher is truly engaged in finding each kid's motivation to achieve progress",
    number: "09.",
    url: imgqs9,
  },
];

export const imglist = [
  {
    url: imgqs1,
  },
  {
    url: imgqs2,
  },
  {
    url: imgqs3,
  },
];

export const dataCourse = [
  {
    key: "1",
    courseId: "01",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "2",
    courseId: "02",
    courseName: <p className="font-roboto_bold">"Swim Advanced"</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "3",
    courseId: "03",
    courseName: <p className="font-roboto_bold">"Little Frog 2."</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "4",
    courseId: "04",
    courseName: <p className="font-roboto_bold">"School Of Fish."</p>,
    status: ["pending"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "5",
    courseId: "05",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    status: ["pending"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "6",
    courseId: "06",
    courseName: <p className="font-roboto_bold">"Little Frog 1"</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "7",
    courseId: "07",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "8",
    courseId: "08",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "9",
    courseId: "09",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "10",
    courseId: "10",
    courseName: <p className="font-roboto_bold">Master Of Swimming...</p>,
    status: ["active"],
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
];
export const dataReview = [
  {
    key: "1",
    student: "Mac Alister",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px] dh:w-[800px]">
        <p className="text-black opacity-70 line-clamp-4 dh:line-clamp-2">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "2",
    student: "Mac Alister",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px] dh:w-[800px]">
        <p className="text-black opacity-70 line-clamp-4 dh:line-clamp-2">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "3",
    student: "Mac Alister",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px] dh:w-[800px]">
        <p className="text-black opacity-70 line-clamp-4 dh:line-clamp-2">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "4",
    student: "Mac Alister",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px] dh:w-[800px]">
        <p className="text-black opacity-70 line-clamp-4  dh:line-clamp-2">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "5",
    student: "Mac Alister",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px] dh:w-[800px]">
        <p className="text-black opacity-70 line-clamp-4 dh:line-clamp-2">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
  {
    key: "6",
    student: "Mac Alister",
    courseName: <p className="font-roboto_bold">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px] dh:w-[800px]">
        <p className="text-black opacity-70 line-clamp-4 dh:line-clamp-2">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
    action: (
      <button className="hover:text-red-500 text-black">
        <Icon icon={"mdi:bin"} className="w-6 h-6 "></Icon>
      </button>
    ),
  },
];

export const columnCourse = [
  {
    title: "CourseId",
    dataIndex: "courseId",
    key: "courseId",
  },
  {
    title: "CourseName",
    dataIndex: "courseName",
    key: "courseName",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        {status.map((st) => {
          let color = st.length > 5 ? "geekblue" : "green";
          if (st === "active") {
            color = "text-green-500";
          } else if (st === "pending") {
            color = "text-orange-500";
          }
          return (
            <p className={`${color} font-roboto_bold`} key={st}>
              {st}
            </p>
          );
        })}
      </>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];
export const columnReview = [
  {
    title: "Student",
    dataIndex: "student",
    key: "student",
  },
  {
    title: "CourseName",
    dataIndex: "courseName",
    key: "courseName",
  },
  {
    title: "Rating",
    dataIndex: "rating",
    key: "rating",
  },
  {
    title: "Review",
    dataIndex: "review",
    key: "review",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const columnReviewUser = [
  {
    title: "Student",
    dataIndex: "student",
    key: "student",
  },
  {
    title: "CourseName",
    dataIndex: "courseName",
    key: "courseName",
  },
  {
    title: "Rating",
    dataIndex: "rating",
    key: "rating",
  },
  {
    title: "Review",
    dataIndex: "review",
    key: "review",
  },
];
export const dataReviewUser = [
  {
    key: "1",
    student: (
      <div className="flex gap-2 justify-center items-center">
        <div className="">
          <img className="h-10 w-10 rounded-full" src={imgteach3}></img>
        </div>
        <div className="flex flex-col">
          <p className="">Tay Nguyen Van</p>
          <p className="text-gray-400">taynv@info.com</p>
        </div>
      </div>
    ),
    courseName: <p className="">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px]   ">
        <p className="text-black opacity-70 line-clamp-4">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
  },
  {
    key: "2",
    student: (
      <div className="flex gap-2 justify-center items-center">
        <div className="">
          <img className="h-10 w-10 rounded-full" src={imgteach3}></img>
        </div>
        <div className="flex flex-col">
          <p className="">Tay Nguyen Van</p>
          <p className="text-gray-400">taynv@info.com</p>
        </div>
      </div>
    ),
    courseName: <p className="">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px]   ">
        <p className="text-black opacity-70 line-clamp-4">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
  },
  {
    key: "3",
    student: (
      <div className="flex gap-2 justify-center items-center">
        <div className="">
          <img className="h-10 w-10 rounded-full" src={imgteach3}></img>
        </div>
        <div className="flex flex-col">
          <p className="">Tay Nguyen Van</p>
          <p className="text-gray-400">taynv@info.com</p>
        </div>
      </div>
    ),
    courseName: <p className="">"Master Of Swimming..."</p>,
    rating: (
      <div className="flex">
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
        <Icon
          icon={"material-symbols:star"}
          className="w-6 h-6 text-yellow-500"
        ></Icon>
      </div>
    ),
    review: (
      <div className=" w-[200px]   ">
        <p className="text-black opacity-70 line-clamp-4">{`Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities Classes conducted with adults in the water. The little ones start their adventure in the water with the use of games and activities`}</p>
      </div>
    ),
  },
];
export const listNote = [
  {
    key: "1",
    Title: "Start a great new day with full energy. Love everyone",
    Date: "november 18, 2023",
    author: "Yen Ngoc Nguyen",
  },
  {
    key: "2",
    Title: "It's extremely cloudy today",
    Date: "December 20, 2022",
    author: "Yen Ngoc Nguyen",
  },
  {
    key: "3",
    Title:
      "This Friday there is a routine swimming test, you have to go 15 minutes early to prepare everything",
    Date: "november 18, 2023",
    author: "",
  },
  {
    key: "4",
    Title: "There will be a picnic coming up to connect with each other",
    Date: "november 18, 2023",
    author: "Yen Ngoc Nguyen",
  },
  {
    key: "5",
    Title: "Today we have a make-up class and must be on time",
    Date: "november 18, 2023",
    author: "Yen Ngoc Nguyen",
  },
];
const items1 = [
  {
    label: (
      <div className="flex gap-2 items-center">
        <Icon className="w-6 h-6" icon={"clarity:email-line"}></Icon>
        <p className="">Send Email To All</p>
      </div>
    ),
    key: "0",
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <Icon className="w-6 h-6" icon={"pajamas:remove"}></Icon>
        <p className="">Remove Select</p>
      </div>
    ),
    key: "1",
  },
];
const items2 = [
  {
    label: (
      <div className="flex gap-2 items-center">
        <Icon className="w-6 h-6" icon={"carbon:view"}></Icon>
        <p className="">Send Email</p>
      </div>
    ),
    key: "1",
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <Icon className="w-6 h-6" icon={"clarity:email-line"}></Icon>
        <p className="">View Detail</p>
      </div>
    ),
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <Icon className="w-6 h-6" icon={"pajamas:remove"}></Icon>
        <p className="">Remove Select</p>
      </div>
    ),
    key: "3",
  },
];

export const columnStudent = [
  {
    title: "Student",
    dataIndex: "student",
    key: "student",
    sorter: (a, b) => a.student.length - b.student.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Enrolled Courses",
    dataIndex: "enrolledcourses",
    key: "enrolledcourses",
    sorter: (a, b) => a.enrolledcourses.length - b.enrolledcourses.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    sorter: (a, b) => a.address.length - b.address.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (_, { type }) => (
      <>
        {type.map((t) => {
          let color = t.length > 5 ? "geekblue" : "green";
          if (t === "Trial") {
            color = "text-orange-500";
          } else if (t === "Standard") {
            color = "text-blue-500";
          }
          return (
            <p className={`${color} font-roboto_bold `} key={t}>
              {t}
            </p>
          );
        })}
      </>
    ),
    sorter: (a, b) => a.type.length - b.type.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        {status.map((st) => {
          let color = st.length > 5 ? "geekblue" : "green";
          if (st === "active") {
            color = "text-green-500";
          } else if (st === "suspend") {
            color = "text-orange-500";
          }
          return (
            <p className={`${color} font-roboto_bold `} key={st}>
              {st}
            </p>
          );
        })}
      </>
    ),
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: (
      <Dropdown
        menu={{
          items: items1,
        }}
        trigger={["click"]}
        placement="bottomLeft"
      >
        <button className="">
          <Icon icon={"bi:three-dots"} className="w-6 h-6"></Icon>
        </button>
      </Dropdown>
    ),
    dataIndex: "action",
    key: "action",
  },
];

export const columnAllCourse = [
  {
    title: "Course ID",
    dataIndex: "courseid",
    key: "courseid",
    sorter: (a, b) => a.courseid.length - b.courseid.length,
    sortDirections: ["descend", "ascend"],
    responsive: ["xl"],
  },
  {
    title: "Course Name",
    dataIndex: "coursename",
    key: "coursename",
    sorter: (a, b) => a.coursename.length - b.coursename.length,
    sortDirections: ["descend", "ascend"],
    render: (text) => <a>{text}</a>,
  },

  {
    title: "Capacity",
    dataIndex: "capacity",
    key: "capacity",
    sorter: (a, b) => a.capacity.length - b.capacity.length,
    sortDirections: ["descend", "ascend"],
    responsive: ["lg"],
  },
  {
    title: "Enrolled",
    dataIndex: "enrolled",
    key: "enrolled",

    sorter: (a, b) => a.enrolled.length - b.enrolled.length,
    sortDirections: ["descend", "ascend"],
    responsive: ["sm"],
  },

  {
    title: "Pending",
    dataIndex: "pending",
    key: "pending",
    responsive: ["lg"],

    sorter: (a, b) => a.pending.length - b.pending.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
    responsive: ["md"],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        {status.map((st) => {
          let color = st.length > 5 ? "geekblue" : "green";
          let bg = st.length > 5 ? "geekblue" : "green";

          if (st === "Open") {
            color = "text-green-500";
            bg = "bg-green-50";
          } else if (st === "Close") {
            color = "text-orange-500";
            bg = "bg-orange-50";
          }
          return (
            <div className={`p-[2px] ${bg} flex justify-center items-center `}>
              <p className={`${color} font-roboto_bold `} key={st}>
                {st}
              </p>
            </div>
          );
        })}
      </>
    ),
    responsive: ["xl"],
  },
];

export const dataAllCourse = [
  {
    key: "1",
    courseid: "0001",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col items-start  ">
          <p className="font-roboto_bold">School of Fish -</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "0",

    duration: "10 lessons",
    status: ["Open"],
  },
  {
    key: "2",
    courseid: "0002",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School of Fish -</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "0",

    duration: "10 lessons",
    status: ["Open"],
  },
  {
    key: "3",
    courseid: "0003",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School of Fish </p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: 0,

    duration: "12 lessons",
    status: ["Close"],
  },
  {
    key: "4",
    courseid: "0004",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School Of Fish +</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "3",

    duration: "14 lessons",
    status: ["Close"],
  },
  {
    key: "5",
    courseid: "0005",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">Fast Track</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "0",

    duration: "10 lessons",
    status: ["Open"],
  },
  {
    key: "6",
    courseid: "0006",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School of Fish -</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "0",

    duration: "10 lessons",
    status: ["Open"],
  },
  {
    key: "7",
    courseid: "0007",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School of Fish -</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "0",

    duration: "10 lessons",
    status: ["Open"],
  },
  {
    key: "8",
    courseid: "0008",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School of Fish </p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: 0,

    duration: "12 lessons",
    status: ["Close"],
  },
  {
    key: "9",
    courseid: "0009",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">School Of Fish +</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "3",

    duration: "14 lessons",
    status: ["Close"],
  },
  {
    key: "10",
    courseid: "00010",
    coursename: (
      <div className="flex gap-2 items-center">
        <img className="h-10 w-10 object-cover rounded-xl" src={imgqs1}></img>
        <div className="flex flex-col  ">
          <p className="font-roboto_bold">Fast Track</p>
          <p className="">{"(01/01/2024 - 01/04/2024)"}</p>
        </div>
      </div>
    ),
    capacity: "20",
    enrolled: "10",
    pending: "0",

    duration: "10 lessons",
    status: ["Open"],
  },
];

export const columnEnrolled = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
  },

  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },

  {
    title: "Phone Number",
    dataIndex: "phonenumber",
    key: "phonenumber",
  },
];

export const dataEnrolled = [
  {
    key: "1",
    image: <img className="h-8 w-8 rounded-full" src={imgteach1}></img>,
    fullname: <p className="">Thuong Trinh Van</p>,
    age: 18,
    gender: "Male",
    phonenumber: "0345 282 532",
  },
  {
    key: "2",
    image: <img className="h-8 w-8 rounded-full" src={imgteach2}></img>,
    fullname: <p className="">Huong Do Mai</p>,
    age: 11,
    gender: "Male",
    phonenumber: "0365 582 274",
  },
  {
    key: "3",
    image: <img className="h-8 w-8 rounded-full" src={imgteach3}></img>,
    fullname: <p className="">Khoi Le Cong</p>,
    age: 7,
    gender: "Male",
    phonenumber: "0789 456 123",
  },
  {
    key: "4",
    image: <img className="h-8 w-8 rounded-full" src={imgteach4}></img>,
    fullname: <p className="">Thuan Le Van</p>,
    age: 6,
    gender: "Male",
    phonenumber: "0345 282 532",
  },
  {
    key: "5",
    image: <img className="h-8 w-8 rounded-full" src={imgteach1}></img>,
    fullname: <p className="">Tay Nguyen Van</p>,
    age: 12,
    gender: "Male",
    phonenumber: "0456 489 478",
  },
];

export const columnPending = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
  },

  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },

  {
    title: "Phone Number",
    dataIndex: "phonenumber",
    key: "phonenumber",
  },
];

export const dataPending = [
  {
    key: "1",
    image: <img className="h-8 w-8 rounded-full" src={imgteach1}></img>,
    fullname: <p className="">Liem Duy Luong</p>,
    age: 6,
    gender: "Male",
    phonenumber: "0345 282 532",
  },
  {
    key: "2",
    image: <img className="h-8 w-8 rounded-full" src={imgteach2}></img>,
    fullname: <p className="">Vinh Phan Nguyen</p>,
    age: 12,
    gender: "Male",
    phonenumber: "0365 582 274",
  },
];
export const LessonTest = [
  {
    key: "1",
    title: "Lesson 1",
    description: "Water Comfort and Safety",
  },
  {
    key: "2",
    title: "Lesson 2",
    description: "Breathing",
  },
  {
    key: "3",
    title: "Lesson 3",
    description: "Floating",
  },
  {
    key: "4",
    title: "Lesson 4",
    description: "Kicking",
  },
  {
    key: "5",
    title: "Lesson 5",
    description: " Arm Movement",
  },
  {
    key: "6",
    title: "Lesson 6",
    description: " Treading Water",
  },
  {
    key: "7",
    title: "Lesson 7",
    description: "Breathing in the Water",
  },
  {
    key: "8",
    title: "Lesson 8",
    description: "Safety Awareness",
  },
  {
    key: "9",
    title: "Lesson 9",
    description: "Swim with an instructor",
  },
  {
    key: "10",
    title: "Lesson 10",
    description: "Practice",
  },
  {
    key: "1",
    title: "Lesson 1",
    description: "Water Comfort and Safety",
  },
  {
    key: "2",
    title: "Lesson 2",
    description: "Breathing",
  },
  {
    key: "3",
    title: "Lesson 3",
    description: "Floating",
  },
  {
    key: "4",
    title: "Lesson 4",
    description: "Kicking",
  },
  {
    key: "5",
    title: "Lesson 5",
    description: " Arm Movement",
  },
  {
    key: "6",
    title: "Lesson 6",
    description: " Treading Water",
  },
  {
    key: "7",
    title: "Lesson 7",
    description: "Breathing in the Water",
  },
  {
    key: "8",
    title: "Lesson 8",
    description: "Safety Awareness",
  },
  {
    key: "9",
    title: "Lesson 9",
    description: "Swim with an instructor",
  },
  {
    key: "10",
    title: "Lesson 10",
    description: "Practice",
  },
  {
    key: "1",
    title: "Lesson 1",
    description: "Water Comfort and Safety",
  },
  {
    key: "2",
    title: "Lesson 2",
    description: "Breathing",
  },
  {
    key: "3",
    title: "Lesson 3",
    description: "Floating",
  },
  {
    key: "4",
    title: "Lesson 4",
    description: "Kicking",
  },
  {
    key: "5",
    title: "Lesson 5",
    description: " Arm Movement",
  },
  {
    key: "6",
    title: "Lesson 6",
    description: " Treading Water",
  },
  {
    key: "7",
    title: "Lesson 7",
    description: "Breathing in the Water",
  },
  {
    key: "8",
    title: "Lesson 8",
    description: "Safety Awareness",
  },
  {
    key: "9",
    title: "Lesson 9",
    description: "Swim with an instructor",
  },
  {
    key: "10",
    title: "Lesson 10",
    description: "Practice",
  },
];

export const dataSourcematrix = [
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach1}
        ></img>
        <p className="font-roboto_bold">Liem Luong Duy</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200 ">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach2}
        ></img>
        <p className="font-roboto_bold">Khoi Le Cong Minh</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach3}
        ></img>
        <p className="font-roboto_bold">Van Thuong Trinh</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach4}
        ></img>
        <p className="font-roboto_bold">Yen Nguyen Ngoc</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach1}
        ></img>
        <p className="font-roboto_bold">Thuan Le Van</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach2}
        ></img>
        <p className="font-roboto_bold">Hien Pham Gia</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach3}
        ></img>
        <p className="font-roboto_bold">Hoa Nguyen Thi Mai</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach4}
        ></img>
        <p className="font-roboto_bold">Thao Ly Tran Thanh</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach1}
        ></img>
        <p className="font-roboto_bold">Vinh Phan Nguyen Anh</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach2}
        ></img>
        <p className="font-roboto_bold">Huong Do Mai</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
  {
    level_0: (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-12 object-cover rounded-md shadow-sm shadow-black"
          src={imgteach2}
        ></img>
        <p className="font-roboto_bold">Tay Nguyen Van</p>
      </div>
    ),
    level_1: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          7/7
        </div>
      </div>
    ),
    level_2: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={100} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          10/10
        </div>
      </div>
    ),
    level_3: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={40} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          4/10
        </div>
      </div>
    ),
    level_4: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/15
        </div>
      </div>
    ),
    level_5: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/16
        </div>
      </div>
    ),
    level_6: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/20
        </div>
      </div>
    ),
    level_7: (
      <div className="flex w-[100%] cursor-pointer items-center">
        <div className="w-full">
          <Progress type="circle" percent={0} size={30} />
        </div>
        <div className="w-full bg-white flex justify-center items-center p-2 rounded-md shadow-sm shadow-black border-[1px] border-black cursor-pointer hover:bg-blue-200">
          0/10
        </div>
      </div>
    ),
  },
];

export const columnsmatrix = [
  {
    title: (
      <Select
        defaultValue={"hr_1a"}
        className="w-[200px]"
        options={[
          { value: "hr_1a", label: "HR 1A : Kmiec" },
          { value: "hr_2a", label: "HR 2A : Kmiec" },
          { value: "hr_3a", label: "HR 3A : Kmiec" },
        ]}
      />
    ),
    dataIndex: "level_0",
    key: "level_0",
    align: "center",
    fixed: "left",
  },
  {
    title: "Swim Level 1 StartFish",
    dataIndex: "level_1",
    key: "level_1",
    align: "center",
  },
  {
    title: "Swim Level 2 Seahorse",
    dataIndex: "level_2",
    key: "level_2",
    align: "center",
  },
  {
    title: "Swim Level 3 Seal",
    dataIndex: "level_3",
    key: "level_3",
    align: "center",
  },
  {
    title: "Swim Level 4 Barracuda",
    dataIndex: "level_4",
    key: "level_4",
    align: "center",
  },
  {
    title: "Swim Level 5 Swordfish",
    dataIndex: "level_5",
    key: "level_5",
    align: "center",
  },
  {
    title: "Swim Level 6 Dolphin",
    dataIndex: "level_6",
    key: "level_6",
    align: "center",
  },
  {
    title: "Swim Level 7 Shark ",
    dataIndex: "level_7",
    key: "level_7",
    align: "center",
  },
];

export const dataLessonChoose = [
  {
    key: 1,
    img: imgswimminglesson,
    title: "Swimming Lesson",
    desc: "Embark on a splashing adventure of water exploration and skill-building with our Child Swimming Course tailored for young swimmers.",
    color: "#a369fb",
  },
  {
    key: 2,
    img: imgadultcourse,
    title: "Adult Course",
    desc: "Designed for individuals of all experience levels, this course offers a supportive environment where beginners can conquer water apprehension, and intermediate swimmers can refine their strokes.",
    color: "#000000",
  },
  {
    key: 3,
    img: imgclub,
    title: "Club",
    desc: "Dive into the world of competitive swimming excellence with our Club Swimming Course. Tailored for aspiring athletes, this program is designed to elevate swimmers to their highest potential",
    color: "#ba0000",
  },
  {
    key: 4,
    img: imgfasttrack,
    title: "Fast Track",
    desc: "Whether you're a beginner aiming for swift improvement or a swimmer looking to refine your skills in a short timeframe, our Fast Track Swimming Course is your express ticket to aquatic success. ",
    color: "#ba0000",
  },
  {
    key: 5,
    img: imgtraining,
    title: "Training",
    desc: "Immerse yourself in the art and science of swimming with our Training Swimming Course, meticulously crafted for individuals looking to elevate their aquatic prowess.",
    color: "#d95b43",
  },
];

export const dataMembership = [
  {
    key: 1,
    img: membership_1,
    icon: "bx:child",
    name: "Thien Phuc Nguyen",
    year: "3",
  },
  {
    key: 2,
    img: membership_2,
    icon: "bx:child",
    name: "Bao An Nguyen",
    year: "8",
  },
  {
    key: 3,
    img: membership_3,
    icon: "el:adult",
    name: "Thy Tran Ngoc Linh",
    year: "30 ",
  },
  {
    key: 4,
    img: membership_4,
    icon: "el:adult",
    name: "Anh Nguyen Minh",
    year: "32",
  },
];

export const dataTypesAdultCourse = [
  {
    key: "1",
    name: "Beginner",
    img: imgdiamondbeginner,
    desc: "An excellent class for adults who wish to become more comfortable in the water and learn basic strokes. Our instructors work at you pace.",
  },
  {
    key: "2",
    name: "Techquine",
    img: imgdiamondtechnique,
    desc: "A class for adult swimmers who have basic swimming but would like to develop their technique for better efficiency through the water.",
  },
  {
    key: "3",
    name: "Master",
    img: imgdiamondmaster,
    desc: "A class for swimmers who are proficient in multiple strokes and generally swim with good technique. Training off sets is primarily to improve endurance and coordination resulting in further distances and quicker swimming.",
  },
  {
    key: "4",
    name: "Aqua Fitness",
    img: imgdiamondmaster,
    desc: "Aqua fitness is suitable for all ages and fitness abilities. It incorporates a range of movements and exercises aimed at enhancing cardiovascular fitness, strength, flexibility, and overall health. An effective workout experience in a social environment",
  },
];

export const DataTypesClub = [
  {
    key: 1,
    img: imgclubtype_1,
    title: "Development Squad - Learning to Train",
    skill: [
      {
        key: 1,
        desc: "The Development squad is for ages 9 and under. All swimmers must have a good basic knowledge of two or three strokes. More importantly, they must have a keen interest in learning more and great attitude to others and their own progression.",
      },
      {
        key: 2,
        desc: "Up to 4 sessions/week",
      },
      {
        key: 3,
        desc: "Cost 2,500,000/ month",
      },
    ],
  },
  {
    key: 2,
    img: imgclubtype_2,
    title: "Purple Squad - Training to Train",
    skill: [
      {
        key: 1,
        desc: "The Purple squad is for ages 9 to 11. The expectation is that all swimmers are technically efficient in at least three strokes. At the beginning of the year they will be assessed on potential, attitude, ability and speed.",
      },
      {
        key: 2,
        desc: "Up to 4 sessions/week - Cost 2,500,000/ month",
      },
      {
        key: 3,
        desc: "5 sessions/week - Cost 3,000,000/ month",
      },
    ],
  },
  {
    key: 3,
    img: imgclubtype_3,
    title: "Bronze Squad - Training to Compete",
    skill: [
      {
        key: 1,
        desc: "The Bronze squad is for ages 11+. The main focus being on developing racing technique and ‘training to compete’. All swimmers need to have a good understanding of all strokes, turns and starts.",
      },
      {
        key: 2,
        desc: "Up to 4 sessions/week - Cost 2,500,000/ month",
      },
      {
        key: 3,
        desc: "5 sessions/week - Cost 3,000,000/ month",
      },
    ],
  },
  {
    key: 4,
    img: imgclubtype_1,
    title: "Silver Squad",
    skill: [
      {
        key: 1,
        desc: "The Silver squad will focus primarily on technique, endurance and speed for ‘training to compete’. All swimmers need to swim all strokes at a good level. Swimmers will be able to compete in a range of strokes and distances at this level.",
      },
      {
        key: 2,
        desc: "Up to 4 sessions/week - Cost 2,500,000/ month",
      },
      {
        key: 3,
        desc: "5 sessions/week - Cost 3,000,000/ month",
      },
    ],
  },
  {
    key: 5,
    img: imgclubtype_2,
    title: "Gold Squad",
    skill: [
      {
        key: 1,
        desc: "The top standard level is the Gold Squad. This squad is made up of advanced swimmers which are continuing the swimmer pathway of ‘training to compete’. Commitment to training and competition should be high and many advanced drills and techniques are learnt.",
      },
      {
        key: 2,
        desc: "Up to 4 sessions/week - Cost 2,500,000/ month",
      },
      {
        key: 3,
        desc: "5 sessions/week - Cost 3,000,000/ month",
      },
    ],
  },
  {
    key: 6,
    img: imgclubtype_3,
    title: "Elite Squad",
    skill: [
      {
        key: 1,
        desc: "Elite squad members focus on refining their skills into race efficiencies and striving towards National age-group qualifying times. The increased training regime allows swimmers to refine speed, technique, and stroke. This Squad will generally train daily and have individual nutrition and land trying programs as standard.",
      },
      {
        key: 2,
        desc: "Up to 7 sessions/week",
      },
      {
        key: 3,
        desc: "Cost 3,000,000/ month",
      },
    ],
  },
];

export const DataWeekFastTrack = [
  {
    key: 1,
    img: imgweekfasttrack_1,
    title: "Fast Track 1",
    skill: [
      {
        key: 1,
        desc: "Everyday",
      },
      {
        key: 2,
        desc: "2 Weeks",
      },
      {
        key: 3,
        desc: "Same level",
      },
      {
        key: 4,
        desc: "Maximum 8 members",
      },
    ],
  },
  {
    key: 2,
    img: imgweekfasttrack_2,
    title: "Fast Track 2",
    skill: [
      {
        key: 1,
        desc: "Everyday",
      },
      {
        key: 2,
        desc: "2 Weeks",
      },
      {
        key: 3,
        desc: "Same level",
      },
      {
        key: 4,
        desc: "Maximum 8 members",
      },
    ],
  },
  {
    key: 3,
    img: imgweekfasttrack_3,
    title: "Fast Track 3",
    skill: [
      {
        key: 1,
        desc: "Everyday",
      },
      {
        key: 2,
        desc: "2 Weeks",
      },
      {
        key: 3,
        desc: "Same level",
      },
      {
        key: 4,
        desc: "Maximum 8 members",
      },
    ],
  },
];

export const dataLadingPage = [
  {
    img: imgPublicSwimming,
    title: "PUBLIC ​SWIMMING",
    pathUrl: path.PUBLICSWIMMING,
    textButton: "FIND OUT MORE",
    desc: "Explore aquatic enjoyment at Aquatics Hub! Whether it's lap swimming or a fun family day, we cater to all. Join our public sessions for a vibrant swimming community experience.",
  },
  {
    img: imgChildrendSwimmingLesson,
    title: "CHILDREN ​SWIMMING LESSONS",
    pathUrl: path.SWIMMINGLESSON,
    textButton: "BOOK & FIND OUT MORE",
    desc: "Join our School of Fish group lessons ​during the week or on the weekend. ​Small groups with trained instructors ​for fast progression.",
  },
  {
    img: imgFlyingFish,
    title: "FLYING FISH ​SWIM CLUB",
    pathUrl: path.CLUB,
    textButton: "BOOK & FIND OUT MORE",
    desc: "We offer six squads from Development ​to Elite. Training takes place ​throughout the week and on ​weekends for all levels of competitive ​swimmer.",
  },
  {
    img: imgAdultclass,
    title: "ADULT ​SWIMMING COURSES",
    pathUrl: path.ADULTCOURSE,
    textButton: "BOOK & FIND OUT MORE",
    desc: "Evening courses are available for ​beginner, technique and master ​swimmers. Whether you need to gain ​water confidence or train with others, ​come and join our courses.",
  },
  {
    img: imgSwimmingRental,
    title: "SWIMMING LANE RENTAL",
    pathUrl: path.LANERENTAL,
    textButton: "PRICES & CONTACT",
    desc: "Specifically for Hanoi Swimming ​Clubs, we offer lane rental during ​specific times of the week. Lane rental ​includes the entry fee for swimmers ​and coach.",
  },
  {
    img: imgSchoolSwimming,
    title: "SCHOOL ​SWIMMING",
    pathUrl: true,
    textButton: "CO​NTACT US",
    desc: "For local schools and nurseries ​wishing to add swimming to their ​curriculum, the Aquatics Hub can ​provide a quality program with ​instructors during the school day. ​Contact us if interested.",
  },
  {
    img: imgPoolparty,
    title: "POOL PARTY",
    pathUrl: path.POOLPARTY,
    textButton: "PRICES & CONTACT",
    desc: "The ultimate party venue for children’s ​birthday parties. We combine an hour ​of organised pool games with food ​and drink. This is completed with ​decorations and cake.",
  },
];
