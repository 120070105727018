import React, { useState } from "react";
import { Table, Modal, DatePicker, Select, notification } from "antd";
import { withTranslation, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { apiAddadultMember } from "../../services/usermember";

const ModalAddAdult = ({
  t,
  openadult,
  handleCancel,
  months,
  isAddFromProfile = false,
  mainUserCode = "",
}) => {
  //   const [openChild, setOpenChild] = useState(false);
  const currentDate = new Date();
  const [currentMember, setCurrentMember] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: `${currentDate.getFullYear()}${
      months[currentDate.getMonth()].MonthNum
    }${
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate()
    }`,
    gender: "Male",
  });
  const [adultMembers, setAdultMembers] = useState([]);
  return (
    <div>
      <Modal
        open={openadult}
        title={
          <div className="flex justify-center items-center">
            <p className="text-[24px] font-roboto_bold">
              Member Adult infomation
            </p>
          </div>
        }
        onCancel={handleCancel}
        footer={[
          <div className="flex justify-center items-center rounded-lg pt-2">
            <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg">
              <p
                className="text-white text-[20px] font-roboto_bold"
                onClick={async () => {
                  if (isAddFromProfile) {
                    await apiAddadultMember({
                      mainAccountId: mainUserCode,
                      firstName: currentMember?.firstName,
                      lastName: currentMember?.lastName,
                      dateOfBirth: currentMember?.dateOfBirth,
                      gender: currentMember?.gender,
                    })
                      .then((res) => {
                        if (res.status === 201) {
                          notification.success({
                            message: "Member Adult added successfully",
                            placement: "topRight",
                            duration: 2,
                          });
                          setCurrentMember({
                            firstName: "",
                            lastName: "",
                            dateOfBirth: `${currentDate.getFullYear()}${
                              months[currentDate.getMonth()].MonthNum
                            }${
                              currentDate.getDate() < 10
                                ? "0" + currentDate.getDate()
                                : currentDate.getDate()
                            }`,
                            gender: "Male",
                          });
                          handleCancel();
                        }
                      })
                      .catch((err) => {
                        notification.error({
                          message: "Error adding adult",

                          placement: "topRight",
                          duration: 2,
                        });
                      });
                  } else {
                    setAdultMembers([...adultMembers, currentMember]);
                    setCurrentMember({
                      firstName: "",
                      lastName: "",
                      dateOfBirth: `${currentDate.getFullYear()}${
                        months[currentDate.getMonth()].MonthNum
                      }${
                        currentDate.getDate() < 10
                          ? "0" + currentDate.getDate()
                          : currentDate.getDate()
                      }`,
                      gender: "Male",
                    });
                  }
                }}
              >
                Add
              </p>
            </button>
          </div>,
        ]}
      >
        <div className="flex flex-col gap-10">
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-col gap-[10px] w-[49%]">
              <input
                placeholder={t("signup_firstname")}
                className="border-slate-300 outline-none border-b-2 p-[5px] "
                value={currentMember?.firstName}
                onChange={(e) =>
                  setCurrentMember({
                    ...currentMember,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-[49%] flex flex-col gap-[10px]">
              <input
                placeholder={t("signup_lastname")}
                className="border-slate-300 outline-none border-b-2 p-[5px] "
                value={currentMember?.lastName}
                onChange={(e) =>
                  setCurrentMember({
                    ...currentMember,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <DatePicker
            popupStyle={{
              height: "400px",
              overflow: "scroll",
            }}
            className="w-[100%] h-10"
            placeholder={t("signup_dateofbirth")}
            // value={moment(currentMember.dateOfBirth, "YYYYMMDD")}
            value={dayjs(currentMember?.dateOfBirth, "YYYYMMDD")}
            onChange={(e) => {
              if (e != null) {
                if (e.$D < 10) {
                  setCurrentMember({
                    ...currentMember,
                    dateOfBirth: `${e.$y}${months[String(e.$M)].MonthNum}0${
                      e.$D
                    }`,
                  });
                } else {
                  setCurrentMember({
                    ...currentMember,
                    dateOfBirth: `${e.$y}${months[String(e.$M)].MonthNum}${
                      e.$D
                    }`,
                  });
                }
              }
            }}
          />
          <div className="w-[100%] 2xl:w-[80%] md:flex  justify-between">
            <Select
              defaultValue="Male"
              size="large"
              className="w-[100%] md:w-[120px] h-10 mt-4 md:mt-0"
              value={currentMember?.gender}
              onChange={(e) =>
                setCurrentMember({
                  ...currentMember,
                  gender: e,
                })
              }
              options={[
                {
                  value: "Male",
                  label: (
                    <div className="flex gap-[10px] items-center">Male</div>
                  ),
                },
                {
                  value: "Female",
                  label: (
                    <div className="flex gap-[10px] items-center">Female</div>
                  ),
                },
                {
                  value: "Other",
                  label: (
                    <div className="flex gap-[10px] items-center">Other</div>
                  ),
                },
              ]}
            />
          </div>
          <Table
            columns={[
              {
                title: "First name",
                dataIndex: "firstName",
                key: "firstname",
              },
              {
                title: "Last name",
                dataIndex: "lastName",
                key: "lastname",
              },
              {
                title: "DOB",
                dataIndex: "dateOfBirth",
                key: "dob",
              },
              {
                title: "Gender",
                dataIndex: "gender",
                key: "gender",
              },
            ]}
            dataSource={adultMembers}
          />
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ModalAddAdult);
