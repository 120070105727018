/** @format */
import axiosConfig from "../axiosConfig";

export const apisecondstage = async (params) => {
  try {
    const response = await axiosConfig({
      method: "GET",
      url: `/v1/user-second-stage?section=${params}`,
    });

    return response;
  } catch (error) {
    return error;
  }
};
export const apiGetQuizz = async (params) => {
  try {
    const response = await axiosConfig({
      method: "GET",
      url: `/v1/user-quiz?section=${params}`,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const apiPostQuiz = async (payload) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: `/v1/user-update-quiz-level`,
      data: payload
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const apiGetCart = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: `/v1/user-enrollment-cart-detail`,
    data: payload
  });

  return response;
};

export const apiPostCart = async (payload) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: `/v1/user-enrollment-cart`,
      data: payload
    });

    return response;
  } catch (error) {
    return error;
  }
};