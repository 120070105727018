/** @format */

import React, { useState, useEffect } from "react";
import "./style.css";
import ReactPlayer from "react-player/youtube";
import { Table, Empty, Modal } from "antd";
import BreakTimeImg from "../../../../assets/breaktime.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  path,
  encryptString,
  formatCurrency,
  courseObject,
} from "../../../../utils/constant";
import {
  Loading,
  TimeTable,
  ModalChooseLevel,
  TimeTable_v2,
} from "../../../../components";
import { apiThirdStage } from "../../../../services/stageDetails";
import { withTranslation, useTranslation } from "react-i18next";

const ThirdStage = ({ t }) => {
  const location = useLocation();
  const dataState = location.state.data;
  const dataType = location.state.type;
  // const columns = ["Wednesday", "Saturday", "Sunday"];
  const columns = ["Wednesday"];

  const [isLoading, setIsLoading] = useState(true);
  const [dataTimetable, setDataTimetable] = useState({
    MonCourse: [],
    WedCourse: [],
    SatCourse: [],
    SunCourse: [],
    Fasttrack: {
      Fasttrack_1: [],
      Fasttrack_2: [],
      Fasttrack_3: [],
      Fasttrack_4: [],
    },
  });
  const [isOpenPromoModal, setIsOpenPromoModal] = useState(false);
  const [promoVidUrl, setPromoVidUrl] = useState("");
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };
  const [record, setRecord] = useState();
  const [currentSemester, setCurrentSemester] = useState("");
  const [isOpenChooseLevel, setIsOpenChooseLevel] = useState(false);
  const handleShowDetail = (e) => {
    setIsOpenChooseLevel(!isOpenChooseLevel);
    if (e) {
      navigate(
        "../" + path.COURSEDETAIL + `/${encryptString({ str: record?.key })}`,
        {
          state: {
            EnrollmentType: "SOF",
            enrollment: {
              quiz: { ...e },
              selectedUserInAttendanceList:
                record?.data.selectedUserInAttendanceList,
              Number: record?.key,
              StartDate:
                record?.data.timeTable.length > 0
                  ? record?.data.timeTable[0].timeTableDay
                  : "-",
              EndDate:
                record?.data.timeTable.length > 0
                  ? record?.data.timeTable[record?.data.timeTable.length - 1]
                      .timeTableDay
                  : "-",
              Enrolled: record?.data.enrolled,
              PictureUrl:
                record?.data.pictureUrl == ""
                  ? dataState.img
                  : record?.data.pictureUrl,
              Description: record?.data.description,
              Price: record?.data.price,
              Timetable: record?.data.timeTable,

              Capacity: record?.data.capacity,
              Lecture: {
                Code: record?.data.lectureInformation.code,
                Description: record?.data.lectureInformation.description,
                PictureUrl: record?.data.lectureInformation.pictureUrl,
              },
              Duration: record?.data.duration,
            },
            userInfo: e.userInfo,
          },
        }
      );
    }
  };
  const handleNavigate = (recordRow) => {
    setIsOpenChooseLevel(true);
    setRecord(recordRow);
  };

  const handleClickPlay = (record) => {
    setIsOpenPromoModal(true);
    setPromoVidUrl(record.data?.lessonUrl ?? "");
  };
  const handleCancelPlay = () => {
    setIsOpenPromoModal(false);
  };

  useEffect(() => {
    if (dataType == "Fasttrack") {
      apiThirdStage("Fasttrack")
        .then((response) => {
          let Fasttrack_1 = [];
          let Fasttrack_2 = [];
          let Fasttrack_3 = [];
          let Fasttrack_4 = [];
          if (response.data.data.class.length > 0) {
            for (
              let index = 0;
              index < response.data.data.class.length;
              index++
            ) {
              const element = response.data.data.class[index];

              if (element.timeTable.length > 0) {
                let Lane = "";
                switch (element.area[0]?.description.slice(-1)) {
                  case "1":
                    Lane = "Lane_1&2";
                    break;
                  case "3":
                    Lane = "Lane_3&4";
                    break;
                  case "5":
                    Lane = "Lane_5&6";
                    break;

                  default:
                    break;
                }

                switch (
                  element.fasttrackType.substr(element.fasttrackType.length - 1)
                ) {
                  case "1":
                    Fasttrack_1 = [
                      ...Fasttrack_1,
                      courseObject(element, response, Lane),
                    ];
                    break;
                  case "2":
                    Fasttrack_2 = [
                      ...Fasttrack_2,
                      courseObject(element, response, Lane),
                    ];
                    break;
                  case "3":
                    Fasttrack_3 = [
                      ...Fasttrack_3,
                      courseObject(element, response, Lane),
                    ];
                    break;
                  case "4":
                    Fasttrack_4 = [
                      ...Fasttrack_4,
                      courseObject(element, response, Lane),
                    ];
                    break;

                  default:
                    break;
                }
              }
            }
          }

          setDataTimetable({
            ...dataTimetable,
            Fasttrack: {
              Fasttrack_1: Fasttrack_1,
              Fasttrack_2: Fasttrack_2,
              Fasttrack_3: Fasttrack_3,
              Fasttrack_4: Fasttrack_4,
            },
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(true);
          console.error(err.message);
        });
    } else {
      apiThirdStage("SOF")
        .then((response) => {
          let MorningCoursesMonday = [];
          let AfternoonCoursesMonday = [];
          let MorningCoursesWednesday = [];
          let AfternoonCoursesWednesday = [];
          let MorningCoursesSaturday = [];
          let AfternoonCoursesSaturday = [];
          let MorningCoursesSunday = [];
          let AfternoonCoursesSunday = [];

          if (response.data.data.class.length > 0) {
            setCurrentSemester(response.data.data.class[0].semester);
            for (
              let index = 0;
              index < response.data.data.class.length;
              index++
            ) {
              const element = response.data.data.class[index];

              if (element.timeTable.length > 0) {
                let Lane = "";
                switch (element.area[0]?.description.slice(-1)) {
                  case "1":
                    Lane = "Wednesday";
                    break;
                  case "3":
                    Lane = "Saturday";
                    break;
                  case "5":
                    Lane = "Sunday";
                    break;

                  default:
                    break;
                }

                switch (element.timeTable[0]?.timeTableWeekDay) {
                  case 1:
                    switch (element.startTime.trim().slice(-2)) {
                      case "AM":
                        MorningCoursesMonday = [
                          ...MorningCoursesMonday,
                          courseObject(element, response, Lane),
                        ];
                        break;
                      case "PM":
                        AfternoonCoursesMonday = [
                          ...AfternoonCoursesMonday,
                          courseObject(element, response, Lane),
                        ];
                        break;

                      default:
                        break;
                    }
                    break;

                  case 3:
                    switch (element.startTime.trim().slice(-2)) {
                      case "AM":
                        MorningCoursesWednesday = [
                          ...MorningCoursesWednesday,
                          courseObject(element, response, Lane),
                        ];
                        break;
                      case "PM":
                        AfternoonCoursesWednesday = [
                          ...AfternoonCoursesWednesday,
                          courseObject(element, response, Lane),
                        ];
                        break;

                      default:
                        break;
                    }
                    break;

                  case 6:
                    switch (element.startTime.trim().slice(-2)) {
                      case "AM":
                        MorningCoursesSaturday = [
                          ...MorningCoursesSaturday,
                          courseObject(element, response, Lane),
                        ];
                        break;
                      case "PM":
                        AfternoonCoursesSaturday = [
                          ...AfternoonCoursesSaturday,
                          courseObject(element, response, Lane),
                        ];
                        break;

                      default:
                        break;
                    }
                    break;

                  case 7:
                    switch (element.startTime.trim().slice(-2)) {
                      case "AM":
                        MorningCoursesSunday = [
                          ...MorningCoursesSunday,
                          courseObject(element, response, Lane),
                        ];
                        break;
                      case "PM":
                        AfternoonCoursesSunday = [
                          ...AfternoonCoursesSunday,
                          courseObject(element, response, Lane),
                        ];
                        break;

                      default:
                        break;
                    }
                    break;

                  default:
                    break;
                }
              }
            }
          }
          setDataTimetable({
            ...dataTimetable,
            MonCourse: MorningCoursesMonday.concat(AfternoonCoursesMonday),
            WedCourse: MorningCoursesWednesday.concat(
              AfternoonCoursesWednesday
            ),
            SatCourse: MorningCoursesSaturday.concat(AfternoonCoursesSaturday),
            SunCourse: MorningCoursesSunday.concat(AfternoonCoursesSunday),
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(true);
          console.error(err.message);
        });
    }
  }, []);

  return (
    <div className="w-full">
      <div className="relative flex flex-col gap-8 w-[90%] mx-auto mt-[100px]">
        <p className="font-bold text-xl">{t("sof_title_1")}</p>
        <p className="font-bold text-xl">{t("sof_title_2")}</p>
        <p className="font-bold text-xl">{t("sof_title_3")}</p>
      </div>

      <div className="pt-10 pv:max-sm:pt-0">
        <div className="bg-white w-[90%] mx-auto pv:max-xl:w-[95%]">
          <div className="py-10">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold text-center">Timetable</p>
            </div>
            {/* {dataType == "Fasttrack" ? null : (
              <div className="py-5 flex justify-start w-[95%] mx-auto">
                <p className="text-2xl text-start pv:max-sm:text-xl pv:max-md:text-center">
                  <b> Current semester: </b>
                  {currentSemester}
                </p>
              </div>
            )} */}
            <div className=" flex flex-col">
              {isLoading ? (
                <Loading />
              ) : (
                <div className="w-[60%] pv:max-md:w-[90%] md:max-xl:w-[75%] h-fit mx-auto flex flex-col justify-around items-center sticky top-24">
                  {/* {dataType == "Fasttrack" ? (
                    <>
                      {dataTimetable.Fasttrack.Fasttrack_1.length > 0 ? (
                        <TimeTable
                          data={dataTimetable.Fasttrack.Fasttrack_1}
                          columns={columns}
                          mainAttribute="time"
                          columnAttribute="Lane"
                          handleClick={handleNavigate}
                          handleClickPlay={handleClickPlay}
                          Weekday={"Fasttrack 1"}
                        />
                      ) : null}
                    </>
                  ) : ( */}
                  <>
                    {[
                      ...dataTimetable.MonCourse,
                      ...dataTimetable.WedCourse,
                      ...dataTimetable.SatCourse,
                      ...dataTimetable.SunCourse,
                    ].length > 0 ? (
                      <TimeTable_v2
                        data={[
                          ...dataTimetable.MonCourse,
                          ...dataTimetable.WedCourse,
                          ...dataTimetable.SatCourse,
                          ...dataTimetable.SunCourse,
                        ]}
                        // displayColumnsName={true}
                        columns={columns}
                        mainAttribute="time"
                        columnAttribute="Lane"
                        handleClick={handleNavigate}
                        handleClickPlay={handleClickPlay}
                        Weekday={"Time"}
                      />
                    ) : null}
                  </>
                  {/* )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpenPromoModal ? (
        <Modal
          open={isOpenPromoModal}
          onCancel={handleCancelPlay}
          footer={null}
          width={750}
        >
          <div className="w-full flex justify-center">
            <ReactPlayer url={promoVidUrl} />
          </div>
        </Modal>
      ) : null}

      {isOpenChooseLevel ? (
        <ModalChooseLevel
          isShowModal={isOpenChooseLevel}
          handleShowDetail={handleShowDetail}
          type={"SOF"}
          currentLevel={dataState}
        />
      ) : null}
    </div>
  );
};

export default withTranslation()(ThirdStage);
