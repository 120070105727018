/** @format */

import { Icon } from "@iconify/react";
import React from "react";
import { yearOld, getCurrentYear } from "../../utils/constant";

const CardMemberShip = ({
  code,
  img,
  name,
  year,
  icon,
  handleChangeMember,
  selectedMember,
  UserType,
  gender = "Male",
}) => {
  const currentyear = getCurrentYear();

  return (
    <label className="">
      <input
        type="radio"
        name="payment_method"
        checked={selectedMember.UserID === code ? true : false}
        value={code}
        onClick={(e) =>
          handleChangeMember({
            value: e.target.value,
            userType: UserType,
          })
        }
      />
      <div className="relative bg-white shadow-lg rounded-lg mx-auto max-w-xs transition-transform transform hover:scale-105 hover:shadow-2xl cursor-pointer">
        {UserType == "main" ? (
          <div className="absolute top-2 left-0 p-2 bg-blue-100 text-gray-800 font-semibold rounded-e-lg">
            Main
          </div>
        ) : null}

        <div className="w-full flex items-center justify-center p-4">
          <img
            className="object-cover w-48 aspect-square rounded-full transition-transform transform hover:scale-110"
            src={img}
            alt={name}
          />
        </div>

        <div
          className={`p-4 flex items-center bg-gradient-to-r  ${
            gender == "Male"
              ? "from-blue-50 to-blue-100"
              : "from-pink-50 to-pink-100"
          } border-b border-gray-200`}
        >
          <div
            className={`flex items-center justify-center w-10 h-10 ${
              gender == "Male" ? "bg-blue-200" : "bg-pink-200"
            } rounded-full shadow-md transition-shadow hover:shadow-lg`}
          >
            <Icon
              icon={icon}
              className={`w-8 h-6 ${
                gender == "Male" ? "text-blue-700" : "text-pink-700"
              }`}
            />
          </div>
          <p className="ml-3 text-xl font-semibold text-gray-800">{name}</p>
        </div>
      </div>
    </label>
  );
};

export default CardMemberShip;
