/** @format */

import { Icon } from "@iconify/react";
import React from "react";
// import avatar from "../../assets/teach_1.jpg";
const HeaderAdmin = () => {
  return (
    <>
      <div className="h-[60px] grid grid-cols-4 items-center shadow-sm  w-[95%] mx-auto ">
        <div className="col-span-1 ">
          <p className="text-black font-bold text-xl ">Aquatics hub</p>
        </div>

        <div className="col-span-3 grid grid-cols-3">
          <div className="col-span-1">
            <form className="flex items-center  ">
              <div className="relative w-full">
                <input
                  type="text"
                  id="simple-search"
                  className=" w-full bg-white border border-gray-300 text-black text-sm rounded-lg pl-10 p-2.5  "
                  placeholder="Search..."
                  required
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ml-2 text-sm font-medium text-white bg-gray-500 rounded-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:bg-secondary2 dark:bg-secondary1 dark:hover:bg-secondary2 dark:focus:bg-secondary2"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </form>
          </div>
          <div className="col-span-2">
            <div className="flex justify-end gap-10">
              <div className="flex items-center gap-2">
                <button className="hover:text-secondary2">
                  <Icon className="w-8 h-8 " icon={"uil:setting"}></Icon>
                </button>
                <button className="hover:text-secondary2">
                  <Icon
                    className="w-8 h-8"
                    icon={"iconamoon:notification-light"}
                  ></Icon>
                </button>
                <button className="hover:text-secondary2">
                  <Icon className="w-8 h-8" icon={"ep:message"}></Icon>
                </button>
              </div>
              <div className="flex items-center justify-around gap-6">
                <div className="flex gap-2 items-center">
                  <div className="h-8 w-8 rounded-full">
                    <img
                      className=" h-full w-full rounded-full object-cover"
                      //   src={avatar}
                    ></img>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-bold">Instructor</p>
                    <p className="">Instructor@info.com</p>
                  </div>
                </div>
                <button className=" h-[40px] px-4 py-2 bg-secondary1 flex justify-center items-center rounded-lg">
                  <p className="text-white">Logout</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderAdmin;
