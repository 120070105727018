/** @format */

import { path } from "../constant";
import {
  //   //student-loggedIn
  CheckOut,
  //   CheckOutEmbled,
  //   MyLearning,
  //   QRWallet,
  //   CheckOutSuccessVNPay,
  //   //instructor-loggedIn
  //   StudentList,
  //   StudentProfile,
  //   InstructorCalendar,
  //   InstructorHome,
  //   InstructorProfile,
  UserProfile,
  //   AllCourse,
  //   CourseEnrollDetail,
  //   InstructorCourseDetail,
  //   //Employee
  //   HomePageEmployee,
  //   QRWalletDetail,
  //   Cafeteria,
  SelectMember,
  PaymentStatus,
} from "../../pages";

export const allowedRoles = ["Employee", "Lecture", "user"];

export const routes = [
  //student
  {
    path: `${path.CHECKOUT}`,
    exact: true,
    component: <CheckOut />,
    main: () => <CheckOut />,
    role: ["user"],
  },
  {
    path: `${path.PAYMENTSTATUS}`,
    exact: true,
    component: <PaymentStatus />,
    main: () => <PaymentStatus />,
    role: ["user"],
  },
  {
    path: `${path.USERPROFILE}`,
    exact: true,
    component: <UserProfile />,
    main: () => <UserProfile />,
    role: ["user"],
  },
  // {
  //   path: `${path.COURSEENROLLDETAIL}/:courseCode`,
  //   exact: true,
  //   // component: <CourseEnrollDetail />,
  //   // main: () => <CourseEnrollDetail />,
  //   role: ["user"],
  // },
  // {
  //   path: `${path.CHECKOUTEMBLED}`,
  //   exact: true,
  //   // component: <CheckOutEmbled />,
  //   // main: () => <CheckOutEmbled />,
  //   role: ["user"],
  // },
  // {
  //   path: `${path.QRWALLET}`,
  //   exact: true,
  //   // component: <QRWallet />,
  //   // main: () => <QRWallet />,
  //   role: ["user"],
  // },
  // {
  //   path: `${path.QRWALLETDETAIL}/:ticketCode`,
  //   exact: true,
  //   // component: <QRWalletDetail />,
  //   // main: () => <QRWalletDetail />,
  //   role: ["user"],
  // },
  // {
  //   path: `${path.CHECKOUTSUCCESSVNPAY}`,
  //   exact: true,
  //   // component: <CheckOutSuccessVNPay />,
  //   // main: () => <CheckOutSuccessVNPay />,
  //   role: ["user"],
  // },
  {
    path: `${path.SELECTMEMBERSGIP}`,
    exact: true,
    component: <SelectMember />,
    main: () => <SelectMember />,
    role: ["user"],
  },

  // //instructor
  // {
  //   path: `${path.STUDENTLIST}`,
  //   exact: true,
  //   // component: <StudentList />,
  //   // main: () => <StudentList />,
  //   role: ["Lecture"],
  // },
  // {
  //   path: `${path.STUDENTPROFILE}`,
  //   exact: true,
  //   // component: <StudentProfile />,
  //   // main: () => <StudentProfile />,
  //   role: ["Lecture"],
  // },
  // {
  //   path: `${path.INSTRUCTORCALENDAR}`,
  //   exact: true,
  //   // component: <InstructorCalendar />,
  //   // main: () => <InstructorCalendar />,
  //   role: ["Lecture"],
  // },
  // {
  //   path: `${path.INSTRUCTORPROFILE}`,
  //   exact: true,
  //   // component: <InstructorProfile />,
  //   // main: () => <InstructorProfile />,
  //   role: ["Lecture"],
  // },
  // {

  // {
  //   path: `${path.INSTRUCTORCOURSEDETAIL}/:courseCode`,
  //   exact: true,
  //   // component: <InstructorCourseDetail />,
  //   // main: () => <InstructorCourseDetail />,
  //   role: ["Lecture"],
  // },
  // {
  //   path: `${path.INSTRUCTORHOME}`,
  //   exact: true,
  //   // component: <InstructorHome />,
  //   // main: () => <InstructorHome />,
  //   role: ["Lecture"],
  // },

  // //Employee
  // {
  //   path: `${path.HOMEPAGEEMPLOYEE}`,
  //   exact: true,
  //   // component: <HomePageEmployee />,
  //   // main: () => <HomePageEmployee />,
  //   role: ["Employee"],
  // },
  // //Employee
  // {
  //   path: `${path.CAFETERIA}`,
  //   exact: true,
  //   // component: <Cafeteria />,
  //   // main: () => <Cafeteria />,
  //   role: ["Employee"],
  // },
];
