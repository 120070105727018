/** @format */

import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { path } from "../utils/constant";

import { useNavigate } from "react-router-dom";
const SidebarAdmin = ({ handleclick }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Sidebar collapsed={collapsed} width="100%" className="h-full ">
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled ? "#ffffff" : "#000000",
                  backgroundColor: active ? "#0D9AFF24" : undefined,
                  color: active ? "#0D99FF" : "#000000",
                };
            },
          }}
        >
          <MenuItem
            className={`flex justify-end ${collapsed ? "justify-center" : ""}`}
          >
            <button
              className="p-2"
              onClick={() => {
                setCollapsed(!collapsed);
                handleclick(!collapsed);
              }}
            >
              <Icon icon={"bx-menu"} className="w-6 h-6 "></Icon>
            </button>
          </MenuItem>

          <MenuItem
            icon={<Icon icon={"gridicons:calendar"} className="w-6 h-6"></Icon>}
            onClick={() => {
              navigate("../" + path.INSTRUCTORCALENDAR);
            }}
          >
            Time Table
          </MenuItem>

          <SubMenu
            defaultOpen
            label="Manage Course"
            icon={
              <Icon
                icon={"eos-icons:subscription-management"}
                className="w-6 h-6"
              />
            }
          >
            <MenuItem
              className=""
              onClick={() => {
                navigate("../" + path.ALLCOURSE);
              }}
            >
              <p className="w-[80%] ml-auto">Course List</p>
            </MenuItem>
          </SubMenu>
          <SubMenu
            defaultOpen
            label="Instructor"
            icon={<Icon icon={"mdi:lecture"} className={`w-6 h-6 `} />}
            active
          >
            <MenuItem
              className=""
              onClick={() => {
                navigate("../" + path.INSTRUCTORPROFILE);
              }}
            >
              <p className="w-[80%] ml-auto">Pesonal Profile</p>
            </MenuItem>
          </SubMenu>
          {/* <SubMenu
              active
              label="Charts"
              rootStyles={{
                ["& > ." + menuClasses.button]: {
                  backgroundColor: "green",
                  color: "#9f0099",
                  "&:hover": {
                    backgroundColor: "#0D9AFF24",
                  },
                },
                ["." + menuClasses.subMenuContent]: {
                  backgroundColor: "#0D9AFF24",
                },
              }}
            >
              <MenuItem> Pie charts</MenuItem>
              <MenuItem> Line charts</MenuItem>
              <MenuItem> Bar charts</MenuItem>
            </SubMenu> */}
        </Menu>
      </Sidebar>
    </>
  );
};

export default SidebarAdmin;
