import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DefaultLayout } from "./layouts";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
function App() {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="w-full font-roboto_regular">
      <Routes>
        <Route path="/*" element={<DefaultLayout />} />
      </Routes>
    </div>
  );
}

export default App;
