/** @format */
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { UserCard, CommonCard, CardCourse, Loading } from "../../components";
import { SidebarAdmin, HeaderAdmin } from "../../components";

import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import { apiGetEnrollment } from "../../../services/lesson";

const InstructorStudentProfile = () => {
  const [isLoadingEnrollments, setIsLoadingEnrollments] = useState(true);
  const [enrollments, setEnrollments] = useState([
    {
      Number: "",
      StartDate: "",
      EndDate: "",
      ViceLecture: "",
      Lecture: "",
      Enrolled: 0,
      Capacity: 0,
      Lesson: {
        Code: "",
        Description: "",
        Detail: "",
        Level: "",
        ModuleNumber: 0,
        Modules: [],
        PictureURL: "",
        Price: 1,
      },
    },
  ]);
  //   useEffect(() => {
  //     const fetchEnrollments = async () => {
  //       const response = await apiGetEnrollment();

  //       if (response?.status === 200) {
  //         setEnrollments(response.data.Enrollments);
  //         setIsLoadingEnrollments(false);
  //       }
  //     };
  //     fetchEnrollments();
  //   }, []);
  const [resize, setResize] = useState(false);
  const handleclick = (value) => {
    setResize(value);
  };
  return (
    <div className="pt-[100px]">
      <div className="w-full ">
        <HeaderAdmin />
      </div>
      <div className={`w-full grid grid-cols-4`}>
        <div className={`col-span-1`}>
          <SidebarAdmin handleclick={handleclick} />
        </div>
        <div className="col-span-3 grid grid-cols-3">
          <div className="col-span-1">
            <UserCard
              avatarURL="https://i.postimg.cc/85TkzKHg/profile-2.jpg"
              fullName="Lương Duy Liêm"
              userLevel="Entry level"
            />
          </div>
          <div className="col-span-2">
            <div className="flex flex-col gap-[1rem] px-6">
              <div className="font-bold text-lg">Student Information</div>
              <div className="flex gap-[1rem] items-center">
                <Icon icon={"ic:outline-mail"} className="w-8 h-8" />
                <div>info@softnio.com</div>
              </div>
              <div className="flex gap-[1rem] items-center">
                <Icon icon={"ph:calendar-light"} className="w-8 h-8" />
                <div>02 June, 2001</div>
              </div>
              <div className="flex gap-[1rem] items-center">
                <Icon icon={"ph:phone"} className="w-8 h-8" />
                <div>043214321</div>
              </div>
              <div className="flex gap-[1rem] items-center">
                <Icon icon={"carbon:location"} className="w-8 h-8" />
                <div>
                  202 Lý Chính Thắng, Tây Ninh, Bình Thuận, An Giang, Long An,
                  Giồng Riềng, Cần Đước, Sài Gòn, Phú Mỹ, Quảng Nam, Bình Định
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructorStudentProfile;
