/** @format */

const VI_TRANSLATE = {
  Company_name: "Công ty TNHH Goodlife",
  Business_Registration: "Đăng ký kinh doanh",
  Business_License:
    "Giấy phép kinh doanh: 0110496704 do Phòng ĐKKD Thành phố Hà Nội cấp ngày 22/02/2024",
  Business_Qualification_License:
    "Giấy phép đủ điều kiện kinh doanh: 217 /GCN-SVHTT do Sở Văn hóa và Thể thao Thành phố Hà Nội cấp ngày 07/06/2024",
  // HomePage
  HomePage_welcome: "Chào mừng",
  HomePage_BannerTitle: "TẠO RA VĂN HÓA BƠI Ở HÀ NỘI",
  Homepage_WelcomeBanner:
    "Chào mừng bạn đến với Aquatics Hub, một bể bơi 8 làn, dài 25 mét hiện đại ở Hà Nội, phục vụ người bơi lội ở mọi lứa tuổi và trình độ kỹ năng, mang lại niềm vui và sức khỏe dưới nước suốt đời cho các cá nhân và gia đình",
  HomePage_Course: "KHÓA HỌC",
  HomePage_SwimClass: "LỚP BƠI",
  HomePage_AdultCourses: "KHÓA HỌC NGƯỜI LỚN",
  HomePage_SwimClub: "CÂU LẠC BỘ BƠI",
  HomePage_Rental: "CHO THUÊ",
  HomePage_LaneRental: "THUÊ ĐƯỜNG BƠI",
  HomePage_PoolRental: "THUÊ HỒ BƠI",
  HomePage_ContactRental: "LIÊN HỆ",
  HomePage_Contact: "LIÊN HỆ",
  HomePage_schedule: "Lịch trình - từ thứ Hai ngày 19 tháng 8 năm 2024",

  // AdultCourse
  AdultCourse_register: "ĐĂNG KÝ",
  AdultCourse_CominginJune: "VÀO THÁNG 6",
  AdultCourse_Home: "TRANG CHỦ",
  AdultCourse_FutureCourse: "CÁC KHÓA TRONG TƯƠNG LAI",
  AdultCourse_beginner: "LỚP CHO NGƯỜI MỚI BẮT ĐẦU",
  AdultCourse_beginner_Description:
    "Một lớp học tuyệt vời dành cho người lớn muốn cảm thấy thoải mái hơn trong nước và học các kiểu bơi cơ bản. Các huấn luyện viên của chúng tôi sẽ giúp bạn tiến bộ theo tốc độ của riêng bạn.",
  AdultCourse_weekly: "Hàng tuần:",
  AdultCourse_timely: "Thứ Hai, Thứ Ba, Thứ Năm & Thứ Sáu từ 19:30-20:30",
  AdultCourse_Technique: "LỚP BƠI LỘI KỸ THUẬT",
  AdultCourse_Technique_Description:
    "Một lớp học cho người lớn đã biết bơi cơ bản nhưng muốn phát triển kỹ thuật của mình để di chuyển hiệu quả hơn trong nước.",
  AdultCourse_Master: "LỚP BƠI LỘI CHUYÊN NGHIỆP",
  AdultCourse_Master_Description:
    "Một lớp học cho những người bơi thành thạo nhiều kiểu bơi và thường bơi với kỹ thuật tốt. Đào tạo chủ yếu nhằm cải thiện sức bền và sự phối hợp, dẫn đến khoảng cách bơi xa hơn và nhanh hơn.",
  AdultCourse_Session: "Mùa học",
  Swimming_Session: "Mùa học",

  adult_course_title_1: "Đăng ký đợt 1 (26/08/24-20/10/24)",
  adult_course_title_2:
    "Ngày kết thúc đăng ký: Thứ Tư ngày 21 tháng 8 năm 2024",
  // PublicSwiming
  PublicSwiming_OnOffer: "ĐANG ƯU ĐÃI",
  PublicSwiming_OpenPrice: "GIÁ BƠI MỞ",
  PublicSwiming_Age: "TUỔI",
  PublicSwiming_CommingLater: "đến sau",
  PublicSwiming_ticket: "VÉ",
  PublicSwiming_Free: "MIỄN PHÍ",
  PublicSwiming_LanePrice: "GIÁ BƠI LANE",
  PublicSwiming_Schedule: "LỊCH TRÌNH",
  PublicSwiming_Policy:
    "Chính sách dành cho người bơi lội không có người đi cùng của Aquatics Hub",
  PublicSwiming_Public: "BƠI CÔNG CỘNG",
  PublicSwiming_Public_Description:
    "Khám phá niềm vui dưới nước tại Aquatics Hub! Cho dù đó là bơi vòng hay một ngày vui vẻ cùng gia đình, chúng tôi đều phục vụ tất cả mọi người. Tham gia các buổi bơi công cộng của chúng tôi để trải nghiệm cộng đồng bơi lội sôi động.",
  PublicSwiming_Lane: "BƠI THEO LÀN",
  PublicSwiming_Lane_Description:
    "Trong suốt tuần, chúng tôi cung cấp các làn bơi để rèn luyện sức khỏe và tập luyện. Bạn chọn làn bơi phù hợp với tốc độ của mình, sau đó tham gia. Ít nhất có bốn làn bơi có sẵn trong các buổi này.",
  PublicSwiming_Open: "BƠI TỰ DO",
  PublicSwiming_Open_Description:
    "Bơi tự do dành cho mọi lứa tuổi và khả năng. Hãy đến với bạn bè và gia đình để bơi trong một môi trường thoải mái và không gò bó.",
  PublicSwiming_Adult: "NGƯỜI LỚN",
  PublicSwiming_Child: "TRẺ EM(3-16 TUỔI)",
  PublicSwiming_Price: "GIÁ",
  // PublicSwiming_purchasex10: "ĐẶT 10X VÉ",
  PublicSwiming_purchasex10: "Mua vé tại quầy",

  sof_title_1: "Đăng ký đợt 1 (26/08/24-20/10/24)",
  sof_title_2: " Ngày kết thúc đăng ký: Thứ Tư, ngày 21 tháng 8 năm 2024",
  sof_title_3:
    "Chọn một khối thời gian bên dưới. Con bạn sẽ được phân bổ một bài học 40 phút trong khoảng thời gian đó, sau khi kết thúc đăng ký.",
  // Swimming Lesson
  SwimmingCourse_School: "CẤP ĐỘ SHCOOL OF FISH",
  SwimmingCourse_Level: "BẠN Ở CẤP ĐỘ NÀO ?",
  SwimmingCourse_Summer2024: "LỚP MỞ - HÈ 2024",
  SwimmingCourse_Book: "CHỌN NGAY",
  SwimmingCourse_ENQUIRE: "LIÊN HỆ",
  SwimmingCourse_Sep2024: "SẮP RA MẮT - THÁNG 9 NĂM 2024",
  SwimmingCourse_CourseforU: "Khóa học dành cho bạn !!!",
  //Club
  Club_FlyingSquad: "BIỆT ĐỘI CÁ BAY",
  Club_RequestTrial: "YÊU CẦU DÙNG THỬ",
  Club_MorePricing: "THÊM VỀ GIÁ",
  Club_ViewWeekSession: "XEM LỊCH HỌC HÀNG TUẦN",
  Club_SessionDateCost: "Ngày và chi phí của phiên",
  Club_FlyingFishSche: "LỊCH CÁ BAY",
  Club_Monday: "THỨ 2",
  Club_Tue: "THƯ 3",
  Club_wed: "THỨ 4",
  Club_Th: "THỨ 5",
  Club_Fri: "THỨ 6",
  Club_Sa: "THỨ 7",
  Club_Su: "CHỦ NHẬT",
  Club_purple: "MÀU TÍM",
  Club_bronze: "ĐỒNG",
  Club_silver: "BẠC",
  Club_Gold: "VÀNG",
  Club_Dev: "PHÁT TRIỂN",
  Club_Elitie: "ƯU TÚ",

  // Modal request trial

  modal_request_trial_label_email: "Email",
  modal_request_trial_label_phone: "Số điện thoại",
  modal_request_trial_label_name: "Tên",
  modal_request_trial_label_request: "Yêu cầu",

  // Lane Rental
  lane_rental_title: "CHO THUÊ LÀN ĐƯỜNG & HỒ BƠI ​",
  lane_rental_description:
    "Aquatics Hub không chỉ cung cấp một hồ bơi; đó là tấm bạt dưới nước của bạn cho các sự kiện, bữa tiệc và thậm chí cả ​huấn luyện cá nhân. Thuê hồ bơi 8 làn, 25m đa năng của chúng tôi cho mọi hoạt động từ tổ chức lễ kỷ niệm cho đến cho thuê làn đường quanh năm.",
  lane_rental_contact: "Liên hệ",
  lane_rental_lane_hire: "THUÊ LÊN ĐƯỜNG - CÓ SẴN TỪ THÁNG 7 NĂM 2024",
  lane_rental_pool_price: "GIÁ HỒ BƠI - TỪ 5.000.000/ GIỜ",
  lane_rental_home: "Trang chủ",

  // Aquarium
  aquarium_price: "GIÁ AQUARIUM",
  aquarium_register: "Đăng ký",
  aquarium_home: "TRANG CHỦ",

  //  Footer
  title_footer: "Các khóa học dành cho bạn",
  description_footer: "Liên hệ ngay với chúng tôi",
  title_button_footer: "Tìm khóa học phù hợp với tôi",
  footer_bottom_Name: "Hồ bơi",
  Footer_bottom_Name_description:
    "Học viện Bơi lội đam mê thể thao và hoạt động thể chất. Chúng tôi được thành lập dựa trên niềm đam mê thể thao dưới nước và phát triển bản thân.",
  Footer_bottom_Location: "Địa chỉ",
  Footer_bottom_Hours: "Giờ làm việc",
  Footer_bottom_time_1: "Mỗi ngày: 6:00 - 22:00",
  Footer_bottom_time_2: "T7: 7:00 - 21:00",
  Footer_bottom_time_3: "CN: 8:00 - 20:00",
  Footer_bottom_Explore: "Thông tin",
  Footer_bottom_Ourteam: "Giới thiệu",
  Footer_bottom_AboutUs: "Về chúng tôi",
  Footer_bottom_Service: "Dịch vụ",
  Footer_bottom_DiscoverBenefit: "Lợi ích",
  Footer_bottom_Resource: "Tài nguyên",
  Footer_bottom_copyright: "bởi Công ty TECHFIS SOLUTION",
  Footer_ward: "Phường ",
  Footer_district: "Quận ",

  Footer_bottom_policy: "Chính sách",
  Footer_bottom_Information_security_policy: "Chính sách bảo mật thông tin",
  Footer_bottom_complaint_response_policy: "Chính sách phản hồi khiếu nại",
  Footer_bottom_Payment_policy: "Chính sách thanh toán",
  Footer_bottom_refund_policy: "Chính sách đổi, hủy và hoàn tiền",
  Footer_bottom_Online_purchasing_policy: "Chính sách mua hàng trực tuyến",
  Footer_bottom_Accept_Payments: "Chấp nhận thanh toán",
  Footer_bottom_contact: "Liên hệ",
  footer_bottom_service_Course: "Khóa học bơi",
  // Private Header
  h_pr_Name: "Quản lý hồ bơi",
  h_pr_placeholder_search: "Tìm kiếm...",
  h_pr_Logout: "Đăng xuất",

  // Public Header
  h_pu_phone: "Số điện thoại",
  h_pu_email: "Email",
  h_pu_placeholder_search: "Tìm kiếm...",
  h_pu_signup: "Đăng ký",
  h_pu_signin: "Đăng nhập",

  // Navigation
  nav_Home: "TRANG CHỦ",
  nav_Pages: "DANH MỤC",
  nav_Booking: "ĐẶT VÉ",
  nav_Contact: "LIÊN HỆ",
  nav_view_profile: "Hồ sơ cá nhân",
  nav_my_learning: "Danh sách khóa học của tôi",
  nav_sign_out: "Đăng xuất",
  subnav_swimmingleague: "GIẢI BƠI",
  subnav_aboutUs: "VỀ CHÚNG TÔI",
  subnav_ourTeam: "GIỚI THIỆU",
  subnav_allCourse: "CÁC KHÓA HỌC",
  subnav_allpoollane: "CÁC LÀN BƠI",
  subnav_waterAerobics: "THỂ THAO NƯỚC",
  nav_qrwallet: "Ví QR",

  // Private Sidebar
  pv_sidebar_dashboard: "Bảng điều khiển",
  pv_sidebar_timetable: "Thời gian biểu",
  pv_sidebar_manage_student: "Quản lý học sinh",
  pv_sidebar_student_list: "Danh sách học sinh",
  pv_sidebar_managecourse: "Quản lý khóa học",
  pv_sidebar_courselist: "Danh sách khóa học",
  pv_sidebar_instructor: "Giảng viên",
  pv_sidebar_personprofile: "Trang cá nhân",

  // Card Pool
  btn_booknow: "Đăng ký ngay",

  // Note Teacher
  note_teacher_Delete_note: "Xóa ghi chú",
  note_teacher_add_on: "Thêm vào",
  note_teacher_by: "Bởi",

  // Explore Class
  explore_class_werook: "Chúng ta thật tuyệt vời",
  explore_class_title: "Khám phá khóa học",
  explore_class_description:
    "Dù là trẻ em lần đầu mới tiếp xúc với nước hoặc là người lớn muốn tập bơi, các khóa học bơi của chúng tôi đều có thể đáp ứng đối với mọi cấp độ.",
  explore_class_seemore: "Xem thêm các khóa học. Xem",
  explore_class_fulllist: "tất cả danh sách",

  //Introduce Service
  is_welcome: "Chào mừng đến với hồ bơi của chúng tôi",
  is_welcome_description:
    "Chúng tôi thấu hiểu tâm lý phụ huynh rằng sự an toàn dành cho con là quan trọng nhất. Tại Học viện Bơi lội, chúng tôi cam kết rằng các vận động viên của bạn luôn được chăm sóc tốt nhất. Đội ngũ giảng viên được tuyển dụng kỹ lưỡng và trải qua các giai đoạn đào tạo toàn diện. Ngoài ra, các khóa học của chúng tôi luôn được duy trì với số lượng học viên ít nhằm đảm bảo chất lượng và an toàn.",
  is_welcome_who: "Chúng tôi là ai",
  is_welcome_who_description:
    "Môi trường dạy bơi cởi mở và thân thiện. Mỗi chiếc phao nhỏ đều đặc biệt và độc đáo đối với chúng tôi",
  is_welcome_ourprogram: "Chương trình của chúng tôi",
  is_welcome_ourprogram_description:
    "Môi trường dạy bơi cởi mở và thân thiện. Mỗi chiếc phao nhỏ đều đặc biệt và độc đáo đối với chúng tôi",
  is_welcome_about: "Về câu lạc bộ hồ bơi",
  is_welcome_about_description:
    "Chúng tôi thấu hiểu tâm lý phụ huynh rằng sự an toàn dành cho con là quan trọng nhất. Tại Học viện Bơi lội, chúng tôi cam kết rằng các vận động viên của bạn luôn được chăm sóc tốt nhất. Đội ngũ giảng viên được tuyển dụng kỹ lưỡng và trải qua các giai đoạn đào tạo toàn diện. Ngoài ra, các khóa học của chúng tôi luôn được duy trì với số lượng học viên ít nhằm đảm bảo chất lượng và an toàn.",
  is_welcome_ourhistory: "Lịch sử của chúng tôi",
  is_welcome_ourhistory_description:
    "Môi trường dạy bơi cởi mở và thân thiện. Mỗi chiếc phao nhỏ đều đặc biệt và độc đáo đối với chúng tôi",
  is_welcome_misson: "Sứ mệnh của chúng tôi",
  is_welcome_misson_description:
    "Môi trường dạy bơi cởi mở và thân thiện. Mỗi chiếc phao nhỏ đều đặc biệt và độc đáo đối với chúng tôi",
  is_welcome_tojoin: "Cách tham gia",
  is_welcome_tojoin_description:
    "Môi trường dạy bơi cởi mở và thân thiện. Mỗi chiếc phao nhỏ đều đặc biệt và độc đáo đối với chúng tôi",

  //Join To Day
  jointoday_title: "Tham gia ngay hôm nay",
  jointoday_description:
    "Môi trường dạy bơi cởi mở và thân thiện. Mỗi chiếc phao nhỏ đều đặc biệt và độc đáo đối với chúng tôi",
  jointoday_title_bottom: "Khóa học mời bạn tham gia",
  jointoday_des_01: "Nhiệt tình",
  jointoday_des_02: "Thân thiện",
  jointoday_des_03: "Thấu hiểu",
  jointoday_des_04: "Kinh nghiệm",
  jointoday_des_05: "Tận tâm",
  jointoday_des_06: "Chất lượng",
  // Home Page
  HomePage_button_banner_1: "CÁCH ĐĂNG KÝ",
  HomePage_button_banner_2: "LIÊN HỆ",
  HomePage_flash_TitleTop: "MỘT NGÀY TUYỆT VỜI",
  HomePage_flash_TitleBottom:
    "Chúng tôi sẽ giúp bạn đạt được mục tiêu của mình",
  HomePage_flash_desctiption:
    "Chúng tôi chú trọng vào việc làm cho các lớp học trở nên thú vị, đa dạng và độc đáo, đồng thời đảm bảo chất lượng về mặt kỹ năng bơi lội và phát triển cá nhân",
  HomePage_wellcome: "Chào mừng bạn đến với hồ bơi của chúng tôi",
  HomePage_Expore_pool_title: "Khám phá các đường bơi",
  HomePage_Expore_pool_description:
    "Dù bạn là trẻ em muốn làm quen với nước hay là người lớn, chúng tôi đều có khả năng cung cấp nhiều loại hình lớp học cho tất cả các trình độ khác nhau",
  HomePage_Expore_pool_seemore: "Xem thêm về hồ bơi. Xem",
  HomePage_Expore_pool_fulllist: "Chi tiết",
  HomePage_Expore_passion_title: "KỸ NĂNG & ĐAM MÊ",
  HomePage_Expore_passion_title_bottom: "Giảng viên",
  HomePage_Expore_passion_description:
    "Trường chúng tôi có rất nhiều giảng viên có trình độ. Tất cả đều có một điểm chung là niềm đam mê với thể thao và mỗi giảng viên có cách hướng dẫn độc đáo của riêng mình.",

  // Pool
  // Card Pool
  card_pool__more_info: "Xem thêm thông tin",

  // Pool All
  poolall_title_top_banner: "BÀI HỌC BƠI CHO NGƯỜI LỚN VÀ TRẺ EM",
  poolall_title_bottom_banner: "NƠI CÁC GIA ĐÌNH VỪA HỌC VỪA CHƠI",
  poolall_title_description_banner:
    "Chào mừng bạn đến với hồ bơi Vui vẻ, nơi mà mỗi ngày đều là ngày thích hợp để bơi! Bầu không khí sôi động và thú vị của chúng tôi là nơi lý tưởng để thư giãn, bơi lvà nghỉ ngơi sau những ngày bận rộn. Hãy đến tham gia, trải nghiệm cùng chúng tôi và cùng tận hưởng niềm vui dưới ánh mặt trời.",
  // Pool Detail
  pooldetail_about: "Thông tin",
  pooldetail_booknow: "Đăng ký ngay",

  // Login
  login_username: "Tên đăng nhập",
  login_signin: "Đăng nhập",
  login_signup: "Đăng ký",
  login_wrong_email: "Định dạng email không đúng",
  login_valid_number: "Chỉ nhập số",
  login_valid_required: " Bắt buộc!",
  login_password_not_match: "Mật khẩu không trùng",
  signIn_success: "Đăng nhập thành công",
  signUp_success: "Đăng ký thành công",
  signup_firstname: "Họ",
  signup_lastname: "Tên",
  sign_phone: "Số điện thoại",
  sign_Email: "Nhập số điện thoại đăng ký",

  sign_password: "Mật khẩu",
  signup_confirmpassword: "Xác nhận mật khẩu",
  signup_age: "Tuổi",
  signup_dateofbirth: "Ngày sinh",
  signup_address: "Địa chỉ",
  signup_already: "Đã có tài khoản ?",
  signIn_have_account: "Chưa có tài khoản ?",
  sign_student: "Học viên",
  sign_lecturer: "Giảng viên",
  sign_employee: "Nhân viên",
  login_valid_age: "Tuổi không hợp lệ",
  login_valid_Incorrect_credentials: "Thông tin xác thực không chính xác",

  login_valid_user_exits: "Người dùng không tồn tại",
  sign_in_forgot_password: "Quên mật khẩu ?",
  Login_btn_Home: "Trang chủ",
  Sign_up_addadult: "Thêm người lớn",
  Sign_up_addchild: "Thêm trẻ em",
  sign_up_already_account: "Bạn đã có tài khoản?",
  sign_up_sign_in: "Đăng nhập",

  // Error Page
  error_page_back: "Về trang chủ",

  // Check Out
  check_out_title: "Thanh toán",
  check_out_address_title: "Địa chỉ thanh toán",
  check_out_address_conutry: "Quốc gia",
  check_out_require: "Bắt buộc",
  check_out_note:
    "Học viện bơi lội luôn tuân thủ các quy định về thuế đối với các giao dịch được thực hiện",
  check_out_method_title: "Phương thức thanh toán",
  check_out_secured: "Kết nối bảo mật",
  check_out_order_title: "Chi tiết đơn hàng",
  check_out_Summary: "Tổng kết",
  check_out_price: "Đơn giá",
  check_out_transaction_fee: "Phí giao dịch",

  check_out_total: "Tổng",
  check_out_purchase_title:
    "Để việc thanh toán được hoàn tất, bạn đồng ý với những điều này",
  check_out_term: "Điều khoản dịch vụ",
  check_out_next: "Tiếp theo",
  check_out_money_back: "Hoàn tiền trong 30 ngày",
  check_out_cash:
    "Tiền mặt - Thanh toán tại quầy thật sớm để hoàn tất đăng ký nhé",
  check_out_vnpay_recommend: "khuyến khích thanh toán bằng mã qr",

  enroll_successful: "Đăng ký thành công",
  payments_error:
    "Lỗi thanh toán, vui lòng thử lại. Nếu vấn đề vẫn tiếp diễn, đừng ngần ngại liên hệ với chúng tôi",
  error_checkout: "Đã xảy ra sự cố, vui lòng liên hệ với quản trị viên",
  // Course Detail Public
  pu_course_detail_startdate: "Ngày bắt đầu",
  pu_course_detail_enddate: "Ngày kết thúc",
  pu_course_detail_length: "Số bài học",
  pu_course_detail_duration: "Thời lượng",
  pu_course_detail_overview: "Tổng quan",
  pu_course_detail_book_class: "Đăng ký khóa học",
  pu_course_detail_aready: "Bạn đã đăng ký khóa học này",
  pu_course_detail_student: "HỌC VIÊN",
  pu_course_detail_course: "KHÓA HỌC",
  pu_course_detail_location: "ĐỊA CHỈ",
  pu_course_detail_lecturer: "GIẢNG VIÊN",
  pu_course_detail_schedule: "Lịch học",
  pu_course_detail_full_continue: "Lớp đã đầy, tiếp tục ?",
  pu_course_register_full: "Đăng ký lớp đã đầy",
  pu_course_detail_registration_confirmation: "Xác nhận đăng ký",
  pu_course_detail_registration_confirmation_desc:
    "Bạn có chắc chắn hoàn tất đăng ký ?",
  pu_course_detail_me_yes: "Đồng ý",
  pu_course_detail_me_no: "Không",
  pu_course_detail_me_enroll: "Đăng ký khóa học",
  pu_course_detail_me_sure: "Bạn có chắc chắn đăng ký khóa học này không?",
  check_out_service_charge: "Phí dịch vụ",

  // My Learning
  my_learning_title: "Khóa Học Của Tôi",
  my_learning_not_learn:
    "Hiện tại bạn không có khóa học nào. Hãy đăng ký một khóa học mới",

  // Course Enroll Detail
  ced_overview: "TỔNG QUAN KHÓA HỌC",
  ced_overview_description: "MÔ TẢ TỔNG QUAN",
  ced_instructor: "GIẢNG VIÊN",
  ced_faq: "CÂU HỎI THƯỜNG GẶP",
  ced_review: "MỘT SỐ ĐÁNH GIÁ",
  ced_enter_your_review: "Nhập đánh giá của bạn",
  ced_btn_send: "Gửi",
  ced_review_course_title: "Cảm nhận của bạn về khóa học",
  ced_timetable: "THỜI KHÓA BIỂU",
  // User Profile
  user_profile_info: "Thông Tin Cá Nhân",
  user_profile_secure_setting: "Thiết Lập Bảo Mật",
  user_profile_edit_profile: "Chỉnh Sửa Hồ Sơ Cá Nhân",
  user_profile_info_title: "Thông Tin Cá Nhân",
  user_profile_info_title_bottom:
    "Thông tin cơ bản, như tên và địa chỉ của bạn, sử dụng",
  user_profile_info_basic_title: "Cơ Bản",
  user_profile_info_basic_full_name: "Họ và Tên",
  user_profile_info_basic_first_name: "Họ",
  user_profile_info_basic_last_name: "Tên",
  user_profile_info_basic_gender: "Giới tính",
  user_profile_info_basic_email: "Email",
  user_profile_info_basic_phone: "Số điện thoại",
  user_profile_info_basic_birth: "Ngày sinh",
  user_profile_info_basic_address: "Địa chỉ",
  user_profile_info_basic_level: "Cấp độ",
  user_profile_secure_setting_title: "Thiết lập bảo mật",
  user_profile_secure_setting_title_bottom:
    "Những cài đặt này giúp bạn giữ an toàn cho tài khoản của mình.",
  user_profile_secure_setting_change_title: "Thay đổi mật khẩu",
  user_profile_secure_setting_change_title_bottom:
    "Đặt mật khẩu duy nhất để bảo vệ tài khoản của bạn.",
  user_profile_secure_setting_change_lasttime: "Lần thay đổi cuối cùng",
  user_profile_update_profile_title: "Cập nhật hồ sơ",
  user_profile_update_profile_title_bottom:
    "Những thây đổi này giúp bạn cập nhật hồ sơ cá nhân",
  user_profile_change_avatar: "Thay đổi ảnh đại điện",
  user_profile_mypurchase: "Các dịch vụ của tôi",

  user_profile_type: "Loại: ",
  user_profile_start: "Ngày bắt đầu: ",
  user_profile_end: "Ngày kết thúc: ",
  user_profile_status: "Trạng thái: ",

  user_profile_family_member: "Thành viên gia đình",

  user_profile_family_member_fullname: "Họ và tên",
  user_profile_family_member_dob: "Ngày sinh",
  user_profile_family_member_gender: "giới tính",
  user_profile_family_member_level: "Cấp độ",

  user_profile_family_member_course: "Khóa học",

  user_profile_family_member_table_des: "Tên khóa học",
  user_profile_family_member_table_classnumber: "Mã lớp học",
  user_profile_family_member_table_historynumber: "Mã lịch sử",
  user_profile_family_member_table_type: "Loại",
  user_profile_family_member_table_status: "Trạng thái",
  user_profile_family_member_table_payments: "Thanh toán",
  user_profile_family_member_table_createdate: "Ngày tạo",
  user_profile_family_member_table_view: "Xem",

  user_profile_my_learning: "Khóa học của tôi",
  // Teacher Profile
  teach_profile_phone: "Số điện thoại",
  teach_profile_email: "Email",
  teach_profile_about: "Giới thiệu",
  teach_profile_course: "Khóa học đang dạy",
  teach_profile_review: "Danh sách đánh giá",
  teach_profile_review_student: "Học viên",
  teach_profile_review_lecturer: "Giảng viên",
  teach_profile_review_rating: "Độ hài lòng",
  teach_profile_review_review: "Đánh giá",

  // Public All Course
  public_all_course_total: "Tổng số khóa học",
  public_all_course_course: "khóa học",
  public_all_course_full_list_course: "Danh sách tất cả các khóa học",

  // Instructor Calendar
  in_calendar_January: "Tháng 1",
  in_calendar_February: "Tháng 2",
  in_calendar_March: "Tháng 3",
  in_calendar_April: "Tháng 4",
  in_calendar_May: "Tháng 5",
  in_calendar_June: "Tháng 6",
  in_calendar_July: "Tháng 7",
  in_calendar_August: "Tháng 8",
  in_calendar_September: "Tháng 9",
  in_calendar_October: "Tháng 10",
  in_calendar_November: "Tháng 11",
  in_calendar_December: "Tháng 12",
  in_calendar_number_class_today: "Số lớp học hôm nay",

  // Instructor Profile
  in_profile_person_information: "Thông tin cá nhân",
  in_profile_person_course: "Khóa học",
  in_profile_person_review: "Đáng giá",
  in_profile_person_course_id: "Mã khóa học",
  in_profile_person_course_name: "Tên khóa học",
  in_profile_person_course_status: "Trạng thái",
  in_profile_person_course_action: "Hành động",
  in_profile_person_review_student: "Học Viên",
  in_profile_person_review_course_name: "Tên khóa học",
  in_profile_person_review_course_rating: "Xếp hạng",
  in_profile_person_review_course_review: "Đánh giá",
  in_profile_person_review_course_action: "Hành động",
  in_profile_person_lecturer_note: "Ghi chú của giảng viên",
  in_profile_person_add_note: "Thêm ghi chú",
  in_profile_person_gender: "Giới tính",
  in_profile_person_date_of_birth: "Ngày sinh",
  in_profile_person_phone: "Số điện thoại",
  in_profile_person_addtion_info: "Thông tin bổ sung",
  in_profile_person_address: "Địa chỉ",
  in_profile_person_full_name: "Họ và tên",
  in_profile_person_first_name: "Họ",
  in_profile_person_last_name: "Tên",
  in_profile_person_email_address: "Địa chỉ email",

  // Student List
  student_list_title: "Danh Sách Học Viên",
  student_list_type: "Loại tài khoản",
  student_list_btn_add: "Thêm học viên mới",
  student_list_table_student: "Học viên",
  student_list_table_enrolled_course: "Khóa đã đăng ký",
  student_list_table_phone: "Số điện thoại",
  student_list_table_address: "Địa chỉ",
  student_list_table_type: "Loại tài khoản",
  student_list_table_status: "Trạng thái",
  student_list_table_trial: "Học thử",
  student_list_table_standard: "Học chính thức",

  // All course
  all_course_title: "Danh sách khóa học",
  all_course_export: "Xuất báo cáo",
  all_course_add_course: "Thêm khóa học",
  all_course_open_lesson: "Mở thêm bài học",
  all_course_table_course_id: "Mã khóa học",
  all_course_table_course_name: "Tên khóa học",
  all_course_table_course_capacity: "Số học viên tối đa",
  all_course_table_course_enrolled: "Đã tham gia",
  all_course_table_course_pending: "Đang chờ",
  all_course_table_course_duration: "Thời gian học",
  all_course_table_course_status: "Trạng thái",

  // Not Authorize
  not_authorize_title: "403 - Trang không ủy quyền",
  not_authorize_description: "Rất tiếc bạn không được phép truy cập trang này",
  not_authorize_back: "Quay lại trang chủ",

  // Instructor Course detail

  instructor_course_detail_btn_back: "Quay lại",
  instructor_course_detail_btn_add_course: "Thêm course",
  instructor_course_detail_btn_add_lesson: "Thêm bài học",
  instructor_course_detail_tab_overview: "Tổng quan",
  instructor_course_detail_tab_enrolled: "Danh sách đã đăng ký",
  instructor_course_detail_tab_pending: "Danh sách chờ",
  instructor_course_detail_tab_note: "Ghi chú",
  instructor_course_detail_tab_time: "Thời gian",
  instructor_course_detail_tab_prsence: "Có mặt",
  instructor_course_detail_avatar: "Ảnh đại diện",
  instructor_course_detail_fullname: "Họ tên",
  instructor_course_detail_age: "Tuổi",
  instructor_course_detail_gender: "Giới tính",
  instructor_course_detail_phone: "Số điện thoại",
  instructor_course_detail_email: "Email",

  // List Course Search
  course_count: "Đã tìm thây số khóa học phù hợp",
  not_course: "Không tìm thấy kết quả phù hợp",

  publicswimming_note1_item1:
    "Cho phép di chuyển tự do khắp hồ bơi. Người bơi có thể di chuyển tự do, chơi đùa và giao lưu mà không bị giới hạn bởi bất kỳ ranh giới cụ thể nào.",
  publicswimming_note1_item2:
    "Lý tưởng cho việc giải trí, thoải mái. Phù hợp cho gia đình, trẻ em và những người muốn tận hưởng thời gian dưới nước mà không cần theo một lịch trình cụ thể",
  publicswimming_note1_item3:
    "Bầu không khí thường thoải mái hơn, lý tưởng cho các hoạt động giao lưu và giải trí",
  publicswimming_note2_item1:
    "Hạn chế mỗi người bơi trong một làn cụ thể. Thiết lập này đảm bảo luồng di chuyển có tổ chức, ngăn chặn sự cản trở giữa các người bơi và cho phép bơi lội không bị gián đoạn.",
  publicswimming_note2_item2:
    "Hoàn hảo cho việc tập luyện có cấu trúc và đào tạo. Thích hợp cho những người muốn cải thiện kỹ thuật bơi, sức bền và thể lực tổng thể.",
  publicswimming_note2_item3:
    "Có tổ chức hơn và tập trung vào mục tiêu thể dục. Môi trường có cấu trúc giúp người bơi tập trung vào các bài tập và đạt được mục tiêu đào tạo của họ",
  publicswimming_thankyou: "Xin cám ơn quý khách!",

  //#region Policies
  select_info_sec_policy: "Chính sách bảo mật thông tin",
  select_dispute_and_complaint: "Chính sách phản hồi khiếu nại",
  select_payment_policy: "Chính sách thanh toán",
  select_exchange_cancellation: "Chính sách đổi, hủy và hoàn tiền",
  select_online_purchasing_policy: "Chính sách mua hàng trực tuyến",
  //#region InfoSecPolicy
  info_sec_policy: "CHÍNH SÁCH BẢO MẬT THÔNG TIN CÁ NHÂN",
  info_sec_policy_section1_description:
    "Nhằm đảm bảo an toàn cho website và bảo mật thông tin cho người tiêu dùng, chúng tôi đưa ra một số chính sách bảo mật thông tin cho khách hàng cá nhân và tổ chức khi mua hàng tại website:",
  info_sec_policy_section1_detail: `Chính sách bảo mật sẽ giải thích cách chúng tôi tiếp nhận, sử dụng và (trong trường hợp nào đó) tiết lộ thông tin cá nhân của bạn. Chính sách cũng sẽ giải thích các bước chúng tôi thực hiện để bảo mật thông tin cá nhân của khách hàng. Cuối cùng, chính sách bảo mật sẽ giải thích quyền lựa chọn của bạn về việc thu thập, sử dụng và tiết lộ thông tin cá nhân của mình.`,
  info_sec_policy_section2_item1_title: `Mục đích và phạm vi thu thập thông tin:`,
  info_sec_policy_section2_item1_detail1: `Việc thu thập dữ liệu của website:`,
  info_sec_policy_section2_item1_detail2: `sẽ giúp chúng tôi:`,
  info_sec_policy_section2_item1_list1: `Nắm bắt được các yêu cầu, mong muốn của khách hàng nhằm nâng cao chất lượng sản phẩm`,
  info_sec_policy_section2_item1_list2: `Giúp khách hàng cập nhật các thông tin chương trình khuyến mại, giảm giá do chúng tôi tổ chức một cách sớm nhất.`,
  info_sec_policy_section2_item1_list3: `Hỗ trợ khách hàng khi có khiếu nại, tranh chấp một cách nhanh nhất.`,
  info_sec_policy_section2_item1_detail3: `Thu thập các thông tin sau của khách hàng:`,
  info_sec_policy_section2_item1_list4: `Trong quá trình sử dụng dịch vụ trên website, chúng tôi lưu giữ thông tin chi tiết về lịch sử đặt mua khóa học, lịch sử học, các câu hỏi, vướng mắc cần được hỗ trợ, giải đáp của khách hàng.`,
  info_sec_policy_section2_item1_detail4: `Các khách hàng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu của mình. Ngoài ra, khách hàng có trách nhiệm thông báo kịp thời với ban quản trị website về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.`,
  info_sec_policy_section2_item2_title: "Phạm vi sử dụng thông tin:",
  info_sec_policy_section2_item2_description:
    "Công ty sử dụng thông tin khách hàng cung cấp để:",
  info_sec_policy_section2_item2_list1: "Cung cấp các dịch vụ đến khách hàng.",
  info_sec_policy_section2_item2_list2:
    "Gửi thông tin chương trình mới, các chương trình ưu đãi, khuyến mại nếu khách hàng chấp nhận điều khoản này trong khi đăng ký.",
  info_sec_policy_section2_item2_list3:
    "Gửi các thông báo về các hoạt động trao đổi thông tin giữa khách hàng và Aquatics Hub.",
  info_sec_policy_section2_item2_list4:
    "Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của khách hàng hoặc các hoạt động giả mạo khách hàng.",
  info_sec_policy_section2_item2_list5:
    "Liên lạc và giải quyết với khách hàng trong những trường hợp đặc biệt.",
  info_sec_policy_section2_item2_list6:
    "Không sử dụng thông tin cá nhân của khách hàng ngoài mục đích xác nhận và liên hệ có liên quan đến dịch vụ giao nhận trên website",
  info_sec_policy_section2_item2_detail:
    "Trong trường hợp có yêu cầu của pháp luật: Công ty có trách nhiệm hợp tác cung cấp thông tin cá nhân khách hàng khi có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an điều tra liên quan đến hành vi vi phạm pháp luật nào đó của khách hàng. Ngoài ra, không ai có quyền xâm phạm vào thông tin cá nhân của khách hàng.",
  info_sec_policy_section2_item3_title: "THỜI GIAN LƯU TRỮ THÔNG TIN",
  info_sec_policy_section2_item3_detail:
    "Dữ liệu cá nhân của khách hàng sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ. Trường hợp còn lại, trong mọi trường hợp thông tin cá nhân khách hàng sẽ được bảo mật trên máy chủ của website",
  info_sec_policy_section2_item4_title:
    "CAM KẾT BẢO MẬT THÔNG TIN CÁ NHÂN KHÁCH HÀNG",
  info_sec_policy_section2_item4_detail1:
    "Thông tin cá nhân của khách hàng trên website",
  info_sec_policy_section2_item4_detail2:
    "cam kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá nhân của website. Việc thu thập và sử dụng thông tin của mỗi khách hàng chỉ được thực hiện khi có sự đồng ý của khách hàng đó trừ những trường hợp pháp luật có quy định khác.",
  info_sec_policy_section2_item4_list1:
    "Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ ba về thông tin cá nhân của khách hàng khi không có sự cho phép đồng ý từ khách hàng.",
  info_sec_policy_section2_item4_list2:
    "Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân khách hàng, chúng tôi sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho khách hàng được biết.",
  info_sec_policy_section2_item4_list3:
    "Aquatics Hub yêu cầu các cá nhân khi sử dụng website, phải cung cấp đầy đủ thông tin cá nhân có liên quan như: Họ và tên, địa chỉ liên lạc, email, số điện thoại, …., và chịu trách nhiệm về tính pháp lý của những thông tin trên. Aquatics Hub không chịu trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến quyền lợi của khách hàng nếu xét thấy thông tin cá nhân của khách hàng cung cấp khi đăng ký ban đầu là không chính xác.",
  info_sec_policy_section2_item5_title:
    "Những người được tổ chức có thể tiếp nhận thông tin:",
  info_sec_policy_section2_item5_detail1: "Hoạt động kinh doanh của website",
  info_sec_policy_section2_item5_detail2:
    "không bao gồm việc bán các thông tin của khách hàng cho bên thứ ba. Website",
  info_sec_policy_section2_item5_detail3:
    "chỉ cung cấp thông tin khách hàng cho các bên được liệt kê dưới đây nhằm đảm bảo quyền lợi của khách hàng theo những cam kết bảo mật của Aquatics Hub:",
  info_sec_policy_section2_item5_list1:
    "Các đối tác có ký Hợp đồng để thực hiện một phần dịch vụ cho Aquatics Hub (đối tác kỹ thuật, đối tác cung cấp dịch vụ thanh toán trực tuyến...). Các đối tác này sẽ nhận được những thông tin theo thỏa thuận hợp đồng (có thể 1 phần hoặc toàn bộ thông tin tùy theo điều khoản của Hợp đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ do Công ty cung cấp.",
  info_sec_policy_section2_item5_list2:
    "Cơ quan nhà nước khi có yêu cầu: Aquatics Hub sẽ cung cấp thông tin của khách hàng để phục vụ quá trình điều tra của các cơ quan đó.",
  info_sec_policy_section2_item6_title:
    "Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân",
  info_sec_policy_section2_item6_list1: "Điện thoại: 0969685445",
  info_sec_policy_section2_item6_list2: "Email: contact@aquaticshub.vn",
  info_sec_policy_section2_item6_list3:
    "Địa chỉ: 98 đường Tô Ngọc Vân, phường Quảng An, quận Tây Hồ, thủ đô Hà Nội, Việt Nam",
  info_sec_policy_section2_item7_title:
    "Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình.",
  info_sec_policy_section2_item7_detail1:
    "Khách hàng có thể thực hiện quyền chỉnh sửa, cập nhật thông tin cá nhân của mình bằng cách liên hệ với chúng tôi qua số điện thoại, email hoặc địa chỉ liên lạc được công bố dưới chân website:",
  info_sec_policy_section2_item7_detail2:
    "Hoặc Khách hàng có thể tự đăng nhập vào tài khoản thành viên trên website",
  info_sec_policy_section2_item7_detail3:
    "để trực tiếp thực hiện chỉnh sửa, cập nhật thông tin cá nhân của mình.",
  info_sec_policy_section2_item8_title:
    "Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi đã thông báo",
  info_sec_policy_section2_item8_detail1:
    "Khi người tiêu dùng phát hiện thông tin cá nhân của mình trên website ",
  info_sec_policy_section2_item8_detail2:
    "bị sử dụng sai mục đích hoặc ngoài phạm vi sử dụng thông tin, người tiêu dùng có thể gửi khiếu nại đến Công ty Cổ phần Tư vấn và Giáo dục Aquatics Hub theo các cách thức sau:",
  info_sec_policy_section2_item8_list1:
    "Gọi điện thoại đến số Hotline: 0969685445;",
  info_sec_policy_section2_item8_list2:
    "Gửi email khiếu nại đến địa chỉ: contact@aquaticshub.vn;",
  info_sec_policy_section2_item8_list3:
    "Gửi văn bản khiếu nại theo đường bưu điện đến hoặc gửi trực tiếp tại địa chỉ:  98 đường Tô Ngọc Vân, phường Quảng An, quận Tây Hồ, thủ đô Hà Nội, Việt Nam",
  info_sec_policy_section2_item9_detail1:
    "Thời hạn để Công ty xử lý phản ánh liên quan đến thông tin cá nhân khách hàng là 03 (ba) ngày làm việc, kể từ ngày tiếp nhận được khiếu nại của khách hàng.",
  info_sec_policy_section2_item9_detail2:
    "Trong mọi trường hợp, Công ty đề cao biện pháp thương lượng, hòa giải với khách hàng để thống nhất và đưa ra biện pháp giải quyết, xử lý khiếu nại.",
  info_sec_policy_section2_item9_detail3:
    "Trong trường hợp hai bên không đạt được sự thỏa thuận như mong muốn dẫn đến thương lượng, hòa giải không thành, một trong hai bên có quyền đưa vụ việc ra tòa án nhân dân có thẩm quyền để giải quyết theo quy định của pháp luật.",
  //#endregion
  //#region OnlPurPolicy
  onl_pur_policy: "Chính sách mua hàng trực tuyến",
  onl_pur_policy_title1: "Để mua dịch vụ trực tuyến trên website",
  onl_pur_policy_title2: "khách hàng có thể thực hiện theo quy trình sau:",
  onl_pur_policy_step1_title1: "Bước 1: Khách hàng truy cập vào website",
  onl_pur_policy_step1_title2:
    "Trên giao diện trang chủ, khách hàng nhấn chọn:",
  onl_pur_policy_step1_detail1:
    "Đăng nhập: áp dụng với khách hàng đã đăng ký tài khoản hoặc đã được cấp tài khoản bởi admin.",
  onl_pur_policy_step1_detail2:
    "Đăng ký: áp dụng đối với khách hàng lần đầu truy cập website,  chưa có/ chưa tạo tài khoản trước đó.",
  onl_pur_policy_step1_detail3:
    "Trong trang mục “Đăng ký tài khoản” khách hàng vui lòng điền đầy đủ thông tin bao gồm: Họ tên, Số điện thoại, Email, Mật khẩu, Nhập lại mật khẩu, Ngày sinh, Giới tính, Địa chỉ và nhấn “Đăng ký tài khoản”",
  onl_pur_policy_step2_title:
    "Bước 2: Khách hàng sau khi thực hiện xong đăng nhập hoặc đăng ký thành công, có thể lựa chọn loại khoá học mong muốn tham gia. Các sản phẩm, dịch vụ hiện có tại Aquatics Hub:",
  onl_pur_policy_step2_list1:
    "School of Fish: Đào tạo các lớp bơi theo trình độ cho người lớn, trẻ em.",
  onl_pur_policy_step2_list2:
    "Flying Fish: Các lớp huấn luyện bơi để đi thi đấu.",
  onl_pur_policy_step2_list3:
    "Technique based progression: Các lớp huấn luyện kỹ thuật bơi.",
  onl_pur_policy_step2_list4: "Fast Track: Mở các gói bơi theo các kỳ lễ.",
  onl_pur_policy_step2_list5:
    "Public swimming; Lane rental; Pool rental: Cho thuê bể bơi, làn bơi, vé lượt.",
  onl_pur_policy_step2_list6: "School Group: Đào tạo bơi cho các trường.",
  onl_pur_policy_step3_title:
    "Bước 3: Khách hàng chọn học kỳ mong muốn đăng ký học.",
  onl_pur_policy_step4_title:
    "Bước 4: Khách hàng lựa chọn khoá học mong muốn tham gia và tiến hành thanh toán:",
  note: "Lưu ý",
  onl_pur_policy_step5_note1:
    "Trong vòng 48 giờ kể từ khi nhận được thông tin đặt hàng, hệ thống sẽ gửi tới khách hàng một “Thông báo xác thực” qua email mà khách hàng đã đăng ký với hệ thống.",
  onl_pur_policy_step5_note2:
    "Trường hợp khách hàng muốn chuyển đổi/ hủy đơn hàng, vui lòng liên hệ trực tiếp với chúng tôi  qua hotline 0969685445  để được hỗ trợ.chi tiết.",
  //#endregion

  // #region Disputes and complaints
  com_policy_title:
    "BIỆN PHÁP - CƠ CHẾ - THỦ TỤC GIẢI QUYẾT TRANH CHẤP, KHIẾU NẠI",
  com_policy_description_1:
    "Khách hàng đồng ý và thừa nhận rằng, khi được Aquatics HUB cấp tài khoản để đăng ký khóa học hoặc sử dụng dịch vụ của Aquatics HUB, mọi tranh chấp phát sinh trong quá trình khách hàng sử dụng sẽ được giải quyết theo quy định pháp luật hiện hành của nước Cộng hòa xã hội chủ nghĩa Việt Nam.",
  com_policy_description_2:
    "Mọi khiếu nại phát sinh trong quá trình học tập, thuê nhà phải được gửi đến Aquatics HUB hoặc các đơn vị thành viên ngay sau khi xảy ra sự việc phát sinh khiếu nại. Aquatics HUB sẽ giải quyết các tranh chấp của khách hàng. Aquatics HUB sẽ xem xét từng trường hợp cụ thể để tìm ra giải pháp phù hợp. Khi thực hiện quyền khiếu nại, người khiếu nại có nghĩa vụ cung cấp tài liệu, chứng cứ, căn cứ liên quan đến việc khiếu nại và chịu trách nhiệm về nội dung khiếu nại, tài liệu, chứng cứ, căn cứ do mình cung cấp theo quy định của pháp luật.",
  com_policy_description_3:
    "Aquatics HUB chỉ hỗ trợ, giải quyết khiếu nại, tố cáo của khách hàng trong trường hợp khách hàng cung cấp thông tin đầy đủ, trung thực, chính xác.",
  com_policy_description_4:
    "Khách hàng đồng ý bảo vệ, bồi thường và giữ cho Aquatics HUB không bị ảnh hưởng bởi mọi nghĩa vụ pháp lý, kiện tụng, tổn thất và chi phí bao gồm nhưng không giới hạn ở phí tòa án, phí luật sư và phí tư vấn liên quan đến việc giải quyết hoặc phát sinh từ hành vi vi phạm của khách hàng trong suốt khóa học học tập hoặc sử dụng dịch vụ của Aquatics HUB.",
  com_policy_title_2: "Thủ tục yêu cầu chuyển đổi/hoàn tiền",
  com_policy_step_1: " Bước 1:",
  com_policy_step_1_desc: `Biên nhận: Tiếp nhận yêu cầu giải quyết khiếu nại của khách hàng (gọi tắt là “người khiếu nại”) và kiểm tra thông tin khiếu nại để chấp nhận hoặc từ chối.`,
  com_policy_step_2: "Bước 2:",
  com_policy_step_2_desc:
    "Xử lý khiếu nại: Thu thập thông tin, hình ảnh, bằng chứng liên quan.",
  com_policy_step_3: "Bước 3:",
  com_policy_step_3_desc:
    "Hoàn thiện khiếu nại: Xác nhận kết quả, đề xuất phương án xử lý đối với từng hành vi vi phạm và tiến hành kết thúc khiếu nại.",
  com_policy_sp_contact: "HỖ TRỢ ĐỊA CHỈ LIÊN HỆ",
  com_policy_name: "Tên",
  com_policy_phone: "Số điện thoại",
  com_policy_address: "Địa chỉ",
  // #endregion

  // #region  Payment policy
  pay_policy_method_2: "Thẻ nội địa và tài khoản ngân hàng",
  pay_policy_method_3: "Thẻ thanh toán quốc tế",
  pay_policy_similar_1:
    " Similar to the payment method Domestic card and bank account",
  pay_policy_similar_2:
    "Tương tự với phương thức thanh toán Thẻ nội địa và tài khoản ngân hàng",
  pay_policy_title_1: "Hướng dẫn thanh toán VN PAY",
  pay_policy_desc_1:
    "Cổng thanh toán VN PAY là giải pháp thanh toán được phát triển bởi Công ty Cổ phần Giải pháp Thanh toán Việt Nam (VN PAY). Khách hàng sử dụng thẻ/tài khoản ngân hàng, tính năng QR Pay/VN PAY-QR được tích hợp trên ứng dụng Mobile Banking của ngân hàng hoặc Ví điện tử liên kết để thanh toán giao dịch và nhập mã giảm giá (nếu có)",
  pay_policy_title_2:
    "Quét mã VN PAY-QR trên 35+ Ứng dụng Mobile Banking và 15+ Ví điện tử liên kết",
  pay_policy_title_3: "40+ ATM/thẻ nội địa/tài khoản ngân hàng",
  pay_policy_title_4: "4 Thẻ thanh toán quốc tế",
  pay_policy_title_5: "Các phương thức thanh toán qua VN PAY",
  pay_policy_support: "KÊNH HỖ TRỢ VN PAY",
  pay_policy_email: "Email",
  pay_policy_switchboard: "Tổng đài",

  pay_policy_confirm_bankCard: "Xác nhận giỏ hàng",
  pay_policy_confirm_desc_bankCard:
    "Bạn chọn sản phẩm dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng Tại trang thanh toán, quý khách vui lòng kiểm tra sản phẩm đã đặt, điền thông tin người nhận hàng, chọn phương thức thanh toán VN PAY và nhấn nút Đặt hàng ngay.",
  pay_policy_select_method_bankCard: "Chọn phương thức thanh toán",
  pay_policy_select_method_desc_bankCard:
    "Màn hình thanh toán chuyển sang giao diện cổng thanh toán VN PAY. Chọn phương thức Thẻ nội địa và tài khoản ngân hàng rồi chọn ngân hàng muốn thanh toán bằng thẻ trong danh sách",
  pay_policy_verification_bankCard: "Xác minh",
  pay_policy_verification_desc_bankCard:
    "Vui lòng nhập thông tin thẻ/tài khoản theo yêu cầu và chọn Tiếp tục. Mã OTP sẽ được gửi về điện thoại đã đăng ký, nhập mã OTP để hoàn tất giao dịch",
  pay_policy_verification_note_bankCard:
    "Lưu ý: Giao dịch sẽ hết hạn sau 15 phút",
  pay_policy_reCheck: "Kiểm tra lại thông tin",
  pay_policy_reCheck_desc_bankCard:
    "Khi thanh toán hoàn tất, bạn sẽ nhận được thông báo xác nhận đơn hàng thành công trên website",

  pay_policy_confirm_vnpay_qr: "Xác nhận giỏ hàng",
  pay_policy_confirm_desc_vnpay_qr:
    "Bạn chọn sản phẩm dịch vụ rồi chọn Thanh toán ngay hoặc Đặt hàng. Tại trang thanh toán, quý khách vui lòng kiểm tra sản phẩm đã đặt, điền thông tin người nhận hàng, chọn phương thức thanh toán VN PAY và nhấn nút Đặt hàng ngay.",
  pay_policy_select_method_vnpay_qr: "Chọn phương thức thanh toán",
  pay_policy_select_method_desc_vnpay_qr:
    "Màn hình thanh toán chuyển sang giao diện cổng thanh toán VN PAY. Chọn phương thức Ứng dụng thanh toán hỗ trợ VN PAY-QR",
  pay_policy_scan_vnpay_qr: "Quét mã QR",
  pay_policy_scan_desc_vnpay_qr:
    "Hệ thống hiển thị mã QR kèm theo số tiền cần thanh toán. Vui lòng kiểm tra lại số tiền này. Sử dụng ứng dụng ngân hàng (theo danh sách), chọn Scan Code và quét mã QR trên màn hình thanh toán website.",
  pay_policy_scan_note_vnpay_qr:
    "Lưu ý: Mã QR có hiệu lực trong 15 phút Để quá trình thanh toán thành công, khách hàng vui lòng xem trước điều kiện và quét mã trên điện thoại để sẵn sàng tránh trường hợp hết thời gian chờ ảnh hưởng đến thanh toán và mã khuyến mãi.",
  pay_policy_reCheck_vnpay_qr: "Kiểm tra lại thông tin",
  pay_policy_reCheck_desc_vnpay_qr:
    "Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất thanh toán. Khi thanh toán hoàn tất, bạn sẽ nhận được thông báo xác nhận đơn hàng thành công trên website",

  // #endregion

  // #region Refund policy
  ref_policy_title_1: "QUY ĐỊNH VỀ CHUYỂN ĐỔI VÀ HOÀN TIỀN",
  ref_policy_subtitle_1: "Phạm vi ứng dụng",
  ref_policy_subtitle_2: "Các định nghĩa",
  ref_policy_subtitle_3: "Quy định cụ thể",
  ref_policy_subtitle_4: "Chuyển đổi khóa học",
  ref_policy_subtitle_5: "Hoàn tiền khóa học",
  ref_policy_subtitle_6: "Thủ tục yêu cầu chuyển đổi/hoàn tiền",
  ref_policy_step_1: "Bước 1:",
  ref_policy_step_2: "Bước 2:",
  ref_policy_step_3: "Bước 3:",
  ref_policy_step_4: "Bước 4:",
  ref_policy_desc_1:
    "Áp dụng cho khách hàng đăng ký các khóa học và dịch vụ (thuê toàn bộ hồ bơi, thuê làn, thuê khu tập kỹ thuật) của Aquatics Hub. Không áp dụng cho khách hàng mua vé sử dụng một lần.",
  ref_policy_desc_2:
    "Chuyển đổi (chỉ áp dụng cho các khóa học): Thay đổi từ khóa học này sang khóa học khác có giá trị thanh toán bằng nhau.",
  ref_policy_desc_3: `Hoàn trả: Hoàn trả số tiền đã đóng cho sinh viên (không bao gồm phí dịch vụ thanh toán nếu có).`,
  ref_policy_desc_4:
    "Hủy khóa học/dịch vụ: Thay đổi lựa chọn sau khi thanh toán đã được thực hiện và giao dịch được xác nhận.",
  ref_policy_desc_5:
    "Chênh lệch học phí: Chênh lệch học phí = Giá niêm yết của khóa học/dịch vụ thay thế - Giá niêm yết của khóa học/dịch vụ ban đầu.",
  ref_policy_desc_6: "Giá niêm yết được tính tại thời điểm chuyển đổi.",
  ref_policy_desc_7: `Trong trường hợp khóa học/dịch vụ thay thế có giá trị thanh toán thấp hơn khóa học/dịch vụ ban đầu, số tiền chênh lệch sẽ được giữ lại để thanh toán sau hoặc hoàn trả cho khách hàng qua hình thức chuyển khoản (Học viên áp dụng chính sách này vui lòng liên hệ hotline: 0969685445 để được tư vấn) và thủ tục).`,
  ref_policy_desc_8:
    "Thời gian đăng ký khóa học thành công: Thời điểm học viên hoàn tất quá trình thanh toán và nhận được thông báo xác nhận giao dịch.",
  ref_policy_desc_9:
    "Thời điểm bắt đầu học: Thời điểm học viên bắt đầu tham gia khóa học với xác nhận từ giáo viên và hệ thống phần mềm của công ty bắt đầu tính toán % hoàn thành khóa học.",
  ref_policy_desc_10:
    "Ngày kết thúc khóa học: Thời điểm học viên không còn quyền truy cập vào khóa học nữa.",
  ref_policy_desc_11:
    "Thời hạn chuyển đổi: Thời điểm sinh viên được phép chuyển đổi khóa học ban đầu sang khóa học thay thế.",
  ref_policy_desc_12:
    "Học viên chỉ được phép chuyển đổi một lần đối với khóa học đã đăng ký thành công. Đối với các trường hợp cần chuyển đổi/hỗ trợ vui lòng liên hệ tới hotline: 0969685445 để được hỗ trợ.",
  ref_policy_desc_13:
    "Điều kiện chuyển đổi bao gồm: Trong vòng 24h kể từ khi đăng ký thành công Tỷ lệ hoàn thành chương trình không vượt quá 10%",
  ref_policy_desc_14:
    "Học viên được phép hoàn trả khóa học đã đăng ký thành công. Đối với các trường hợp hoàn tiền vui lòng liên hệ tới hotline: 0969685445 để được hỗ trợ.",
  ref_policy_desc_15:
    "Điều kiện hoàn tiền bao gồm: Trong vòng 24h kể từ khi đăng ký thành công",
  ref_policy_desc_16: `Đối với các dịch vụ ngoài khóa học (như thuê hồ bơi, thuê làn bơi, thuê khu tập kỹ thuật)For services other than courses (such as renting the pool, renting a swimming lane, renting a technical training area):`,
  ref_policy_desc_17:
    "Khách hàng có thể thay đổi lịch thuê tùy theo điều kiện thực tế của hồ bơi cho phép thay đổi lịch trình.",
  ref_policy_desc_18:
    "Nếu lịch thuê không thể thay đổi do tình hình thực tế của hồ bơi, khách hàng có thể được hoàn lại tiền.",
  ref_policy_desc_19:
    "Điều kiện hoàn tiền: Trong vòng 24h kể từ khi đăng ký thành công Mọi thắc mắc, thắc mắc hoặc cần tư vấn, hỗ trợ vui lòng liên hệ: Công ty Aquatics Hub qua số điện thoại 0969685445 hoặc email: contact@aquaticshub.vn (Giờ hỗ trợ từ 7h00 đến 21h00 hàng ngày)",
  ref_policy_desc_20:
    "Liên hệ theo số HOTLINE 0969685445 hoặc qua email: contact@aquaticshub.vn cung cấp số điện thoại dùng để đăng ký tài khoản và thông tin khóa học của học viên.",
  ref_policy_desc_21:
    "Aquatics Hub xem xét yêu cầu chuyển đổi hoặc hoàn tiền khóa học và các điều kiện.",
  ref_policy_desc_22:
    "Aquatics Hub công bố quyết định chuyển đổi khóa học/hoàn trả học phí. Để biết thông tin hoàn trả, Aquatics Hub yêu cầu thông tin ngân hàng để hoàn trả học phí cho học viên.",
  ref_policy_desc_23:
    "Aquatics Hub tiến hành chuyển đổi lớp học/hoàn tiền. Thời gian hoàn trả ước tính là trong vòng 15 ngày làm việc kể từ ngày xác nhận đủ điều kiện hoàn trả. Lưu ý: Để xác minh thông tin hoàn tiền, yêu cầu phụ huynh xuất trình biên lai chuyển khoản để Aquatics Hub kiểm tra thông tin và hoàn tiền theo quy định.",

  // #endregion

  // #region General policy
  Footer_bottom_general_policy: "Chính sách và quy định chung",
  select_General_policy: "Chính Sách Và Quy Định Chung",
  general_policy: "Chính Sách Và Quy Định Chung",
  general_policy_section1: "1.GIỚI THIỆU CHUNG",
  general_policy_section1_list1_item1: "Tên chủ sở hữu ",
  general_policy_section1_list1_item2: "CÔNG TY TNHH GOODLIFE (Aquatics Hub)",
  general_policy_section1_list2_item1: "Người đại diện theo pháp luật",
  general_policy_section1_list2_item2: "Ông Nguyễn Hoàng Thái",
  general_policy_section1_list3_item1:
    "Địa chỉ: Tầng 1, tòa nhà 4 tầng, Khách Sạn Công Đoàn Quảng Bá, 98 Tô Ngọc Vân, Phường Quảng An, Quận Tây Hồ, Thành Phố Hà Nội",
  general_policy_section1_list4_item1: "Điện thoại: 0969685445",
  general_policy_section1_list5_item1: "Email: contact@aquaticshub.vn",
  general_policy_section1_list6_item1:
    "Số giấy chứng nhận đăng ký kinh doanh: 0110496704",
  general_policy_section1_list7_item1: "Ngày cấp: 04/10/2023",
  general_policy_section2: "2.ĐIỀU KIỆN HẠN CHẾ",
  general_policy_section2_description:
    "Aquatics Hub chỉ tiếp nhận và giải quyết yêu cầu của các cá nhân, tổ chức là khách hàng của Aquatics Hub. Mọi trường hợp khác Aquatics Hub có quyền cân nhắc, đánh giá và từ chối giải quyết yêu cầu. Trường hợp các cá nhân, tổ chức bị Aquatics Hub từ chối giải quyết, có thể liên hệ trực tiếp Aquatics Hub để tìm hiểu hoặc làm việc với cơ quan có thẩm quyền để giải quyết",
  general_policy_section3: "3.TIÊU CHUẨN SẢN PHẨM, DỊCH VỤ",
  general_policy_section3_description:
    "Sản phẩm/dịch vụ của Aquatics Hub đều đạt tiêu chuẩn về chất lượng, có đầy đủ các giấy phép theo quy định của pháp luật. Nếu có thắc mắc về tiêu chuẩn, chất lượng của sản phẩm/dịch vụ, vui lòng liên hệ trực tiếp với Aquatics Hub để được giải đáp.",
  general_policy_section4: "4.NGHĨA VỤ CỦA CÁC BÊN LIÊN QUAN",
  general_policy_section4_description1:
    "Aquatics Hub cam kết trung thực, tuân thủ quy định của pháp luật khi cung cấp sản phẩm/dịch vụ cho khách hàng, sẵn sàng hợp tác với khách hàng để giải quyết các vướng mắc.",
  general_policy_section4_description2:
    "Khách hàng của Aquatics hub tại website https://aquaticshub.vn có nghĩa vụ tuân thủ các chính sách được niêm yết tại phía cuối website, tuân thủ các quy định của pháp luật, hợp tác với Aquatics Hub. Để giải quyết các vấn đề phát sinh",
  general_policy_section4_description3: `Chúng tôi luôn hoan nghênh các ý kiến đóng góp, liên hệ và phản hồi thông tin từ bạn về “Chính sách và quy định chung này” này. Nếu bạn có những thắc mắc liên quan xin vui lòng liên hệ chủ sở hữu website:`,
  general_policy_section4_list1_item1: "Tên chủ sở hữu",
  general_policy_section4_list2_item1: "CÔNG TY TNHH GOODLIFE (Aquatics Hub)",

  general_policy_section4_list3_item1:
    "Địa chỉ: Tầng 1, tòa nhà 4 tầng, Khách Sạn Công Đoàn Quảng Bá, 98 Tô Ngọc Vân, Phường Quảng An, Quận Tây Hồ, Thành Phố Hà Nội",
  general_policy_section4_list4_item1: "Điện thoại: 0969685445",
  general_policy_section4_list5_item1: "Email: contact@aquaticshub.vn",
  general_policy_section4_list6_item1:
    "Số giấy chứng nhận đăng ký kinh doanh: 0110496704",
  general_policy_section4_list7_item1: "Ngày cấp: 04/10/2023",
  general_policy_section4_description4:
    "Do Phòng Đăng ký kinh doanh Thành phố Hà Nội cấp",
  // #endregion

  // #region Service coure
  sc_policy_title: "KHÓA HỌC BƠI/DỊCH VỤ BƠI TẠI CÔNG TY AQUATICS HUB",
  sc_policy_section1_title:
    "Các khoá bơi/ các dich vụ bơi tại bể bơi Aquatics Hub:",
  sc_policy_section1_description:
    "Chào mừng bạn đến với Aquatics Hub, một bể bơi 8 làn, dài 25 mét hiện đại ở Hà Nội, phục vụ người bơi lội ở mọi lứa tuổi và trình độ kỹ năng, mang lại niềm vui và sức khỏe dưới nước suốt đời cho các cá nhân và gia đình.",
  sc_policy_section_2:
    " CÁC KHOÁ HỌC BƠI THEO NHÓM/CÁ NHÂN CHO HỌC SINH/SINH VIÊN",
  sc_policy_section_2_desciption_1:
    "Các bài học bơi của Aquatics Hub tuân theo phương pháp ​”Trường Cá”, tập trung vào ​kỹ thuật bơi được phát triển bởi các giảng viên chuyên nghiệp. Phương pháp này chia việc học thành các bước có thể quản lý được, ​cung cấp một chương trình giảng dạy có cấu trúc để tăng tốc phát triển kỹ năng.",
  sc_policy_section_2_desciption_2:
    "Các khoá học được tổ chức theo mô hình học hàng ngày, học hàng tuần, hàng kỳ và dành cho các bạn từ mẫu giáo cho đến sinh viên.",
  sc_policy_section_2_desciption_3:
    "Các lớp học theo nhóm được tổ chức với số lượng 10 bạn/lớp và chia theo đúng trình độ của học viên",
  sc_policy_section_2_desciption_4:
    "Các lớp học cá nhân được tổ chức theo quy mô 1-1; 1-3.",
  sc_policy_section_2_desciption_5:
    "Tất cả các lớp học được mở ra để đảm bảo sao cho học viên đạt được kỹ năng tốt nhất sau mỗi khóa học.",
  sc_policy_section_3: "KHOÁ HUẤN LUYỆN ĐỘI BƠI",
  sc_policy_section_3_desciption_1:
    "Aquatics Hub đào tạo và huấn luyện học viên theo mô hình đội bơi đi thi đấu trong và ngoài nước. Các đội bơi của Aquatics Hub sẽ được chia theo lứa tuổi và trình độ.",
  sc_policy_section_3_desciption_2:
    "Các khoá học này phù hợp cho các bạn học sinh và sinh viên có nhu cầu nâng cao kỹ năng để đi thi đấu.",
  sc_policy_section_3_desciption_3:
    "Mô hình của các khoá học này không giới hạn số lượng học viên. Thời lượng học có thể theo ngày/tuần với phí đóng theo gói.",
  sc_policy_section_4:
    "KHOÁ HỌC BƠI THEO CHƯƠNG TRÌNH THỂ DỤC THỂ THAO CỦA CÁC TRƯỜNG HỌC",
  sc_policy_section_4_description_1:
    "Aquatics Hub cũng cung cấp chương trình dạy bơi cho học sinh các trường theo chương trình giáo dục thể chất đang áp dụng tại từng trường.",
  sc_policy_section_4_description_2:
    "Với mô hình này, các trường học cho học sinh đến bể Aquatics Hub và giáo viên tại Aquatics Hub sẽ phối hợp cùng giáo viên các trường để lên chương trình và dạy bơi cho học sinh.",
  sc_policy_section_4_description_3:
    "Thời gian học sẽ áp dụng phù hợp với thời gian học sinh đi học tại trường.",
  sc_policy_section_5: "CÁC KHOÁ HỌC BƠI CHO NGƯỜI LỚN",
  sc_policy_section_5_description_1:
    "Aquatics Hub tin rằng việc học tập là một quá trình cả đời và chinh phục khả năng ở mọi lứa tuổi! Các lớp học bơi dành cho người lớn của Aquatics Hub mở ra cho mọi cấp độ, từ những người mới bắt đầu học cách tự tin dưới nước cho đến những người bơi lội có kinh nghiệm được đào tạo thường xuyên hoặc chuẩn bị cho cuộc thi ba môn phối hợp. Các khoá học bơi cho người lớn ở Aquatics Hub gồm các khoá dành cho người mới bắt đầu học; các khoá học chuyên sâu về kỹ thuật, các khoá học bơi kết hợp thể lực, thi đấu. Ngoài ra còn có các khoá dạy cứu hộ, dạy giáo viên bơi và cung cấp chứng chỉ cứu hộ và chứng chỉ dạy bơi Các khoá học này được tổ chức theo mô hình hàng ngày/tuần/tháng/kỳ dễ dàng phù hợp với lịch của khách hàng.",
  sc_policy_section_6: "DỊCH VỤ BƠI TỰ DO/ CHO THUÊ LÀN BƠI/CHO THUÊ BỂ BƠI",
  sc_policy_section_6_description_1:
    "Khám phá niềm vui dưới nước tại Aquatics Hub! Cho dù đó là bạn đến với bể để bơi tự do hay bơi cùng với gia đình thì Aquatics Hub đều chào đón các bạn. Hãy đến với bể của chúng tôi để có trải nghiệm cộng đồng bơi lội sôi động.",
  sc_policy_section_6_description_2:
    "- Dành cho các khách hàng lẻ, các câu lạc bộ bơi, các công ty, các doanh nghiệp có nhu cầu mua vé bơi tự do cho chính mình, cho nhân viên của công ty/ doanh nghiệp/ tổ chức.",
  sc_policy_section_7: "DỊCH VỤ CHO THUÊ BỂ KỸ THUẬT VÀ BỂ NGÂM ĐÁ",
  sc_policy_section_7_description_1:
    "Khám phá The Aquarium, khu bảo tồn thủy sinh mang tính cách mạng ở Hà Nội mang đến cho khách hàng một không gian bơi lội trong nhà chưa từng có. Đắm mình trong bể bơi chuyên dụng dài 8 mét của chúng tôi, được trang bị để bơi vô tận, xoay vòng, phân tích kỹ thuật bơi. Liệu pháp lạnh trẻ hóa có sẵn trong bồn ngâm.",
  sc_policy_section_7_description_2:
    "Bể bơi kỹ thuật dành cho bất kỳ cá nhân, câu lạc bộ bơi, đơn vị, công ty, doanh nghiệp có nhu cầu nâng cao kỹ thuật, sức bền, giải toả căng thẳng lo lắng và phục hồi sức khoẻ.",
  sc_policy_section_7_description_3:
    "Các lớp được tổ chức tại Aquatics Hub với mô hình đa dạng, giáo viên có chuyên môn và có khả năng nói được hai ngôn ngữ.",
  sc_policy_section_7_description_4:
    "Ngoài ra, Aquatics Hub còn tổ chức lớp học bơi tại các trường học, cơ sở, đơn vị có nhu cầu mời giáo viên của Aquatics Hub đến dạy.",
  sc_policy_section_7_description_5:
    "Học phí của Aquatics Hub phù hợp với nhu cầu học của học viên.",
  sc_policy_section_8: "QUY TRÌNH ĐĂNG KÝ HỌC BƠI NHƯ SAU",
  sc_policy_section_8_description_1:
    "Học viên đăng ký trực tiếp qua số điện thoại.",
  sc_policy_section_8_description_2:
    "hotline của Aquatics Hub: 0969685445 (Admin) hoặc gửi thư vào địa chỉ hòm thư:",
  sc_policy_section_8_description_3:
    "contact@aquaticshub.vn hoặc học viên đăng ký trực tiếp trên website của Aquatics hub.",
  sc_policy_section_8_description_4:
    "- Học viên cung cấp thông tin: tên, số điện thoại liên hệ, khóa học.",
  sc_policy_section_8_description_5:
    "- Học viên tự chọn lịch học mong muốn hoặc khớp lịch với giáo viên.",
  sc_policy_section_8_description_6:
    "- Buổi học đầu tiên, Aquatics Hub sẽ đón bạn tại bể bơi, nhận giáo viên, nhận lớp và bắt đầu buổi học đầu tiên.",
  sc_policy_section_8_description_7: "Lưu ý:",
  sc_policy_section_8_description_8:
    "Học viên thanh toán 100% học phí vào buổi học đầu tiên. Học phí lớp bơi kèm nhóm đã bao gồm vé vào bể bơi.",
  sc_policy_section_8_description_9: "CAM KẾT 100% HỌC VIÊN SAU KHÓA HỌC:",
  sc_policy_section_8_description_10:
    "Bơi đúng kĩ thuật và bơi đúng theo mô hình đào tạo  bơi đúng kĩ thuật và bơi đúng theo mô hình đào tạo của từng loại hình lớp từng loại hình lớp.",
  sc_policy_section_8_description_11: "Đặc biệt:",
  sc_policy_section_8_description_12: "Vui lòng chỉ liên hệ với số điện thoại",
  sc_policy_section_8_description_13:
    "0969685445 (Admin) hoặc email vào địa chỉ: contact@aquaticshub.vn",
  sc_policy_section_8_description_14:
    "khi đăng ký học bơi, trước khi đến bể bơi, trong suốt quá trình học bơi cũng như sau khi kết thúc khóa học. Chúng tôi không chịu bất cứ trách nhiệm nào khi bạn học nhầm địa chỉ, nhầm trung tâm, sai HLV nếu bạn liên hệ với ai khác!",
  sc_policy_section_8_description_15:
    " Học viên tham khảo các khoá học bơi và các dịch vụ bơi tại website của Aquatics Hub:",
  // #endregion

  // #region Select Member
  SelectMember_title: "Vui lòng chọn thành viên cho khóa học",
  SelectMember_please_select: "Vui lòng chọn một thành viên",
  SelectMember_main_user: "Người dùng chính",
  SelectMember_child: "Thành viên",
  SelectMember_no_child:
    "Chưa có thành viên trẻ nào được đăng ký.Nhấp vào nút để đến trang thêm thành viên.",
  SelectMember_Add_member: "Thêm thành viên",
  SelectMember_next: "Trang tiếp theo",
  // #endregion

  //#endregion

  // #endregion Payments status

  payments_status_success: "Giao dịch mua hàng của bạn đã thành công",
  payments_status_thankyou: "Cảm ơn!",
  payments_status_appreciate:
    "Chúng tôi đánh giá cao hoạt động kinh doanh của bạn và hy vọng bạn thích sản phẩm mới của mình.",
  payments_status_home: "Trang chủ",
  payments_status_success: "Thanh toán thành công",
  payments_status_warning: "Giao dịch đáng ngờ",
  payments_status_warning_desc:
    "Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường)",
  payments_status_incomplete: "Thanh toán không thành công",
  payments_status_bankOutOfOrder: "Ngân hàng thanh toán đang bảo trì",
  payments_status_AccNotInternetBanking:
    "Tài khoản của quý khách chưa đăng ký dịch vụ InternetBanking tại ngân hàng",
  payments_status_AccAuthInforWrong3Times:
    "Xác thực sai thông tin tài khoản quá số lần quy định",
  payments_status_paymentExpired:
    "Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch",
  payments_status_AccIsBlocked: "Tài khoản của quý khách bị khóa",
  payments_status_WrongOTP:
    "Sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch",
  payments_status_TransCancelled: "Giao dịch đã được hủy",
  payments_status_AccNotEnounghBalance:
    "Quý khách không đủ số dư để thực hiện giao dịch",
  payments_status_MaxTransCapPerDay:
    "Tài khoản của quý khách đã vượt quá hạn mức giao dịch trong ngày",
  payments_status_PaymentAuthWrong3Times:
    "Sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
  payments_status_UnknowErrContactAdmin:
    "Có lỗi xảy ra, xin quý khách vui lòng liên hệ chúng tôi để được giải quyết",
  payments_status_continueRegister: "Tiếp tục đăng ký ?",
  payments_status_tksForReg:
    "Cảm ơn bạn đã đăng ký, chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất",
  payments_status_DONE: "Hoàn tất",
  pu_course_detail_addToCart: "Thêm vào giỏ hàng",
  payments_status_bookingSuccess_cash: "Đăng ký thành công",
  payments_status_bookingSuccess_cash_desc:
    "Thanh toán tại quầy thật sớm để hoàn tất đăng ký nhé",

  payments_status_tksForReg:
    "Cảm ơn bạn đã đăng ký, chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất",
  payments_status_DONE: "Hoàn tất",

  course_detail_enrolled: "Bạn đã đăng ký khóa học này",
  course_detail_complete_payment: "Hoàn tất thanh toán",
  course_cart: "Giỏ hàng",
  course_detail_enrolled_2: "Bạn đã đăng ký khóa học này",
  course_detail_are_you_sure:
    "Bạn có chắc chắn hoàn tất thanh toán cho khóa học này không?",
  course_detail_add_this_course: "Thêm khóa học này vào giỏ hàng?",
  my_cart: "Giỏ hàng của tôi",
  Go_to_my_cart: "Đến giỏ hàng của tôi",

  //#endregion
};

export default VI_TRANSLATE;
