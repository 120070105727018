import React from "react";
import { useNavigate } from "react-router-dom";

// const Card = ({ startDate, finishDate, session, time, price, isSelected, onSelect }) => {
const Card = ({
  startDate,
  finishDate,
  session,
  time,
  price,
  available,

  quizResult,
  path,
}) => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));
  return (
    //   <div className="flex flex-col w-full mx-auto">
    <div className=" flex flex-col gap-8">
      <div
        className={`bg-white shadow-lg px- py-4 w-full mx-auto ${
          session === "1" ? "border-black border-2" : ""
        }`}
        //   onClick={onSelect}
        // <div
        //   className="bg-white shadow-lg px- py-4 w-full mx-auto"
      >
        <div className="text-center">
          <p className="lg:text-[1.2vw] md:text-sm sm:text-base font-bold text-[#b88364] mb-1">
            START: {startDate}
          </p>
          <p className="lg:text-[1.2vw] md:text-sm sm:text-base font-bold text-[#b88364] mb-1">
            FINISH: {finishDate}
          </p>
          <p className="text-xl sm:text-3xl md:text-2xl font-roboto_regular text-black mb-1">
            SESSION {session}
          </p>
          <p className="lg:text-[1.2vw] md:text-sm sm:text-base font-bold text-[#b88364] mb-1">
            {time}
          </p>
          <p className="lg:text-[1.2vw] md:text-sm sm:text-base font-bold text-[#b88364]">
            {price}
          </p>
        </div>
      </div>

      {available === true ? (
        <button
          className=" uppercase bg-[#dcd3d1] w-full py-6 rounded-full pv:max-ph:rounded-full"
          onClick={() => {
            if (user) {
              sessionStorage.setItem(
                "secondStage",
                JSON.stringify({ ...quizResult })
              );

              navigate("../" + path, {
                state: {
                  data: { ...quizResult },
                  type: "SOF",
                },
              });
            } else {
              sessionStorage.setItem(
                "secondStage",
                JSON.stringify({ ...quizResult })
              );

              navigate("../login", {
                state: {
                  redirectCallBack: navigate("../" + path, {
                    state: {
                      data: { ...quizResult },
                    },
                  }),
                },
              });
            }
          }}
        >
          <p className="underline lg:text-[1.2vw] md:text-sm sm:text-base">
            BOOK NOW
          </p>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Card;
