/** @format */

import React, { useState } from "react";
import "../styles.css";
import { Icon } from "@iconify/react";
import { Modal, Select, Result } from "antd";
import { notification, Popconfirm } from "antd";
import { apiEnrollLesson, apiEnrollLesson_VNPay } from "../../services/lesson";
import { formatCurrency, path } from "../../utils/constant";
import { useNavigate, useLocation } from "react-router-dom";
import { apiPostQuiz } from "../../services/secondstage";

//Multi-lang
import { withTranslation, useTranslation } from "react-i18next";
import iconmomo from "../../assets/MoMo_Logo.ico";
import iconvnpay from "../../assets/logo-primary.svg";
import iconalepay from "../../assets/AlePay.ico";
import iconCash from "../../assets/iconCash.png";

const Checkout = ({ t }) => {
  //Multi Lang
  // const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // const enrollmentCart = JSON.parse(sessionStorage.getItem("enrollmentCart"));
  const enrollmentCart = JSON.parse(location.state.enrollmentCart);
  const secondStageData = JSON.parse(sessionStorage.getItem("secondStage"))
  const [openContinueReg, setOpenContinueReg] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const method = [
    {
      value: "cash",
      name: `${t("check_out_cash")}`,
      icon: (
        <div className="">
          <img className="h-12 w-12 " src={iconCash} alt="cash"></img>
        </div>
      ),
      // subMethod: [{ icon: "logos:mastercard" }, { icon: "logos:visa" }],
    },
    {
      value: "vnp",
      name: (
        <div className="flex items-center gap-2">
          Vn pay
          <p className="text-red-500">(*{t("check_out_vnpay_recommend")})</p>
        </div>
      ),
      icon: (
        <div className="">
          <img className="h-12 w-12 " src={iconvnpay} alt="vnpay"></img>
          <p className=""></p>
        </div>
      ),
    },
    // {
    //   value: "momo",
    //   name: "Momo",
    //   icon: (
    //     <div className="">
    //       <img className="h-12 w-12 " src={iconmomo} alt="momo"></img>
    //     </div>
    //   ),
    // },
  ];

  const handleCourseEnroll = async () => {
    if (enrollmentCart?.enrollInfo.EnrollmentType == "SOF") {
      if (enrollmentCart?.enrollmentDetail.quiz.answers.length > 0) {
        await apiPostQuiz({
          answers: enrollmentCart?.enrollmentDetail.quiz.answers,
          quizName: enrollmentCart?.enrollmentDetail.quiz.name,
          userId: enrollmentCart?.enrollInfo.UserID,
          userType: enrollmentCart?.enrollInfo.UserType,
        }).catch((err) => {
          notification.error({
            message: "Error evaluating user",

            placement: "topRight",
            duration: 2,
          });
        });
      }
    }

    await apiEnrollLesson({
      userId: enrollmentCart?.enrollInfo.UserID,
      userType: enrollmentCart?.enrollInfo.UserType,
      enrollmentType: enrollmentCart?.enrollInfo.EnrollmentType,
      enrollmentCode: enrollmentCart?.enrollInfo.EnrollmentCode,
      paymentMethod: paymentMethod,
      instructionLevel: enrollmentCart?.enrollmentDetail.quiz.instructorLevel,
      targetLevel: enrollmentCart?.enrollmentDetail.quiz.targetID,
    })
      .then((res) => {
        if (res?.status === 200) {
          notification.success({
            message: `${t("enroll_successful")}`,
            placement: "topRight",
            duration: 2,
          });
          switch (paymentMethod) {
            case "vnp":
              if (res.data.status === 200) {
                window.location = res.data.data.checkoutUrl;
              } else {
                notification.error({
                  message: `${t("payments_error")}`,
                  placement: "topRight",
                  duration: 2,
                });
              }
              break;

            default:
              if (res.data.status === 200) {
                setOpenContinueReg(!openContinueReg)
              }
              break;
          }
        }
      })
      .catch((err) => {
        notification.error({
          message: `${t("error_checkout")}`,
          placement: "topRight",
          duration: 2,
        });
        console.error(err.message);
      });
  };

  return (
    <div className="md:flex justify-between bg-white pt-[90px]">
      <div className="p-[3rem] px-[2rem] flex flex-col gap-[25px] w-[100%] md:w-[57%] bg-white">
        <div className="flex flex-col gap-[20px]">
          <h1 className="font-bold text-3xl">{t("check_out_title")}</h1>
          <h1 className="font-bold text-2xl">{t("check_out_address_title")}</h1>
        </div>
        <div className="flex flex-col gap-[15px]">
          <div className="flex justify-between items-center w-[50%] sm:max-md:w-[35%] md:max-lg:w-[50%] lg:w-[35%]">
            <div className="font-bold text-lg">
              {t("check_out_address_conutry")}
            </div>
            <div className="text-lg">{t("check_out_require")}</div>
          </div>
          <div class="w-[50%] sm:max-md:w-[35%] md:max-lg:w-[50%] lg:w-[35%] checkcout_select">
            <Select
              defaultValue="vietnam"
              size="large"
              className="w-full"
              options={[
                {
                  value: "vietnam",
                  label: (
                    <div className="flex gap-[10px] items-center">
                      <Icon icon={"ph:globe-bold"} className="w-5 h-5" />
                      Việt Nam
                    </div>
                  ),
                },
                {
                  value: "uk",
                  label: (
                    <div className="flex gap-[10px] items-center">
                      <Icon icon={"ph:globe-bold"} className="w-5 h-5" />
                      United Kingdom
                    </div>
                  ),
                },
                {
                  value: "russia",
                  label: (
                    <div className="flex gap-[10px] items-center">
                      <Icon icon={"ph:globe-bold"} className="w-5 h-5" />
                      Russia
                    </div>
                  ),
                },
              ]}
            />
          </div>

          <p className="text-slate-400">{t("check_out_note")}</p>
        </div>
        <div className="flex flex-col gap-[15px]">
          <div className="flex justify-between items-center">
            <div className="font-bold text-lg">
              {t("check_out_method_title")}
            </div>
            <div className="font-bold text-lg flex gap-[10px]">
              <div>{t("check_out_secured")}</div>
              <div className="flex items-center">
                <Icon
                  icon={"material-symbols:shield-lock"}
                  className="w-8 h-8"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[15px]">
            {method.map((item, index) => {
              if (paymentMethod === item.value) {
                return (
                  <label>
                    <input
                      type="radio"
                      name="payment_method"
                      value={item.value}
                      checked
                      onClick={(e) => setPaymentMethod(e.target.value)}
                    />
                    <div className="flex items-center justify-between p-[10px] pr-[30px]">
                      <div className="flex gap-[25px] items-center">
                        <Icon
                          icon={"system-uicons:radio-on"}
                          className="w-8 h-8"
                        />
                        <div className="flex gap-[10px] items-center">
                          {item.icon}
                          <div className="font-bold text-lg">{item.name}</div>
                        </div>
                      </div>
                      {item.subMethod ? (
                        <div className="flex items-center gap-[7px]">
                          {item.subMethod.map((ele, idx) => {
                            return <Icon icon={ele.icon} className="w-8 h-8" />;
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </label>
                );
              } else {
                return (
                  <label>
                    <input
                      type="radio"
                      name="payment_method"
                      value={item.value}
                      onClick={(e) => setPaymentMethod(e.target.value)}
                    />
                    <div className="flex items-center justify-between p-[10px] pr-[30px]">
                      <div className="flex gap-[25px] items-center">
                        <Icon
                          icon={"akar-icons:radio"}
                          className="w-8 h-8"
                          values="credit"
                        />
                        <div className="flex gap-[10px] items-center">
                          {item.icon}
                          <div className="font-bold text-lg">{item.name}</div>
                        </div>
                      </div>
                      {item.subMethod ? (
                        <div className="flex items-center gap-[7px]">
                          {item.subMethod.map((ele, idx) => {
                            return <Icon icon={ele.icon} className="w-8 h-8" />;
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </label>
                );
              }
            })}
          </div>
        </div>
        <div className="flex flex-col gap-[15px]">
          <div className="font-bold text-lg">{t("check_out_order_title")}</div>
          <div className="flex flex-col gap-[15px]">
            <div className="flex items-center gap-[30px]">
              <img
                className="w-[6rem] h-[6rem] object-cover"
                src={enrollmentCart?.enrollmentDetail.PictureUrl}
                alt="image"
              />
              <div className="flex flex-col gap-[20px]">
                <h1 className="font-bold text-lg">
                  {enrollmentCart?.enrollmentDetail.Description}
                </h1>
                <div className="text-slate-400 text-lg">
                  {formatCurrency(enrollmentCart?.enrollmentDetail.Price)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[100%] md:max-lg:w-[50%] lg:w-[40%] px-[2rem] py-[6rem] sm:max-md:px-[5rem] md:max-lg:px-[2rem] lg:px-[5rem] bg-primary flex flex-col gap-[30px]">
        <h1 className="font-bold text-2xl">{t("check_out_Summary")}</h1>
        <div className="flex flex-col w-full gap-[15px]">
          <div className="flex flex-col gap-[15px]">
            <div className="flex justify-between">
              <div className="font-bold text-lg">{t("check_out_price")}</div>
              <div className="text-slate-400">
                {formatCurrency(enrollmentCart?.enrollmentDetail.Price)}
              </div>
            </div>
            {/* <div className="flex justify-between">
              <div className="font-bold text-lg">
                {t("check_out_service_charge")}
              </div>
              <div className="text-slate-400">1%</div>
            </div> */}
          </div>
          <div className="border-b-2 border-black"></div>
          <div className="flex justify-between">
            <div className="font-bold text-lg">{t("check_out_total")}</div>
            <div className="font-bold text-lg">
              {formatCurrency(enrollmentCart?.enrollmentDetail.Price)}
            </div>
          </div>
        </div>
        <p className="text-black flex flex-col items-center">
          {t("check_out_purchase_title")}
          <span
            className="text-[#9623F0] cursor-pointer"
            onClick={() => window.open(path.POLICY)}
          >
            {t("check_out_term")}
          </span>
        </p>
        <div className="flex flex-col items-center gap-[15px]">
          <Popconfirm
            title={t("pu_course_detail_registration_confirmation")}
            description={t("pu_course_detail_registration_confirmation_desc")}
            onConfirm={() => {
              handleCourseEnroll();
            }}
            okText={
              <p className="text-black">{t("pu_course_detail_me_yes")}</p>
            }
            cancelText={t("pu_course_detail_me_no")}
          >
            <button
              type="button"
              className={`p-5 font-bold text-white bg-[#9623F0] outline-none w-[70%] rounded-md flex items-center gap-x-1 justify-center cursor-pointer`}
            // onClick={() => {

            // }}
            >
              <p className="">{t("check_out_next")}</p>
            </button>
          </Popconfirm>
        </div>
      </div>

      {openContinueReg
        ? <Modal
          open={openContinueReg}
          footer={null}
          onCancel={() => {
            notification.success({
              message: t("payments_status_tksForReg"),
              placement: "topRight",
              duration: 2,
            });
            navigate("../" + path.HOME)
          }}
        >
          <Result
            status={"success"}
            title={t("payments_status_bookingSuccess_cash")}
            subTitle={t("payments_status_bookingSuccess_cash_desc")}
          />
          <div className="flex justify-between">
            <button className=" w-[50%] mx-auto py-3 bg-secondary1 rounded-lg">
              <p
                className="text-white text-[20px] font-roboto_bold"
                onClick={() => {
                  navigate("../" + secondStageData?.pathUrl + "s", {
                    state: {
                      data: secondStageData
                    },
                  })
                }}
              >
                {t("payments_status_continueRegister")}
              </p>
            </button>
            <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg p-4">
              <p
                className="text-white text-[20px] font-roboto_bold"
                onClick={() => {
                  notification.success({
                    message: t("payments_status_tksForReg"),
                    placement: "topRight",
                    duration: 2,
                  });
                  navigate("../" + path.HOME)
                }}
              >
                {t("payments_status_DONE")}
              </p>
            </button>
          </div>
        </Modal>
        : null}
    </div>
  );
};

export default withTranslation()(Checkout);
