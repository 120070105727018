/** @format */
import "./style.css";
import { useNavigate } from "react-router-dom";
import { path } from "../../utils/constant";
import ImageAssessDenied from "../../assets/assess-denied.png";
import { withTranslation, useTranslation } from "react-i18next";

import "./style.css";

const NotAuthorizePage = ({ t }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("../" + path.HOME);
  };
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-4">
      <div className="text-red-500 text-6xl font-roboto_bold">
        <p className="">{t("not_authorize_title")}</p>
      </div>
      <div className="">
        <img className="object-cover" src={ImageAssessDenied}></img>
      </div>
      <div className="text-secondary1 text-4xl">
        {t("not_authorize_description")}
      </div>

      <button
        className="px-4 py-2 bg-secondary1 hover:bg-secondary2 rounded-xl"
        onClick={() => handleNavigate()}
      >
        <p className="text-white"> {t("not_authorize_back")}</p>
      </button>
    </div>
  );
};

export default withTranslation()(NotAuthorizePage);
