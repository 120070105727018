/** @format */

import axiosConfig from "../axiosConfig";
export const apiCourseRating = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/Student_Rating_2",
    data: payload,
  });

  return response;
};
export const apiSearchCourse = (description) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axiosConfig({
        method: "GET",
        url: `/Searching?Key=${description}`,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
