import { useNavigate, useSearchParams } from "react-router-dom";
import { path } from "../../utils/constant";
import { withTranslation, useTranslation } from "react-i18next";
import { notification, Result } from "antd";

const PaymentStatus = ({ t }) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams()
  const vnpayCallbackInfo = {
    vnp_Amount: searchParams.get("vnp_Amount"),
    vnp_BankCode: searchParams.get("vnp_BankCode"),
    vnp_BankTranNo: searchParams.get("vnp_BankTranNo"),
    vnp_CardType: searchParams.get("vnp_CardType"),
    vnp_OrderInfo: searchParams.get("vnp_OrderInfo"),
    vnp_PayDate: searchParams.get("vnp_PayDate"),
    vnp_ResponseCode: searchParams.get("vnp_ResponseCode"),
    vnp_TmnCode: searchParams.get("vnp_TmnCode"),
    vnp_TransactionNo: searchParams.get("vnp_TransactionNo"),
    vnp_TransactionStatus: searchParams.get("vnp_TransactionStatus"),
    vnp_TxnRef: searchParams.get("vnp_TxnRef"),
    vnp_SecureHash: searchParams.get("vnp_SecureHash")
  }
  const handleTitlePaymentError = (responseCode) => {
    let responseStr = ""
    switch (responseCode) {
      case "09":
        responseStr = t("payments_status_AccNotInternetBanking")
        break;
      case "10":
        responseStr = t("payments_status_AccAuthInforWrong3Times")
        break;
      case "11":
        responseStr = t("payments_status_paymentExpired")
        break;
      case "12":
        responseStr = t("payments_status_AccIsBlocked")
        break;
      case "13":
        responseStr = t("payments_status_WrongOTP")
        break;
      case "24":
        responseStr = t("payments_status_TransCancelled")
        break;
      case "51":
        responseStr = t("payments_status_AccNotEnounghBalance")
        break;
      case "65":
        responseStr = t("payments_status_MaxTransCapPerDay")
        break;
      case "79":
        responseStr = t("payments_status_PaymentAuthWrong3Times")
        break;

      default:
        responseStr = t("payments_status_UnknowErrContactAdmin")
        break;
    }
    return responseStr
  }

  const secondStageData = JSON.parse(sessionStorage.getItem("secondStage"))

  return (
    <div className="min-h-[80vh] flex items-center justify-center bg-gray-100 p-4 sm:p-8">
      <div className="bg-white p-6 sm:p-8 md:p-12 rounded-lg shadow-lg text-center w-full max-w-md mt-[50px]">
        <Result
          status={
            (vnpayCallbackInfo.vnp_ResponseCode == "00")
              ? "success"
              : (vnpayCallbackInfo.vnp_ResponseCode == "07")
                ? "warning"
                : (vnpayCallbackInfo.vnp_ResponseCode == "75")
                  ? "info"
                  : (vnpayCallbackInfo.vnp_ResponseCode == "09" || vnpayCallbackInfo.vnp_ResponseCode == "10" || vnpayCallbackInfo.vnp_ResponseCode == "11" ||
                    vnpayCallbackInfo.vnp_ResponseCode == "12" || vnpayCallbackInfo.vnp_ResponseCode == "13" || vnpayCallbackInfo.vnp_ResponseCode == "24" ||
                    vnpayCallbackInfo.vnp_ResponseCode == "51" || vnpayCallbackInfo.vnp_ResponseCode == "65" || vnpayCallbackInfo.vnp_ResponseCode == "79")
                    ? "error"
                    : "500"
          }
          title={
            (vnpayCallbackInfo.vnp_ResponseCode == "00")
              ? t("payments_status_success")
              : (vnpayCallbackInfo.vnp_ResponseCode == "07")
                ? t("payments_status_warning")
                : t("payments_status_incomplete")
          }
          subTitle={
            (vnpayCallbackInfo.vnp_ResponseCode == "00")
              ? t("payments_status_success_desc")
              : (vnpayCallbackInfo.vnp_ResponseCode == "07")
                ? t("payments_status_warning_desc")
                : (vnpayCallbackInfo.vnp_ResponseCode == "75")
                  ? t("payments_status_bankOutOfOrder")
                  : handleTitlePaymentError(vnpayCallbackInfo.vnp_ResponseCode)
          }
        />
        <div className="flex justify-between">
          <button className=" w-[50%] mx-auto py-3 bg-secondary1 rounded-lg">
            <p
              className="text-white text-[20px] font-roboto_bold"
              onClick={() => {
                const thirdStagePath =
                  vnpayCallbackInfo.vnp_TxnRef.substring(0, 3) == "SOF"
                    ? path.SWIMMINGLESSONS
                    : vnpayCallbackInfo.vnp_TxnRef.substring(0, 3) == "ADT"
                      ? path.ADULTCOURSES
                      : path.SWIMMINGLESSONS

                navigate("../" + thirdStagePath, {
                  state: {
                    data: secondStageData
                  },
                })
              }}
            >
              {t("payments_status_continueRegister")}
            </p>
          </button>
          <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg p-4">
            <p
              className="text-white text-[20px] font-roboto_bold"
              onClick={() => {
                notification.success({
                  message: t("payments_status_tksForReg"),
                  placement: "topRight",
                  duration: 2,
                });
                navigate("../" + path.HOME)
              }}
            >
              {t("payments_status_DONE")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PaymentStatus);
