import { notification } from "antd";
import axiosConfig from "../axiosConfig";

export const apiGet = async ({ params = undefined, endpoint = "" }) => {
    try {
        let paramsObject = {}
        if (params !== undefined) {
            const ParamKeys = Object.keys(params)
            ParamKeys.forEach((key) => {
                const ParamsItem = { [key]: params[key] }
                paramsObject = { ...paramsObject, ...ParamsItem }
            });
        }

        const response = await axiosConfig({
            method: "GET",
            url: endpoint,
            params: paramsObject
        })

        return response;
    } catch (error) {
        // notification.error({
        //     message: error.message,
        //     placement: "topRight",
        //     duration: 2,
        // });
        console.error(error.message);
    }
};

export const apiPost = async ({ payload = undefined, endpoint = "" }) => {
    try {
        let payloadObject = {}
        if (payload !== undefined) {
            const payloadKeys = Object.keys(payload)
            payloadKeys.forEach((key) => {
                const payloadItem = { [key]: payload[key] }
                payloadObject = { ...payloadObject, ...payloadItem }
            });
        }

        const response = await axiosConfig({
            method: "POST",
            url: endpoint,
            data: payloadObject
        })

        return response;
    } catch (error) {
        // notification.error({
        //     message: error.message,
        //     placement: "topRight",
        //     duration: 2,
        // });
        console.error(error.message);
    }
};