/** @format */

import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import {
  decryptString,
  formatCurrency,
  path,
  getMinutesBetween,
} from "../../../utils/constant";
import { Popconfirm, notification } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { apiusermember } from "../../../services/usermember";
import { apiRePayment } from "../../../services/lesson";
import { CardMemberShip } from "../../../components";
import { withTranslation } from "react-i18next";
import { apiPostCart } from "../../../services/secondstage";
import { apiGetCart } from "../../../services/secondstage";
const CourseDetail = ({ t, isFasttrack }) => {
  const [cartInfo, setCartInfo] = useState([]);

  let { courseCode } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const weekDays = [
    { key: 1, description: "Monday" },
    { key: 2, description: "Tuesday" },
    { key: 3, description: "Wednesday" },
    { key: 4, description: "Thursday" },
    { key: 5, description: "Friday" },
    { key: 6, description: "Saturday" },
    { key: 7, description: "Sunday" },
  ];

  const user = JSON.parse(sessionStorage.getItem("UserInformation"));
  const enrollment = location.state?.enrollment;
  const selectedMember = location.state?.userInfo;

  const fullRegisterDisplay =
    enrollment?.Enrolled / enrollment?.Capacity == 1 ? true : false;
  const [enrollInfo, setEnrollInfo] = useState({
    EnrollmentCode: decryptString({ encryptedStr: courseCode }),
    IsPending: false,
    EnrollmentType: location.state?.EnrollmentType,
  });

  const handleNavigatePaymentURL = (checkOutURL) => {
    window.location = checkOutURL;
  };

  // #region Modal Select Member
  const handleClick = () => {
    navigate(`../${path.CHECKOUT}`, {
      state: {
        enrollmentCart: JSON.stringify({
          enrollInfo: {
            ...enrollInfo,
            UserType: selectedMember.UserType,
            UserID: selectedMember.UserID,
          },
          enrollmentDetail: enrollment,
        }),
      },
    });
  };
  // #endregion
  const [disabledAddToCart, setDisableAddToCart] = useState(false);
  useEffect(() => {
    apiGetCart({ userId: user?.code })
      .then((res) => {
        if (res.status === 200) {

          if (
            res.data.data.filter((obj) => {
              return (
                obj.enrollmentId === enrollment.Number &&
                obj.studentId === selectedMember.UserID
              );
            }).length > 0
          ) {
            setDisableAddToCart(true);
          }
        }
        setCartInfo(res.data.data.length);
      })
      .catch((err) => console.error(err.message));
  }, []);

  return (
    <div className="">
      <div className="h-auto w-full">
        <div className="relative h-[300px] pv:max-md:h-[200px] md:max-lg:h-[250px] w-full mb-[200px] pvmax:max-ph:mb-[20px] pv:max-pvmax:mb-[20px]">
          <div className="absolute top-[30%] pv:max-md:top-[20%] md:max-xl:top-[25%] flex justify-center w-full">
            <p className="text-secondary2 text-6xl font-roboto_bold sm:max-md:text-4xl ph:max-sm:text-3xl pvmax:max-ph:text-4xl pv:max-pvmax:text-2xl">
              {enrollment?.Description}
            </p>
          </div>
          <div className=" absolute top-16  w-full h-full flex flex-col items-center justify-end pvmax:max-ph:hidden pv:max-pvmax:hidden">
            <div className="  mx-auto w-[90%] bg-white shadow-2xl h-[180px] rounded-xl grid grid-cols-4 sm:max-md:grid-cols-2 ph:max-sm:grid-cols-2 pvmax:max-ph:grid-cols-1  ">
              <div className="flex items-center gap-2 justify-center">
                <Icon
                  icon={"mdi:calendar"}
                  className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
                ></Icon>
                <div className="flex flex-col text-xl gap-6 sm:max-md:gap-2  ph:max-sm:gap-2">
                  <p className="">{t("pu_course_detail_startdate")}</p>
                  <p className="font-roboto_bold">{enrollment?.StartDate}</p>
                </div>
              </div>
              <div className="flex items-center gap-2 justify-center">
                <Icon
                  icon={"mdi:calendar"}
                  className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
                ></Icon>
                <div className="flex flex-col text-xl gap-6 sm:max-md:gap-2  ph:max-sm:gap-2">
                  <p className="">{t("pu_course_detail_enddate")}</p>
                  <p className="font-roboto_bold">{enrollment?.EndDate}</p>
                </div>
              </div>
              <div className="flex items-center gap-2 justify-center">
                <Icon
                  icon={"bxs:layer"}
                  className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
                ></Icon>
                <div className="flex flex-col text-xl gap-6 sm:max-md:gap-2 ph:max-sm:gap-2">
                  <p className="">{t("pu_course_detail_length")}</p>
                  <p className="font-roboto_bold">
                    {enrollment?.Duration}{" "}
                    {enrollment?.Duration > 1 ? "Weeks" : "Week"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2 justify-center">
                <Icon
                  icon={"mdi:clock-outline"}
                  className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
                ></Icon>
                <div className="flex flex-col text-xl gap-6  sm:max-md:gap-2  ph:max-sm:gap-2">
                  <p className="">{t("pu_course_detail_duration")}</p>
                  <p className="font-roboto_bold">
                    {enrollment?.Duration} x{" "}
                    {getMinutesBetween(
                      enrollment?.Timetable[0].timeTableStartTime,
                      enrollment?.Timetable[0].timeTableEndTime
                    )}{" "}
                    minutes lesson
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" hidden py-4 w-full h-full pvmax:max-ph:flex pv:max-pvmax:flex flex-col items-center justify-end ">
          <div className="  mx-auto w-[90%] bg-white shadow-2xl h-[180px] rounded-xl grid grid-cols-4 sm:max-md:grid-cols-2 ph:max-sm:grid-cols-2  pvmax:max-ph:h-[360px] pvmax:max-ph:grid-cols-1 pv:max-pvmax:grid-cols-1 pv:max-pvmax:h-[360px] ">
            <div className="flex items-center gap-2 justify-center">
              <Icon
                icon={"mdi:calendar"}
                className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
              ></Icon>
              <div className="flex flex-col text-xl gap-6 sm:max-md:gap-2  ph:max-sm:gap-2 pv:max-pvmax:flex-row pv:max-pvmax:items-center pvmax:max-ph:flex-row pvmax:max-ph:items-center pv:max-pvmax:text-[16px]">
                <p className="">{t("pu_course_detail_startdate")}</p>
                <p className="font-roboto_bold">{enrollment?.StartDate}</p>
              </div>
            </div>
            <div className="flex items-center gap-2 justify-center">
              <Icon
                icon={"mdi:calendar"}
                className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
              ></Icon>
              <div className="flex flex-col text-xl gap-6 sm:max-md:gap-2  ph:max-sm:gap-2 pvmax:max-ph:flex-row pvmax:max-ph:items-center pv:max-pvmax:flex-row pv:max-pvmax:items-center pv:max-pvmax:text-[16px]">
                <p className="">{t("pu_course_detail_enddate")}</p>
                <p className="font-roboto_bold">{enrollment?.EndDate}</p>
              </div>
            </div>
            <div className="flex items-center gap-2 justify-center">
              <Icon
                icon={"bxs:layer"}
                className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
              ></Icon>
              <div className="flex flex-col text-xl gap-6 sm:max-md:gap-2 ph:max-sm:gap-2 pvmax:max-ph:flex-row pvmax:max-ph:items-center pv:max-pvmax:flex-row pv:max-pvmax:items-center pv:max-pvmax:text-[16px]">
                <p className="">{t("pu_course_detail_length")}</p>
                <p className="font-roboto_bold">
                  {enrollment?.Duration}{" "}
                  {enrollment?.Duration > 1 ? "Weeks" : "Week"}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 justify-center">
              <Icon
                icon={"mdi:clock-outline"}
                className="text-secondary2 w-12 h-12 sm:max-md:w-9 sm:max-md:h-9 ph:max-sm:h-9 ph:max-sm:w-9"
              ></Icon>
              <div className="flex flex-col text-xl gap-6  sm:max-md:gap-2  ph:max-sm:gap-2 pvmax:max-ph:flex-row pvmax:max-ph:items-center pv:max-pvmax:flex-row pv:max-pvmax:items-center pv:max-pvmax:text-[16px] ">
                <p className="">{t("pu_course_detail_duration")}</p>

                <p className="font-roboto_bold">
                  {enrollment?.Duration} x{" "}
                  {getMinutesBetween(
                    enrollment?.Timetable[0].timeTableStartTime,
                    enrollment?.Timetable[0].timeTableEndTime
                  )}{" "}
                  minutes lesson
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" p-4 h-auto bg-white mx-auto w-[90%]  grid grid-cols-2 py-10 mb-10 rounded-2xl sm:max-md:grid-cols-1 pv:max-pvmax:grid-cols-1 ph:max-sm:grid-cols-1 pvmax:max-ph:grid-cols-1 pv:max-md:gap-10 md:gap-10 pv:max-ph:w-[100%] ">
          <div className="flex flex-col gap-4 ">
            <p className="text-4xl font-roboto_bold pv:max-pvmax:text-xl">
              {t("pu_course_detail_schedule")}
            </p>
            <p className="text-xl">
              {enrollment?.isFasttrack ? (
                <span>Everyday</span>
              ) : (
                <>
                  <span>Every </span>
                  <span className="font-roboto_bold">
                    {
                      weekDays[enrollment?.Timetable[0].timeTableWeekDay - 1]
                        ?.description
                    }
                  </span>{" "}
                  from {enrollment?.StartDate} - {enrollment?.EndDate}
                </>
              )}
            </p>
            <p className="text-2xl">
              Start from{" "}
              <span className="font-roboto_bold">
                {enrollment?.Timetable[0].timeTableStartTime}
              </span>{" "}
              to{" "}
              <span className="font-roboto_bold">
                {enrollment?.Timetable[0].timeTableEndTime}
              </span>
            </p>
          </div>
          <div className="flex flex-col  items-center gap-4">
            {user ? (
              <div className="flex gap-4">
                {selectedMember !== undefined ? (
                  enrollment?.selectedUserInAttendanceList.filter((obj) => {
                    return obj.code === selectedMember.UserID;
                  }).length > 0 ? (
                    enrollment?.selectedUserInAttendanceList.filter((obj) => {
                      return obj.code === selectedMember.UserID;
                    })[0].isInAttendanceList ? null : (
                      <button
                        disabled={disabledAddToCart}
                        className="px-10 py-2 border-2 border-[#0B2470] rounded-2xl hover:bg-[#0B2470] hover:text-white disabled:bg-slate-300 disabled:text-black disabled:border-slate-300 disabled:cursor-no-drop"
                        onClick={async () => {
                          await apiPostCart({
                            userId: selectedMember.UserID,
                            userType: selectedMember.UserType,
                            enrollmentType: enrollInfo.EnrollmentType,
                            enrollmentCode: enrollment?.Number,
                            action: "add",
                          })
                            .then((res) => {
                              if (res.data.status == 200) {
                                notification.success({
                                  message: "Add to cart success",
                                  placement: "topRight",
                                  duration: 2,
                                });
                                setDisableAddToCart(!disabledAddToCart);
                              }

                              sessionStorage.setItem(
                                "CartInfo",
                                JSON.stringify(res.data.data)
                              );
                            })
                            .catch((err) => console.error(err.message));
                        }}
                      >
                        <p className="text-2xl">
                          {t("pu_course_detail_addToCart")}
                        </p>
                      </button>
                    )
                  ) : null
                ) : null}
                {selectedMember !== undefined ? (
                  <Popconfirm
                    title={
                      enrollment?.selectedUserInAttendanceList.filter((obj) => {
                        return obj.code === selectedMember.UserID;
                      }).length > 0
                        ? enrollment?.selectedUserInAttendanceList.filter(
                            (obj) => {
                              return obj.code === selectedMember.UserID;
                            }
                          )[0].isInAttendanceList
                          ? enrollment?.selectedUserInAttendanceList.filter(
                              (obj) => {
                                return obj.code === selectedMember.UserID;
                              }
                            )[0].paymentMethod == "Cash"
                            ? "You have enrolled this course"
                            : "Complete payment"
                          : t("pu_course_detail_me_enroll")
                        : null
                    }
                    description={
                      enrollment?.selectedUserInAttendanceList.filter((obj) => {
                        return obj.code === selectedMember.UserID;
                      }).length > 0
                        ? enrollment?.selectedUserInAttendanceList.filter(
                            (obj) => {
                              return obj.code === selectedMember.UserID;
                            }
                          )[0].isInAttendanceList
                          ? enrollment?.selectedUserInAttendanceList.filter(
                              (obj) => {
                                return obj.code === selectedMember.UserID;
                              }
                            )[0].paymentMethod == "Cash"
                            ? "You have enrolled this course"
                            : "Are you sure to complete payment for this course ?"
                          : t("pu_course_detail_me_sure")
                        : null
                    }
                    okButtonProps={{
                      disabled:
                        enrollment?.selectedUserInAttendanceList.filter(
                          (obj) => {
                            return obj.code === selectedMember.UserID;
                          }
                        ).length > 0
                          ? enrollment?.selectedUserInAttendanceList.filter(
                              (obj) => {
                                return obj.code === selectedMember.UserID;
                              }
                            )[0].isInAttendanceList
                            ? enrollment?.selectedUserInAttendanceList.filter(
                                (obj) => {
                                  return obj.code === selectedMember.UserID;
                                }
                              )[0].paymentMethod != "Cash"
                              ? false
                              : true
                            : false
                          : false,
                    }}
                    onConfirm={async () => {
                      // if (submitType == "Book") {
                      //   if (
                      //     enrollment?.selectedUserInAttendanceList.filter((obj) => {
                      //       return obj.code === selectedMember;
                      //     }).length > 0
                      //   ) {
                      //     if (
                      //       enrollment?.selectedUserInAttendanceList.filter((obj) => {
                      //         return obj.code === selectedMember;
                      //       })[0].isInAttendanceList
                      //     ) {
                      //       if (
                      //         enrollment?.selectedUserInAttendanceList.filter(
                      //           (obj) => {
                      //             return obj.code === selectedMember;
                      //           }
                      //         )[0].paymentMethod != "Cash"
                      //       ) {
                      //         if (
                      //           enrollment?.selectedUserInAttendanceList.filter(
                      //             (obj) => {
                      //               return obj.code === selectedMember;
                      //             }
                      //           )[0].checkoutUrl != ""
                      //         ) {
                      //           if (
                      //             enrollment?.selectedUserInAttendanceList.filter(
                      //               (obj) => {
                      //                 return obj.code === selectedMember;
                      //               }
                      //             )[0].expiredAt != ""
                      //           ) {
                      //             if (
                      //               new Date() <
                      //               new Date(
                      //                 enrollment?.selectedUserInAttendanceList.filter(
                      //                   (obj) => {
                      //                     return obj.code === selectedMember;
                      //                   }
                      //                 )[0].expiredAt
                      //               )
                      //             ) {
                      //               handleNavigatePaymentURL(
                      //                 enrollment?.selectedUserInAttendanceList.filter(
                      //                   (obj) => {
                      //                     return obj.code === selectedMember;
                      //                   }
                      //                 )[0].checkoutUrl
                      //               );
                      //             } else {
                      //               const historyID =
                      //                 enrollment?.selectedUserInAttendanceList.filter(
                      //                   (obj) => {
                      //                     return obj.code === selectedMember;
                      //                   }
                      //                 )[0].historyId;
                      //               await apiRePayment(historyID)
                      //                 .then((res) => {
                      //                   if (res.status == 200) {
                      //                     window.location = res.data.data.checkoutUrl;
                      //                   }
                      //                 })
                      //                 .catch((err) => {
                      //                   console.error(err.message);
                      //                   notification.info({
                      //                     message:
                      //                       "Payment expired, please contact Admin",

                      //                     placement: "topRight",
                      //                     duration: 2,
                      //                   });
                      //                 });
                      //             }
                      //           }
                      //         } else {
                      //           handleClick();
                      //         }
                      //       }
                      //     } else {
                      //       handleClick();
                      //     }
                      //   }
                      // } else {
                      //   await apiPostCart({
                      //     userId: selectedMember,
                      //     userType: selectedMemberType,
                      //     enrollmentType: enrollInfo.EnrollmentType,
                      //     enrollmentCode: enrollment?.Number,
                      //     action: "add"
                      //   }).then((res) => {
                      //     if (res.data.status == 200) {
                      //       setOpen(!open)
                      //       notification.success({
                      //         message: "Add to cart success",
                      //         placement: "topRight",
                      //         duration: 2,
                      //       });
                      //     }
                      //   }).catch((err) => console.error(err.message))
                      // }

                      if (
                        enrollment?.selectedUserInAttendanceList.filter(
                          (obj) => {
                            return obj.code === selectedMember.UserID;
                          }
                        ).length > 0
                      ) {
                        if (
                          enrollment?.selectedUserInAttendanceList.filter(
                            (obj) => {
                              return obj.code === selectedMember.UserID;
                            }
                          )[0].isInAttendanceList
                        ) {
                          if (
                            enrollment?.selectedUserInAttendanceList.filter(
                              (obj) => {
                                return obj.code === selectedMember.UserID;
                              }
                            )[0].paymentMethod != "Cash"
                          ) {
                            if (
                              enrollment?.selectedUserInAttendanceList.filter(
                                (obj) => {
                                  return obj.code === selectedMember.UserID;
                                }
                              )[0].checkoutUrl != ""
                            ) {
                              if (
                                enrollment?.selectedUserInAttendanceList.filter(
                                  (obj) => {
                                    return obj.code === selectedMember.UserID;
                                  }
                                )[0].expiredAt != ""
                              ) {
                                if (
                                  new Date() <
                                  new Date(
                                    enrollment?.selectedUserInAttendanceList.filter(
                                      (obj) => {
                                        return (
                                          obj.code === selectedMember.UserID
                                        );
                                      }
                                    )[0].expiredAt
                                  )
                                ) {
                                  handleNavigatePaymentURL(
                                    enrollment?.selectedUserInAttendanceList.filter(
                                      (obj) => {
                                        return (
                                          obj.code === selectedMember.UserID
                                        );
                                      }
                                    )[0].checkoutUrl
                                  );
                                } else {
                                  const historyID =
                                    enrollment?.selectedUserInAttendanceList.filter(
                                      (obj) => {
                                        return (
                                          obj.code === selectedMember.UserID
                                        );
                                      }
                                    )[0].historyId;
                                  await apiRePayment(historyID)
                                    .then((res) => {
                                      if (res.status == 200) {
                                        window.location =
                                          res.data.data.checkoutUrl;
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err.message);
                                      notification.info({
                                        message:
                                          "Payment expired, please contact Admin",

                                        placement: "topRight",
                                        duration: 2,
                                      });
                                    });
                                }
                              }
                            } else {
                              handleClick();
                            }
                          }
                        } else {
                          handleClick();
                        }
                      }
                    }}
                    okText={
                      <p className="text-black">
                        {t("pu_course_detail_me_yes")}
                      </p>
                    }
                    cancelText={t("pu_course_detail_me_no")}
                  >
                    <button className="px-10 py-2 border-2 border-[#0B2470] rounded-2xl hover:bg-[#0B2470] hover:text-white disabled:bg-slate-300 disabled:text-black disabled:border-slate-300 disabled:cursor-no-drop">
                      <p className="text-2xl">
                        {t("pu_course_detail_book_class")}
                      </p>
                    </button>
                  </Popconfirm>
                ) : null}
              </div>
            ) : (
              // </Popconfirm>
              <button
                onClick={() => navigate("../" + `${path.LOGIN}`)}
                className="px-10 py-2 border-2 border-[#0B2470] rounded-2xl hover:bg-[#0B2470] hover:text-white disabled:bg-slate-300 disabled:text-black disabled:border-slate-300 disabled:cursor-no-drop"
              >
                <p className="text-2xl">Login now to register</p>
              </button>
            )}
            <div className=" flex flex-col gap-6">
              <div className="grid grid-cols-2 gap-20 pv:max-pvmax:gap-0 text-2xl">
                <p className="uppercase">Price </p>
                <p className="text-secondary1 text-xl">
                  {formatCurrency(enrollment?.Price)}
                </p>
              </div>

              <div
                className={`grid grid-cols-2 gap-20 pv:max-pvmax:gap-0 ${
                  fullRegisterDisplay ? "" : "hidden"
                }`}
              >
                <p className="">{t("pu_course_detail_full_continue")}</p>
                <input
                  type="checkbox"
                  onChange={() =>
                    setEnrollInfo({
                      ...enrollInfo,
                      IsPending: !enrollInfo.IsPending,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CourseDetail);
