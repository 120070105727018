/** @format */

import { path } from "../constant";
import {
  //common
  HomePage,
  ErrorPage,
  Policy,
  SwimCourse,
  // second stage
  SwimmingLesson,
  AdultCourse,
  Club,
  FastTrack,
  Training,
  PublicSwimming,
  RentalPool,
  // third stage
  SwimmingLessons,
  AdultCourses,
  FastTracks,
  Clubs,
  PublicSwimmings,
  //course
  CourseDetail,
  AquariumPrice,
  LaneRental,

  // PoolAll,
  // PoolDetails,
  // PublicAllCourse,
  // ListCourseSearch,
  // //student-notlogin
  // TeacherProfile,
  // CourseEnrollDetail,
  // ContactUs,
  // SelectMembership,
  // // Third Stage
  // UserProfile,

  // // InstructorHome
  // InstructorHomes,

  // Instructor
  InstructorCalendar,
  InstructorProfile,
  AllCourse,
  InstructorCourseDetail,
  InstructorStudentProfile,
  PoolParty,
  StepForm,
} from "../../pages";

const routes = [
  //#region common
  {
    path: `${path.HOME}`,
    exact: true,
    component: <HomePage />,
    main: () => <HomePage />,
  },
  {
    path: `${path.ERROR}`,
    exact: true,
    component: <ErrorPage />,
    main: () => <ErrorPage />,
  },
  {
    path: `${path.POLICY}`,
    exact: true,
    component: <Policy />,
    main: () => <Policy />,
  },
  {
    path: `${path.SERVICECOURSE}`,
    exact: true,
    component: <SwimCourse />,
    main: () => <SwimCourse />,
  },
  //#endregion

  //#region second stage
  {
    path: `${path.SWIMMINGLESSON}`,
    exact: true,
    component: <SwimmingLesson />,
    main: () => <SwimmingLesson />,
  },
  {
    path: `${path.ADULTCOURSE}`,
    exact: true,
    component: <AdultCourse />,
    main: () => <AdultCourse />,
  },
  {
    path: `${path.CLUB}`,
    exact: true,
    component: <Club />,
    main: () => <Club />,
  },
  {
    path: `${path.FASTTRACK}`,
    exact: true,
    component: <FastTrack />,
    main: () => <FastTrack />,
  },
  {
    path: `${path.TRAINING}`,
    exact: true,
    component: <Training />,
    main: () => <Training />,
  },
  {
    path: `${path.PUBLICSWIMMING}`,
    exact: true,
    component: <PublicSwimming />,
    main: () => <PublicSwimming />,
  },
  {
    path: `${path.RENTALPOOLLANE}`,
    exact: true,
    component: <RentalPool />,
    main: () => <RentalPool />,
  },
  {
    path: `${path.LANERENTAL}`,
    exact: true,
    component: <LaneRental />,
    main: () => <LaneRental />,
  },

  {
    path: `${path.POOLPARTY}`,
    exact: true,
    component: <PoolParty />,
    main: () => <PoolParty />,
  },
  //#endregion

  //#region third stage
  {
    path: `${path.FASTTRACKS}`,
    exact: true,
    component: <FastTracks />,
    main: () => <FastTracks />,
  },
  {
    path: `${path.ADULTCOURSES}`,
    exact: true,
    component: <AdultCourses />,
    main: () => <AdultCourses />,
  },
  {
    path: `${path.SWIMMINGLESSONS}`,
    exact: true,
    component: <SwimmingLessons />,
    main: () => <SwimmingLessons />,
  },
  {
    path: `${path.CLUBS}`,
    exact: true,
    component: <Clubs />,
    main: () => <Clubs />,
  },

  {
    path: `${path.AQUARIUMPRICE}`,
    exact: true,
    component: <AquariumPrice />,
    main: () => <AquariumPrice />,
  },
  {
    path: `${path.PUBLICSWIMMINGS}`,
    exact: true,
    component: <PublicSwimmings />,
    main: () => <PublicSwimmings />,
  },
  //#endregion

  //#region course
  {
    path: `${path.COURSEDETAIL}/:courseCode`,
    exact: true,
    component: <CourseDetail />,
    main: () => <CourseDetail />,
  },
  //#endregion

  // {
  //   path: `${path.CONTACTUS}`,
  //   exact: true,
  //   // component: <ContactUs />,
  //   // main: () => <ContactUs />,
  // },
  // {
  //   path: `${path.POOLALL}`,
  //   exact: true,
  //   // component: <PoolAll />,
  //   // main: () => <PoolAll />,
  // },
  // {
  //   path: `${path.POOLDETAIL}/:poolCode`,
  //   exact: true,
  //   // component: <PoolDetails />,
  //   // main: () => <PoolDetails />,
  // },
  // {
  //   path: `${path.TEACHERPROFILE}/:code`,
  //   exact: true,
  //   // component: <TeacherProfile />,
  //   // main: () => <TeacherProfile />,
  // },
  // {
  //   path: `${path.PUBLICALLCOURSE}`,
  //   exact: true,
  //   // component: <PublicAllCourse />,
  //   // main: () => <PublicAllCourse />,
  // },
  // {
  //   path: `${path.LISTCOURSESEARCH}`,
  //   exact: true,
  //   // component: <ListCourseSearch />,
  //   // main: () => <ListCourseSearch />,
  // },

  // {
  //   path: `${path.SELECTMEMBERSGIP}`,
  //   exact: true,
  //   // component: <SelectMembership />,
  //   // main: () => <SelectMembership />,
  // },

  // // test
  // {
  //   path: `${path.USERPROFILE}`,
  //   exact: true,
  //   // component: <UserProfile />,
  //   // main: () => <UserProfile />,
  // },

  {
    path: `${path.INSTRUCTORCALENDAR}`,
    exact: true,
    component: <InstructorCalendar />,
    main: () => <InstructorCalendar />,
    // role: ["Lecture"],
  },
  {
    path: `${path.ALLCOURSE}`,
    exact: true,
    component: <AllCourse />,
    main: () => <AllCourse />,
  },
  {
    path: `${path.INSTRUCTORPROFILE}`,
    exact: true,
    component: <InstructorProfile />,
    main: () => <InstructorProfile />,
  },
  {
    path: `${path.INSTRUCTORCOURSEDETAIL}`,
    exact: true,
    component: <InstructorCourseDetail />,
    main: () => <InstructorCourseDetail />,
  },
  {
    path: `${path.STUDENTPROFILE}`,
    exact: true,
    component: <InstructorStudentProfile />,
    main: () => <InstructorStudentProfile />,
  },

  {
    path: `${path.STEPFORM}`,
    exact: true,
    component: <StepForm />,
    main: () => <StepForm />,
  },
];

export default routes;
