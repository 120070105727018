/** @format */

import React, { useState, useEffect, useRef } from "react";
import {
  apiGetUserEnrollment,
  apienrollmentRefresh,
  apiusermemberEnrollment,
} from "../../services/usermember";
import { apiRePayment } from "../../services/lesson";
import { Icon } from "@iconify/react";
import {
  Tabs,
  Divider,
  Dropdown,
  Modal,
  Table,
  Popconfirm,
  notification,
  Empty,
  Button,
} from "antd";
import { withTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { exportComponentAsPNG } from "react-component-export-image";
import {
  ModalAddAdult,
  ModalAddChild,
  ModalChangePassword,
  ModalCouseDetail,
  ModalEditAdultProfile,
  ModalEditChildProfile,
  ModalEditUserProfile,
} from "../../components";
import { apiGetCart, apiPostCart } from "../../services/secondstage";
import { apiChangePass } from "../../services/auth";
import { useLocation } from "react-router-dom";
// API
import { apiusermember } from "../../services/usermember";

const UserProfile = ({ t }) => {
  const location = useLocation();

  const [valueTab, setValueTab] = useState("1");

  useEffect(() => {
    if (location.state) {
      setValueTab(location.state);
    } else setValueTab("1");
  }, [location.state]);

  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserInformation"))
  );
  const [child, setChild] = useState([
    JSON.parse(sessionStorage.getItem("ChildInformation")),
  ]);

  const [levelData, setlevelData] = useState([]);

  const [adult, setAdult] = useState([
    JSON.parse(sessionStorage.getItem("AdultInformation")),
  ]);

  const [datachild, setDatachild] = useState([]);
  const [dataadult, setDataadult] = useState([]);

  const [enrollmentChild, setenrollmentChild] = useState(
    JSON.parse(sessionStorage.getItem("enrollmentChild"))
  );
  const [enrollmentMain, setenrollmentMain] = useState(
    JSON.parse(sessionStorage.getItem("enrollmentMain"))
  );
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 768 ? true : false
  );
  const [tabPosition, setTabPosition] = useState(
    window.innerWidth <= 1024 ? "top" : "left"
  );
  const pdfRef = useRef();
  const [open, setOpen] = useState(false);
  const [cartInfo, setCartInfo] = useState([]);
  const [selectedQRCode, setSelectedQRCode] = useState({
    owner: {
      code: "",
      description: "",
    },
    qrcontent: "",
  });

  // Modal child
  const [openChild, setOpenChild] = useState(false);
  const showModalChild = () => {
    setOpenChild(true);
  };
  const handleCancelAddChild = () => {
    setOpenChild(false);
  };
  // Modal Adult
  const [openadult, setOpenAdult] = useState(false);
  const showModalAdult = () => {
    setOpenAdult(true);
  };
  const showModal = (e = undefined) => {
    setOpen(true);
    if (e) {
      setSelectedQRCode(e);
    }
  };
  const handleCancelAddAdult = () => {
    setOpenAdult(false);
  };

  // Modal Course Detail

  const [openModalCourseDetail, setModalCourseDetail] = useState(false);
  const [dataCourseDetail, setDataCourseDetail] = useState([]);
  const showModalCourseDetail = (e = undefined) => {
    setModalCourseDetail(true);
    if (e) {
      setDataCourseDetail(e);
    }
  };
  const handleCourseOk = () => {
    setModalCourseDetail(false);
  };
  const handleCourseCancel = () => {
    setModalCourseDetail(false);
  };

  // Modal Change password

  const [openModalChangePassword, setModalChangePassword] = useState(false);
  const showModalChangePassword = () => {
    setModalChangePassword(true);
  };
  const handleChangePasswordOk = () => {
    setModalChangePassword(false);
  };
  const handleChangePasswordCancel = () => {
    setModalChangePassword(false);
  };

  // Modal Edit user Profile

  const [openModalEditUserProfile, setModalEditUserProfile] = useState(false);
  const showModalEditUserProfile = () => {
    setModalEditUserProfile(true);
  };
  const handleEditUserProfileOk = () => {
    setModalEditUserProfile(false);
  };
  const handleEditUserProfileCancel = () => {
    setModalEditUserProfile(false);
  };

  // Modal Edit child Profile

  const [openModalEditChildProfile, setModalEditChildProfile] = useState(false);
  const showModalEditChildProfile = () => {
    setModalEditChildProfile(true);
  };
  const handleEditChildProfileOk = () => {
    setModalEditChildProfile(false);
  };
  const handleEditChildProfileCancel = () => {
    setModalEditChildProfile(false);
  };

  // Modal Edit adult Profile

  const [openModalEditAdultProfile, setModalEditAdultProfile] = useState(false);
  const showModalEditAdultProfile = () => {
    setModalEditAdultProfile(true);
  };
  const handleEditAdultProfileOk = () => {
    setModalEditAdultProfile(false);
  };
  const handleEditAdultProfileCancel = () => {
    setModalEditAdultProfile(false);
  };

  useEffect(() => {
    apienrollmentRefresh().then((response) => {
      if (response.status === 200) {
        setenrollmentChild(response.data.data.enrollmentChild);
        setenrollmentMain(response.data.data.enrollmentMain);
      }
    });
    apiusermemberEnrollment().then((response) => {
      if (response.status === 200) {
        setUser(response.data.data.information);
        setChild(response.data.data.childMembers);
        setAdult(response.data.data.adultMembers);
        setlevelData(response.data.data.level);
      }
    });
    apiGetCart({ userId: user.code })
      .then((res) => {
        if (res.status === 200) {
          setCartInfo(res.data.data);
        }
      })
      .catch((err) => console.error(err.message));
  }, [
    openChild,
    openadult,
    openModalEditAdultProfile,
    openModalEditUserProfile,
    openModalEditChildProfile,
  ]);

  const itemsfamily = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-between gap-4">
          <Icon icon={"noto:child"} className=""></Icon>
          <div className="">
            <p className="">Child</p>
          </div>
        </div>
      ),
      children: (
        <>
          <button className=""></button>
          {child.map((item, index) => {
            return (
              <div className="" key={index}>
                <div className=" grid grid-cols-3 pv:max-md:grid-cols-1 gap-4">
                  <div className="w-full py-8 rounded-b-md col-span-1">
                    <div className="relative">
                      <img
                        className="rounded-xl w-[100px] object-cover mx-auto aspect-square"
                        alt="avatar"
                        src={item.pictureUrl}
                      />

                      <div
                        className="w-[50px]  aspect-square absolute bottom-[5px] right-[5px] cursor-pointer pv:max-xl:relative pv:max-xl:mx-auto pv:max-xl:mt-[20px] border-[1px] border-black  hover:border-blue-500"
                        onClick={() => showModal(item.code)}
                      >
                        <QRCode
                          id="qrcode"
                          size={50}
                          bgColor="white"
                          fgColor="black"
                          value={item.code}
                        />
                      </div>
                    </div>

                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]  ">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_fullname")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.description}
                      </p>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_dob")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.dateOfBirth}
                      </p>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_gender")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.gender}
                      </p>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_level")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {levelData[`level${item?.level}`]?.name}
                      </p>
                    </div>

                    {/* <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_gender")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.gender}
                      </p>
                    </div> */}
                    <Divider />
                    <div className="">
                      <Button
                        className=" "
                        type="primary"
                        onClick={() => {
                          setDatachild(item);
                          showModalEditChildProfile();
                        }}
                      >
                        <p className="">Edit Infomation</p>
                      </Button>
                    </div>
                  </div>

                  <div className="flex col-span-2 w-full">
                    <Divider
                      type="vertical"
                      className="w-[1px] bg-black h-full pv:max-md:hidden"
                    ></Divider>
                    <div className="w-full grid grid-cols-1">
                      {item && item.enrollmentHistory ? (
                        item.enrollmentHistory.length > 0 ? (
                          item.enrollmentHistory.map((courseItem, index) => {
                            // Check if courseItem is undefined or empty
                            if (
                              !courseItem ||
                              Object.keys(courseItem).length === 0
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="h-[100px] flex items-center justify-center text-gray-500"
                                >
                                  No course details available.
                                </div>
                              );
                            }

                            return (
                              <div
                                key={index}
                                className="h-[100px] rounded-lg border-[1px] border-blue-200 hover:border-blue-400 cursor-pointer"
                                onClick={() => {
                                  showModalCourseDetail(courseItem);
                                }}
                              >
                                <div className="p-2 relative">
                                  <div className="flex justify-around">
                                    <p className="text-[16px] font-roboto_bold pv:max-sm:text-[8px] line-clamp-2 pv:max-xl:line-clamp-1">
                                      {courseItem.description}
                                    </p>
                                  </div>
                                  <div className="flex justify-around py-2 gap-2 pv:max-md:flex pv:max-sm:flex-col md:max-2xl:flex-col">
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_type")}
                                      </p>
                                      <p>{courseItem.type}</p>
                                    </div>
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_start")}
                                      </p>
                                      <p>{courseItem.startDate}</p>
                                    </div>
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_end")}
                                      </p>
                                      <p>{courseItem.endDate}</p>
                                    </div>
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_status")}
                                      </p>
                                      <p
                                        className={`font-bold ${
                                          courseItem.status === "Paid"
                                            ? "text-green-400"
                                            : "text-red-400"
                                        }`}
                                      >
                                        {courseItem.status}
                                      </p>
                                    </div>
                                  </div>
                                  {/* Uncomment and handle the Popconfirm section if needed */}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="col-span-2 text-center text-gray-500">
                            No enrollment history available.
                          </p>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <Divider
                  type="horizontal"
                  className="w-full bg-black"
                ></Divider>
              </div>
            );
          })}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex items-center justify-between gap-4">
          <Icon icon={"noto:adult"} className=""></Icon>
          <div className="">
            <p className="">Adult</p>
          </div>
        </div>
      ),
      children: (
        <>
          {adult?.map((item, index) => {
            return (
              <div className="" key={index}>
                <div className=" grid grid-cols-3 pv:max-md:grid-cols-1 gap-4">
                  <div className="w-full py-8 rounded-b-md col-span-1">
                    <div className="relative">
                      <img
                        className="rounded-xl w-[100px] object-cover mx-auto aspect-square"
                        alt="avatar"
                        src={item.pictureUrl}
                      />

                      <div
                        className="w-[50px]  aspect-square absolute bottom-[5px] right-[5px] cursor-pointer pv:max-xl:relative pv:max-xl:mx-auto pv:max-xl:mt-[20px] border-[1px] border-black  hover:border-blue-500"
                        onClick={() => showModal(item.code)}
                      >
                        <QRCode
                          id="qrcode"
                          size={50}
                          bgColor="white"
                          fgColor="black"
                          value={item.code}
                        />
                      </div>
                    </div>

                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]  ">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_fullname")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.description}
                      </p>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_dob")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.dateOfBirth}
                      </p>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_gender")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {item.gender}
                      </p>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-2 pv:max-md:flex pv:max-md:flex-col pv:max-ph:text-[16px]">
                      <p className="font-roboto_bold text-start">
                        {t("user_profile_family_member_level")}
                      </p>
                      <p className="md:max-lg:col-span-2 text-start">
                        {levelData[`level${item?.level}`]?.name}
                      </p>
                    </div>
                    <Divider />

                    <div className="">
                      <Button
                        className=" "
                        type="primary"
                        onClick={() => {
                          setDataadult(item);
                          showModalEditAdultProfile();
                        }}
                      >
                        <p className="">Edit Infomation</p>
                      </Button>
                    </div>
                  </div>

                  <div className="flex col-span-2 w-full">
                    <Divider
                      type="vertical"
                      className="w-[1px] bg-black h-full pv:max-md:hidden"
                    ></Divider>
                    <div className="w-full grid grid-cols-1">
                      {item && item.enrollmentHistory ? (
                        item.enrollmentHistory.length > 0 ? (
                          item.enrollmentHistory.map((courseItem, index) => {
                            // Check if courseItem is undefined or empty
                            if (
                              !courseItem ||
                              Object.keys(courseItem).length === 0
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="h-[100px] flex items-center justify-center text-gray-500"
                                >
                                  No course details available.
                                </div>
                              );
                            }

                            return (
                              <div
                                key={index}
                                className="h-[100px] rounded-lg border-[1px] border-blue-200 hover:border-blue-400 cursor-pointer"
                                onClick={() => {
                                  showModalCourseDetail(courseItem);
                                }}
                              >
                                <div className="p-2 relative">
                                  <div className="flex justify-around">
                                    <p className="text-[16px] font-roboto_bold pv:max-sm:text-[8px] line-clamp-2 pv:max-xl:line-clamp-1">
                                      {courseItem.description}
                                    </p>
                                  </div>
                                  <div className="flex justify-around py-2 gap-2 pv:max-md:flex pv:max-sm:flex-col md:max-2xl:flex-col">
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_type")}
                                      </p>
                                      <p>{courseItem.type}</p>
                                    </div>
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_start")}
                                      </p>
                                      <p>{courseItem.startDate}</p>
                                    </div>
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_end")}
                                      </p>
                                      <p>{courseItem.endDate}</p>
                                    </div>
                                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                                      <p className="font-roboto_bold">
                                        {t("user_profile_status")}
                                      </p>
                                      <p
                                        className={`font-bold ${
                                          courseItem.status === "Paid"
                                            ? "text-green-400"
                                            : "text-red-400"
                                        }`}
                                      >
                                        {courseItem.status}
                                      </p>
                                    </div>
                                  </div>
                                  {/* Uncomment and handle the Popconfirm section if needed */}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="col-span-2 text-center text-gray-500">
                            No enrollment history available.
                          </p>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <Divider
                  type="horizontal"
                  className="w-full bg-black"
                ></Divider>
              </div>
            );
          })}
        </>
      ),
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-between gap-4">
          <Icon icon={"material-symbols:person-outline"} className=""></Icon>
          <div className="">
            <p className="">{t("user_profile_info")}</p>
          </div>
        </div>
      ),
      children: (
        <div className="w-[95%] mx-auto py-8 rounded-b-md">
          <div className="">
            <p className="text-xl font-roboto_bold">
              {t("user_profile_info_title")}
            </p>
          </div>
          <div className="">
            <p className="text-lg text-gray-600">
              {t("user_profile_info_title_bottom")}
            </p>
          </div>
          <div className="flex gap-2">
            <Button
              className=" "
              type="primary"
              onClick={() => {
                showModalChangePassword();
              }}
            >
              <p className="">Change Password</p>
            </Button>
            <Button
              className=" "
              type="primary"
              onClick={() => {
                showModalEditUserProfile();
              }}
            >
              <p className="">Edit Infomation</p>
            </Button>
          </div>

          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col ">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_full_name")}
            </p>
            <p className="sm:max-lg:col-span-2">{user?.description}</p>
          </div>
          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_gender")}
            </p>
            <p className="sm:max-lg:col-span-2">{user?.gender}</p>
          </div>
          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_email")}
            </p>
            <p className="sm:max-lg:col-span-2">{user?.email}</p>
          </div>
          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_phone")}
            </p>
            <p className="sm:max-lg:col-span-2">{user?.code}</p>
          </div>
          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col ">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_birth")}
            </p>
            <p className="sm:max-lg:col-span-2">{user?.dateOfBirth}</p>
          </div>
          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_address")}
            </p>
            <p className="line-clamp-2 sm:max-lg:col-span-2">{user?.address}</p>
          </div>
          <Divider />

          <div className="grid grid-cols-2 text-lg text-gray-600 md:max-lg:grid-cols-3 pv:max-md:flex pv:max-md:flex-col">
            <p className="font-roboto_bold">
              {t("user_profile_info_basic_level")}
            </p>
            <p className="line-clamp-2 sm:max-lg:col-span-2">
              {levelData[`level${user?.level}`]?.name}
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex items-center justify-between gap-4">
          <Icon icon={"carbon:purchase"} className=""></Icon>
          <div className="">
            <p className="">{t("user_profile_mypurchase")}</p>
          </div>
        </div>
      ),
      children: (
        <div className=" grid  pv:max-md:grid-cols-2 md:max-lg:grid-cols-2 lg:max-xl:grid-cols-2 2xl:grid-cols-2">
          {enrollmentMain?.map((item, index) => {
            return (
              <div
                key={index}
                className=" border-[1px] border-gray-200 rounded-xl m-4 pv:max-lg:m-1 cursor-pointer hover:border-blue-300"
                onClick={() => {
                  showModalCourseDetail(item);
                }}
              >
                <div className="p-2 relative">
                  <div className="flex justify-around">
                    <p className="text-[16px] font-roboto_bold pv:max-sm:text-[8px] line-clamp-2 pv:max-xl:line-clamp-1">
                      {item?.name}
                    </p>
                  </div>
                  <div className="flex justify-around py-2 gap-2 pv:max-md:flex pv:max-sm:flex-col md:max-2xl:flex-col ">
                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                      <p className="font-roboto_bold">
                        {t("user_profile_type")}
                      </p>
                      <p className="">{item?.type}</p>
                    </div>
                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                      <p className="font-roboto_bold">
                        {t("user_profile_start")}
                      </p>
                      <p className="">{item?.startDate}</p>
                    </div>
                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                      <p className="font-roboto_bold">
                        {t("user_profile_end")}
                      </p>
                      <p className="">{item?.endDate}</p>
                    </div>
                    <div className="flex items-center gap-1 pv:max-sm:text-[8px]">
                      <p className="font-roboto_bold">
                        {t("user_profile_status")}
                      </p>
                      <p
                        className={`font-bold ${
                          item?.status == "Paid"
                            ? "text-green-400"
                            : "text-red-400"
                        }`}
                      >
                        {item?.status}
                      </p>
                    </div>
                  </div>

                  {item?.status == "Paid" ? null : (
                    <Popconfirm
                      title={"Payment"}
                      description={
                        item?.paymentMethod == "Cash"
                          ? "Please contact admin for payment"
                          : "Are you sure to complete payment for this course ?"
                      }
                      okButtonProps={{
                        disabled:
                          item?.paymentMethod == "VN Pay" ? false : true,
                      }}
                      onConfirm={async () => {
                        if (item?.paymentMethod == "VN Pay") {
                          if (item?.checkoutUrl != "") {
                            if (item?.expiredAt != "") {
                              if (new Date() < new Date(item?.expiredAt)) {
                                window.location = item?.checkoutUrl;
                              } else {
                                await apiRePayment(item?.historyId)
                                  .then((res) => {
                                    if (res.status == 200) {
                                      window.location =
                                        res.data.data.checkoutUrl;
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err.message);
                                    notification.info({
                                      message:
                                        "Payment expired, please contact Admin",

                                      placement: "topRight",
                                      duration: 2,
                                    });
                                  });
                              }
                            }
                          }
                        }
                      }}
                      okText={
                        <p className="text-black">
                          {t("pu_course_detail_me_yes")}
                        </p>
                      }
                      cancelText={t("pu_course_detail_me_no")}
                    >
                      <div className="w-full h-full bg-gradient-to-t from-slate-800 absolute top-0 left-0"></div>
                    </Popconfirm>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex items-center justify-between gap-4">
          <Icon
            className=""
            icon={"material-symbols-light:family-link-sharp"}
          ></Icon>
          <p className="">{t("user_profile_family_member")}</p>
        </div>
      ),
      children: (
        <div className="flex flex-col items-end">
          <div className="flex gap-2">
            <div className="flex justify-center gap-2 pr-4">
              <div className="flex items-center justify-center hover:underline">
                <button
                  type="button"
                  className=" rounded-full flex justify-center items-center"
                  onClick={() => showModalChild()}
                >
                  <p className="font-bold">Add Child </p>
                </button>
              </div>
            </div>
            <div className="flex justify-center gap-2 pr-4">
              <div className="flex items-center justify-center hover:underline">
                <button
                  type="button"
                  className=" rounded-full flex justify-center items-center"
                  onClick={() => showModalAdult()}
                >
                  <p className="font-bold">Add Adult </p>
                </button>
              </div>
            </div>
          </div>
          {/* <Tabs
            defaultActiveKey="1"
            tabPosition={"top"}
            items={familyMembers(child, showModal)}
            className="bg-white px-2 w-full"
          /> */}

          <Tabs
            defaultActiveKey="1"
            tabPosition={"top"}
            items={itemsfamily}
            className="bg-white px-2 w-full"
          />
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="flex items-center justify-between gap-4">
          <Icon className="" icon={"mdi:cart-outline"}></Icon>
          <p className="">{t("my_cart")}</p>
        </div>
      ),
      children: (
        <div className="">
          <Table
            dataSource={cartInfo}
            columns={[
              { title: "Course", dataIndex: "description" },
              { title: "Start Date", dataIndex: "startDate" },
              { title: "End Date", dataIndex: "endDate" },
              { title: "Member", dataIndex: "student" },
              {
                title: "Action",
                dataIndex: "action",
                align: "center",
                render: (e, row) => {
                  return (
                    <div className="w-full h-full flex justify-around">
                      <Popconfirm
                        title={"Remove item"}
                        description={"Are you sure remove this course ?"}
                        onConfirm={async () => {
                          let updatedEnrollmentType = row.enrollmentType;
                          if (updatedEnrollmentType === "ADT") {
                            updatedEnrollmentType = "Adult";
                          } else if (updatedEnrollmentType === "SOF") {
                            updatedEnrollmentType = "SOF";
                          }
                          await apiPostCart({
                            userId: row.studentId,
                            userType: row.studentType,
                            enrollmentType: updatedEnrollmentType,
                            enrollmentCode: row.enrollmentId,
                            action: "remove",
                          })
                            .then((res) => {
                              if (res.data.status == 200) {
                                // apiGetCart({ userId: user.code })
                                //   .then((res) => {
                                //     if (res.status === 200) {
                                //       setCartInfo(res.data.data);
                                //     }
                                //   })
                                //   .catch((err) => console.error(err.message));
                                sessionStorage.setItem(
                                  "CartInfo",
                                  JSON.stringify(res.data.data)
                                );
                                notification.success({
                                  message: "Remove Cart successfully !",
                                  placement: "topRight",
                                  duration: 2,
                                });
                                setCartInfo(res.data.data);
                              }
                            })
                            .catch((err) => console.error(err.message));
                        }}
                        okText={
                          <p className="text-black">
                            {t("pu_course_detail_me_yes")}
                          </p>
                        }
                        cancelText={t("pu_course_detail_me_no")}
                      >
                        <div className="text-sky-300 cursor-pointer hover:underline ">
                          Remove
                        </div>
                      </Popconfirm>
                    </div>
                  );
                },
              },
            ]}
            className="rounded-b-3xl w-[95%] mx-auto p-0 overflow-hidden  "
            scroll={{
              x: "max-content",
            }}
          />
        </div>
      ),
    },
  ];
  const months = [
    { MonthNum: "01", Description: "January", ShortDescription: "Jan" },
    { MonthNum: "02", Description: "February", ShortDescription: "Feb" },
    { MonthNum: "03", Description: "March", ShortDescription: "Mar" },
    { MonthNum: "04", Description: "April", ShortDescription: "Apr" },
    { MonthNum: "05", Description: "May", ShortDescription: "May" },
    { MonthNum: "06", Description: "June", ShortDescription: "Jun" },
    { MonthNum: "07", Description: "July", ShortDescription: "Jul" },
    { MonthNum: "08", Description: "August", ShortDescription: "Aug" },
    { MonthNum: "09", Description: "September", ShortDescription: "Sep" },
    { MonthNum: "10", Description: "October", ShortDescription: "Oct" },
    { MonthNum: "11", Description: "November", ShortDescription: "Nov" },
    { MonthNum: "12", Description: "December", ShortDescription: "Dec" },
  ];

  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const downloadPDF = (e) => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const useCord = true;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width + 20;
      const imgHeight = canvas.height + 20;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`${e.owner.description}.pdf`);
    });

    setOpen(false);
  };

  // initial data
  useEffect(() => {
    const fetchuserMember = async () => {
      const response = await apiusermember({
        mainUserCode: user.code,
      });

      if (response?.status === 201 || response?.status === 200) {
        // setChild(response?.data.data.childMembers);
      }
    };
    fetchuserMember();

    apiGetUserEnrollment()
      .then((response) => {
        if (response.status == 200) {
          // setUserEnrollment(response.data.data.mainTicket)
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [openChild, openadult, openModalChangePassword]);

  return (
    <>
      <div className=" py-4 w-[90%] mx-auto pt-[200px] md:max-lg:pt-[25px] lg:max-xl:pt-[30px] xl:max-2xl:pt-[60px] 2xl:pt-[40px] pv:max-md:pt-4 ">
        <div className="  bg-white rounded-t-xl shadow-md flex   justify-between pv:max-ph:justify-center">
          <div className="flex gap-2 items-center pv:max-ph:p-2 ph:max-dh:p-4 pv:max-ph:flex-col pv:max-ph:items-center ">
            <div className="h-20 w-20 pv:max-ph:h-14 pv:max-ph:w-14 relative">
              <img
                className=" h-20 w-20 rounded-full object-cover pv:max-ph:h-14 pv:max-ph:w-14 "
                src={user?.pictureUrl}
              ></img>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <div className="flex gap-2 items-center">
                          <Icon className="w-6 h-6" icon={"mdi:camera"}></Icon>
                          <p className="">{t("user_profile_change_avatar")}</p>
                        </div>
                      ),
                      key: "1",
                    },
                  ],
                }}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <button className=" absolute bottom-0 right-2 pv:max-ph:left-10 pv:max-ph:top-0">
                  <Icon
                    icon={"fluent:camera-edit-20-filled"}
                    className=""
                  ></Icon>
                </button>
              </Dropdown>
            </div>

            <div className="flex flex-col items-start">
              <p className="pv:max-ph:text-center">{user?.description}</p>
              <p className="text-gray-400 pv:max-ph:text-center">
                {user?.email}
              </p>
            </div>

            <div
              className="cursor-pointer ml-[10px] border-[1px] border-black p-2 hover:border-blue-500"
              id="qr"
              onClick={() =>
                showModal({
                  owner: {
                    code: user?.uuid,
                    description: user?.description,
                  },
                  qrcontent: user?.uuid,
                })
              }
            >
              <QRCode
                id="qrcode"
                size={50}
                bgColor="white"
                fgColor="black"
                value={user?.uuid}
              />
            </div>
          </div>
        </div>

        <Tabs
          defaultActiveKey={valueTab}
          activeKey={valueTab}
          onChange={(key) => setValueTab(key)}
          tabPosition={tabPosition}
          items={items}
          className="bg-white px-2"
        />

        {open ? (
          <Modal
            open={open}
            title={isMobile ? "" : "Export"}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              isMobile ? (
                <></>
              ) : (
                <div className="flex justify-center gap-4">
                  <button
                    className="bg-slate-600 hover:bg-secondary1 px-4 py-2 rounded-lg"
                    type="primary"
                    onClick={() => {
                      downloadPDF(selectedQRCode);
                    }}
                  >
                    <p className="text-white text-xl">PDF</p>
                  </button>

                  <button
                    className="bg-slate-600 hover:bg-secondary1 px-4 py-2 rounded-lg"
                    type="primary"
                    onClick={() => exportComponentAsPNG(pdfRef, "wallet.png")}
                  >
                    <p className="text-white text-xl">PNG</p>
                  </button>
                </div>
              )
            }
            className={`modalQR ${
              isMobile ? "flex justify-center w-[90%]" : "mr-96"
            }`}
            centered
          >
            {isMobile ? (
              <div className="h-full flex items-center">
                <QRCode
                  id="qrcode"
                  size={200}
                  bgColor="white"
                  fgColor="black"
                  value={selectedQRCode.qrcontent}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-10 ">
                <div
                  ref={pdfRef}
                  className={`w-full h-full bg-white p-4 rounded-xl min-w-fit flex justify-center`}
                >
                  <QRCode
                    id="qrcode"
                    size={200}
                    bgColor="white"
                    fgColor="black"
                    value={selectedQRCode.qrcontent}
                  />
                </div>
                <p className="text-xl">Export QR Code by PNG OR PDF</p>
              </div>
            )}
          </Modal>
        ) : null}

        {/* Modal add child */}

        {openChild ? (
          <ModalAddChild
            openChild={openChild}
            handleCancel={handleCancelAddChild}
            months={months}
            isAddFromProfile={true}
            mainUserCode={user.code}
          />
        ) : null}
        {openadult ? (
          <ModalAddAdult
            openadult={openadult}
            handleCancel={handleCancelAddAdult}
            months={months}
            isAddFromProfile={true}
            mainUserCode={user.code}
          />
        ) : null}

        {openModalCourseDetail ? (
          <ModalCouseDetail
            open={openModalCourseDetail}
            onOk={handleCourseOk}
            onCancel={handleCourseCancel}
            data={dataCourseDetail}
          />
        ) : null}

        {openModalChangePassword ? (
          <ModalChangePassword
            open={openModalChangePassword}
            onCancel={handleChangePasswordCancel}
            onOk={handleChangePasswordOk}
            userId={user?.code}
            // open={openModalCourseDetail}
            // onOk={handleCourseOk}
            // onCancel={handleCourseCancel}
            // data={dataCourseDetail}
          />
        ) : null}

        {openModalEditUserProfile ? (
          <ModalEditUserProfile
            open={openModalEditUserProfile}
            onCancel={handleEditUserProfileCancel}
            onOk={handleEditUserProfileOk}
            userData={user}
            months={months}
            userType="main"
          />
        ) : null}

        {openModalEditChildProfile ? (
          <ModalEditChildProfile
            open={openModalEditChildProfile}
            onCancel={handleEditChildProfileCancel}
            onOk={handleEditChildProfileOk}
            userData={datachild}
            months={months}
            userType="sub-child"
          />
        ) : null}

        {openModalEditAdultProfile ? (
          <ModalEditAdultProfile
            open={openModalEditAdultProfile}
            onCancel={handleEditAdultProfileCancel}
            onOk={handleEditAdultProfileOk}
            userData={dataadult}
            months={months}
            userType="sub-adult"
          />
        ) : null}
      </div>
    </>
  );
};

export default withTranslation()(UserProfile);
