/** @format */

import React, { useState } from "react";

import { Tabs } from "antd";
import { Icon } from "@iconify/react";
import { SidebarAdmin, HeaderAdmin } from "../../components";

import { Table } from "antd";
import { withTranslation } from "react-i18next";
import { apiGetLectureByCode } from "../../services/lecture";

import { listNote } from "../../utils/data";
// import { NoteTeacher } from "../../components/commocomponents";
import { useEffect } from "react";
import { encryptString, path } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
const InstructorProfile = ({ t }) => {
  const navigate = useNavigate();
  const lecturer = JSON.parse(sessionStorage.getItem("UserInformation"));
  const lecturerEnrollments = JSON.parse(
    sessionStorage.getItem("LectureEnrollment")
  );
  const [courseData, setCourseData] = useState([
    {
      courseId: "00001",
      courseName: "Fast track 1",
      level: "seahorse",
      duration: "12 weeks",
      startdate: "12/12/2023",
      enddate: "12/2/2024",
    },
    {
      courseId: "00002",
      courseName: "Fast track 2",
      level: "seahorse",
      duration: "12 weeks",
      startdate: "12/12/2023",
      enddate: "12/2/2024",
    },
    {
      courseId: "00003",
      courseName: "Fast track 3",
      level: "seahorse",
      duration: "12 weeks",
      startdate: "12/12/2023",
      enddate: "12/2/2024",
    },
  ]);
  const [lecture, setLecture] = useState({
    Code: "",
    Description: "",
    PictureURL: "",
    FirstName: "",
    LastName: "",
    DateOfBirth: "",
    Age: 0,
    Address: "",
    Email: "",
    Position: "",
    Detail: "",
    Rating: 0,
    ReviewList: [
      {
        Lecture: "Nguyen Ngoc Yen",
        Reviewer: "Tay Nguyen",
        Star: 5,
        Content: "Giao vien day qua co tam",
      },
    ],
  });
  const [resize, setResize] = useState(false);
  const handleclick = (value) => {
    setResize(value);
  };
  const handleNavigate = (courseCode) => {
    navigate("../" + path.INSTRUCTORCOURSEDETAIL);
  };
  //   useEffect(() => {
  //     const fetchLecture = async () => {
  //       const response = await apiGetLectureByCode(lecturer?.Code);
  //       if (response?.status === 200) {
  //         setLecture(response.data.LectureInformation.Information);
  //       }
  //     };
  //     fetchLecture();
  //   }, []);

  //   // define data Course
  //   useEffect(() => {
  //     const tempArray = [];
  //     for (let index = 0; index < lecturerEnrollments.length; index++) {
  //       const element = lecturerEnrollments[index];

  //       tempArray.push({
  //         key: index,

  //         courseName: <p className="">{element?.Lesson?.Description}</p>,
  //         level: <p className="">{element?.Lesson?.Level}</p>,
  //         duration: <p className="">{element?.Duration} Day</p>,
  //         startdate: <p className="">{element?.StartDate}</p>,
  //         enddate: <p className="">{element?.EndDate}</p>,
  //       });
  //       setCourseData(tempArray);
  //     }
  //   }, []);
  //   // define data Review
  //   useEffect(() => {
  //     const tempArray = [];
  //     for (let index = 0; index < lecturerEnrollments.length; index++) {
  //       const element = lecturerEnrollments[index];

  //       tempArray.push({
  //         key: index,
  //         courseName: <p className="">{element?.Lesson?.Description}</p>,
  //         courseId: element?.Number,
  //         level: <p className="">{element?.Lesson?.Level}</p>,
  //         duration: <p className="">{element?.Duration} Day</p>,
  //         startdate: <p className="">{element?.StartDate}</p>,
  //         enddate: <p className="">{element?.EndDate}</p>,
  //       });
  //       setCourseData(tempArray);
  //     }
  //   }, []);

  const columnCourse = [
    {
      title: t("in_profile_person_course_id"),
      dataIndex: "courseId",
      key: "courseId",
    },
    {
      title: t("in_profile_person_course_name"),
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      key: "startdate",
    },
    {
      title: "End Date",
      dataIndex: "enddate",
      key: "enddate",
    },
  ];
  const columnReview = [
    {
      title: t("teach_profile_review_student"),
      dataIndex: "Reviewer",
      key: "Reviewer",
    },
    {
      title: t("teach_profile_review_lecturer"),
      dataIndex: "Lecture",
      key: "Lecture",
    },
    {
      title: t("teach_profile_review_rating"),
      dataIndex: "Star",
      key: "Star",
      align: "center",
      render: (Star) => {
        return (
          <div className="flex justify-center items-center gap-2">
            {Array.from(Array(Star), (e, i) => {
              return (
                <Icon
                  icon="gridicons:star"
                  width={24}
                  height={24}
                  className="text-yellow-500"
                />
              );
            })}
            {Array.from(Array(5 - Star), (e, i) => {
              return (
                <Icon
                  icon="gridicons:star-outline"
                  width={24}
                  height={24}
                  className="text-yellow-500"
                />
              );
            })}
          </div>
        );
      },
    },
    {
      title: t("teach_profile_review_review"),
      dataIndex: "Content",
      key: "Content",
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <div className=" flex items-center gap-2">
          <Icon className="w-6 h-6" icon={"gg:profile"}></Icon>
          <p className="">{t("in_profile_person_information")}</p>
        </div>
      ),
      children: (
        <div className="h-auto w-[90%] mx-auto grid-cols-4 grid bg-white  rounded-lg lg:max-xl:grid-cols-5 md:max-lg:grid-cols-5 sm:max-md:grid-cols-5  ph:max-sm:grid-cols-5 pvmax:max-ph:grid-cols-5 pv:max-pvmax:grid-cols-5  ">
          <div className="col-span-1 flex items-center justify-center p-4 lg:max-xl:col-span-2 md:max-lg:col-span-5 md:max-lg:justify-center sm:max-md:col-span-5  sm:max-md:justify-center ph:max-sm:col-span-5 pvmax:max-ph:col-span-5 pv:max-pvmax:col-span-5">
            <img
              className="object-cover rounded-xl w-[200px] h-[200px]"
              src={
                "https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
              }
              alt="image"
            ></img>
          </div>
          <div className="col-span-3 h-auto p-4  xl:max-dv:text-sm lg:max-xl:text-black md:max-lg:col-span-5 md:max-lg:flex  md:max-lg:flex-col md:max-lg:text-[16px]   sm:max-md:text-[16px] ph:max-sm:text-[16px]  sm:max-md:flex-col sm:max-md:flex sm:max-md:col-span-5 ph:max-sm:col-span-5 pvmax:max-ph:col-span-5 pv:max-pvmax:col-span-5">
            <div className="flex gap-6 lg:max-xl:flex-col  md:max-lg:flex-col sm:max-md:flex-col ph:max-sm:flex-col pvmax:max-ph:flex-col  pv:max-pvmax:hidden">
              <div className="flex gap-2  ">
                <div className=" flex flex-col gap-5">
                  <p className="font-bold">{t("in_profile_person_gender")}</p>
                  <p className="font-bold">
                    {t("in_profile_person_first_name")}
                  </p>
                  <p className="font-bold">{t("in_profile_person_phone")}</p>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="">
                    {lecturer.Gender ? lecturer.Gender : "Male"}
                  </p>
                  <p className="">
                    {lecturer?.FirstName ? lecturer?.FirstName : "An"}
                  </p>
                  <p className="">
                    {lecturer.Code ? lecturer.Code : "0375875162"}
                  </p>
                </div>
              </div>
              <div className="flex gap-2">
                <div className=" flex flex-col gap-5">
                  <p className="font-bold">
                    {t("in_profile_person_full_name")}
                  </p>
                  <p className="font-bold">
                    {t("in_profile_person_last_name")}
                  </p>
                  <p className="font-bold">
                    {t("in_profile_person_email_address")}
                  </p>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="">
                    {lecturer.Description
                      ? lecturer.Description
                      : "Pham Thanh An"}
                  </p>
                  <p className="">
                    {lecturer?.LastName ? lecturer?.LastName : "Pham Thanh"}
                  </p>
                  <p className="">
                    {lecturer.Email ? lecturer.Email : "info@gmail.com"}
                  </p>
                </div>
              </div>
            </div>

            <div className="pv:max-pvmax:flex pv:max-pvmax:flex-col pv:max-pvmax:gap-2 hidden">
              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">{t("in_profile_person_gender")}</p>
                </div>
                <div className="flex flex-col ">
                  <p className="">
                    {lecturer.Gender ? lecturer.Gender : "Male"}
                  </p>
                </div>
              </div>

              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">
                    {t("in_profile_person_first_name")}
                  </p>
                </div>
                <div className="flex flex-col ">
                  <p className="">
                    {lecturer?.FirstName ? lecturer?.FirstName : "An"}
                  </p>
                </div>
              </div>

              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">
                    {t("in_profile_person_last_name")}
                  </p>
                </div>
                <div className="flex flex-col ">
                  <p className="">{lecturer?.LastName}</p>
                </div>
              </div>

              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">{t("in_profile_person_phone")}</p>
                </div>
                <div className="flex flex-col ">
                  <p className="">{lecturer?.Code}</p>
                </div>
              </div>

              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">
                    {t("in_profile_person_full_name")}
                  </p>
                </div>
                <div className="flex flex-col ">
                  <p className="">{lecturer?.Description}</p>
                </div>
              </div>
              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">
                    {t("in_profile_person_email_address")}
                  </p>
                </div>
                <div className="flex flex-col ">
                  <p className="">
                    {lecturer.Gender ? lecturer.Gender : "Male"}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-2 py-8">
              <p className="font-bold">{t("in_profile_person_addtion_info")}</p>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex gap-6 lg:max-xl:gap-2  pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">
                    {t("in_profile_person_date_of_birth")}
                  </p>
                </div>
                <div className="flex flex-col ">
                  <p className="">
                    {/* {lecturer.DateOfBirth.replaceAll(".", "-")} */}
                    20/12/1990
                  </p>
                </div>
              </div>

              <div className="flex gap-12 lg:max-xl:gap-2 pv:max-lg:flex-col pv:max-lg:gap-0">
                <div className=" flex flex-col ">
                  <p className="font-bold">{t("in_profile_person_address")}</p>
                </div>
                <div className="flex flex-col ">
                  <p className="">
                    {lecturer?.Address
                      ? lecturer?.Address
                      : "202 Ly Chinh Thang , Ward 9 , District 3 , Ho Chi Minh City"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div className=" flex items-center gap-2">
    //       <Icon className="w-6 h-6" icon={"ph:book-bold"}></Icon>
    //       <p className="">{t("in_profile_person_course")}</p>
    //     </div>
    //   ),
    //   children: (
    //     <Table
    //       sortDirections={true}
    //       columns={columnCourse}
    //       dataSource={courseData}
    //       pagination={{ pageSize: 5, position: ["bottomCenter"] }}
    //       style={{
    //         width: "90%",
    //         margin: "auto",
    //         padding: "0",
    //         overflow: "hidden",
    //       }}
    //       className="cursor-pointer"
    //       scroll={{
    //         x: "max-content",
    //       }}
    //       onRow={(e) => ({
    //         onClick: () => {
    //           handleNavigate(e.courseId);
    //         },
    //       })}
    //     />
    //   ),
    // },
    {
      key: "3",
      label: (
        <div className=" flex items-center gap-2">
          <Icon className="w-6 h-6" icon={"bx:like"}></Icon>
          <p className="">{t("in_profile_person_review")}</p>
        </div>
      ),
      children: (
        <Table
          sortDirections={true}
          columns={columnReview}
          dataSource={lecture?.ReviewList}
          pagination={{ pageSize: 5, position: ["bottomCenter"] }}
          style={{
            width: "90%",
            margin: "auto",
            padding: "0",
            overflow: "hidden",
          }}
          scroll={{
            x: "max-content",
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="w-[100%] pv:max-pvmax:w-[80%] mx-2  pt-[100px]">
        <div className="w-full ">
          <HeaderAdmin />
        </div>

        <div className={`w-full grid grid-cols-4`}>
          <div className={`col-span-1`}>
            <SidebarAdmin handleclick={handleclick} />
          </div>
          <div className="col-span-3 my-10 mx-2">
            <Tabs
              defaultActiveKey="1"
              items={items}
              size="middle"
              type="line"
              className="py-2"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(InstructorProfile);
