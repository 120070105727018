import { apiGet, apiPost } from "./";
import axiosConfig from "../axiosConfig";
import { formatDateToYYYYMMDD } from "../utils/constant";
export const apiThirdStage = async (type = "SOF") => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const response = await apiGet({
    endpoint: "/v1/user-third-stage",
    params: {
      section: type,
      uuid: user ? user.uuid : "",
    },
  });

  return response;
};

// export const apiClubs = async (data) => {
//   const response = await apiPost({
//     endpoint: "/v1/user-contact-club",
//     payload: {
//       data,
//     },
//   });

//   return response;
// };

export const apiClubs = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/v1/user-contact-club",
    data: {
      ...payload,
    },
  });

  return response;
};
