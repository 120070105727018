import React, { useState } from "react";
import { Modal, Button, notification } from "antd";
import {
  apisecondstage,
  apiGetQuizz,
  apiPostQuiz,
} from "../../services/secondstage";
const ExperiencePage = () => {
  const [select, setSelect] = useState("");

  const handleSelect = (e) => {
    setSelect(e);
  };

  return (
    <div className="m-auto transition animate-fade ">
      <div className=" text-center p-5">
        <h1 className="text-4xl font-semibold">Which describes your best?</h1>
        <p className="p-3">This will help us personalize your experience.</p>
      </div>
      <div className="max-md:m-3">
        <div
          className={`mt-1 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Student" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Student")}
        >
          <img alt="student" />
          <p className=" pl-4 text-sm pt-3">
            <span className="font-semibold">Student</span> or soon to be
            enrolled
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Professional" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Professional")}
        >
          <img alt="professional" />
          <p className="pl-4 text-sm pt-3">
            <span>Professional</span> pursuing a career
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Parent" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Parent")}
        >
          <img alt="parent" />
          <p className="pl-4 text-sm pt-3">
            <span>Parent</span> of a school-age child
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Lifelong Learner" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Lifelong Learner")}
        >
          <img alt="lifelonglearner" />
          <p className="pl-4 text-sm pt-3">
            <span>lifelong learner</span>
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Teacher" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Teacher")}
        >
          <img alt="teacher" />
          <p className="pl-4 text-sm pt-3">
            <span>Teacher</span>
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Other" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Other")}
        >
          <img alt="other" />
          <p className="pl-4 text-sm pt-3">
            <span>Other</span>
          </p>
        </div>
      </div>
    </div>
  );
};
const InterestPage = () => {
  const [select, setSelect] = useState("");

  const handleSelect = (e) => {
    setSelect(e);
  };
  return (
    <div className="m-auto transition animate-fade mb-7">
      <div className=" text-center p-7">
        <h1 className="text-4xl font-semibold">
          Which are you most interested in?
        </h1>
        <p className="p-5 ">
          Choose just one. This will help us get started (but won't limit your
          experience).
        </p>
      </div>
      <div className="max-md:m-3">
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Learning" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Learning")}
        >
          <img alt="professional" className="w-7 h-7 mt-1" />
          <p className=" pl-4 text-sm pt-2">
            Learning specific skills to advance my career
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Exploring" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Exploring")}
        >
          <img alt="professional" className="w-7 h-7 mt-1" />
          <p className="pl-4 text-sm pt-2">
            Exploring new topic I'm interested in
          </p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Refressing" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Refressing")}
        >
          <img alt="parent" className="w-7 h-7 mt-1" />
          <p className="pl-4 text-sm pt-2">Refressing my math foundations</p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "Exercising" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("Exercising")}
        >
          <img alt="lifelonglearner" className="w-7 h-7 mt-1" />
          <p className="pl-4 text-sm pt-2">Exercising my brain to stay sharp</p>
        </div>
        <div
          className={`mt-2 flex h-14 border-[1.5px] border-s text-lg border-gray-200 p-2  pl-4 cursor-pointer rounded-lg transition hover:ease-in-out hover:duration-300 hover:scale-105 ${
            select === "something else" ? "border-yellow-600 shadow-lg " : ""
          }`}
          onClick={() => handleSelect("something else")}
        >
          <img alt="teacher" className="w-7 h-7 mt-1" />
          <p className="pl-4 text-sm pt-2">something else</p>
        </div>
      </div>
    </div>
  );
};

const StepsForm = () => {
  const forms = [<ExperiencePage />, <InterestPage />];

  const [page, setPage] = useState(0);

  const handlePages = () => {
    setPage((page) => page + 1);
  };

  const handlePagesBack = () => {
    setPage((page) => page - 1);
  };

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const [dataQuiz, setDataQuiz] = useState({
    code: "",
    description: "",
    type: "",
    quizSet: [
      {
        question: "",
        options: [],
      },
    ],
  });
  const [quizResult, setQuizResult] = useState({
    answers: [],
    name: "",
    color: "",
    detail: "",
    pictureUrl: "",
    targetId: "1",
  });
  const handleCompleteQuiz = async (userAnswers) => {
    let answers = [];
    for (let i = 0; i < userAnswers.length; i++) {
      const element = userAnswers[i];
      if (element === "Yes") {
        answers.push(1);
      } else if (element === "No") {
        answers.push(0);
      } else if (i === userAnswers.length - 1) {
        if (element) {
          answers.push(Number(element));
        } else {
          answers.push(0);
        }
      } else {
        answers.push(element);
      }
    }

    await apiPostQuiz({
      answers: answers,
      quizName: dataQuiz?.description,
    })
      .then((res) => {
        if (res.status === 200) {
          setQuizResult({
            ...quizResult,
            answers: answers,
            name: res.data.data.name,
            color: res.data.data.color,
            pictureUrl: res.data.data.pictureUrl,
            detail: res.data.data.detail,
            targetId: res.data.data.index,
          });
          setIsModalOpen(true);
          setModalVisible(false);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error evaluating user",

          placement: "topRight",
          duration: 2,
        });
      });
  };
  const [modalVisible, setModalVisible] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    // if (user) {
    setModalVisible(true);
    // } else {
    //   notification.info({
    //     message: "Please log in to continue",
    //     placement: "topRight",
    //     duration: 2,
    //   });
    //   navigate("../" + path.LOGIN);
    // }
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  return (
    <div className="pt-[100px]">
      <Button type="primary" onClick={showModal}>
        Open Modal with customized footer
      </Button>
      <Modal
        open={open}
        title="Quiz"
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handlePagesBack}>
            Previous
          </Button>,
          <Button type="primary" onClick={handlePages}>
            Next
          </Button>,
        ]}
      >
        <div className="w-full mt-16">
          <div className="relative w-4/6 m-auto p-5">
            <div
              className={`text-xl absolute -left-1 top-3 cursor-pointer ${
                page === 0 ? "hidden" : "visible"
              }`}
              onClick={handlePagesBack}
            />
            <div className="m-auto bg-[#E5E5E5] w-full">
              <div
                className={`${
                  page === 0 ? "w-1/2" : page === 1 ? "w-2/2" : page === 2
                } h-1 bg-green-600`}
              ></div>
            </div>
          </div>
          <div className="flex align-middle justify-center flex-col">
            {forms[page]}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StepsForm;
