import { withTranslation } from "react-i18next";

const SwimCourse = ({ t }) => {
  return (
    // <div className="p-6 flex flex-col gap-5">
    //   <div className="flex flex-col gap-5">
    //     <h2 className="font-bold text-4xl uppercase">{t("info_sec_policy")}</h2>
    //     <p>
    //       {t("info_sec_policy_section1_description")}{" "}
    //       <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>.
    //     </p>
    //     <p>{t("info_sec_policy_section1_detail")}</p>
    //   </div>
    //   <div className="flex flex-col gap-5">
    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item1_title")}
    //       </h2>
    //       <p>
    //         {t("info_sec_policy_section2_item1_detail1")}
    //         <a href="https://aquaticshub.vn/"> https://aquaticshub.vn/ </a>
    //         {t("info_sec_policy_section2_item1_detail2")}
    //       </p>
    //       <ul className="list-disc pl-5">
    //         <li>{t("info_sec_policy_section2_item1_list1")}</li>
    //         <li>{t("info_sec_policy_section2_item1_list2")}</li>
    //         <li>{t("info_sec_policy_section2_item1_list3")}</li>
    //       </ul>

    //       <p className="my-6">
    //         Website:
    //         <a href="https://aquaticshub.vn/"> https://aquaticshub.vn/</a>
    //       </p>
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item1_detail3")}
    //       </h2>
    //       <ul className="list-disc pl-5">
    //         <li>
    //           {t("signup_firstname")} - {t("signup_lastname")}
    //         </li>
    //         <li>{t("signup_address")}</li>
    //         <li>{t("sign_phone")}</li>
    //         <li>Email</li>
    //         <li>
    //           {t("login_username")}, {t("sign_password")}
    //         </li>
    //         <li>{t("info_sec_policy_section2_item1_list4")}</li>
    //       </ul>
    //       <p>{t("info_sec_policy_section2_item1_detail4")}</p>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item2_title")}
    //       </h2>
    //       <p>{t("info_sec_policy_section2_item2_description")}</p>
    //       <ul className="list-disc pl-5">
    //         <li>{t("info_sec_policy_section2_item2_list1")}</li>
    //         <li>{t("info_sec_policy_section2_item2_list2")}</li>
    //         <li>{t("info_sec_policy_section2_item2_list3")}</li>
    //         <li>{t("info_sec_policy_section2_item2_list4")}</li>
    //         <li>{t("info_sec_policy_section2_item2_list5")}</li>
    //         <li>
    //           {t("info_sec_policy_section2_item2_list6")}{" "}
    //           <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>.
    //         </li>
    //       </ul>
    //       <p>{t("info_sec_policy_section2_item2_detail")}</p>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item3_title")}
    //       </h2>
    //       <p>
    //         {t("info_sec_policy_section2_item3_detail")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>.
    //       </p>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item4_title")}
    //       </h2>
    //       <p>
    //         {t("info_sec_policy_section2_item4_detail1")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>{" "}
    //         {t("info_sec_policy_section2_item4_detail2")}
    //       </p>
    //       <p>- {t("info_sec_policy_section2_item4_list1")}</p>
    //       <p>- {t("info_sec_policy_section2_item4_list2")}</p>
    //       <p>- {t("info_sec_policy_section2_item4_list3")}</p>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item5_title")}
    //       </h2>
    //       <p>
    //         {t("info_sec_policy_section2_item5_detail1")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>{" "}
    //         {t("info_sec_policy_section2_item5_detail2")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>{" "}
    //         {t("info_sec_policy_section2_item5_detail3")}
    //       </p>
    //       <ul className="list-disc pl-5">
    //         <li>{t("info_sec_policy_section2_item5_list1")}</li>
    //         <li>{t("info_sec_policy_section2_item5_list2")}</li>
    //       </ul>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         {t("info_sec_policy_section2_item6_title")}
    //       </h2>
    //       <ul className="list-disc pl-5">
    //         <li>{t("info_sec_policy_section2_item6_list1")}</li>
    //         <li>{t("info_sec_policy_section2_item6_list2")}</li>
    //         <li>{t("info_sec_policy_section2_item6_list3")}</li>
    //       </ul>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         - {t("info_sec_policy_section2_item7_title")}
    //       </h2>
    //       <p>
    //         {t("info_sec_policy_section2_item7_detail1")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>{" "}
    //       </p>
    //       <p>
    //         {t("info_sec_policy_section2_item7_detail2")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>{" "}
    //         {t("info_sec_policy_section2_item7_detail3")}
    //       </p>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">
    //         - {t("info_sec_policy_section2_item8_title")}
    //       </h2>
    //       <p>
    //         {t("info_sec_policy_section2_item8_detail1")}{" "}
    //         <a href="https://aquaticshub.vn/">https://aquaticshub.vn/</a>{" "}
    //         {t("info_sec_policy_section2_item8_detail2")}
    //       </p>
    //       <ul className="list-disc pl-5">
    //         <li>{t("info_sec_policy_section2_item8_list1")}</li>
    //         <li>{t("info_sec_policy_section2_item8_list2")}</li>
    //         <li>{t("info_sec_policy_section2_item8_list3")}</li>
    //       </ul>
    //     </div>

    //     <div className="flex flex-col gap-5">
    //       <h2 className="font-bold text-xl">{t("note")}</h2>
    //       <ul className="list-disc pl-5 italic">
    //         <li>{t("info_sec_policy_section2_item9_detail1")}</li>
    //         <li>{t("info_sec_policy_section2_item9_detail2")}</li>
    //         <li>{t("info_sec_policy_section2_item9_detail3")}</li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
    <div className="mx-10 mt-10 pv:max-ph:mx-0 pt-[90px] pb-[60px] flex flex-col">
      <div className="p-6 flex flex-col gap-5 ">
        <div className="flex flex-col gap-5">
          <h2 className="font-bold text-3xl uppercase">
            {t("sc_policy_title")}
          </h2>
          <p className="text-lg leading-7">
            {t("sc_policy_section1_description")}
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <p className="text-xl font-bold">{t("sc_policy_section1_title")}</p>
        </div>
        <div className="flex flex-col gap-5">
          <div className="">
            <p className="text-xl font-bold">{t("sc_policy_section_2")}</p>
          </div>
          <div className="">
            <p className="leading-7 text-lg">
              {t("sc_policy_section_2_desciption_1")}
              <br></br>
              {t("sc_policy_section_2_desciption_2")} <br></br>
              {t("sc_policy_section_2_desciption_3")}
              <br></br>
              {t("sc_policy_section_2_desciption_4")}
              <br></br>
              {t("sc_policy_section_2_desciption_5")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-xl font-bold">
            <p className="">{t("sc_policy_section_3")}</p>
          </div>
          <div className="">
            <p className="leading-7 text-lg">
              {t("sc_policy_section_3_desciption_1")}
              <br></br>
              {t("sc_policy_section_3_desciption_2")}.<br></br>
              {t("sc_policy_section_3_desciption_3")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="">
            <p className="text-xl font-bold">{t("sc_policy_section_4")}</p>
          </div>
          <div className="">
            <p className="leading-7 text-lg">
              {t("sc_policy_section_4_description_1")}
              <br></br>
              {t("sc_policy_section_4_description_2")}
              <br></br>
              {t("sc_policy_section_4_description_3")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="">
            <p className="text-xl font-bold">{t("sc_policy_section_5")}</p>
          </div>
          <div className="">
            <p className="leading-7 text-lg">
              {t("sc_policy_section_5_description_1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="">
            <p className="font-bold text-xl">{t("sc_policy_section_6")}</p>
          </div>
          <div className="">
            <p className="leading-7 text-lg">
              {t("sc_policy_section_6_description_1")}

              <br></br>
              {t("sc_policy_section_6_description_2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="text-xl font-bold">
            <p className="">{t("sc_policy_section_7")}</p>
          </div>
          <div className="">
            <p className="leading-7 text-lg">
              {t("sc_policy_section_7_description_1")}
              <br></br>
              {t("sc_policy_section_7_description_2")}
              <br></br>
              {t("sc_policy_section_7_description_3")}
              <br></br>
              {t("sc_policy_section_7_description_4")}

              <br></br>
              {t("sc_policy_section_7_description_5")}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="">
            <p className="text-xl font-bold">{t("sc_policy_section_8")}</p>
          </div>
          <div className="flex flex-col gap-5">
            <p className="text-lg leading-7">
              {t("sc_policy_section_8_description_1")}{" "}
              <b>{t("sc_policy_section_8_description_2")} </b>{" "}
              {t("sc_policy_section_8_description_3")}
              <a href="https://aquaticshub.vn/ " className="hover:underline">
                https://aquaticshub.vn.
              </a>
            </p>
            <div className="flex flex-col gap-3">
              <p className="text-lg leading-7">
                {t("sc_policy_section_8_description_4")}
              </p>
              <p className="text-lg leading-7">
                {t("sc_policy_section_8_description_5")}
              </p>
              <p className="text-lg leading-7">
                {t("sc_policy_section_8_description_6")}
              </p>
            </div>
            <div className="">
              <p className="text-lg leading-7">
                <b> {t("sc_policy_section_8_description_7")} </b>{" "}
                {t("sc_policy_section_8_description_8")}
              </p>
            </div>
            <div className="">
              <p className="text-lg leading-7">
                <b>{t("sc_policy_section_8_description_9")}</b>{" "}
                {t("sc_policy_section_8_description_10")}
              </p>
            </div>
            <div className="">
              <p className="text-lg leading-7">
                <b>{t("sc_policy_section_8_description_11")} </b>{" "}
                {t("sc_policy_section_8_description_12")}{" "}
                <b>{t("sc_policy_section_8_description_13")}</b>{" "}
                {t("sc_policy_section_8_description_14")}
              </p>
              <p className="">{t("sc_policy_section_8_description_15")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SwimCourse);
