/** @format */
import axiosConfig from "../axiosConfig";

export const apiGetLesson = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      const response =
        id != undefined
          ? await axiosConfig({
            method: "GET",
            url: "/Lesson",
            params: {
              LessonCode: id,
            },
          })
          : await axiosConfig({
            method: "GET",
            url: "/Lesson",
          });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const apiGetEnrollment = async (payload) => {
  const response =
    payload != undefined
      ? await axiosConfig({
        baseURL: "http://103.157.218.115/PoolManagement/hs/PoolManagement/V1",
        method: "GET",
        url: "/Enrollment",
        params: payload,
      })
      : await axiosConfig({
        baseURL: "http://103.157.218.115/PoolManagement/hs/PoolManagement/V1",
        method: "GET",
        url: "/Enrollment",
      });
  return response
}

export const apiEnrollLesson = async (payload) => {
  // const ipRes = await axiosConfig({
  //   baseURL: "https://api.ipify.org",
  //   method: "GET",
  //   url: "/?format=json",
  // });
  const ipRes = { data: { ip: "103.157.218.115" } }
  const lang = localStorage.getItem("lang");
  const bodyPayload = { ...payload, language: lang, ip: ipRes.data.ip };

  const response = await axiosConfig({
    method: "POST",
    url: "/v1/user-enrollment",
    data: bodyPayload,
  });
  return response;
};

// export const apiEnrollLesson_VNPay = async (payload) => {
//   const ipRes = await axiosConfig({
//     baseURL: "https://api.ipify.org",
//     method: "GET",
//     url: "/?format=json",
//   });
//   const lang = localStorage.getItem("lang");
//   const bodyPayload = { ...payload, Language: lang, IP: ipRes.data.ip };

//   const response = await axiosConfig({
//     method: "POST",
//     url: "/Student_Enrollment2",
//     data: bodyPayload,
//   });

//   return response;
// };

export const apiEnrollCallback = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/PaymentComplete",
    data: payload,
  });

  return response;
};

export const apiEnrollCallback_VNPay = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/v1/user-enrollment-complete-vnp",
    data: payload,
  });

  return response;
};

export const apiCourseRating = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/Student_Rating_2",
    data: payload,
  });

  return response;
};

export const apiGetQR = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/v1/user-qr-wallet",
    data: payload,
  });

  return response;
};

export const apiCheckinStudent = async (payload) => {
  const response = await axiosConfig({
    method: "PUT",
    url: "/PoolTicket",
    data: payload,
  });

  return response;
};

export const apiQRTicket = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/PoolTicket",
    data: payload,
  });

  return response;
};

export const apiGetPaymentStatus = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/PaymentStatus",
    data: payload,
  });

  return response;
};

export const apiRePayment = async (historyID) => {
  // const ipRes = await axiosConfig({
  //   baseURL: "https://api.ipify.org",
  //   method: "GET",
  //   url: "/?format=json",
  // });
  const ipRes = { data: { ip: "103.157.218.115" } }
  const lang = localStorage.getItem("lang");
  const bodyPayload = { historyId: historyID, language: lang, ip: ipRes.data.ip };

  const response = await axiosConfig({
    method: "POST",
    url: "/v1/user-re-enrollment",
    data: bodyPayload,
  });
  return response;  
};

export const apiEnrollmentCalendar = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/Enrollment_Calendar",
    data: payload,
  });

  return response;
};
export const apiStudentApprove = async (payload) => {
  const response = await axiosConfig({
    method: "POST",
    url: "/Lecture_StudentPromote",
    data: payload,
  });

  return response;
};
