import React from "react";
import { Modal, Button } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
const ModalCouseDetail = ({ open, onCancel, onOk, t, data }) => {
  return (
    <div>
      <Modal
        open={open}
        title={
          <div className="flex justify-center ">
            <p className="text-blue-500 font-bold text-3xl">{data.name}</p>
          </div>
        }
        onOk={onOk}
        onCancel={onCancel}
        footer={[]}
      >
        <div className="">
          <div className="flex flex-col gap-5">
            <div className="flex items-center  gap-2 text-xl">
              <Icon icon={"uiw:date"} className="h-10 w-10"></Icon>
              <div className="flex justify-center items-center gap-2 text-lg">
                <p className="">
                  <b>Start date :</b> {data.startDate}
                </p>
                <p className="">
                  <b>End date :</b> {data.endDate}
                </p>
              </div>
            </div>
            <div className="flex items-center  gap-2 text-xl ">
              <Icon
                className="h-10 w-10"
                icon={"material-symbols-light:payments-outline-rounded"}
              ></Icon>
              <p className="">
                <b>Payment method:</b> {data.paymentMethod}
              </p>
            </div>
            <div className="flex items-center  gap-2 text-xl">
              <Icon icon={"carbon:course"} className="h-10 w-10"></Icon>
              <p className="">
                <b>Type Course:</b> {data.type}
              </p>
            </div>
            <div className="flex items-center  gap-2 text-xl">
              <Icon icon={"f7:status"} className="h-10 w-10"></Icon>
              <div className="flex items-center gap-2">
                <b>Type Course:</b>{" "}
                <p
                  className={`text-green-400 ${
                    data.status === "Paid" ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {data.status}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCouseDetail;
