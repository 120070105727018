/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Spin, Radio, Modal, Button, notification, Tabs } from "antd";
import { Icon } from "@iconify/react";
import { apiGetQuizz, apiPostQuiz } from "../../services/secondstage";
import QuizModal from "../../components/Modal/ModalQuiz";
import { CardMemberShip } from "../../components";
import { withTranslation } from "react-i18next";
import flag from "../../assets/vie-en.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { path } from "../../utils/constant";
import { apiusermember } from "../../services/usermember";

const ModalChooseLevel = ({
  isShowModal,
  handleShowDetail,
  type = "SOF",
  currentLevel,
}) => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  //#region Select Member
  const [dataMember, setDataMember] = useState({
    adultMembers: [
      {
        code: "",
        dateOfBirth: "",
        description: "",
        gender: "",
        pictureUrl: "",
      },
    ],
    childMembers: [
      {
        code: "",
        dateOfBirth: "",
        description: "",
        gender: "",
        pictureUrl: "",
      },
    ],
  });
  const [selectedMember, setSelectedMember] = useState({
    UserID: user?.code,
    UserType: "main",
  });

  const handleChangeMember = (e) => {
    setQuizResult({
      ...quizResult,
      userInfo: {
        UserID: e.value,
        UserType: e.userType,
      },
    });
    setSelectedMember({ UserID: e.value, UserType: e.userType });
  };
  //#endregion

  const [quizResult, setQuizResult] = useState({
    answers: currentLevel.answers,
    name: currentLevel.name,
    color: currentLevel.color,
    detail: currentLevel.detail,
    pictureUrl: currentLevel.pictureUrl,
    instructorLevel: "VietnameseOnly",
    targetID: currentLevel.targetId,
    userInfo: {
      UserID: user?.code,
      UserType: "main",
    },
  });
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = type == "Adult" ? 1 : 3;
  const handleNext = () => {
    switch (currentStep) {
      case 1:
        setQuizResult({
          ...quizResult,
          userInfo: {
            UserID: selectedMember.UserID,
            UserType: selectedMember.UserType,
          },
        });
        if (type == "Adult") {
        } else {
          setCurrentStep(currentStep + 1);
        }
        break;
      case 2:
        if (quizResult.answers.length > 0) {
          if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
          }
        } else {
          notification.info({
            message: "Please specify your level",

            placement: "topRight",
            duration: 2,
          });
        }
        break;

      default:
        setCurrentStep(currentStep + 1);
        break;
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const [dataQuiz, setDataQuiz] = useState({
    code: "",
    description: "",
    type: "",
    quizSet: [
      {
        question: "",
        options: [],
      },
    ],
  });
  const [modalQuizVisible, setModalQuizVisible] = useState(false);
  const handleCloseQuizModal = () => {
    setModalQuizVisible(false);
  };
  const [modalQuizResult, setModalQuizResult] = useState(false);
  const handleCompleteQuiz = async (userAnswers) => {
    await apiPostQuiz({
      answers: userAnswers,
      quizName: dataQuiz?.description,
    })
      .then((res) => {
        if (res.status === 200) {
          setQuizResult({
            ...quizResult,
            answers: userAnswers,
            name: res.data.data.name,
            color: res.data.data.color,
            pictureUrl: res.data.data.pictureUrl,
            detail: res.data.data.detail,
            targetID: res.data.data.index,
          });
          setModalQuizResult(true);
          setModalQuizVisible(false);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error evaluating user",

          placement: "topRight",
          duration: 2,
        });
      });
  };
  const onChangeInstructorLevel = (e) => {
    setQuizResult({ ...quizResult, instructorLevel: e.target.value });
  };
  const onChangeTargetLevel = (e) => {
    setQuizResult({ ...quizResult, targetID: e.target.value });
  };

  const items = [
    {
      key: "1",
      children: (
        <div className="w-[95%] mx-auto  my-10  rounded-xl">
          <div className="flex justify-center gap-8 pv:max-md:flex-col pv:max-md:items-center flex-wrap pv:max-md:flex-nowrap w-full">
            <div className="w-[250px] pv:max-md:w-[220px]">
              <CardMemberShip
                code={user.code}
                img={user.pictureUrl}
                name={user.description}
                year={user.dateOfBirth}
                icon={"el:adult"}
                selectedMember={selectedMember}
                UserType="main"
                handleChangeMember={handleChangeMember}
                gender={user.gender}
              />
            </div>

            {dataMember?.childMembers.length !== 0
              ? dataMember?.childMembers.map((item, index) => {
                  return (
                    <div className="w-[250px] pv:max-md:w-[220px]">
                      <CardMemberShip
                        code={item.code}
                        img={item.pictureUrl}
                        name={item.description}
                        year={item.dateOfBirth}
                        icon={"bx:child"}
                        selectedMember={selectedMember}
                        UserType="sub-child"
                        handleChangeMember={handleChangeMember}
                        gender={item.gender}
                      />
                    </div>
                  );
                })
              : null}

            {dataMember?.adultMembers.length !== 0
              ? dataMember?.adultMembers.map((item, index) => {
                  return (
                    <div className="w-[250px] pv:max-md:w-[220px]">
                      <CardMemberShip
                        code={item.code}
                        img={item.pictureUrl}
                        name={item.description}
                        year={item.dateOfBirth}
                        icon={"el:adult"}
                        selectedMember={selectedMember}
                        UserType="sub-adult"
                        handleChangeMember={handleChangeMember}
                        gender={item.gender}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      children: (
        <div className="w-[95%] mx-auto py-4 rounded-b-md ">
          {currentLevel.targetID !== "" &&
          currentLevel.targetID !== undefined ? (
            quizResult.pictureUrl !== "" ? (
              <>
                <img
                  className="object-cover h-[50%]"
                  src={quizResult.pictureUrl}
                ></img>
              </>
            ) : null
          ) : (
            <>
              {quizResult.pictureUrl !== "" ? (
                <>
                  <div className="flex items-center justify-center flex-col  ">
                    <img
                      className="h-40 object-cover"
                      src={quizResult.pictureUrl}
                    ></img>
                    <p className="p-4">{quizResult.detail}</p>
                    <button
                      className="relative bg-gray-600 text-white font-bold px-6 py-3 rounded-full text-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-110 shadow-xl flex items-center justify-center overflow-hidden"
                      onClick={() => {
                        setModalQuizVisible(true);
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <Icon icon="mdi:fish" className="text-3xl text-white" />
                        <span className="text-lg font-medium">Retry Quiz</span>
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center justify-center h-full">
                    <div className="text-center flex items-center justify-center flex-col mb-4 h-full">
                      <p className="text-2xl font-medium mb-4">
                        Choose a level that best describes your swimmer:
                      </p>
                      <button
                        className="relative bg-gray-600 text-white font-bold px-6 py-3 rounded-full text-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-110 shadow-xl flex items-center justify-center overflow-hidden"
                        onClick={() => {
                          setModalQuizVisible(true);
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <Icon
                            icon="mdi:fish"
                            className="text-3xl text-white"
                          />
                          <span className="text-lg font-medium">
                            Start Quiz
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* <div className="text-center flex items-center justify-center flex-col mb-4">
            <div className="w-full bg-white p-4 rounded-md shadow-md mt-6">
              <p className="text-xl font-medium mb-4">Choose a level that best describes your swimmer:</p>
              <Radio.Group
                onChange={onChangeTargetLevel}
                value={quizResult.targetID}
                className="flex flex-col gap-4"
              >
                <div className="flex items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/starfish.png"
                    alt="Vietnamese Flag"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="1" className="text-center">STARFISH</Radio>
                </div>
                <div className="flex items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/seahorse.png"
                    alt="English Flag"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="2">SEAHORSE</Radio>
                </div>
                <div className="flex  items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/seal.png"
                    alt="Vietnamese and English Flags"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="3">SEAL</Radio>
                </div>
                <div className="flex  items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/barracuda.png"
                    alt="Vietnamese and English Flags"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="4">BARRACUDA</Radio>
                </div>
                <div className="flex  items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/swordfish.png"
                    alt="Vietnamese and English Flags"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="5">SWORDFISH</Radio>
                </div>
                <div className="flex  items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/dolphin.png"
                    alt="Vietnamese and English Flags"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="6">DOLPHIN</Radio>
                </div>
                <div className="flex  items-center gap-2">
                  <img
                    src="https://1cinnovation.vn/AquaticPicture/Badges/shark.png"
                    alt="Vietnamese and English Flags"
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <Radio value="7">SHARK</Radio>
                </div>
              </Radio.Group>
            </div>
          </div> */}
        </div>
      ),
    },
    {
      key: "3",
      children: (
        <div className="text-center flex items-center justify-center flex-col mb-4">
          <div className="w-full bg-white p-4 rounded-md shadow-md">
            <p className="text-xl font-medium mb-4">SWIMMER'S LANGUAGE</p>
            <Radio.Group
              onChange={onChangeInstructorLevel}
              value={quizResult.instructorLevel}
              className="flex flex-col gap-4"
            >
              <div className="flex items-center gap-2">
                <img
                  src="https://th.bing.com/th/id/R.adfac8931ef2691053cc2c5a20aab5b8?rik=nG9Nn8FUvjuUYA&pid=ImgRaw&r=0"
                  alt="Vietnamese Flag"
                  className="w-12 h-12 object-cover rounded-full"
                />
                <Radio value="VietnameseOnly" className="text-center">
                  VIETNAMESE ONLY
                </Radio>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="https://cmkt-image-prd.freetls.fastly.net/0.1.0/ps/1822915/6793/3682/m1/fpnw/wm1/british-flag-.jpg?1477811604&s=392fc272ec4e558e1e9f3b7dea190f2f"
                  alt="English Flag"
                  className="w-12 h-12 object-cover rounded-full"
                />
                <Radio value="EnglishOnly">ENGLISH ONLY</Radio>
              </div>
              <div className="flex  items-center gap-2">
                <img
                  src={flag}
                  alt="Vietnamese and English Flags"
                  className="w-12 h-12 object-cover rounded-full"
                />
                <Radio value="VietnameseAndEnglish">VIETNAMESE & ENGLISH</Radio>
              </div>
            </Radio.Group>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchQuiz = async () => {
      const response = await apiGetQuizz(type);

      if (response?.status === 201 || response?.status === 200) {
        setDataQuiz(response?.data.data);
      }
    };
    fetchQuiz();

    apiusermember({
      mainUserCode: user?.code,
    })
      .then((response) => {
        if (response?.status === 201 || response?.status === 200) {
          setDataMember(response?.data.data);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);
  const [isQuizResultAvailable, setIsQuizResultAvailable] = useState(false);
  useEffect(() => {
    setIsQuizResultAvailable(Boolean(quizResult));
  }, [quizResult]);

  return (
    <>
      {modalQuizVisible ? (
        <QuizModal
          visible={modalQuizVisible}
          onCancel={handleCloseQuizModal}
          questions={dataQuiz?.quizSet}
          onComplete={handleCompleteQuiz}
        />
      ) : null}

      {modalQuizResult ? (
        <Modal
          className="flex items-center justify-center"
          // width={700}
          open={modalQuizResult}
          onCancel={() => setModalQuizResult(false)}
          footer={null}
          // bodyStyle={{ height: '80vh', overflowY: 'auto' }}
          centered
        >
          <div className="">
            <div className="flex flex-col justify-center items-center">
              <p className="text-[20px] font-roboto_bold">You are ...</p>

              <div className="">
                <img
                  className="object-cover"
                  src={
                    quizResult.pictureUrl !== ""
                      ? quizResult.pictureUrl
                      : "http://103.157.218.115/AquaticPicture/Badges/starfish.png"
                  }
                ></img>
                <div className="flex justify-center items-center">
                  <p className="text-[24px] text-[#55c1ff]">
                    {quizResult.name !== "" ? quizResult.name : "Starfish"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      <Modal
        width={
          window.innerWidth < 768
            ? "80%"
            : window.innerWidth < 1440
            ? "60%"
            : "50%"
        }
        className="flex items-center justify-center"
        title="You are..."
        open={isShowModal}
        onCancel={() => handleShowDetail()}
        centered
        footer={[
          currentStep === totalSteps ? (
            <>
              {type == "Adult" ? null : (
                <Button key="prev" onClick={handlePrev}>
                  Previous
                </Button>
              )}
              <Button
                key="ok"
                type="primary"
                onClick={() => {
                  if (user) {
                    if (type == "Adult") {
                      handleShowDetail(quizResult);
                    } else {
                      if (
                        quizResult.targetID !== "" &&
                        quizResult.targetID !== undefined
                      ) {
                        handleShowDetail(quizResult);
                      } else {
                        notification.info({
                          message: "Please specify your level",

                          placement: "topRight",
                          duration: 2,
                        });
                      }
                    }
                  } else {
                    navigate("../" + path.LOGIN, {
                      state: {
                        redirectCallBack: handleShowDetail(quizResult),
                      },
                    });
                  }
                }}
              >
                OK
              </Button>
            </>
          ) : (
            <>
              {type == "Adult" ? null : (
                <Button key="prev" disabled>
                  Previous
                </Button>
              )}
              <Button
                key="next"
                type="primary"
                onClick={handleNext}
                disabled={quizResult === ""}
              >
                Next
              </Button>
            </>
          ),
        ]}
      >
        <Tabs
          activeKey={String(currentStep)} // Chuyển đổi số thành chuỗi
          onChange={(key) => setCurrentStep(Number(key))} // Chuyển đổi chuỗi thành số
          items={items}
          className="bg-white px-2 w-full"
        />
      </Modal>
    </>
  );
};

export default withTranslation()(ModalChooseLevel);
