/** @format */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { withTranslation } from "react-i18next"

const PublicSwmmings = ({ t }) => {
  const dataSourceWeekdays = [
    {
      key: "1",
      time: (
        <p className="">
          6:00 <br /> 7:30
        </p>
      ),
      weekdays: "Lane Swimming",
    },
    {
      key: "2",
      time: (
        <p className="">
          8:00 <br /> 10:30
        </p>
      ),
      weekdays: "Open Swimming",
    },
    {
      key: "3",
      time: (
        <p className="">
          11:00 <br /> 12:30
        </p>
      ),
      weekdays: "Lane Swimming",
    },
    {
      key: "4",
      time: (
        <p className="">
          13:00 <br /> 15:30
        </p>
      ),
      weekdays: "Open Swimming",
    },
    {
      key: "5",
      time: (
        <p className="">
          16:00 <br /> 17:30
        </p>
      ),
      weekdays: "Lane Swimming",
    },
    {
      key: "6",
      time: (
        <p className="">
          18:00 <br /> 22:00
        </p>
      ),
      weekdays: "Open Swimming",
    },
  ];

  const columnsweekDays = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 200,
    },
    {
      title: "WeekDays",
      dataIndex: "weekdays",
      key: "weekdays",
    },
  ];
  const dataSourceWeekend = [
    {
      key: "1",
      time: (
        <p className="">
          6:00 <br /> 22:00
        </p>
      ),
      weekend: "Lane Swimming / Lane Swimming",
    },
  ];
  const columnsweekend = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 200,
    },
    {
      title: "Weekend",
      dataIndex: "weekend",
      key: "weekend",
    },
  ];

  const CustomTable = ({ columns, data }) => {
    return (
      <table className=" w-full">
        <thead>
          <tr>
            {columns.map((column, index) =>
              index === 0 ? (
                <th
                  key={column.key}
                  className="text-[20px] font-roboto_bold text-[#215c98] w-[10%] "
                >
                  {column.title}
                </th>
              ) : (
                <th
                  key={column.key}
                  className="text-[20px] font-roboto_bold text-[#215c98] w-[90%] "
                >
                  {column.title}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.key}>
              {columns.map((column, index) =>
                index === 0 ? (
                  <td
                    key={column.key}
                    className="text-center text-[18px] w-[100px] "
                  >
                    {item[column.dataIndex]}
                  </td>
                ) : (
                  <td
                    key={column.key}
                    className={`text-center text-[18px] w-[400px]  ${item[column.key] === "Lane Swimming"
                        ? "bg-[#dae9f8]"
                        : item[column.key] === "Open Swimming"
                          ? "bg-[#4d93d9]"
                          : "bg-[#f2ceef]"
                      }`}
                  >
                    {item[column.dataIndex]}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  return (
    <div className="pt-[180px] w-[70%] mx-auto">
      <div className="py-10">
        <CustomTable
          columns={columnsweekDays}
          data={dataSourceWeekdays}
        ></CustomTable>
        <CustomTable
          columns={columnsweekend}
          data={dataSourceWeekend}
        ></CustomTable>
      </div>
      <div className="w-full flex flex-col gap-8 p-10 pv:max-md:p-4 md:max-xl:p-8 text-[#215c98]">
        <h3 className="font-bold">FYI/ {t("note")}</h3>
        <div className="w-full flex flex-col gap-2">
          <h4 className="font-bold">1. Open swimming:</h4>
          <p className="text-justify">- {t("publicswimming_note1_item1")}</p>
          <p className="text-justify">- {t("publicswimming_note1_item2")}</p>
          <p className="text-justify">- {t("publicswimming_note1_item3")}</p>
        </div>
        <div className="w-full flex flex-col gap-2">
          <h4 className="font-bold">2. Lane swimming:</h4>
          <p className="text-justify">- {t("publicswimming_note2_item1")}</p>
          <p className="text-justify">- {t("publicswimming_note2_item2")}</p>
          <p className="text-justify">- {t("publicswimming_note2_item3")}</p>
        </div>
        <p className="italic">{t("publicswimming_thankyou")}</p>
      </div>
    </div>
  );
};

export default withTranslation()(PublicSwmmings);
