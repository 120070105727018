import { formatCurrency } from "../../../../../utils/constant";

const PriceTable = ({ columnsLaneHire, dataPerLane }) => {
  const renderPriceTableContent = () => {
    return dataPerLane.map((lane, index) => {
      return (
        <tr
          key={index}
          className={`bg-white bg-opacity-25 justify-center${index % 2 === 0 ? "bg-gray-100" : ""
}`}
        >
          <td class="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{lane.laneHire}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane01)}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane02)}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane03)}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane04)}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane05)}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane06)}</div>
          </td>
          <td className="bg-white bg-opacity-25 justify-center">
            <div class="box-border h-32 w-32 p-4 border-4 flex items-center justify-center">{formatCurrency(lane.lane07)}</div>
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="w-full my-4 table-auto text-center text-sm lg:text-lg ">
      <thead className="text-black lg:text-2xl">
        <tr>
          {columnsLaneHire.map((laneHire, index) => {
            return (
              <th key={index} className="py-3 px-6">
                {laneHire.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="text-gray-600">{renderPriceTableContent()}</tbody>
    </table>
  );
};

export default PriceTable;
