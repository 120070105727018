/** @format */

import React, { useState, useEffect } from "react";
import { Button, Tabs, Table } from "antd";
import imgbackground from "../../assets/mainpool.png";

import { decryptString, path } from "../../utils/constant";
import { apiGetEnrollment } from "../../services/lesson";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components";
import { withTranslation } from "react-i18next";
import { SidebarAdmin, HeaderAdmin } from "../../components";

const InstructorCourseDetail = ({ t }) => {
  let { courseCode } = useParams();
  const navigate = useNavigate();

  const [isLoadingEnrollment, setIsLoadingEnrollment] = useState(true);
  const [enrollment, setEnrollment] = useState({
    Number: "",
    StartDate: "",
    EndDate: "",
    ViceLecture: "",
    Lecture: "",
    Enrolled: 0,
    Capacity: 0,
    SelectedStudentInAttendanceList: 0,
    SelectedStudentInPendingList: 0,
    Lesson: {
      Code: "",
      Description: "",
      Detail: "",
      Level: "",
      ModuleNumber: 0,
      Modules: [],
      PictureURL: "",
      Price: 1,
    },
    AttendanceList: [
      {
        Code: "",
        Description: "",
        Gender: "",
        Email: "",
        PictureURL: "",
        Age: 0,
      },
    ],
  });
  const [enrollmentData, setenrollmentData] = useState([
    {
      Code: "0375875162",
      Description: "Nguyen Thanh Trung ",
      Gender: "Male",

      PictureURL:
        "https://i.pinimg.com/474x/0a/a8/58/0aa8581c2cb0aa948d63ce3ddad90c81.jpg",
      Age: 10,
    },
    {
      Code: "0909100509",
      Description: "Le Viet Anh ",
      Gender: "Male",

      PictureURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSinUiRqVB94sfZZbtNZgPJswUTs4R7YDskvXfVjUSejKfQqAoMaedQBNfybdIdduiix4&usqp=CAU",
      Age: 10,
    },
    {
      Code: "0918198111",
      Description: "Tran Thao Ly",
      Gender: "FeMale",

      PictureURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSinUiRqVB94sfZZbtNZgPJswUTs4R7YDskvXfVjUSejKfQqAoMaedQBNfybdIdduiix4&usqp=CAU",
      Age: 10,
    },
  ]);

  const handleBack = () => {
    navigate(-1);
  };

  const enrolledColumns = [
    {
      title: t("instructor_course_detail_avatar"),
      dataIndex: "PictureURL",
      key: "PictureURL",

      render: (PictureURL) => {
        return (
          <div className="flex w-full h-full items-center justify-center">
            <img
              className="h-8 w-8 rounded-full"
              src={PictureURL}
              alt="image"
            ></img>
          </div>
        );
      },
    },
    {
      title: "Full Name",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
    },

    {
      title: "Phone Number",
      dataIndex: "Code",
      key: "Code",
    },
  ];
  const columnPending = [
    {
      title: t("instructor_course_detail_avatar"),
      dataIndex: "PictureURL",
      key: "PictureURL",
      render: (PictureURL) => {
        return (
          <div className="flex w-full h-full items-center justify-center">
            <img
              className="h-8 w-8 rounded-full"
              src={PictureURL}
              alt="image"
            ></img>
          </div>
        );
      },
    },
    {
      title: t("instructor_course_detail_fullname"),
      dataIndex: "Description",
      key: "fullname",
    },

    {
      title: t("instructor_course_detail_age"),
      dataIndex: "Age",
      key: "age",
    },
    {
      title: t("instructor_course_detail_gender"),
      dataIndex: "Gender",
      key: "gender",
    },

    {
      title: t("instructor_course_detail_phone"),
      dataIndex: "Code",
      key: "Code",
    },
    {
      title: t("instructor_course_detail_email"),
      dataIndex: "Email",
      key: "Email",
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <div className=" flex items-center gap-2">
          <p className="">{t("instructor_course_detail_tab_overview")}</p>
        </div>
      ),
      children: <p>{enrollment?.Lesson?.Detail}</p>,
    },
    {
      key: "2",
      label: (
        <div className=" flex items-center gap-2">
          <p className="">{t("instructor_course_detail_tab_enrolled")}</p>
        </div>
      ),
      children: (
        <div className="">
          <Table
            sortDirections={true}
            columns={enrolledColumns}
            dataSource={enrollmentData}
            pagination={{ pageSize: 5, position: ["bottomCenter"] }}
            style={{
              width: "100%",
              margin: "auto",
              padding: "0",
              overflow: "hidden",
            }}
            scroll={{
              x: "max-content",
            }}
            onRow={(e) => ({
              onClick: () => {
                navigate("../" + path.STUDENTPROFILE);
              },
            })}
          />
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <div className=" flex items-center gap-2">
    //       <p className="">{t("instructor_course_detail_tab_pending")}</p>
    //     </div>
    //   ),
    //   children: (
    //     <div className="">
    //       <Table
    //         sortDirections={true}
    //         columns={columnPending}
    //         dataSource={enrollment?.PendingList}
    //         pagination={{ pageSize: 5, position: ["bottomCenter"] }}
    //         style={{
    //           width: "100%",
    //           margin: "auto",
    //           padding: "0",
    //           overflow: "hidden",
    //         }}
    //         scroll={{
    //           x: "max-content",
    //         }}
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: (
    //     <div className=" flex items-center gap-2">
    //       <p className="">{t("instructor_course_detail_tab_note")}</p>
    //     </div>
    //   ),
    //   children: <p>{enrollment?.Lesson.Detail}</p>,
    // },
    // {
    //   key: "5",
    //   label: (
    //     <div className=" flex items-center gap-2">
    //       <p className="">{t("instructor_course_detail_tab_time")}</p>
    //     </div>
    //   ),
    //   children: <></>,
    // },
    // {
    //   key: "6",
    //   label: (
    //     <div className=" flex items-center gap-2">
    //       <p className="">{t("instructor_course_detail_tab_prsence")}</p>
    //     </div>
    //   ),
    //   children: <></>,
    // },
  ];

  //   useEffect(() => {
  //     const fetchEnrollment = async () => {
  //       const response = await apiGetEnrollment({
  //         EnrollmentCode: decryptString({ encryptedStr: courseCode }),
  //       });

  //       if (response?.status === 200) {
  //         setEnrollment({ ...response.data.Enrollment });
  //         setIsLoadingEnrollment(false);
  //       }
  //     };
  //     fetchEnrollment();
  //   }, []);
  const [resize, setResize] = useState(false);
  const handleclick = (value) => {
    setResize(value);
  };
  return (
    <>
      <div className="pt-[100px]">
        <div className="w-full ">
          <HeaderAdmin />
        </div>
        <div className={`w-full grid grid-cols-4`}>
          <div className={`col-span-1`}>
            <SidebarAdmin handleclick={handleclick} />
          </div>
          <div className="col-span-3 my-10">
            <div className="h-auto bg-white p-6 rounded-xl m-8 w-[100%] pv:max-md:mx-2">
              <div className="flex justify-center pb-8 pv:max-md:justify-start pv:max-md:py-2">
                <p className="text-3xl  pv:max-md:text-lg font-bold">
                  {/* {enrollment?.Lesson.Description} */}
                  Fast Track -
                </p>
              </div>
              <div className=" w-[80%] mx-auto gap-x-3">
                <div className=" pv:max-lg:col-span-3 ">
                  <div className=" ">
                    <img
                      src={
                        enrollment?.Lesson.PictureURL
                          ? enrollment?.Lesson.PictureURL
                          : imgbackground
                      }
                      className="h-[400px] w-[100%] mx-auto object-cover rounded-md pv:max-md:h-[200px] "
                    ></img>
                  </div>
                  <div className="">
                    <Tabs
                      defaultActiveKey="1"
                      items={items}
                      size="large"
                      type="line"
                      className="py-2 w-[100%] mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(InstructorCourseDetail);
