/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { path } from "../../../../utils/constant";
const AquariumPrice = ({ t }) => {
  const navigate = useNavigate();
  const [mockDataTable, setMockdataTable] = useState([
    {
      key: "1",
      row_1: "",
      row_2: "FLOW MACHINE",
      row_3: " FLOW MACHINE",
      row_4: "ANALYSIS",
      row_5: "COLD PLUNGE",
    },
    {
      key: "2",
      row_1: "SESSION",
      row_2: "1",
      row_3: "1",
      row_4: "1",
      row_5: "1",
    },
    {
      key: "3",
      row_1: "DURATION",
      row_2: "30 MINUTES",
      row_3: "1 HOUR",
      row_4: "1 HOUR",
      row_5: "PLUNGE",
    },
    {
      key: "4",
      row_1: "PRICE",
      row_2: "VND 500,000",
      row_3: "VND 800,000",
      row_4: "VND 3,000,000",
      row_5: "250,000",
    },
  ]);

  return (
    <div className="bg-[#4e858b] ">
      <div className="w-[60%] mx-auto flex flex-col gap-10 pt-20 pv:max-md:w-[95%] pv:max-md:pt-4">
        <p className=" text-5xl text-[#edd3c5]">{t("aquarium_price")}</p>

        <div className="grid grid-cols-4 w-[100%] mx-auto gap-2">
          {mockDataTable.map((item, index) => {
            return (
              <div className="flex flex-col gap-2 pv:max-lg:font-bold">
                <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[2vw]">{item.row_1}</p>
                </div>
                <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[2vw]">{item.row_2}</p>
                </div>
                <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[2vw]">{item.row_3}</p>
                </div>
                <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[2vw]">{item.row_4}</p>
                </div>
                <div className="pv:max-lg:h-[12vw] h-[100px]  w-full bg-[#cccccc] flex justify-center items-center">
                  <p className="text-[2vw]">{item.row_5}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-[90%] mx-auto flex justify-center py-10">
        <button
          className=" uppercase bg-[#dcd3d1] w-[400px] pv:max-lg:w-[300px]  py-4 rounded-full pv:max-ph:rounded-full"
          onClick={() => {
            navigate("../" + path.HOME);
          }}
        >
          <p className="underline text-2xl pv:max-lg:text-xl">
            {t("aquarium_home")}
          </p>
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(AquariumPrice);
