/** @format */

import React, { useEffect, useState } from "react";
import { CardTypesClub, ModalRequestTrial } from "../../../../components";
import { DataTypesClub } from "../../../../utils/data";
import { Table, Checkbox, Modal } from "antd";
import { apisecondstage } from "../../../../services/secondstage";
import { useNavigate, useLocation } from "react-router-dom";
import { notification } from "antd";
import { apiContactUs } from "../../../../services/metadata";
import { formatCurrency, path } from "../../../../utils/constant";
import Levels from "./Sections/Levels";
import CardSession from "../../../../components/Card/CardSession";
import { withTranslation, useTranslation } from "react-i18next";
import imgSchedule from "../../../../assets/Final_PIctures/club_schedule.jpg";
import imgSchedule2 from "../../../../assets/Final_PIctures/club_schedule_2.png";
const CustomTable = ({ columns, data }) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.key}>{column.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.key}>
            {columns.map((column) => (
              <td key={column.key}>{item[column.dataIndex]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Club = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  // define const
  const location = useLocation();
  // const DataState = location.state.data;
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const getSize = () => {
    setWidth(window.innerWidth);
  };
  const [data, setData] = useState({
    description: "",
    cost: {
      level: "",
      _2days: "",
      _3days: "",
      _4days: "",
      _5days: "",
    },
    level: {
      level1: {
        name: "",
        color: "",
        detail: "",
        pictureUrl: "",
      },
      level2: {
        name: "",
        color: "",
        detail: "",
        pictureUrl: "",
      },
      level3: {
        name: "",
        color: "",
        detail: "",
        pictureUrl: "",
      },
    },
    nowClass: [
      {
        detail: "",
        estCost: " ",
        pictureUrl: "",
        title: "",
      },
    ],
    gallery: [],
  });
  const [dataCost, setDataCost] = useState([]);
  const [dataSelectlevel, setdataSelectlevel] = useState([]);

  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const columnsCost = [
    {
      title: (
        <p className="text-[28px] pv:max-sm:text-lg font-roboto_bold text-white">
          Level
        </p>
      ),
      dataIndex: "level",
      key: "level",
      fixed: "left",
      width: `${isMobile ? "8%" : ""} `,
      render: (level) => (
        <p className={`text-[28px] pv:max-ph:text-[16px]`}>{level}</p>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px]  pv:max-sm:text-lg  font-roboto_bold text-white">
            2 days
          </p>

          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_2days",
      key: "_2days",
      render: (price) => (
        <p className={`text-[22px] font-[350] pv:max-ph:text-[16px]`}>
          {formatCurrency(price)}
        </p>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px] pv:max-sm:text-lg  font-roboto_bold text-white">
            3 days
          </p>

          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_3days",
      key: "_3days",
      render: (price) => (
        <p className={`text-[22px] font-[350] pv:max-ph:text-[16px]`}>
          {formatCurrency(price)}
        </p>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px] pv:max-sm:text-lg  font-roboto_bold text-white">
            4 days
          </p>

          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_4days",
      key: "_4days",
      render: (price) => (
        <p className={`text-[22px] font-[350] pv:max-ph:text-[16px]`}>
          {formatCurrency(price)}
        </p>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px] pv:max-sm:text-lg  font-roboto_bold text-white">
            5 days
          </p>

          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_5days",
      key: "_5days",
      render: (price) => (
        <p className={`text-[22px] font-[350] pv:max-ph:text-[16px]`}>
          {formatCurrency(price)}
        </p>
      ),
    },
  ];

  const columnsTypeClubs = [
    // {
    //   title: (
    //     <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
    //       No
    //     </p>
    //   ),
    //   dataIndex: "no",
    //   key: "no",
    //   fixed: "left",
    //   render: (no) => (
    //     <p className={`text-[28px] pv:max-ph:text-[16px]`}>{no}</p>
    //   ),
    // },
    {
      title: (
        <p className="text-[28px] pv:max-sm:text-lg  font-roboto_bold text-white">
          Level
        </p>
      ),
      dataIndex: "level",
      key: "level",
      fixed: "left",
      render: (level) => (
        <p className={`text-[28px] pv:max-ph:text-[16px]`}>{level}</p>
      ),
    },

    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px]  pv:max-sm:text-lg  font-roboto_bold text-white">
            2 days
          </p>
          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_2days",
      key: "_2days",
      render: (text) => (
        <div className="">
          <Checkbox value={text}> {formatCurrency(text)}</Checkbox>
        </div>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px] pv:max-sm:text-lg  font-roboto_bold text-white">
            3 days
          </p>
          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_3days",
      key: "_3days",
      render: (text) => (
        <Checkbox value={text}>{formatCurrency(text)}</Checkbox>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px] pv:max-sm:text-lg  font-roboto_bold text-white">
            4 days
          </p>
          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_4days",
      key: "_4days",
      render: (text) => (
        <Checkbox value={text}>{formatCurrency(text)}</Checkbox>
      ),
    },
    {
      title: (
        <div className="flex flex-col gap-0">
          <p className="text-[28px]  pv:max-sm:text-lg  font-roboto_bold text-white">
            5 days
          </p>
          <p className="text-[16px] pv:max-sm:text-lg  text-white">(course)</p>
        </div>
      ),
      dataIndex: "_5days",
      key: "_5days",
      render: (text) => (
        <Checkbox value={text}>{formatCurrency(text)}</Checkbox>
      ),
    },
  ];

  const columnsSelctDate = [
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          -
        </p>
      ),
      dataIndex: "-",
      key: "-",
      fixed: "left",
      render: (no) => (
        <p className={`text-[28px] pv:max-ph:text-[16px]`}>{no}</p>
      ),
    },
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Mon
        </p>
      ),
      dataIndex: "monday",
      key: "monday",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },

    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Tue
        </p>
      ),

      dataIndex: "tuesday",
      key: "two",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Wed
        </p>
      ),
      dataIndex: "wednesday",
      key: "wednesday",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Thu
        </p>
      ),
      dataIndex: "thusday",
      key: "thusday",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Fri
        </p>
      ),
      dataIndex: "friday",
      key: "friday",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Sat
        </p>
      ),
      dataIndex: "saturday",
      key: "saturday",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },
    {
      title: (
        <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
          Sun
        </p>
      ),
      dataIndex: "sun",
      key: "sun",
      render: (text) => <Checkbox value={text}>{text}</Checkbox>,
    },
  ];

  const dataSource_3 = [
    {
      key: "1",
      "-": "9:00 - 10:00",
    },
    {
      key: "2",
      "-": "10:00 - 11:00",
    },
    {
      key: "3",
      "-": "11:00 - 12:00",
    },

    {
      key: "4",
      "-": "14:00 - 15:00",
    },
    {
      key: "5",
      "-": "15:00 - 16:00",
    },
    {
      key: "6",
      "-": "16:00 - 17:00",
    },
    {
      key: "7",
      "-": "17:00 - 18:00",
    },
  ];

  // effect data
  useEffect(() => {
    const fetchSecondStage = async () => {
      const response = await apisecondstage("Club");

      if (response?.status === 201 || response?.status === 200) {
        setData(response.data.data);
        setDataCost(response.data.data.cost);
        setdataSelectlevel(response.data.data.cost);
      }
    };
    fetchSecondStage();
    window.addEventListener("resize", getSize);
    if (width <= 480) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    fetchSecondStage();
    return () => {
      window.removeEventListener("resize", getSize);
    };
  }, [window.innerWidth]);

  //  function
  const handleClickSubmit = () => {
    if (!user) {
      navigate("../" + path.LOGIN);
    } else if (user != null || user != undefined) {
      notification.success({
        description: "Your request has been submitted",
        message: "message",
        placement: "bottom",
        duration: 2,
      });
      navigate("../" + path.HOME);
    }
  };

  const mockDataClub = {
    level: {
      level1: {
        img: "https://aquaticshub.com/flying-fish/images/c61fb9a8b29a156a2569a0557238469a.jpg",
        name: "Development Squad - Learning to Train",
        detail:
          "The Development squad is for ages 9 and under. All swimmers must have a good basic knowledge of two or three strokes. More importantly, they must have a keen interest in learning more and great attitude to others and their own progression.",
        additionalInfo: [
          { title: "Up to 4 sessions", description: formatCurrency(2500000) },
        ],
      },
      level2: {
        img: "https://aquaticshub.com/flying-fish/images/c5238fd6bb32272704b86e8b989a1410.jpg",
        name: "Purple Squad - Training to Train",
        detail:
          "The Purple squad is for ages 9 to 11. The expectation is that all swimmers are technically efficient in at least three strokes. At the beginning of the year they will be assessed on potential, attitude, ability and speed.",
        additionalInfo: [
          { title: "Up to 4 sessions", description: formatCurrency(2500000) },
          { title: "5 sessions", description: formatCurrency(3000000) },
        ],
      },
      level3: {
        img: "https://aquaticshub.com/flying-fish/images/c3ff7364f4aca4005cf56dac7994c475.jpg",
        name: "Bronze Squad - Training to Compete",
        detail:
          "The Bronze squad is for ages 11+. The main focus being on developing racing technique and ‘training to compete’. All swimmers need to have a good understanding of all strokes, turns and starts.",
        additionalInfo: [
          { title: "Up to 4 sessions", description: formatCurrency(2500000) },
          { title: "5 sessions", description: formatCurrency(3000000) },
        ],
      },
      level4: {
        img: "https://aquaticshub.com/flying-fish/images/789d7ae5aa68e354c4735d7f99accec4.jpg",
        name: "Silver Squad",
        detail:
          "The Silver squad will focus primarily on technique, endurance and speed for ‘training to compete’. All swimmers need to swim all strokes at a good level. Swimmers will be able to compete in a range of strokes and distances at this level.",
        additionalInfo: [
          { title: "Up to 4 sessions", description: formatCurrency(2500000) },
          { title: "5 sessions", description: formatCurrency(3000000) },
        ],
      },
      level5: {
        img: "	https://aquaticshub.com/flying-fish/images/aacaaa2827b3794838d91bbcf898c24d.jpg",
        name: "Gold Squad",
        detail:
          "The top standard level is the Gold Squad. This squad is made up of advanced swimmers which are continuing the swimmer pathway of ‘training to compete’. Commitment to training and competition should be high and many advanced drills and techniques are learnt.",
        additionalInfo: [
          { title: "Up to 4 sessions", description: formatCurrency(2500000) },
          { title: "5 sessions", description: formatCurrency(3000000) },
        ],
      },
      level6: {
        img: "https://aquaticshub.com/flying-fish/images/b8d41fa9b2fa576d776df23098a6aba2.jpg",
        name: "Elite Squad",
        detail:
          "Elite squad members focus on refining their skills into race efficiencies and striving towards National age-group qualifying times. The increased training regime allows swimmers to refine speed, technique, and stroke. This Squad will generally train daily and have individual nutrition and land trying programs as standard.",
        additionalInfo: [
          { title: "Up to 7 sessions", description: formatCurrency(3000000) },
        ],
      },
    },
  };

  const mockDataClub_ = [
    {
      code: "000000001",
      description: "Development Squad - Learning to Train",
      detail:
        "The Development squad is for ages 9 and under. All swimmers must have a good basic knowledge of two or three strokes. More importantly, they must have a keen interest in learning more and great attitude to others and their own progression.",
      price: [{ duration: "Up to 4 sessions", classPrice: 2500000 }],
    },
    {
      code: "000000002",
      description: "Purple Squad - Training to Train",
      detail:
        "The Purple squad is for ages 9 to 11. The expectation is that all swimmers are technically efficient in at least three strokes. At the beginning of the year they will be assessed on potential, attitude, ability and speed.",
      price: [
        { duration: "Up to 4 sessions", classPrice: 2500000 },
        { duration: "5 sessions", classPrice: 3000000 },
      ],
    },
    {
      code: "000000003",
      description: "Bronze Squad - Training to Compete",
      detail:
        "The Bronze squad is for ages 11+. The main focus being on developing racing technique and ‘training to compete’. All swimmers need to have a good understanding of all strokes, turns and starts.",
      price: [
        { duration: "Up to 4 sessions", classPrice: 2500000 },
        { duration: "5 sessions", classPrice: 3000000 },
      ],
    },
    {
      code: "000000001",
      description: "Silver Squad",
      detail:
        "The Silver squad will focus primarily on technique, endurance and speed for ‘training to compete’. All swimmers need to swim all strokes at a good level. Swimmers will be able to compete in a range of strokes and distances at this level.",
      price: [
        { duration: "Up to 4 sessions", classPrice: 2500000 },
        { duration: "5 sessions", classPrice: 3000000 },
      ],
    },
    {
      code: "000000002",
      description: "Gold Squad",
      detail:
        "The top standard level is the Gold Squad. This squad is made up of advanced swimmers which are continuing the swimmer pathway of ‘training to compete’. Commitment to training and competition should be high and many advanced drills and techniques are learnt. ",
      price: [
        { duration: "Up to 4 sessions", classPrice: 2500000 },
        { duration: "5 sessions", classPrice: 3000000 },
      ],
    },
    {
      code: "000000003",
      description: "Elite Squad",
      detail:
        "Elite squad members focus on refining their skills into race efficiencies and striving towards National age-group qualifying times. The increased training regime allows swimmers to refine speed, technique, and stroke. This Squad will generally train daily and have individual nutrition and land trying programs as standard.",
      price: [{ duration: "Up to 7 sessions", classPrice: 3000000 }],
    },
  ];

  const [sessionColumns, setSessionColumns] = useState([
    {
      key: "perWeek",
      title: <p className="font-roboto_bold">Per week</p>,
      dataIndex: "perWeek",
    },
    {
      key: "squad",
      title: <p className="font-roboto_bold">Squad</p>,
      dataIndex: "squad",
    },
    {
      key: "information",
      title: <p className="font-roboto_bold">Information</p>,
      dataIndex: "information",
    },
    {
      key: "autumnSession",
      title: <p className="font-roboto_bold">Autumn Session</p>,
      dataIndex: "autumnSession",
    },
    {
      key: "costMonth",
      title: <p className="font-roboto_bold">Cost/ month</p>,
      dataIndex: "costMonth",
    },
    {
      key: "sessionMonth",
      title: <p className="font-roboto_bold">Sessions/ month</p>,
      dataIndex: "sessionMonth",
    },
    {
      key: "perSession",
      title: <p className="font-roboto_bold">Per Session</p>,
      dataIndex: "perSession",
    },
  ]);

  const [sessionData, setSessionData] = useState([
    {
      key: "1",
      perWeek: "2 Sessions",
      squad: "n/a",
      information: "3 Months - 12 Weeks",
      autumnSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "8",
      perSession: formatCurrency(250000),
    },
    {
      key: "2",
      perWeek: "3 Sessions",
      squad: "n/a",
      information: "3 Months - 12 Weeks",
      autumnSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "12",
      perSession: formatCurrency(167000),
    },
    {
      key: "3",
      perWeek: "4 Sessions",
      squad: "Dev, Bl, Br, S, G",
      information: "3 Months - 12 Weeks",
      autumnSession: formatCurrency(6000000),
      costMonth: formatCurrency(2000000),
      sessionMonth: "16",
      perSession: formatCurrency(125000),
    },
    {
      key: "4",
      perWeek: "5 Sessions",
      squad: "Bl, Br, S, G, E",
      information: "3 Months - 12 Weeks",
      autumnSession: formatCurrency(7500000),
      costMonth: formatCurrency(2500000),
      sessionMonth: "20",
      perSession: formatCurrency(125000),
    },
    {
      key: "5",
      perWeek: "7 Sessions",
      squad: "Elite ",
      information: "3 Months - 12 Weeks",
      autumnSession: formatCurrency(9000000),
      costMonth: formatCurrency(3000000),
      sessionMonth: "28",
      perSession: formatCurrency(107000),
    },
  ]);

  const columnsAutumn = [
    { key: "perWeek", title: "Per week", dataIndex: "perWeek" },
    { key: "squad", title: "Squad", dataIndex: "squad" },
    {
      key: "information",
      title: "Information",
      dataIndex: "information",
    },
    {
      key: "autumnSession",
      title: "Autumn Session",
      dataIndex: "autumnSession",
    },
    { key: "costMonth", title: "Cost/ month", dataIndex: "costMonth" },
    {
      key: "sessionMonth",
      title: "Sessions/ month",
      dataIndex: "sessionMonth",
    },
    { key: "perSession", title: "Per Session", dataIndex: "perSession" },
  ];

  const dataAutumn = [
    {
      key: "1",
      perWeek: "2 Sessions",
      squad: "n/a",
      information: "3 Months - 12 Weeks",
      autumnSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "8",
      perSession: formatCurrency(250000),
    },
    {
      key: "2",
      perWeek: "3 Sessions",
      squad: "n/a",
      information: "3 Months - 12 Weeks",
      autumnSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "12",
      perSession: formatCurrency(167000),
    },
    {
      key: "3",
      perWeek: "4 Sessions",
      squad: "Dev, Bl, Br, S, G",
      information: "3 Months - 12 Weeks",
      autumnSession: formatCurrency(6000000),
      costMonth: formatCurrency(2000000),
      sessionMonth: "16",
      perSession: formatCurrency(125000),
    },
    {
      key: "4",
      perWeek: "5 Sessions",
      squad: "Bl, Br, S, G, E",
      information: "3 Months - 12 Weeks",
      autumnSession: formatCurrency(7500000),
      costMonth: formatCurrency(2500000),
      sessionMonth: "20",
      perSession: formatCurrency(125000),
    },
    {
      key: "5",
      perWeek: "7 Sessions",
      squad: "Elite ",
      information: "3 Months - 12 Weeks",
      autumnSession: formatCurrency(9000000),
      costMonth: formatCurrency(3000000),
      sessionMonth: "28",
      perSession: formatCurrency(107000),
    },
  ];

  const columnsWinter = [
    { key: "perWeek", title: "Per week", dataIndex: "perWeek" },
    { key: "squad", title: "Squad", dataIndex: "squad" },
    {
      key: "information",
      title: "Information",
      dataIndex: "information",
    },
    {
      key: "winterSession",
      title: "Winter Session",
      dataIndex: "winterSession",
    },
    { key: "costMonth", title: "Cost/ month", dataIndex: "costMonth" },
    {
      key: "sessionMonth",
      title: "Sessions/ month",
      dataIndex: "sessionMonth",
    },
    { key: "perSession", title: "Per Session", dataIndex: "perSession" },
  ];

  const dataWinter = [
    {
      key: "1",
      perWeek: "2 Sessions",
      squad: "n/a",
      information: "2.5 Months - 10 Weeks",
      winterSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "8",
      perSession: formatCurrency(250000),
    },
    {
      key: "2",
      perWeek: "3 Sessions",
      squad: "n/a",
      information: "2.5 Months - 10 Weeks",
      winterSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "12",
      perSession: formatCurrency(167000),
    },
    {
      key: "3",
      perWeek: "4 Sessions",
      squad: "Dev, Bl, Br, S, G",
      information: "2.5 Months - 10 Weeks",
      winterSession: formatCurrency(5000000),
      costMonth: formatCurrency(2000000),
      sessionMonth: "16",
      perSession: formatCurrency(125000),
    },
    {
      key: "4",
      perWeek: "5 Sessions",
      squad: "Bl, Br, S, G, E",
      information: "2.5 Months - 10 Weeks",
      winterSession: formatCurrency(6250000),
      costMonth: formatCurrency(2500000),
      sessionMonth: "20",
      perSession: formatCurrency(125000),
    },
    {
      key: "5",
      perWeek: "7 Sessions",
      squad: "Elite ",
      information: "2.5 Months - 10 Weeks",
      winterSession: formatCurrency(7500000),
      costMonth: formatCurrency(3000000),
      sessionMonth: "28",
      perSession: formatCurrency(107000),
    },
  ];

  const columnsSpring = [
    { key: "perWeek", title: "Per week", dataIndex: "perWeek" },
    { key: "squad", title: "Squad", dataIndex: "squad" },
    {
      key: "information",
      title: "Information",
      dataIndex: "information",
    },
    {
      key: "springSession",
      title: "Sping Session",
      dataIndex: "springSession",
    },
    { key: "costMonth", title: "Cost/ month", dataIndex: "costMonth" },
    {
      key: "sessionMonth",
      title: "Sessions/ month",
      dataIndex: "sessionMonth",
    },
    { key: "perSession", title: "Per Session", dataIndex: "perSession" },
  ];

  const dataSpring = [
    {
      key: "1",
      perWeek: "2 Sessions",
      squad: "n/a",
      information: "4 Months - 15 Weeks",
      springSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "8",
      perSession: formatCurrency(250000),
    },
    {
      key: "2",
      perWeek: "3 Sessions",
      squad: "n/a",
      information: "4 Months - 15 Weeks",
      springSession: "n/a",
      costMonth: "n/a",
      sessionMonth: "12",
      perSession: formatCurrency(167000),
    },
    {
      key: "3",
      perWeek: "4 Sessions",
      squad: "Dev, Bl, Br, S, G",
      information: "4 Months - 15 Weeks",
      springSession: formatCurrency(8000000),
      costMonth: formatCurrency(2000000),
      sessionMonth: "16",
      perSession: formatCurrency(125000),
    },
    {
      key: "4",
      perWeek: "5 Sessions",
      squad: "Bl, Br, S, G, E",
      information: "4 Months - 15 Weeks",
      springSession: formatCurrency(10000000),
      costMonth: formatCurrency(2500000),
      sessionMonth: "20",
      perSession: formatCurrency(125000),
    },
    {
      key: "5",
      perWeek: "7 Sessions",
      squad: "Elite ",
      information: "4 Months - 15 Weeks",
      springSession: formatCurrency(12000000),
      costMonth: formatCurrency(3000000),
      sessionMonth: "28",
      perSession: formatCurrency(107000),
    },
  ];

  const [firstloaded, setfirstloaded] = useState(true);
  const setSelectedSession = (e) => {
    setfirstloaded(false);
    switch (e) {
      case "Autumn":
        setSessionColumns([
          { key: "perWeek", title: "Per week", dataIndex: "perWeek" },
          { key: "squad", title: "Squad", dataIndex: "squad" },
          {
            key: "information",
            title: "Information",
            dataIndex: "information",
          },
          {
            key: "autumnSession",
            title: "Autumn Session",
            dataIndex: "autumnSession",
          },
          { key: "costMonth", title: "Cost/ month", dataIndex: "costMonth" },
          {
            key: "sessionMonth",
            title: "Sessions/ month",
            dataIndex: "sessionMonth",
          },
          { key: "perSession", title: "Per Session", dataIndex: "perSession" },
        ]);
        setSessionData([
          {
            key: "1",
            perWeek: "2 Sessions",
            squad: "n/a",
            information: "3 Months - 12 Weeks",
            autumnSession: "n/a",
            costMonth: "n/a",
            sessionMonth: "8",
            perSession: formatCurrency(250000),
          },
          {
            key: "2",
            perWeek: "3 Sessions",
            squad: "n/a",
            information: "3 Months - 12 Weeks",
            autumnSession: "n/a",
            costMonth: "n/a",
            sessionMonth: "12",
            perSession: formatCurrency(167000),
          },
          {
            key: "3",
            perWeek: "4 Sessions",
            squad: "Dev, Bl, Br, S, G",
            information: "3 Months - 12 Weeks",
            autumnSession: formatCurrency(6000000),
            costMonth: formatCurrency(2000000),
            sessionMonth: "16",
            perSession: formatCurrency(125000),
          },
          {
            key: "4",
            perWeek: "5 Sessions",
            squad: "Bl, Br, S, G, E",
            information: "3 Months - 12 Weeks",
            autumnSession: formatCurrency(7500000),
            costMonth: formatCurrency(2500000),
            sessionMonth: "20",
            perSession: formatCurrency(125000),
          },
          {
            key: "5",
            perWeek: "7 Sessions",
            squad: "Elite ",
            information: "3 Months - 12 Weeks",
            autumnSession: formatCurrency(9000000),
            costMonth: formatCurrency(3000000),
            sessionMonth: "28",
            perSession: formatCurrency(107000),
          },
        ]);
        break;
      case "Winter":
        setSessionColumns([
          { key: "perWeek", title: "Per week", dataIndex: "perWeek" },
          { key: "squad", title: "Squad", dataIndex: "squad" },
          {
            key: "information",
            title: "Information",
            dataIndex: "information",
          },
          {
            key: "winterSession",
            title: "Winter Session",
            dataIndex: "winterSession",
          },
          { key: "costMonth", title: "Cost/ month", dataIndex: "costMonth" },
          {
            key: "sessionMonth",
            title: "Sessions/ month",
            dataIndex: "sessionMonth",
          },
          { key: "perSession", title: "Per Session", dataIndex: "perSession" },
        ]);
        setSessionData([
          {
            key: "1",
            perWeek: "2 Sessions",
            squad: "n/a",
            information: "2.5 Months - 10 Weeks",
            winterSession: "n/a",
            costMonth: "n/a",
            sessionMonth: "8",
            perSession: formatCurrency(250000),
          },
          {
            key: "2",
            perWeek: "3 Sessions",
            squad: "n/a",
            information: "2.5 Months - 10 Weeks",
            winterSession: "n/a",
            costMonth: "n/a",
            sessionMonth: "12",
            perSession: formatCurrency(167000),
          },
          {
            key: "3",
            perWeek: "4 Sessions",
            squad: "Dev, Bl, Br, S, G",
            information: "2.5 Months - 10 Weeks",
            winterSession: formatCurrency(5000000),
            costMonth: formatCurrency(2000000),
            sessionMonth: "16",
            perSession: formatCurrency(125000),
          },
          {
            key: "4",
            perWeek: "5 Sessions",
            squad: "Bl, Br, S, G, E",
            information: "2.5 Months - 10 Weeks",
            winterSession: formatCurrency(6250000),
            costMonth: formatCurrency(2500000),
            sessionMonth: "20",
            perSession: formatCurrency(125000),
          },
          {
            key: "5",
            perWeek: "7 Sessions",
            squad: "Elite ",
            information: "2.5 Months - 10 Weeks",
            winterSession: formatCurrency(7500000),
            costMonth: formatCurrency(3000000),
            sessionMonth: "28",
            perSession: formatCurrency(107000),
          },
        ]);
        break;
      case "Spring":
        setSessionColumns([
          { key: "perWeek", title: "Per week", dataIndex: "perWeek" },
          { key: "squad", title: "Squad", dataIndex: "squad" },
          {
            key: "information",
            title: "Information",
            dataIndex: "information",
          },
          {
            key: "springSession",
            title: "Sping Session",
            dataIndex: "springSession",
          },
          { key: "costMonth", title: "Cost/ month", dataIndex: "costMonth" },
          {
            key: "sessionMonth",
            title: "Sessions/ month",
            dataIndex: "sessionMonth",
          },
          { key: "perSession", title: "Per Session", dataIndex: "perSession" },
        ]);
        setSessionData([
          {
            key: "1",
            perWeek: "2 Sessions",
            squad: "n/a",
            information: "4 Months - 15 Weeks",
            springSession: "n/a",
            costMonth: "n/a",
            sessionMonth: "8",
            perSession: formatCurrency(250000),
          },
          {
            key: "2",
            perWeek: "3 Sessions",
            squad: "n/a",
            information: "4 Months - 15 Weeks",
            springSession: "n/a",
            costMonth: "n/a",
            sessionMonth: "12",
            perSession: formatCurrency(167000),
          },
          {
            key: "3",
            perWeek: "4 Sessions",
            squad: "Dev, Bl, Br, S, G",
            information: "4 Months - 15 Weeks",
            springSession: formatCurrency(8000000),
            costMonth: formatCurrency(2000000),
            sessionMonth: "16",
            perSession: formatCurrency(125000),
          },
          {
            key: "4",
            perWeek: "5 Sessions",
            squad: "Bl, Br, S, G, E",
            information: "4 Months - 15 Weeks",
            springSession: formatCurrency(10000000),
            costMonth: formatCurrency(2500000),
            sessionMonth: "20",
            perSession: formatCurrency(125000),
          },
          {
            key: "5",
            perWeek: "7 Sessions",
            squad: "Elite ",
            information: "4 Months - 15 Weeks",
            springSession: formatCurrency(12000000),
            costMonth: formatCurrency(3000000),
            sessionMonth: "28",
            perSession: formatCurrency(107000),
          },
        ]);
        break;

      default:
        break;
    }
  };

  const mockDataSessionAndCost = [
    {
      description: "Summer Session - 12 Week Course",
      // date: "Monday 03/06/2024 - Sunday 25/08/2024",
      // registrationDate: "Monday 14/08/2024 - Wednesday 23/08/2024",
      date: "03.06.2024 - 25.08.2024",
      registrationDate: "14.08.2024",
      status: "Open",
      cost: [
        { duration: "up to 4 sessions/ week", cost: 6000000 },
        { duration: "of 5 sessions/ week", cost: 7500000 },
        { duration: "of Elite squad", cost: 9000000 },
      ],
    },
    {
      description: "Autumn Session - 12 Week Course",
      // date: "Monday 28/08/2024 - Sunday 17/11/2024",
      // registrationDate: "Monday 14/08/2024 - Wednesday 23/08/2024",
      date: "28.08.2024 - 17.11.2024",
      registrationDate: "14.08.2024",
      status: "Not Open",
      cost: [
        { duration: "up to 4 sessions/ week", cost: 6000000 },
        { duration: "of 5 sessions/ week", cost: 7500000 },
        { duration: "of Elite squad", cost: 9000000 },
      ],
    },
    {
      description: `Spring Session - 10 Week Course/ ${formatCurrency(
        3000000
      )}`,
      // date: "Monday 18/11/2024 - Sunday 26/01/2025",
      // registrationDate: "Monday 04/11/2024 - Wednesday 13/11/2024",
      date: "18.11.2024 - 26.01.2025",
      registrationDate: "04.11.2024",
      status: "Not Open",
      cost: [
        { duration: "up to 4 sessions/ week", cost: 5000000 },
        { duration: "of 5 sessions/ week", cost: 6250000 },
        { duration: "of Elite squad", cost: 7500000 },
      ],
    },
    {
      description: `Winter Session - 15 Week Course/ ${formatCurrency(
        4500000
      )}`,
      // date: "Monday 03/02/2025 - Sunday 25/05/2025",
      // registrationDate: "Wednesday 29/01/2025",
      date: "03.02.2025 - 25.05.2025",
      registrationDate: "29.01.2024",
      status: "Not Open",
      cost: [
        { duration: "up to 4 sessions/ week", cost: 8000000 },
        { duration: "of 5 sessions/ week", cost: 10000000 },
        { duration: "of Elite squad", cost: 12000000 },
      ],
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  const headers = ["Name", "Age", "Email"];
  const data_ = [
    ["John Doe", 30, "john@example.com"],
    ["Jack", 30, "john@example.com"],
    ["Jane", 30, "john@example.com"],
    ["Jane Smith", 25, "jane@example.com"],
    ["Bob Johnson", 40, "bob@example.com"],
  ];

  const columns = [
    { key: 1, title: "", dataIndex: "lane1" },
    { key: 2, title: "", dataIndex: "lane2" },
    { key: 3, title: "", dataIndex: "lane3" },
    { key: 4, title: "", dataIndex: "lane4" },
  ];

  const ScheduleTable = ({ title, data, columns }) => (
    <div className="w-full mb-4 mt-8 flex flex-col shadow-md rounded-lg overflow-hidden border border-gray-200">
      <p className="font-roboto_bold text-xl p-2 bg-gray-100 text-gray-700">
        {title}
      </p>
      <Table
        showHeader={false}
        pagination={false}
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content" }}
      />
    </div>
  );

  const [isOpenRequestTrial, setIsOpenRequestTrial] = useState(false);
  const handleOkRequestTrial = async (data) => {
    setIsOpenRequestTrial(!isOpenRequestTrial);
    if (
      data.email != "" &&
      data.phone != "" &&
      data.name != "" &&
      data.request != ""
    ) {
      await apiContactUs(data)
        .then((res) => {
          notification.success({
            message: `Your request has been submitted`,
            placement: "topRight",
            duration: 2,
          });
        })
        .catch((err) => {
          notification.error({
            message: "Something has occurred, please try again",
            placement: "topRight",
            duration: 2,
          });
          console.error(err.message);
        });
    } else {
      notification.info({
        message: "Please fill all information",
        placement: "topRight",
        duration: 2,
      });
    }
  };
  const handleCancelRequestTrial = (data) => {
    setIsOpenRequestTrial(!isOpenRequestTrial);
  };

  const handleRequestTrial = (props) => {
    setIsOpenRequestTrial(true);
  };

  // const handleToThirdStage = () =>
  //   navigate("../" + path.CLUBS, {
  //     state: {
  //       data: DataState,
  //     },
  //   })

  const mockDataTable = [
    {
      key: "1",
      row_1: t("Club_Monday"),
      row_2: t("Club_Dev"),
      row_3: "",
      row_4: "",
      row_5: "",
      row_6: "",
      row_7: t("Elitie"),
    },
    {
      key: "2",
      row_1: t("Club_Tue"),
      row_2: "",
      row_3: t("Club_purple"),
      row_4: t("Club_bronze"),
      row_5: t("Club_silver"),
      row_6: t("Club_Gold"),
      row_7: t("Club_Elitie"),
    },
    {
      key: "3",
      row_1: t("Club_wed"),
      row_2: t("Club_Dev"),
      row_3: "",
      row_4: "",
      row_5: "",
      row_6: "",
      row_7: t("Elitie"),
    },
    {
      key: "4",
      row_1: t("Club_Th"),
      row_2: "",
      row_3: t("Club_purple"),
      row_4: t("Club_bronze"),
      row_5: t("Club_silver"),
      row_6: t("Club_Gold"),
      row_7: t("Club_Elitie"),
    },
    {
      key: "5",
      row_1: t("Club_Fri"),
      row_2: "",
      row_3: t("Club_purple"),
      row_4: t("Club_bronze"),
      row_5: t("Club_silver"),
      row_6: t("Club_Gold"),
      row_7: t("Club_Elitie"),
    },
    {
      key: "6",
      row_1: t("Club_Sa"),
      row_2: t("Club_Dev"),
      row_3: t("Club_purple"),
      row_4: t("Club_bronze"),
      row_5: t("Club_silver"),
      row_6: t("Club_Gold"),
      row_7: t("Club_Elitie"),
    },
    {
      key: "7",
      row_1: t("Club_Su"),
      row_2: t("Club_Dev"),
      row_3: t("Club_purple"),
      row_4: t("Club_bronze"),
      row_5: t("Club_silver"),
      row_6: t("Club_Gold"),
      row_7: t("Club_Elitie"),
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-5 w-[65%] mx-auto pt-[120px] gap-10 pv:max-dv:w-[95%] pv:max-dv:gap-1 pv:max-dv:pt-4 md:max-dv:pt-[100px] ">
        <div className="col-span-2">
          <div className="pb-4">
            <p className="text-[#575958] text-5xl w-[70%] pv:max-dv:text-[3vw] pv:max-dv:w-[100%] ">
              {/* {DataState.title} */}
              FLYING FISH ​SWIM CLUB
              {/* <img
                className="absolute object-cover top-[-120px] right-[-180px] h-[20vw] w-[20vw]"
                src="https://aquaticshub.com/flying-fish/images/a16cff6c0348138cfa84d5bf83af04b8.png"
              ></img> */}
            </p>
          </div>
          <div className=" text-[#391b0b]   w-[100%] text-[24px] flex flex-col gap-10 pv:max-dv:text-[2vw]  pv:max-dv:gap-2 pv:max-md:font-semibold">
            <p className=" ">
              Welcome to Flying Fish Swim Club, ​where passion meets potential!
              We're ​more than a swim club; we're a ​community committed to
              nurturing ​young swimmers with the attitude, ​drive, and potential
              to excel in the ​pool. We promote a long term journey ​through the
              sport of swimming.
            </p>
            <p className="">
              Our culture is not just to teach our ​swimmers how to swim
              competitively ​but to understand the process of ​reaching personal
              goals in an ​environment that is fun and fulfilling. ​We aim not
              to just produce swimmers ​who learn ‘how’ to swim fast and
              efficiently but developing intelligent ​athletes who understand
              ‘why’ they ​enjoy swimming.
            </p>
            <p className="">
              All squads will participate in local ​competitions with higher
              levels offered ​the chance to take part in{" "}
              <b>INTERNATIONAL COMPETITIONS.</b>
            </p>
            <p className="">
              All swimmers need to participate in a ​tryout to be allocated a
              squad place.
            </p>
          </div>
        </div>
        <div className="w-full h-full  object-cover col-span-3 flex flex-col gap-10 pv:max-dv:gap-4 ">
          {data.gallery.map((item, index) => {
            return (
              <div className="h-full">
                <img src={item} className="object-cover h-full" />
              </div>
            );
          })}
        </div>
      </div>

      <div className="pt-[20px] pv:max-ph:pt-[20px] md:max-lg:pt-[65px] lg:max-dh:pt-[75px]  ">
        <div className="">
          <div className="bg-black flex flex-col gap-10 py-10">
            <div className=" w-[60%] pv:max-ph:w-[90%] md:max-lg:w-[80%] mx-auto">
              <p className="text-white text-5xl pv:max-ph:text-3xl pv:max-ph:text-start underline">
                {t("Club_FlyingSquad")}
              </p>
            </div>
            <div className=" w-[60%] pv:max-ph:w-[90%] md:max-lg:w-[80%] mx-auto grid grid-cols-3 text-white gap-6 pv:max-md:grid-cols-1 md:max-xl:grid-cols-2 gap-y-20">
              {data.nowClass.map((item, index) => {
                return (
                  <div className="justify-center bg-white ">
                    <img
                      className="h-[300px] w-full object-cover"
                      src={item.pictureUrl}
                    ></img>
                    <div className="m-4 flex flex-col gap-4">
                      <p className="text-[#1b4235] font-bold text-2xl">
                        {item.title}
                      </p>
                      <p className="text-[#391b0b] text-lg">{item.detail}</p>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div className="w-[90%] mx-auto flex items-center justify-center gap-[10px] pv:max-lg:flex pv:max-lg:flex-wrap py-10">
              <button
                className="w-[150px] pv:max-lg:w-[45%] p-4 px-2 bg-[#dcd3d1] rounded-lg text-black hover:bg-secondary2 hover:text-white"
                onClick={() => handleRequestTrial()}
              >
                {t("Club_RequestTrial")}
              </button>
              <button
                className="w-[200px] pv:max-lg:w-[45%] p-4 px-2 bg-[#dcd3d1] rounded-lg text-black hover:bg-secondary2 hover:text-white"
                onClick={() => showModal()}
              >
                {t("Club_MorePricing")}
              </button>
              <button className="w-[300px] pv:max-lg:w-[92%] p-4 px-2 bg-[#dcd3d1] rounded-lg text-black hover:bg-secondary2 hover:text-white">
                {t("Club_ViewWeekSession")}
              </button>
            </div> */}
          </div>

          {/* <div className="py-10">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold ">
                {t("Club_SessionDateCost")}
              </p>
            </div>
            <div className="w-[90%] mx-auto grid grid-cols-4 pv:max-md:grid-cols-1 md:max-lg:grid-cols-2 lg:max-xl:grid-cols-3 place-items-end pv:max-xl:place-items-center">
              {mockDataSessionAndCost?.map((item, index) => {
                const startDate = item.date.split(" - ")[0];
                const endDate = item.date.split(" - ")[1];
                return (
                  <CardSession
                    registrationDate={item.registrationDate}
                    description={item.description}
                    startDate={startDate}
                    endDate={endDate}
                  />
                );
              })}
            </div>
          </div> */}

          {/* <div className="flex justify-center pb-10">
            <button
              className="w-[150px] pv:max-lg:w-[45%] p-4 px-2 bg-black rounded-lg text-primary hover:bg-secondary hover:text-black"
              onClick={() =>
                (window.location = "mailto:contact@aquaticshub.vn")
              }
            >
              <p className=" font-roboto_bold ">{t("AdultCourse_register")}</p>
            </button>
          </div> */}

          <div className="bg-[#8c52ff] w-full  py-10">
            {/* <div className=" ">
              <div className="  mx-auto flex flex-col justify-start items-start ">
                <p className="text-white text-5xl ">
                  {t("Club_FlyingFishSche")}
                </p>
              </div>
              <div className="grid grid-cols-7 w-[100%] mx-auto gap-3">
                {mockDataTable.map((item, index) => {
                  return (
                    <div className="flex flex-col gap-3">
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-black flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_1}</p>
                      </div>
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#2c2c2c] flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_2}</p>
                      </div>
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#2c2c2c] flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_3}</p>
                      </div>
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#2c2c2c]  flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_4}</p>
                      </div>
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#2c2c2c]  flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_5}</p>
                      </div>
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#2c2c2c]  flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_6}</p>
                      </div>
                      <div className="pv:max-lg:h-[8vw] h-[100px]  w-full bg-[#2c2c2c]  flex justify-center items-center">
                        <p className="text-[1vw] text-white">{item.row_7}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}

            <div className="w-[60%] mx-auto flex flex-col gap-10  pv:max-md:w-[90%]">
              <div className="">
                <p className="underline text-white text-4xl">
                  SQUAD STANDARDS - AUGUST 2024
                </p>
              </div>
              <div className="bg-white p-10">
                <img className="" src={imgSchedule}></img>
              </div>
            </div>
          </div>

          <div className="bg-black w-full  py-10">
            <div className="w-[70%] mx-auto flex flex-col gap-10  pv:max-md:w-[90%]">
              <div className="">
                <p className="underline text-white text-4xl">
                  FLYING FISH SQUAD TRAINING TIMES
                </p>
              </div>
              <div className="">
                <img className="" src={imgSchedule2}></img>
              </div>
            </div>
          </div>

          <div className="bg-[#8c52ff] w-full  py-10 ">
            <div className="w-[60%] mx-auto flex flex-col gap-10  pv:max-xl:w-[90%]">
              <p className="uppercase text-white text-5xl underline pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl   ">
                FLYING FISH COST
              </p>
              <p className="uppercase text-white text-5xl font-bold  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                MONTHLY - VND 2,500,000 (INCLUDES ENTRY TICKET)
              </p>
              <div className="uppercase text-white text-5xl  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                <p className="">DEVELOPMENT, PURPLE & BRONZE SQUADS</p>
                <p className="">2 - 4 SESSIONS PER WEEK</p>
              </div>
              <div className="uppercase text-white text-5xl  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                <p className="">SILVER & GOLD SQUADS</p>
                <p className="">2 - 5 SESSIONS PER WEEK</p>
              </div>
              <div className="uppercase text-white text-5xl  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                <p className="">ELITE SQUAD</p>
                <p className="">2 - 6 SESSIONS PER WEEK</p>
              </div>
              <p className="uppercase text-white text-5xl  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
                *CONTACT US IF YOU WOULD LIKE TO BE A PART OF THE ​FLYING FISH
                COMMUNITY BUT UNABLE TO FIND SUITABLE ​COMMITMENT TO THE
                TRAINING SCHEDULE.
              </p>
            </div>
            <div className="uppercase text-white text-5xl pt-40 w-[60%] mx-auto   pv:max-xl:w-[90%]  pv:max-ph:text-2xl ph:max-md:text-3xl md:max-xl:text-4xl">
              <p className="">STARTS MONDAY 12TH AUGUST 2024</p>
            </div>
            <div className="flex flex-col gap-6 w-[300px] mx-auto pt-20">
              <button
                className="bg-[#dcd3d1] py-4 rounded-3xl flex justify-center"
                onClick={() => {
                  if (user) {
                    navigate("../" + path.CLUBS);
                  } else {
                    navigate("../" + path.LOGIN, {
                      state: {
                        redirectCallBack: navigate("../" + path.CLUBS),
                      },
                    });
                  }
                }}
              >
                <p className="text-2xl">BOOK NOW</p>
              </button>
              <button
                className="bg-[#dcd3d1] py-4 rounded-3xl"
                onClick={() => navigate("../" + path.HOME)}
              >
                <p className="text-2xl">HOME</p>
              </button>
            </div>
          </div>

          {/* <div className="w-[90%] mx-auto flex justify-center pb-10">
            <button
              className="w-[150px] pv:max-lg:w-[45%] p-4 px-2 bg-black rounded-lg text-primary hover:bg-secondary hover:text-black"
              onClick={() => window.location = 'mailto:contact@aquaticshub.vn'}
            >
              REGISTER INTEREST
            </button>
          </div> */}

          {/* <div className="py-10 w-[90%] mx-auto flex flex-row justify-start items-start relative">
            <div className="w-[10%] pr-4 flex flex-col cursor-auto sticky top-24">
              <button
                onClick={() => setSelectedSession("Autumn")}
                className={`bg-[#fafafa] focus:text-[#9623F0] text-xl font-roboto_bold py-2 ${firstloaded ? "text-[#9623F0]" : ""
                  }`}
              >
                Autumn
              </button>
              <button
                onClick={() => setSelectedSession("Winter")}
                className={`bg-[#fafafa] focus:text-[#9623F0] text-xl font-roboto_bold py-2`}
              >
                Winter
              </button>
              <button
                onClick={() => setSelectedSession("Spring")}
                className={`bg-[#fafafa] focus:text-[#9623F0] text-xl font-roboto_bold py-2`}
              >
                Spring
              </button>
            </div>
            <Table
              columns={sessionColumns}
              dataSource={sessionData}
              className="w-full club_stage_2"
              pagination={false}
            />
          </div> */}

          {/* <div className="py-10 w-[90%] mx-auto flex flex-row justify-start items-start relative">
            <CustomTable
              columns={sessionColumns}
              data={sessionData}
              rowsGroupBy={""}
            />
          </div> */}

          <Modal
            width={"850px"}
            open={isModalOpen}
            onOk={() => handleOk()}
            onCancel={() => handleCancel()}
          >
            <ScheduleTable
              title="Autumn Session"
              data={dataAutumn}
              columns={columnsAutumn}
            />
            <ScheduleTable
              title="Winter Session"
              data={dataWinter}
              columns={columnsWinter}
            />
            <ScheduleTable
              title="Spring Session"
              data={dataSpring}
              columns={columnsSpring}
            />
          </Modal>

          <ModalRequestTrial
            open={isOpenRequestTrial}
            onOk={handleOkRequestTrial}
            onCancel={handleCancelRequestTrial}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Club);
