/** @format */

import React, { useState } from "react";
import { SidebarAdmin, HeaderAdmin } from "../../components";

import { Table, Select, Column } from "antd";
import { dataAllCourse, columnAllCourse } from "../../utils/data";
import { Icon } from "@iconify/react";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { path } from "../../utils/constant";

const AllCourse = () => {
  const [resize, setResize] = useState(false);
  const handleclick = (value) => {
    setResize(value);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const navigate = useNavigate();
  const handleNavigate = (courseCode) => {
    navigate("../" + path.INSTRUCTORCOURSEDETAIL);
  };
  return (
    <div>
      <div className="pt-[100px]">
        <div className="w-full ">
          <HeaderAdmin />
        </div>
        <div className={`w-full grid grid-cols-4`}>
          <div className={`col-span-1`}>
            <SidebarAdmin handleclick={handleclick} />
          </div>
          <div className="col-span-3 my-10">
            {/* <div className="w-[95%] mx-auto flex justify-between items-center p-6 bg-white rounded-t-lg ">
              <p className="text-2xl font-bold">ALL Course</p>
              <div className="flex gap-2">
                <button className=" py-2 flex justify-center gap-2 items-center  text-secondary1 px-4  rounded-lg">
                  <Icon icon={"fa6-solid:file-excel"}></Icon>
                  <p className="">Export Report</p>
                </button>
                <button className=" py-2 flex justify-center gap-2 items-center bg-secondary1 text-white px-4  rounded-lg">
                  <Icon icon={"ic:baseline-plus"}></Icon>
                  <p className="">Add Course</p>
                </button>
                <button className=" py-2 flex justify-center gap-2 items-center bg-white border-[2px] boder-[#0B2470] text-secondary1 px-4  rounded-lg">
                  <Icon icon={"ic:baseline-plus"}></Icon>
                  <p className="">Open Lesson</p>
                </button>
              </div>
            </div> */}

            <Table
              sortDirections={true}
              columns={columnAllCourse}
              dataSource={dataAllCourse}
              pagination={{ pageSize: 5, position: ["bottomCenter"] }}
              className="rounded-b-3xl w-[95%] mx-auto p-0 overflow-hidden  "
              scroll={{
                x: "max-content",
              }}
              onRow={(e) => ({
                onClick: () => {
                  handleNavigate(e.courseId);
                },
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCourse;
