/** @format */

import { Icon } from "@iconify/react";
import React from "react";
import avatar from "../../../assets/teach_1.jpg";
import { withTranslation, useTranslation } from "react-i18next";
import { Dropdown, Select } from "antd";
import Logo from "../../../assets/Logo_AquaticHub.png";
import { useNavigate, Link } from "react-router-dom";
import { path } from "../../../utils/constant";

const PrivateHeader = (props) => {
  //Multi Lang
  const { t } = props;
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const currentLanguage = localStorage.getItem("lang");

  // navigate
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate(path.HOME);
    sessionStorage.clear();
  };

  // get User
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const items = [
    {
      label: (
        <button className="hover:text-secondary2 flex  items-center gap-4 ">
          <Icon className="w-6 h-6 " icon={"uil:setting"}></Icon>
          <p className="">Settings</p>
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button className="hover:text-secondary2 flex items-center gap-4 ">
          <Icon
            className="w-6 h-6"
            icon={"iconamoon:notification-light"}
          ></Icon>
          <p className="">Notification</p>
        </button>
      ),
      key: "1",
    },
    {
      label: (
        <button className="hover:text-secondary2 flex  items-center gap-4 ">
          <Icon className="w-6 h-6" icon={"ep:message"}></Icon>
          <p className="">Message Box</p>
        </button>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <button
          className="hover:text-secondary2 flex  items-center gap-4 "
          onClick={() => handleLogout()}
        >
          <p className="">Log Out</p>
        </button>
      ),
      key: "4",
    },
  ];
  return (
    <>
      <div className="h-[60px] grid grid-cols-4  items-center shadow-sm  w-[95%] mx-auto z-50">
        <div className="col-span-1">
          <Link
            to={`../${
              user?.role === "Employee"
                ? path.HOMEPAGEEMPLOYEE
                : path.INSTRUCTORCALENDAR
            }`}
          >
            <p className="text-black font-roboto_bold text-xl sm:max-md:hidden ph:max-sm:hidden pvmax:max-ph:hidden pv:max-pvmax:hidden">
              {t("h_pr_Name")}
            </p>
          </Link>

          <Link to={`../${path.INSTRUCTORCALENDAR}`}>
            <img
              className="h-[60px] object-cover md:hidden "
              src={Logo}
              alt="Logo"
            ></img>
          </Link>
        </div>

        <div className="col-span-3">
          <div className=" ph:max-sm:flex ph:max-sm:justify-end pvmax:max-ph:col-span-1  pv:max-pvmax:col-span-2 pvmax:max-ph:mt-[5px] pv:max-pvmax:mt-[5px]">
            <div className="flex justify-end gap-10 items-center">
              <div className="flex items-center justify-around gap-2 ">
                <div className="flex gap-2 items-center ">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                    placement="bottomCenter"
                  >
                    <butoon className="h-10 w-10 rounded-full cursor-pointer">
                      <img
                        className=" h-full w-full rounded-full object-cover"
                        src={user?.PictureURL}
                        alt="Avatar"
                      ></img>
                    </butoon>
                  </Dropdown>

                  <div className="flex flex-col ">
                    <p className="font-roboto_bold sm:max-md:text-[14px] pv:max-sm:text-[10px] ">
                      {user?.Description}
                    </p>
                    <p className="pv:max-sm:text-[10px]">{user?.Email}</p>
                  </div>
                </div>

                <Select
                  defaultValue={currentLanguage}
                  size="large"
                  className=""
                  suffixIcon={null}
                  onChange={(e) => changeLanguage(e)}
                  options={[
                    {
                      value: "vi",
                      label: (
                        <div className="flex items-center justify-center">
                          <Icon
                            icon={"twemoji:flag-vietnam"}
                            className="w-5 h-5"
                          />
                        </div>
                      ),
                    },
                    {
                      value: "en",
                      label: (
                        <div className="flex items-center justify-center">
                          <Icon icon={"flagpack:gb-ukm"} className="w-5 h-5" />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(PrivateHeader);
