/** @format */

import { notification } from "antd";
import axiosConfig from "../axiosConfig";

export const apiRegister = async (payload) => {
  try {
    if (payload.phoneNumber !== undefined) {
      const response = await axiosConfig({
        method: "POST",
        url: "/v1/user-signup",
        data: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          phoneNumber: payload.phoneNumber,
          dateOfBirth: payload.dateOfBirth,
          email: payload.email,
          address: payload.address,
          password: payload.password,
          gender: payload.gender,
          childUsers: payload.childUsers,
          adultUsers: payload.adultUsers,
        },
      });

      return response;
    } else {
      notification.error({
        message: "Missing required field",
        placement: "topRight",
        duration: 2,
      });
    }
  } catch (error) {
    // notification.error({
    //   message: "User ",
    //   placement: "topRight",
    //   duration: 2,
    // });
    // console.error(err.message);
  }
};
export const apiLogin = async ({
  userCode = undefined,
  password = "123456",
}) => {
  try {
    if (userCode !== undefined) {
      const response = await axiosConfig({
        method: "POST",
        url: "/v1/user-signin",
        data: {
          userCode: userCode,
          password: password,
        },
      });

      return response;
    } else {
      notification.error({
        message: "Missing required field",
        placement: "topRight",
        duration: 2,
      });
    }
  } catch (error) {
    // notification.error({
    //   message: "Incorrect credentials",
    //   placement: "topRight",
    //   duration: 2,
    // });
    // console.error(err.message);
  }
};

export const apiLectureLogin = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axiosConfig({
        method: "POST",
        url: "/Lecture_Signin",
        data: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const apiEmployeeLogin = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axiosConfig({
        method: "POST",
        url: "/Employee_Signin",
        data: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const apiResetPass = async (userId = "") => {
  try {
    if (userId !== "" && userId !== undefined && userId !== null) {
      const response = await axiosConfig({
        method: "POST",
        url: "/v1/user-reset-password",
        data: {
          userId: userId,
        },
      });

      return response;
    } else {
      notification.error({
        message: "Missing required field",
        placement: "topRight",
        duration: 2,
      });
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const apiChangePass = async (payload) => {
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  try {
    if (user !== "" && user !== undefined && user !== null) {
      const response = await axiosConfig({
        method: "POST",
        url: "/v1/user-change-password",
        data: payload,
      });

      return response;
    } else {
      notification.error({
        message: "Missing required field",
        placement: "topRight",
        duration: 2,
      });
    }
  } catch (error) {
    console.error(error.message);
  }
};
