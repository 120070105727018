/** @format */

import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { apisecondstage, apiGetQuizz } from "../../../../services/secondstage";
import { Popover } from "antd";
import { CardWeekFastTrack } from "../../../../components";
import { DataWeekFastTrack } from "../../../../utils/data";
import { Table, Modal } from "antd";
import { formatCurrency, path } from "../../../../utils/constant";
import { useLocation, useNavigate } from "react-router-dom";
import QuizModal from "../../../../components/Modal/ModalQuiz";
import Levels from "./Sections/Levels";
import { CardFasttrack } from "../../../../components";

const FastTrack = () => {
  const location = useLocation();
  const dataState = location.state.data;
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const getSize = () => {
    setWidth(window.innerWidth);
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    description: "",
    class: [
      {
        code: "",
        description: "",
        detail: "",
        lessonUrl: "",
        level: "",
        pictureUrl: "",
        price: "",
        type: "",
      },
    ],
    level: {
      level1: {
        name: "",
        color: "",
        detail: "",
      },
      level2: {
        name: "",
        color: "",
        detail: "",
      },
      level3: {
        name: "",
        color: "",
        detail: "",
      },
      level4: {
        name: "",
        color: "",
        detail: "",
      },
      level5: {
        name: "",
        color: "",
        detail: "",
      },
      level6: {
        name: "",
        color: "",
        detail: "",
      },
      level7: {
        name: "",
        color: "",
        detail: "",
      },
    },
  });
  const handleClick = () => {
    const newDataState = {
      ...dataState,
      UserID: user?.code,
      UserType: "main",
    };
    navigate("../" + path.FASTTRACKS, {
      state: {
        data: newDataState,
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (e) => {
        return (
          <p className="cursor-pointer text-secondary1 font-roboto_bold text-xl">
            {e}
          </p>
        );
      },
    },
    {
      title: "Start",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal_complete_quiz = () => {
    setIsModalOpen(true);
  };
  const handleOk_complete_quiz = () => {
    setIsModalOpen(false);
  };

  const handleCancel_complete_quiz = () => {
    setIsModalOpen(false);
  };
  // define modal quizz
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    // if (user) {
    setModalVisible(true);
    // } else {
    //   notification.info({
    //     message: "Please log in to continue",
    //     placement: "topRight",
    //     duration: 2,
    //   });
    //   navigate("../" + path.LOGIN);
    // }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const handleCompleteQuiz = () => {
    setIsModalOpen(true);
    setModalVisible(false);
  };

  const [dataQuiz, setDataQuiz] = useState({
    code: "",
    description: "",
    type: "",
    quizSet: [
      {
        question: "",
        options: [],
      },
    ],
  });

  // break point resize sidebar
  useEffect(() => {
    const fetchSecondStage = async () => {
      const response = await apisecondstage("fasttrack");
      if (response?.status === 201 || response?.status === 200) {
        setData(response.data.data);
        setIsLoading(false);
      }
    };
    fetchSecondStage();

    const fetchQuiz = async () => {
      const response = await apiGetQuizz("SOF");

      if (response?.status === 201 || response?.status === 200) {
        setDataQuiz(response?.data.data);
      }
    };
    fetchQuiz();
  }, []);

  return (
    <div>
      <QuizModal
        visible={modalVisible}
        onCancel={handleCloseModal}
        questions={dataQuiz?.quizSet}
        onComplete={handleCompleteQuiz}
      />
      <div className="w-full relative  pv:max-sm:pt-[90px]">
        <img
          alt=""
          className="w-full h-[70vh] object-cover "
          src={dataState.img}
        ></img>
        <div className="z-10 absolute top-[30%] left-[5%]  w-[500px] pv:max-ph:w-[300px] pv:max-md:w-[94%]">
          <p className=" text-start uppercase font-bold text-white text-5xl   pv:max-md:left-[-10%] w-[100%]  pv:max-md:text-xl  pv:max-md:top-[50%] pb-8">
            {dataState.title}
          </p>
          <p className="    line-clamp-5  text-lg   text-white pv:max-sm:text-[16px]">
            {data?.description}
          </p>
        </div>

        <div className="w-full h-full bg-gradient-to-t from-slate-800 absolute top-0 left-0"></div>

        {/* <div className="flex justify-center ">
          <div className="w-[95%] mx-auto bg-white absolute bottom-[-20px] rounded-2xl shadow-xl shadow-blue-100 py-2  pv:max-md:bottom-[-60px]">
            <div className="flex justify-around text-xl font-roboto_bold py-5  pv:max-sm:text-lg ">
              <p className="">Description</p>
              <p className="">Levels</p>
              <p className="">Cost</p>
            </div>
          </div>
        </div> */}
      </div>

      <div className="py-20 pv:max-sm:pt-0 md:max-lg:pt-[95px] lg:max-dh:pt-[95px] ">
        <div className="bg-white w-[95%] mx-auto">
          <div className="">
            <div className="py-5 flex justify-center">
              <p className="text-3xl pv:max-sm:text-lg font-roboto_bold">
                {dataState.title}
              </p>
            </div>
            <div className="w-[90%] mx-auto ">
              <p className="text-lg pv:max-sm:text-[16px] text-center">
                {data?.description}
              </p>
            </div>
          </div>

          <div className="py-10">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold pv:max-sm:text-lg">
                Levels
              </p>
            </div>

            <div className=" w-[90%] mx-auto flex justify-around items-center">
              <Levels
                data={data}
                numberofElement={7}
                slidesPerView={`${
                  window.innerWidth < 768
                    ? "1"
                    : window.innerWidth <= 1024
                    ? "3"
                    : window.innerWidth <= 1440
                    ? "4"
                    : "5"
                } `}
              />
            </div>
          </div>

          <div className="flex flex-col  items-center py-10">
            <div className="w-[80%] text-center">
              <p className="italic text-[20px]">
                Would you like to take a quiz to determine your current level?
              </p>
            </div>
            <button
              className=""
              onClick={() => {
                handleOpenModal();
              }}
            >
              <p className="text-red-700 font-roboto_bold text-[20px] hover:">
                Do quiz
              </p>
            </button>
          </div>

          <div className="py-10 w-[70%] mx-auto">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold pv:max-sm:text-lg">
                Schedule
              </p>
            </div>
            {/* <Table
              loading={isLoading}
              columns={columns}
              dataSource={data?.semester}
              pagination={false}
              onRow={(record, index) => {
                return {
                  onClick: () => {
                    handleClick()
                  }
                }
              }}
            /> */}
            <div className="w-[90%] mx-auto grid grid-cols-3 pv:max-md:grid-cols-1 md:max-lg:grid-cols-2 place-items-center gap-[20px]">
              {data.semester?.map((item, index) => {
                return (
                  <CardFasttrack
                    index={index + 1}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    name={item.name}
                    status={item.status}
                    dataState={dataState}
                    onClick={handleClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        width={800}
        open={isModalOpen}
        onOk={handleOk_complete_quiz}
        onCancel={handleCancel_complete_quiz}
        footer={[
          <button
            className="px-4 py-2 bg-secondary1 rounded-2xl text-white font-roboto_bold"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <p className="">OK</p>
          </button>,
        ]}
      >
        <div className="">
          <div className="flex  justify-center items-center">
            <p className="text-[20px] font-roboto_bold">Course for you !!!</p>
          </div>
          <div className="">
            <img
              className="object-cover"
              src="http://103.157.218.115/AquaticPicture/Badges/barracuda.png"
            ></img>
            <div className="flex justify-center items-center">
              <p className="text-[24px] text-[#55c1ff] ">Barracuda</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FastTrack;
