/** @format */

import React from "react";
import img9 from "../../../../assets/img_9.jpg";
import { Icon } from "@iconify/react";
import { withTranslation, useTranslation } from "react-i18next";

const JoinToDay = ({ t }) => {
  // Multi Lang
  const { i18n } = useTranslation();
  return (
    <div className="w-full h-auto bg-white py-20">
      <div className="mx-auto w-[80%] sm:max-md:flex sm:max-md:justify-center ph:max-sm:flex ph:max-sm:justify-center pv:max-pvmax:w-full pv:max-pvmax:flex pv:max-pvmax:justify-center pv:max-pvmax:text-center text-center pvmax:max-ph:w-full pvmax:max-ph:flex pvmax:max-ph:justify-center pb-10 ">
        <p className="font-semibold text-[24px] text-secondary2 sm:max-md:text-[24px] ">
          {t("jointoday_title")}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4 mx-auto w-[80%]  sm:max-md:flex ph:max-sm:flex pv:max-pvmax:flex pv:max-pvmax:justify-center pvmax:max-ph:flex  pvmax:max-ph:justify-center">
        <div className="flex flex-col gap-6">
          <div className="w-[40%] md:max-lg:w-[80%] sm:max-md:flex sm:max-md:justify-center sm:max-md:w-full ph:max-sm:w-full ph:max-sm:flex ph:max-sm:justify-center ph:max-sm:text-center pv:max-pvmax:w-full pv:max-pvmax:flex pv:max-pvmax:justify-center pv:max-pvmax:text-center text-center pvmax:max-ph:flex pvmax:max-ph:justify-center pvmax:max-ph:text-center pvmax:max-ph:w-full  ">
            <p className="text-[20px] font-bold text-start">
              {t("jointoday_title_bottom")}
            </p>
          </div>

          <div className="">
            <p className="text-[16px] whitespace-pre-line ">{t("jointoday_description")}</p>
          </div>
        </div>
        <div
          className="w-full  bg-no-repeat bg-contain  bg-center text-white flex flex-col items-center justify-center gap-6 sm:max-md:hidden ph:max-sm:hidden pv:max-pvmax:hidden pvmax:max-ph:hidden"
          style={{ backgroundImage: `url("${img9}")` }}
        ></div>
      </div>
    </div>
  );
};

export default withTranslation()(JoinToDay);
