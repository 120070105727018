/** @format */

import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { apisecondstage } from "../../../../services/secondstage";
import { useNavigate, useLocation } from "react-router-dom";
import { notification } from "antd";
import { formatCurrency, path } from "../../../../utils/constant";

const Training = () => {
  // define const
  const location = useLocation();
  const navigate = useNavigate();
  const DataState = location.state.data;
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const getSize = () => {
    setWidth(window.innerWidth);
  };
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));
  const [data, setData] = useState({
    description: "",
    cost: [
      {
        level: "",
        price: "",
      },
    ],
    level: {},
  });
  const columns = [
    // {
    //   title: (
    //     <p className="text-[28px] pv:max-ph:text-[18px] font-roboto_bold text-white">
    //       No
    //     </p>
    //   ),
    //   dataIndex: "no",
    //   key: "no",
    //   fixed: "left",
    //   render: (no) => (
    //     <p className={`text-[28px] pv:max-ph:text-[16px]`}>{no}</p>
    //   ),
    // },
    {
      title: (
        <p className="text-[28px] pv:max-sm:text-lg font-roboto_bold text-white">
          Level
        </p>
      ),
      dataIndex: "level",
      key: "level",
      fixed: "left",
      render: (level) => (
        <p className={`text-[28px] pv:max-sm:text-[14px]`}>{level}</p>
      ),
    },
    {
      title: (
        <p className="text-[28px] pv:max-sm:text-lg font-roboto_bold text-white">
          Price
        </p>
      ),
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <p className={`text-[22px] font-[350] pv:max-sm:text-[14px]`}>
          {formatCurrency(price)}
        </p>
      ),
    },
  ];
  // initial data
  useEffect(() => {
    const fetchSecondStage = async () => {
      const response = await apisecondstage("Training");

      if (response?.status === 201 || response?.status === 200) {
        setData(response.data.data);
      }
    };
    fetchSecondStage();
    window.addEventListener("resize", getSize);
    if (width <= 480) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => {
      window.removeEventListener("resize", getSize);
    };
  }, [window.innerWidth]);

  // function
  const handleClickSubmit = () => {
    if (!user) {
      navigate("../" + path.LOGIN);
    } else if (user !== null || user !== undefined) {
      notification.success({
        description: "Your request has been submitted",
        message: "message",
        placement: "bottom",
        duration: 2,
      });
      navigate("../" + path.HOME);
    }
  };
  return (
    <div>
      <div className="w-full relative pv:max-sm:pt-[90px]">
        <img
          alt=""
          className="w-full h-[70vh] object-cover "
          src={DataState.img}
        ></img>
        <p className="font-roboto_bold text-white text-8xl absolute top-[70%] w-[100%] text-center pv:max-ph:top-[40%] pv:max-ph:text-7xl pv:max-pvmax:text-6xl ph:max-md:top-[40%] ph:max-lg:text-7xl md:max-lg:top-[50%] lg:max-dh:top-[50%] lg:max-dh:text-9xl">
          {DataState.title}
        </p>
        {/* <div className="flex justify-center ">
          <div className="w-[95%] mx-auto bg-white absolute bottom-[-20px] rounded-2xl shadow-xl shadow-blue-100 py-2  pv:max-md:bottom-[-60px]">
            <div className="flex justify-around text-xl font-roboto_bold py-5  pv:max-sm:text-lg ">
              <p className="">Description</p>
              <p className="">Levels</p>
              <p className="">Cost</p>
            </div>
          </div>
        </div> */}
      </div>

      <div className="pt-20 pv:max-ph:pt-[95px] md:max-lg:pt-[95px] lg:max-dh:pt-[95px] ">
        <div className="bg-white w-[95%] mx-auto">
          <div className="">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold pv:max-sm:text-lg">
                Description
              </p>
            </div>
            <div className="w-[90%] mx-auto ">
              <p className="text-lg text-justify pv:max-sm:text-[16px]">
                {data?.description}
              </p>
            </div>
            <div className="flex flex-col  items-center py-10">
              <div className="w-[80%] text-center">
                <p className="italic text-[20px]">
                  Would you like to take a quiz to determine your current level?
                </p>
              </div>
              <button className="">
                <p className="text-red-700 font-roboto_bold text-[20px] hover:">
                  Do quiz
                </p>
              </button>
            </div>
          </div>

          {/* <div className="py-10 flex justify-center flex-col">
            <div className="py-5 flex justify-center font-roboto_bold">
              <p className="text-[36px] font-roboto_bold pv:max-sm:text-lg">Date</p>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex w-4/5 items-center justify-center py-4">
                <p className=" text-center pv:max-sm:text-lg">
                  Everyday at 10:00 AM
                </p>
              </div>
            </div>
          </div> */}

          <div className="py-10">
            <div className="py-5 flex justify-center">
              <p className="text-3xl font-roboto_bold pv:max-sm:text-lg">Cost</p>
            </div>
            <div className="w-[90%] mx-auto flex justify-around items-center">
              <Table
                dataSource={data?.cost}
                columns={columns}
                className="w-full table_cost_training"
                pagination={{
                  pageSize: `${isMobile ? "3" : "10"} `,
                  position: ["bottomCenter"],
                }}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-20">
        <div className="flex justify-center">
          <button
            className="text-2xl pv:max-sm:text-lg text-white  rounded-2xl px-20 py-4 bg-[#d95b43] hover:bg-[#854134]"
            onClick={() => handleClickSubmit()}
          >
            <p className=" font-roboto_bold ">Book</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Training;
