const UserCard = (props) => {
  const { avatarURL, fullName, userLevel, children } = props;

  return (
    <div className="flex flex-col gap-[1rem] items-center">
      <img
        src={avatarURL ? avatarURL : ""}
        alt="avatar"
        className="w-[17rem] h-[17rem] rounded-full object-cover"
      />
      <div className="flex flex-col gap-[5px] items-center">
        <div className="font-bold text-lg">{fullName ? fullName : ""}</div>
        <div className="text-md">{userLevel ? userLevel : ""}</div>
      </div>
      {children ? children : <></>}
    </div>
  );
};

export default UserCard;
