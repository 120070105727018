/** @format */

import React, { useEffect, useState } from "react";
import { CardMemberShip, CardFmMember } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { apiusermember } from "../../services/usermember";
import { notification, Tabs } from "antd";
import { withTranslation, useTranslation } from "react-i18next";
import { path } from "../../utils/constant"

const SelectMember = ({ t }) => {
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("UserInformation"));

  // use State
  const data = location.state.data;
  const [userInfo, setUserInfo] = useState({
    UserID: user?.code,
    UserType: "main",
  });
  const [dataMember, setDataMember] = useState({
    // adultMembers: [
    //   {
    //     code: "",
    //     dateOfBirth: "",
    //     description: "",
    //     gender: "",
    //     pictureUrl: "",
    //   },
    // ],
    childMembers: [
      {
        code: "",
        dateOfBirth: "",
        description: "",
        gender: "",
        pictureUrl: "",
      },
    ],
  });
  const [selectedMember, setSelectedMember] = useState();

  const handleClick = () => {
    if (selectedMember !== undefined) {
      // sessionStorage.setItem(
      //   "enrollmentCart",
      //   JSON.stringify({
      //     enrollInfo: {
      //       ...location.state?.enrollInfo,
      //       UserType: userInfo.UserType,
      //       UserID: userInfo.UserID
      //     },
      //     enrollmentDetail: location.state?.enrollment,
      //   })
      // );
      navigate(`../${path.CHECKOUT}`, {
        state: {
          enrollmentCart: JSON.stringify({
            enrollInfo: {
              ...location.state?.enrollInfo,
              UserType: userInfo.UserType,
              UserID: userInfo.UserID
            },
            enrollmentDetail: location.state?.enrollment,
          })
        }
      })
    } else {
      notification.info({
        message: `${t("SelectMember_please_select")}`,
        placement: "topRight",
        duration: 2,
      });
    }
  };

  const handleChangeMember = (e) => {
    setSelectedMember(e.value);
    setUserInfo({
      UserType: e.userType,
      UserID: e.value,
    });
  };

  // initial data user member
  useEffect(() => {
    const fetchuserMember = async () => {
      const response = await apiusermember({
        mainUserCode: user.code,
      });

      if (response?.status === 201 || response?.status === 200) {
        setDataMember(response?.data.data);
      }
    };
    fetchuserMember();
  }, []);
  const items = [
    {
      key: "1",
      label: `${t("SelectMember_main_user")}`,
      children: (
        <div className="py-4">
          <div className=" grid grid-cols-6 gap-6 pv:max-ph:grid-cols-1 ph:max-md:grid-cols-2 md:max-lg:grid-cols-3 lg:max-xl:grid-cols-4 xl:max-dv:grid-cols-5 ">
            <div className="">
              <CardMemberShip
                code={user.code}
                img={user.pictureUrl}
                name={user.description}
                year={user.dateOfBirth}
                icon={"el:adult"}
                selectedMember={selectedMember}
                UserType="main"
                handleChangeMember={handleChangeMember}
              />
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Adult",
    //   children: (
    //     <div className="py-4">
    //       {dataMember?.adultMembers.length !== 0 ? (
    //         <div className="grid grid-cols-6 gap-6 pv:max-ph:grid-cols-1 ph:max-md:grid-cols-2 md:max-lg:grid-cols-3 lg:max-xl:grid-cols-4 xl:max-dv:grid-cols-5 ">
    //           {dataMember?.adultMembers.map((item, index) => {
    //             return (
    //               <CardMemberShip
    //                 code={item.code}
    //                 img={item.pictureUrl}
    //                 name={item.description}
    //                 year={item.dateOfBirth}
    //                 icon={"el:adult"}
    //                 selectedMember={selectedMember}
    //                 UserType="sub-adult"
    //                 handleChangeMember={handleChangeMember}
    //               />
    //             );
    //           })}
    //         </div>
    //       ) : (
    //         <div className="flex flex-col gap-5">
    //           <p className="">
    //             No adult members have been registered yet.Click the button to go
    //             to the add member page.
    //           </p>
    //           <div className="">
    //             <button className="px-4 py-2 bg-secondary1 text-white rounded-lg">
    //               Add member
    //             </button>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      key: "3",
      label: `${t("SelectMember_child")}`,
      children: (
        <div className="py-4">
          {dataMember?.childMembers.length !== 0 ? (
            <div className=" grid grid-cols-6 gap-6 pv:max-ph:grid-cols-1 ph:max-md:grid-cols-2 md:max-lg:grid-cols-3 lg:max-xl:grid-cols-4 xl:max-dv:grid-cols-5">
              {dataMember?.childMembers.map((item, index) => {
                return (
                  <div className="">
                    <CardMemberShip
                      code={item.code}
                      img={item.pictureUrl}
                      name={item.description}
                      year={item.dateOfBirth}
                      icon={"bx:child"}
                      selectedMember={selectedMember}
                      UserType="sub-child"
                      handleChangeMember={handleChangeMember}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col gap-5">
              <p className="">{t("SelectMember_no_child")}</p>
              <div className="flex justify-start">
                <button className="px-4 py-2 bg-secondary1 text-white rounded-lg">
                  {t("SelectMember_Add_member")}
                </button>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  return (
    <div className="  pb-[10px] ">
      {/* <div className="w-full relative">
        <img
          className="w-full  object-cover h-[100vh] pv:max-ph:h-[300px] ph:max-md:h-[400px]  "
          src={data?.img}
        ></img>
        <p className="font-bold text-white text-8xl absolute top-[70%] w-[100%] text-center pv:max-ph:top-[50%] pv:max-ph:text-5xl pv:max-pvmax:text-4xl ph:max-md:top-[40%] ph:max-lg:text-7xl md:max-lg:top-[50%] lg:max-dh:top-[50%] lg:max-dh:text-9xl">
          {data?.title}
        </p>
      </div> */}
      <div className="w-[95%] mx-auto  my-10  rounded-xl">
        <p className="text-3xl font-bold text-center mt-[100px] pv:max-md:mt-[30px] md:max-xl:mt-[60px]">
          {t("SelectMember_title")}
        </p>

        <Tabs defaultActiveKey="1" items={items} />
        <div className="flex justify-center my-10">
          <button
            className="p-8 py-4 bg-[#ece7e3] rounded-full hover:text-white hover:bg-secondary2 "
            onClick={() => {
              handleClick();
            }}
          >
            <p className="underline text-2xl pv:max-ph:text-xl lg:max-xl:text-lg">
              {t("SelectMember_next")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SelectMember);
