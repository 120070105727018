/** @format */

import React, { useState, useEffect } from "react";
import { Button, ModalOTP } from "../../components";
import {
  apiLogin,
  apiRegister,
  apiResetPass,
  apiEmployeeLogin,
} from "../../services/auth";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DatePicker,
  notification,
  Popconfirm,
  Select,
  Modal,
  Table,
  message,
} from "antd";
import { useFormik } from "formik";
import { path } from "../../utils/constant";
import * as Yup from "yup";
import { withTranslation, useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
const Login = ({ onSetUser, t }) => {
  //#region State
  const navigate = useNavigate();
  const location = useLocation();
  const redirectCallBack = location.state?.redirectCallBack
    ? location.state?.redirectCallBack
    : undefined;
  const months = [
    { MonthNum: "01", Description: "January", ShortDescription: "Jan" },
    { MonthNum: "02", Description: "February", ShortDescription: "Feb" },
    { MonthNum: "03", Description: "March", ShortDescription: "Mar" },
    { MonthNum: "04", Description: "April", ShortDescription: "Apr" },
    { MonthNum: "05", Description: "May", ShortDescription: "May" },
    { MonthNum: "06", Description: "June", ShortDescription: "Jun" },
    { MonthNum: "07", Description: "July", ShortDescription: "Jul" },
    { MonthNum: "08", Description: "August", ShortDescription: "Aug" },
    { MonthNum: "09", Description: "September", ShortDescription: "Sep" },
    { MonthNum: "10", Description: "October", ShortDescription: "Oct" },
    { MonthNum: "11", Description: "November", ShortDescription: "Nov" },
    { MonthNum: "12", Description: "December", ShortDescription: "Dec" },
  ];
  const currentDate = new Date();
  const [openAdult, setOpenAdult] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [childMembers, setChildMembers] = useState([]);
  const [adultMembers, setAdultMembers] = useState([]);
  const [signupDOB, setSignupDOB] = useState("");
  const [gender, setGender] = useState("Male");
  const [age, setAge] = useState(0);
  const [register, setRegister] = useState(location.state?.flag);
  const [currentMember, setCurrentMember] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: `${currentDate.getFullYear()}${
      months[currentDate.getMonth()].MonthNum
    }${
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate()
    }`,
    gender: "Male",
    age: 0,
  });
  //#endregion

  //#region Hook Const
  const formikLogin = useFormik({
    initialValues: {
      signIn_Phone: "",
      signIn_Password: "",
    },
    validationSchema: Yup.object({
      signIn_Phone: Yup.string()
        .length(10)
        .matches(/^[0-9]+$/, `${t("login_valid_number")}`)
        .required(`${t("login_valid_required")}`),
      signIn_Password: Yup.string().required(`${t("login_valid_required")}`),
    }),
    onSubmit: (values) => {
      handleLogin({
        userCode: values.signIn_Phone,
        password: values.signIn_Password,
      });
    },
  });

  const formikSignUp = useFormik({
    initialValues: {
      Signup_FirstName: "",
      Signup_LastName: "",
      Signup_PhoneNumber: "",
      Signup_Email: "",
      Signup_Password: "",
      Signup_ConfirmPassword: "",
      Signup_DateOfBirth: "",
      Signup_Gender: "",
      Signup_Address: "",
      Signup_adultUsers: [],
      Signup_childUsers: [],
    },
    validationSchema: Yup.object({
      Signup_FirstName: Yup.string().required(`${t("login_valid_required")}`),
      Signup_LastName: Yup.string().required(`${t("login_valid_required")}`),
      Signup_PhoneNumber: Yup.string()
        .length(10)
        .matches(/^[0-9]+$/, `${t("login_valid_number")}`)
        .required(`${t("login_valid_required")}`),
      Signup_Password: Yup.string().required(`${t("login_valid_required")}`),
      Signup_ConfirmPassword: Yup.string()
        .oneOf([Yup.ref("Signup_Password")], `${t("login_password_not_match")}`)
        .required(`${t("login_valid_required")}`),
      Signup_Address: Yup.string(),
      Signup_Email: Yup.string()
        .required(`${t("login_valid_required")}`)
        .matches(
          /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
          t("login_wrong_email")
        ),
    }),
    onSubmit: (values) => {
      if (age > 0) {
        handleSignup({
          firstName: values.Signup_FirstName,
          lastName: values.Signup_LastName,
          phoneNumber: values.Signup_PhoneNumber,
          dateOfBirth: signupDOB,
          address: values.Signup_Address,
          password: values.Signup_Password,
          email: values.Signup_Email,
          gender: gender,
          adultUsers: adultMembers,
          childUsers: childMembers,
        });
      } else {
        notification.error({
          message: `${t("login_valid_age")}`,
          placement: "topRight",
          duration: 2,
        });
      }
    },
  });

  //#endregion

  //#region Function

  const showModalAdult = () => {
    setOpenAdult(true);
  };
  const showModalChild = () => {
    setOpenChild(true);
  };

  const handleCancelOpenAdult = () => {
    setOpenAdult(false);
    setCurrentMember({
      firstName: "",
      lastName: "",
      dateOfBirth: `${currentDate.getFullYear()}${
        months[currentDate.getMonth()].MonthNum
      }${
        currentDate.getDate() < 10
          ? "0" + currentDate.getDate()
          : currentDate.getDate()
      }`,
      gender: "Male",
      age: 0,
    });
  };
  const handleCancelOpenChild = () => {
    setOpenChild(false);
    setCurrentMember({
      firstName: "",
      lastName: "",
      dateOfBirth: `${currentDate.getFullYear()}${
        months[currentDate.getMonth()].MonthNum
      }${
        currentDate.getDate() < 10
          ? "0" + currentDate.getDate()
          : currentDate.getDate()
      }`,
      gender: "Male",
      age: 0,
    });
  };

  useEffect(() => {
    setRegister(location.state?.flag);
  }, [location.state?.flag]);

  const handSwitch = () => {
    setRegister(!register);
  };

  const handleLogin = async (credentials) => {
    await apiLogin(credentials)
      .then((res) => {
        sessionStorage.setItem(
          "enrollmentMain",
          JSON.stringify(res.data.data.enrollmentMain)
        );
        sessionStorage.setItem(
          "enrollmentChild",
          JSON.stringify(res.data.data.enrollmentChild)
        );
        sessionStorage.setItem(
          "UserInformation",
          JSON.stringify(res.data.data.basicInformation)
        );
        sessionStorage.setItem(
          "ChildInformation",
          JSON.stringify(res.data.data.childMembers)
        );
        sessionStorage.setItem(
          "AdultInformation",
          JSON.stringify(res.data.data.adultMembers)
        );
        onSetUser(res.data.data.basicInformation);
        // // navigate("../" + path.HOME);
        // navigate(-1)

        notification.success({
          message: `${t("signIn_success")}`,
          placement: "topRight",
          duration: 2,
        });
      })
      .then(() => {
        if (redirectCallBack) {
          redirectCallBack();
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        notification.error({
          message: `${t("login_valid_Incorrect_credentials")}`,
          placement: "topRight",
          duration: 2,
        });
        console.error(err.message);
      });
  };

  const handleSignup = async (signupInfo) => {
    await apiRegister(signupInfo)
      .then((res) => {
        sessionStorage.setItem(
          "enrollmentMain",
          JSON.stringify(res.data.data.enrollmentMain)
        );
        sessionStorage.setItem(
          "enrollmentChild",
          JSON.stringify(res.data.data.enrollmentChild)
        );
        sessionStorage.setItem(
          "UserInformation",
          JSON.stringify(res.data.data.basicInformation)
        );
        sessionStorage.setItem(
          "ChildInformation",
          JSON.stringify(res.data.data.family)
        );
        sessionStorage.setItem(
          "AdultInformation",
          JSON.stringify(res.data.data.family)
        );
        onSetUser(res.data.data.basicInformation);

        notification.success({
          message: `${t("signUp_success")}`,
          placement: "topRight",
          duration: 2,
        });
      })
      .then(() => {
        if (redirectCallBack) {
          redirectCallBack();
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        notification.error({
          message: `${t("login_valid_user_exits")}`,
          placement: "topRight",
          duration: 2,
        });
      });
  };

  const handleErrorNoti = (str) => {
    const ErroNoti = str.substring(str.indexOf(" ") + 1);
    return ErroNoti.charAt(0).toUpperCase() + ErroNoti.slice(1);
  };
  //#endregion

  const [loadingResetPass, setLoadingResetPass] = useState(false);
  const [resetPhone, setResetPhone] = useState("");
  const [openResetPassForm, setOpenResetPassForm] = useState(false);
  const handleOpenResetPassForm = () => {
    setResetPhone("");
    setOpenResetPassForm(!openResetPassForm);
  };

  const [openFormOTP, setOpenFormOTP] = useState(false);
  const handleOpenFormOTP = () => {
    setOpenFormOTP(!openFormOTP);
  };

  return (
    <div className="px-6 sm:max-md:px-16 md:max-lg:px-24 lg:max-2xl:px-32 2xl:px-60 py-8 pt-[180px]">
      <div
        className="flex w-[100%] sm:max-md:w-[80%] md:max-lg:w-[70%] lg:max-xl:w-[60%] xl:w-[50%] h-[800px] xl:h-[700px] pv:max-ph:h-auto rounded-lg border-2 border-slate-300 bg-[white] overflow-hidden"
        style={{ margin: "0 auto" }}
      >
        <form
          onSubmit={formikSignUp.handleSubmit}
          id="signUp"
          className={`flex flex-col items-center justify-between h-full p-[20px] min-w-full ${
            register ? "ml-0" : "ml-[-100%]"
          }`}
          style={{ transition: "all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55)" }}
        >
          <h1 className="h-[8%] sm:h-[10%] w-full flex justify-center items-center font-roboto_bold text-2xl">
            {t("login_signup")}
          </h1>
          <div
            style={{ margin: "0 auto" }}
            className="h-[70%] pvmax:max-sm:h-[80%] sm:h-[70%] w-[90%] pvmax:w-[90%] flex flex-col justify-between"
          >
            <div className="hidden sm:flex justify-between">
              <div className="flex flex-col gap-[10px] w-[49%]">
                <input
                  placeholder={t("signup_firstname") + ` * `}
                  className="border-slate-300 outline-none border-b-2 p-[5px] "
                  name="Signup_FirstName"
                  value={formikSignUp.values.Signup_FirstName}
                  onChange={formikSignUp.handleChange}
                />
                {formikSignUp.errors.Signup_FirstName &&
                formikSignUp.touched.Signup_FirstName ? (
                  <p className="text-red-500">
                    {handleErrorNoti(formikSignUp.errors.Signup_FirstName)}
                  </p>
                ) : null}
              </div>
              <div className="w-[49%] flex flex-col gap-[10px]">
                <input
                  placeholder={t("signup_lastname") + ` * `}
                  className="border-slate-300 outline-none border-b-2 p-[5px] "
                  name="Signup_LastName"
                  value={formikSignUp.values.Signup_LastName}
                  onChange={formikSignUp.handleChange}
                />
                {formikSignUp.errors.Signup_LastName &&
                formikSignUp.touched.Signup_LastName ? (
                  <p className="text-red-500">
                    {handleErrorNoti(formikSignUp.errors.Signup_LastName)}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex sm:hidden flex-col gap-[10px] w-[100%]">
              <input
                placeholder={t("signup_firstname") + ` * `}
                className="border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_FirstName"
                value={formikSignUp.values.Signup_FirstName}
                onChange={formikSignUp.handleChange}
              />
              {formikSignUp.errors.Signup_FirstName &&
              formikSignUp.touched.Signup_FirstName ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikSignUp.errors.Signup_FirstName)}
                </p>
              ) : null}
            </div>
            <div className="w-[100%] sm:hidden flex flex-col gap-[10px]">
              <input
                placeholder={t("signup_lastname") + ` * `}
                className="border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_LastName"
                value={formikSignUp.values.Signup_LastName}
                onChange={formikSignUp.handleChange}
              />
              {formikSignUp.errors.Signup_LastName &&
              formikSignUp.touched.Signup_LastName ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikSignUp.errors.Signup_LastName)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col gap-[10px]">
              <input
                placeholder={t("sign_phone") + ` * `}
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_PhoneNumber"
                value={formikSignUp.values.Signup_PhoneNumber}
                onChange={formikSignUp.handleChange}
              />
              {/* {formikSignUp.errors.Signup_PhoneNumber &&
              formikSignUp.touched.Signup_PhoneNumber ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikSignUp.errors.Signup_PhoneNumber)}
                </p>
              ) : null} */}
              {formikSignUp.errors.Signup_PhoneNumber &&
              formikSignUp.touched.Signup_PhoneNumber ? (
                <p className="text-red-500">
                  {formikSignUp.errors.Signup_PhoneNumber.replace(
                    "Signup_PhoneNumber",
                    "Phone number"
                  )}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col gap-[10px]">
              <input
                // type="email"
                placeholder="Email * "
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_Email"
                value={formikSignUp.values.Signup_Email}
                onChange={formikSignUp.handleChange}
              />
              {formikSignUp.errors.Signup_Email &&
              formikSignUp.touched.Signup_Email ? (
                <p className="text-red-500">
                  {formikSignUp.errors.Signup_Email}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col gap-[10px]">
              <input
                type="password"
                placeholder={t("sign_password") + ` * `}
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_Password"
                value={formikSignUp.values.Signup_Password}
                onChange={formikSignUp.handleChange}
              />
              {formikSignUp.errors.Signup_Password &&
              formikSignUp.touched.Signup_Password ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikSignUp.errors.Signup_Password)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col gap-[10px]">
              <input
                type="password"
                placeholder={t("signup_confirmpassword") + ` * `}
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_ConfirmPassword"
                value={formikSignUp.values.Signup_ConfirmPassword}
                onChange={formikSignUp.handleChange}
              />
              {formikSignUp.errors.Signup_ConfirmPassword &&
              formikSignUp.touched.Signup_ConfirmPassword ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikSignUp.errors.Signup_ConfirmPassword)}
                </p>
              ) : null}
            </div>
            <DatePicker
              className=""
              placeholder={t("signup_dateofbirth")}
              onChange={(e) => {
                if (e != null) {
                  if (e.$D < 10) {
                    setSignupDOB(
                      `${e.$y}${months[String(e.$M)].MonthNum}0${e.$D}`
                    );
                  } else {
                    setSignupDOB(
                      `${e.$y}${months[String(e.$M)].MonthNum}${e.$D}`
                    );
                  }
                  const currentDate = new Date();
                  setAge(currentDate.getFullYear() - e.$y);
                }
              }}
            />
            <div className="w-[100%] 2xl:w-[80%] md:flex  justify-between">
              <div className="flex gap-[10px]">
                <div className="font-roboto_bold flex items-center">
                  {t("signup_age")}:
                </div>
                <input
                  value={age}
                  className="w-[50px] border-slate-300 outline-none border-b-2 p-[5px] text-center"
                  disabled
                />
              </div>
              <Select
                defaultValue="Male"
                size="large"
                className="w-[100%] md:w-[120px] h-10 mt-4 md:mt-0"
                onChange={(e) => setGender(e)}
                options={[
                  {
                    value: "Male",
                    label: (
                      <div className="flex gap-[10px] items-center">Male</div>
                    ),
                  },
                  {
                    value: "Female",
                    label: (
                      <div className="flex gap-[10px] items-center">Female</div>
                    ),
                  },
                  {
                    value: "Other",
                    label: (
                      <div className="flex gap-[10px] items-center">Other</div>
                    ),
                  },
                ]}
              />
            </div>
            <div className="flex flex-col gap-[10px]">
              <input
                placeholder={t("signup_address")}
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                name="Signup_Address"
                value={formikSignUp.values.Signup_Address}
                onChange={formikSignUp.handleChange}
              />
              {formikSignUp.errors.Signup_Address &&
              formikSignUp.touched.Signup_Address ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikSignUp.errors.Signup_Address)}
                </p>
              ) : null}
            </div>
          </div>

          <div className="flex justify-around py-4 items-center gap-10">
            <div className="flex justify-center items-center gap-2">
              <button
                type="button"
                className="h-10 w-10 bg-[#3ac977] rounded-full flex justify-center items-center"
                onClick={() => showModalAdult()}
              >
                <Icon
                  icon={"ic:baseline-plus"}
                  className="text-white h-6 w-6 font-bold"
                ></Icon>
              </button>
              <p className="font-bold">
                {t("Sign_up_addadult")} ({adultMembers.length})
              </p>
            </div>
            <div className="flex justify-center items-center gap-2">
              <button
                type="button"
                className="h-10 w-10 bg-[#3ac977] rounded-full flex justify-center items-center"
                onClick={() => showModalChild()}
              >
                <Icon
                  icon={"ic:baseline-plus"}
                  className="text-white h-6 w-6 font-bold"
                ></Icon>
              </button>
              <p className="font-bold">
                {t("Sign_up_addchild")} ({childMembers.length})
              </p>
            </div>
          </div>

          <div className="h-[15%] w-full flex flex-col gap-[1rem] items-center">
            <div
              style={{ margin: "0 auto" }}
              className="items-center justify-center"
            >
              <div className="flex flex-col items-center pvmax:flex-row justify-center gap-[10px] font-roboto_italic ">
                <p>{t("sign_up_already_account")} </p>
                <span
                  onClick={() => handSwitch()}
                  className="font-roboto_bold_italic cursor-pointer"
                >
                  {t("sign_up_sign_in")}
                </span>
              </div>
            </div>
            <div className="w-[150px] pvmax:w-[250px] flex justify-center">
              <div className="w-[150px] pvmax:w-[250px] flex justify-center">
                <button
                  type="submit"
                  className={`p-2 font-roboto_bold w-full text-white bg-secondary1 outline-none  rounded-md flex items-center gap-x-1 justify-center cursor-pointer`}
                >
                  <p className="">{t("login_signup")}</p>
                </button>
              </div>
            </div>
          </div>

          {/* region Modal  */}
          {/* Modal add adult */}
          {openAdult ? (
            <Modal
              open={openAdult}
              title={
                <div className="flex justify-center items-center">
                  <p className="text-[24px] font-roboto_bold">
                    Member adult infomation
                  </p>
                </div>
              }
              onCancel={handleCancelOpenAdult}
              footer={[
                <div className="flex justify-center items-center rounded-lg pt-2">
                  <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg">
                    <p
                      className="text-white text-[20px] font-roboto_bold"
                      onClick={() => {
                        if (
                          currentMember.firstName !== "" &&
                          currentMember.lastName !== ""
                        ) {
                          setAdultMembers([...adultMembers, currentMember]);
                          setCurrentMember({
                            firstName: "",
                            lastName: "",
                            dateOfBirth: `${currentDate.getFullYear()}${
                              months[currentDate.getMonth()].MonthNum
                            }${
                              currentDate.getDate() < 10
                                ? "0" + currentDate.getDate()
                                : currentDate.getDate()
                            }`,
                            gender: "Male",
                            age: 0,
                          });
                        } else {
                          notification.info({
                            message: "First name and last name is required",

                            placement: "topRight",
                            duration: 2,
                          });
                        }
                      }}
                    >
                      Add
                    </p>
                  </button>
                  <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg">
                    <p
                      className="text-white text-[20px] font-roboto_bold"
                      onClick={() => setOpenAdult(!openAdult)}
                    >
                      Done
                    </p>
                  </button>
                </div>,
              ]}
            >
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-col gap-[10px] w-[49%]">
                    <input
                      placeholder={t("signup_firstname") + ` * `}
                      className="border-slate-300 outline-none border-b-2 p-[5px] "
                      value={currentMember.firstName}
                      onChange={(e) =>
                        setCurrentMember({
                          ...currentMember,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="w-[49%] flex flex-col gap-[10px]">
                    <input
                      placeholder={t("signup_lastname") + ` * `}
                      className="border-slate-300 outline-none border-b-2 p-[5px] "
                      value={currentMember.lastName}
                      onChange={(e) =>
                        setCurrentMember({
                          ...currentMember,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <DatePicker
                  popupStyle={{}}
                  className="w-[100%] h-10"
                  placeholder={t("signup_dateofbirth")}
                  // value={moment(currentMember.dateOfBirth, "YYYYMMDD")}
                  value={dayjs(currentMember.dateOfBirth, "YYYYMMDD")}
                  onChange={(e) => {
                    if (e != null) {
                      if (e.$D < 10) {
                        setCurrentMember({
                          ...currentMember,
                          dateOfBirth: `${e.$y}${
                            months[String(e.$M)].MonthNum
                          }0${e.$D}`,
                        });
                      } else {
                        setCurrentMember({
                          ...currentMember,
                          dateOfBirth: `${e.$y}${
                            months[String(e.$M)].MonthNum
                          }${e.$D}`,
                        });
                      }
                      const currentDate = new Date();
                      setCurrentMember({
                        ...currentMember,
                        age: currentDate.getFullYear() - e.$y,
                      });
                    }
                  }}
                />
                <div className="w-[100%] 2xl:w-[80%] flex gap-4 items-center pv:max-md:flex-row-reverse">
                  <Select
                    defaultValue="Male"
                    size="large"
                    className="w-[100%] md:w-[120px] h-10 mt-4 md:mt-0"
                    value={currentMember.gender}
                    onChange={(e) =>
                      setCurrentMember({
                        ...currentMember,
                        gender: e,
                      })
                    }
                    options={[
                      {
                        value: "Male",
                        label: (
                          <div className="flex gap-[10px] items-center">
                            Male
                          </div>
                        ),
                      },
                      {
                        value: "Female",
                        label: (
                          <div className="flex gap-[10px] items-center">
                            Female
                          </div>
                        ),
                      },
                      {
                        value: "Other",
                        label: (
                          <div className="flex gap-[10px] items-center">
                            Other
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className="flex flex-col gap-[10px] w-[50px]">
                    <input
                      placeholder={t("signup_lastname") + ` * `}
                      className="border-slate-300 outline-none border-b-2 p-[5px] text-center"
                      value={currentMember.age}
                      readOnly
                    />
                  </div>
                </div>
                <Table
                  columns={[
                    {
                      title: "First name",
                      dataIndex: "firstName",
                      key: "firstname",
                    },
                    {
                      title: "Last name",
                      dataIndex: "lastName",
                      key: "lastname",
                    },
                    {
                      title: "Age",
                      dataIndex: "age",
                      key: "age",
                    },
                    {
                      title: "Gender",
                      dataIndex: "gender",
                      key: "gender",
                    },
                  ]}
                  dataSource={adultMembers}
                />
              </div>
            </Modal>
          ) : null}

          {/* Modal add child */}
          {openChild ? (
            <Modal
              open={openChild}
              title={
                <div className="flex justify-center items-center">
                  <p className="text-[24px] font-roboto_bold">
                    Member child infomation
                  </p>
                </div>
              }
              onCancel={handleCancelOpenChild}
              footer={[
                <div className="flex justify-center items-center rounded-lg pt-2">
                  <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg">
                    <p
                      className="text-white text-[20px] font-roboto_bold"
                      onClick={() => {
                        if (
                          currentMember.firstName !== "" &&
                          currentMember.lastName !== ""
                        ) {
                          setChildMembers([...childMembers, currentMember]);
                          setCurrentMember({
                            firstName: "",
                            lastName: "",
                            dateOfBirth: `${currentDate.getFullYear()}${
                              months[currentDate.getMonth()].MonthNum
                            }${
                              currentDate.getDate() < 10
                                ? "0" + currentDate.getDate()
                                : currentDate.getDate()
                            }`,
                            gender: "Male",
                            age: 0,
                          });
                        } else {
                          notification.info({
                            message: "First name and last name is required",

                            placement: "topRight",
                            duration: 2,
                          });
                        }
                      }}
                    >
                      Add
                    </p>
                  </button>
                  <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg">
                    <p
                      className="text-white text-[20px] font-roboto_bold"
                      onClick={() => setOpenChild(!openChild)}
                    >
                      Done
                    </p>
                  </button>
                </div>,
              ]}
            >
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-col gap-[10px] w-[49%]">
                    <input
                      placeholder={t("signup_firstname") + ` * `}
                      className="border-slate-300 outline-none border-b-2 p-[5px] "
                      value={currentMember.firstName}
                      onChange={(e) =>
                        setCurrentMember({
                          ...currentMember,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="w-[49%] flex flex-col gap-[10px]">
                    <input
                      placeholder={t("signup_lastname") + ` * `}
                      className="border-slate-300 outline-none border-b-2 p-[5px] "
                      value={currentMember.lastName}
                      onChange={(e) =>
                        setCurrentMember({
                          ...currentMember,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <DatePicker
                  popupStyle={{}}
                  className="w-[100%] h-10"
                  placeholder={t("signup_dateofbirth")}
                  // value={moment(currentMember.dateOfBirth, "YYYYMMDD")}
                  value={dayjs(currentMember.dateOfBirth, "YYYYMMDD")}
                  onChange={(e) => {
                    if (e != null) {
                      if (e.$D < 10) {
                        setCurrentMember({
                          ...currentMember,
                          dateOfBirth: `${e.$y}${
                            months[String(e.$M)].MonthNum
                          }0${e.$D}`,
                        });
                      } else {
                        setCurrentMember({
                          ...currentMember,
                          dateOfBirth: `${e.$y}${
                            months[String(e.$M)].MonthNum
                          }${e.$D}`,
                        });
                      }
                      const currentDate = new Date();
                      setCurrentMember({
                        ...currentMember,
                        age: currentDate.getFullYear() - e.$y,
                      });
                    }
                  }}
                />
                <div className="w-[100%] 2xl:w-[80%] flex gap-4 items-center pv:max-md:flex-row-reverse">
                  <Select
                    defaultValue="Male"
                    size="large"
                    className="w-[100%] md:w-[120px] h-10 mt-4 md:mt-0"
                    value={currentMember.gender}
                    onChange={(e) =>
                      setCurrentMember({
                        ...currentMember,
                        gender: e,
                      })
                    }
                    options={[
                      {
                        value: "Male",
                        label: (
                          <div className="flex gap-[10px] items-center">
                            Male
                          </div>
                        ),
                      },
                      {
                        value: "Female",
                        label: (
                          <div className="flex gap-[10px] items-center">
                            Female
                          </div>
                        ),
                      },
                      {
                        value: "Other",
                        label: (
                          <div className="flex gap-[10px] items-center">
                            Other
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className="flex flex-col gap-[10px] w-[50px]">
                    <input
                      placeholder={t("signup_lastname") + ` * `}
                      className="border-slate-300 outline-none border-b-2 p-[5px] text-center"
                      value={currentMember.age}
                      readOnly
                    />
                  </div>
                </div>
                <Table
                  columns={[
                    {
                      title: "First name",
                      dataIndex: "firstName",
                      key: "firstname",
                    },
                    {
                      title: "Last name",
                      dataIndex: "lastName",
                      key: "lastname",
                    },
                    {
                      title: "Age",
                      dataIndex: "age",
                      key: "age",
                    },
                    {
                      title: "Gender",
                      dataIndex: "gender",
                      key: "gender",
                    },
                  ]}
                  dataSource={childMembers}
                />
              </div>
            </Modal>
          ) : null}
        </form>

        <form
          onSubmit={formikLogin.handleSubmit}
          id="signIn"
          className="flex flex-col items-center justify-center h-full p-[20px] py-[] min-w-full pv:max-ph:py-4"
        >
          <h1 className="h-[5%] md:h-[10%] w-full flex justify-center items-center font-roboto_bold text-2xl">
            {t("login_signin")}
          </h1>
          <div
            style={{ margin: "0 auto" }}
            className="h-[50%] w-[90%] pvmax:w-[90%] flex flex-col justify-evenly pv:max-ph:justify-center pv:max-ph:gap-2"
          >
            <div className="flex flex-col gap-[10px]">
              <input
                placeholder={t("sign_phone") + ` * `}
                name="signIn_Phone"
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                value={formikLogin.values.signIn_Phone}
                onChange={formikLogin.handleChange}
              />
              {formikLogin.errors.signIn_Phone &&
              formikLogin.touched.signIn_Phone ? (
                <p className="text-red-500">
                  {formikLogin.errors.signIn_Phone.replace(
                    "signIn_Phone",
                    "Phone number"
                  )}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col gap-[10px] ">
              <input
                placeholder={t("sign_password") + ` * `}
                name="signIn_Password"
                type="password"
                className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                value={formikLogin.values.signIn_Password}
                onChange={formikLogin.handleChange}
              />
              {formikLogin.errors.signIn_Password &&
              formikLogin.touched.signIn_Password ? (
                <p className="text-red-500">
                  {handleErrorNoti(formikLogin.errors.signIn_Password)}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-full flex flex-col gap-[1rem] items-center ">
            <div
              style={{ margin: "0 auto" }}
              className={`flex flex-col items-center pvmax:flex-row justify-center`}
            >
              <div className=" flex flex-col items-center pvmax:flex-row justify-center gap-[10px] italic mb-4">
                <p>{t("signIn_have_account")}</p>
                <span
                  onClick={() => handSwitch()}
                  className="font-bold not-italic cursor-pointer"
                >
                  {t("login_signup")}
                </span>
              </div>
            </div>
          </div>

          <div className="w-[150px] pvmax:w-[250px] flex flex-col items-center justify-center gap-4">
            <button
              type="submit"
              className={`p-2 font-roboto_bold  text-white bg-secondary1 w-full outline-none  rounded-md flex items-center gap-x-1 justify-center cursor-pointer`}
            >
              <p className="">{t("login_signin")}</p>
            </button>
            <div
              className="underline text-sky-400 cursor-pointer hover:text-sky-600"
              onClick={() => handleOpenResetPassForm()}
            >
              {t("sign_in_forgot_password")}
            </div>
          </div>
        </form>

        {openResetPassForm ? (
          <Modal
            open={openResetPassForm}
            title={
              <div className="flex justify-center items-center">
                <p className="text-[24px] font-roboto_bold">Reset password</p>
              </div>
            }
            onCancel={handleOpenResetPassForm}
            footer={[
              <div className="flex justify-center items-center flex-col gap-4 rounded-lg pt-2">
                <div className="w-[150px] pvmax:w-[250px] flex justify-center">
                  <input
                    placeholder={t("sign_Email") + ` * `}
                    className="w-full border-slate-300 outline-none border-b-2 p-[5px] "
                    value={resetPhone}
                    onChange={(e) => setResetPhone(e.target.value)}
                  />
                </div>
                <Popconfirm
                  title={"Reset password"}
                  description={"Are you sure reset your password ?"}
                  okButtonProps={{
                    disabled: loadingResetPass,
                  }}
                  onConfirm={async () => {
                    if (resetPhone !== "") {
                      await apiResetPass(resetPhone)
                        .then((res) => {
                          if (res.status == 200) {
                            notification.success({
                              message:
                                "Password reset, please check your email",
                              placement: "topRight",
                              duration: 2,
                            });
                            handleOpenResetPassForm();
                          }
                        })
                        .catch((err) => {
                          notification.error({
                            message:
                              "Can't reset password, please contact admin",
                            placement: "topRight",
                            duration: 2,
                          });
                        });
                    }
                  }}
                  okText={
                    <p className="text-black">{t("pu_course_detail_me_yes")}</p>
                  }
                  cancelText={t("pu_course_detail_me_no")}
                >
                  <div className="w-[150px] pvmax:w-[250px] flex justify-center">
                    <button
                      // type="submit"
                      className={`p-2 font-roboto_bold w-full text-white bg-secondary1 outline-none  rounded-md flex items-center gap-x-1 justify-center cursor-pointer`}
                    >
                      <p className="">Reset</p>
                    </button>
                  </div>
                </Popconfirm>
              </div>,
            ]}
          ></Modal>
        ) : null}
      </div>
      <div className="w-[90%] mx-auto flex justify-center py-10">
        <button
          className=" uppercase bg-[#dcd3d1] w-[400px] pv:max-lg:w-[300px]  py-4 rounded-full pv:max-ph:rounded-full"
          onClick={() => {
            navigate("../" + path.HOME);
          }}
        >
          <p className="underline text-2xl pv:max-lg:text-xl">
            {t("Login_btn_Home")}
          </p>
        </button>

        {/* <button
          className=" uppercase bg-[#dcd3d1] w-[400px] pv:max-lg:w-[300px]  py-4 rounded-full pv:max-ph:rounded-full"
          onClick={() => {
            handleOpenFormOTP();
          }}
        >
          <p className="underline text-2xl pv:max-lg:text-xl">Open OTP</p>
        </button> */}
      </div>

      {openFormOTP ? (
        <ModalOTP
          openOTPform={openFormOTP}
          handleCancel={handleOpenFormOTP}
        ></ModalOTP>
      ) : null}
    </div>
  );
};

export default withTranslation()(Login);
