/** @format */

import React from "react";
import ImgFreakySwim from "../../../../assets/home_freakySwim.png";
import ImgHomeCafeRight from "../../../../assets/home_cafe_right.png";
import ImgHomeCafeLeft from "../../../../assets/home_cafe_left.png";
import imgPublicSwimming from "../../../../assets/Final_PIctures/public_swimming.jpg";
import imgChildrendSwimmingLesson from "../../../../assets/Final_PIctures/childrendswimminglesson.jpg";
import imgFlyingFish from "../../../../assets/Final_PIctures/FlyingFish.jpg";
import imgAdultclass from "../../../../assets/Final_PIctures/adultclass.jpg";
import imgPoolparty from "../../../../assets/Final_PIctures/pool-party.jpeg";
import imgAquarium from "../../../../assets/Final_PIctures/aquarium.jpg";
import aquapic_01 from "../../../../assets/aquapic_01.png";
import aquapic_02 from "../../../../assets/aquapic_02.png";
import {
  CardChooseLesscon,
  CardChooseLesscon_Bottom,
  CardSchoolGroup,
} from "../../../../components";
import { useNavigate } from "react-router-dom";
import { path } from "../../../../utils/constant";
import imgAquarium_1 from "../../../../assets/Final_PIctures/aqua_1.jpg";
import imgAquarium_2 from "../../../../assets/Final_PIctures/aqua_2.jpg";
import { dataLadingPage } from "../../../../utils/data";
import { withTranslation, useTranslation } from "react-i18next";

const ChooseLesson = (props) => {
  //Multi Lang
  const { t } = props;
  const { i18n } = useTranslation();
  const { dataLessonChoose, dataColor } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("../" + path.AQUARIUMPRICE);
  };
  const handlefilterData_Top = (arr) => {
    var newArr = arr.filter((el) => {
      return el.region === "1";
    });
    return newArr;
  };

  const handlefilterData_Bottom = (arr) => {
    var newArr = arr.filter((el) => {
      return el.region === "2";
    });
    return newArr;
  };

  const handlefilter_Rental = (arr) => {
    var newArr = arr.filter((el) => {
      return el.region === "3";
    });
    return newArr;
  };

  const handlefilter_Aquarium = (arr) => {
    var newArr = arr.filter((el) => {
      return el.region === "4";
    });
    return newArr;
  };

  const handlefilter_Cafe = (arr) => {
    var newArr = arr.filter((el) => {
      return el.region === "5";
    });
    return newArr;
  };

  const handlefilter_Freaky = (arr) => {
    var newArr = arr.filter((el) => {
      return el.region === "6";
    });
    return newArr;
  };

  return (
    <div className="flex flex-col gap-10 ">
      {dataLadingPage.map((item, index) => {
        if (index % 2 === 0) {
          return (
            <CardChooseLesscon
              key={index}
              img={item.img}
              desc={item.desc}
              title={item.title}
              textButton={item.textButton}
              pathUrl={item.pathUrl}
              order={true}
            ></CardChooseLesscon>
          );
        } else {
          return (
            <CardChooseLesscon
              key={index}
              img={item.img}
              desc={item.desc}
              title={item.title}
              textButton={item.textButton}
              pathUrl={item.pathUrl}
              order={false}
            ></CardChooseLesscon>
          );
        }
      })}

      {/* PUBLIC ​SWIMMING */}
      {/* <div className="bg-[#4e858b] py-20 pv:max-md:py-4">
        <div className="w-[70%] mx-auto mb-10 ph:max-md:w-[60%] ">
          <p className="text-white text-[48px] pv:max-ph:text-3xl">
          {t("HomePage_Course")}
            
            </p>
        </div>
        <div className="w-[80%] mx-auto grid gap-10 md:max-lg:grid-cols-2 lg:grid-cols-3 lg:max-xl:gap-4 md:max-lg:gap-7 pv:max-md:grid-cols-1 ph:max-md:w-[60%] pv:max-ph:w-[90%] md:max-lg:w-[85%]">
          {handlefilterData_Top(dataLessonChoose).map((item, index) => {
            const isReverse = index % 2 === 0 ? true : false;

            return (
              <CardChooseLesscon
                img={item.pictureUrl}
                title={item.description}
                textButton={
                  item.description === "CHILDREN  SWIM CLASSES"
                    ? t("HomePage_SwimClass")
                    : item.description === "ADULT SWIM  COURSES"
                    ? t("HomePage_AdultCourses")
                    : item.description === "FLYING FISH SWIM CLUB"
                    ? t("HomePage_SwimClub")
                    : "BOOK NOW"
                }
                key={index}
                reverse={isReverse}
                isReverseLayout={index % 2 != 0 ? false : true}
                code={item.code}
                pathUrl={item.path}
                estCost={item.estCost ?? ""}
                subTitle={item.subTitle ?? ""}
                color={
                  item.description === "Adult"
                    ? dataColor.adult
                    : item.description === "Club"
                    ? dataColor.club
                    : item.description === "Fasttrack"
                    ? dataColor.fasttrack
                    : item.description === "School of fish"
                    ? dataColor.swimmingLesson
                    : item.description === "Public Swimming"
                    ? dataColor.training
                    : dataColor.adult
                }
              />
            );
          })}
        </div>
      </div> */}

      {/* Aquarium */}
      <div className=" py-24 pv:max-lg:py-4">
        <div className="w-[65%]  mx-auto gap-10  grid grid-cols-3 pv:max-sm:w-[95%] pv:max-lg:grid-cols-1 pv:max-sm:gap-6 sm:max-md:w-[95%] sm:max-md:mx-auto lg:max-dv:w-[90%] lg:max-dv:mx-auto  ">
          <div className="col-span-2 pv:max-ph:col-span-1 w-[100%]  md:max-lg:mx-auto   ">
            <img
              className="w-full h-full  mx-auto   object-cover pv:max-md:h-[55vw] md:max-lg:h-[350px]"
              src={imgAquarium}
            ></img>
          </div>
          <div className="col-span-1 flex flex-col justify-between pv:max-sm:gap-4 pv:max-sm:w-[95%] pv:max-sm:mx-auto sm:max-lg:w-[60%] sm:max-lg:mx-auto sm:max-md:gap-4">
            <div className="">
              <p className="uppercase text-[#1b4235] font-medium text-5xl pv:max-sm:text-5xl sm:max-md:text-5xl md:max-lg:text-2xl lg:max-dv:text-[3.2vw] ">
                THE AQUARIUM
              </p>
            </div>
            <div className="">
              <p className="text-[#391b0b] text-2xl leading-10 pv:max-sm:text-[20px] pv:max-sm:leading-8 md:max-lg:text-lg lg:max-dv:text-[2vw]">
                Explore The Aquarium, a revolutionary aquatic sanctuary in
                Hanoi. Redefining the swim experience, we offer an unmatched
                indoor swim space. Immerse yourself in our 8-meter purpose-built
                swim tank, equipped for endless swimming, turns, stroke
                technique analysis. Rejuvenating cold therapy is available in
                plunge tubs.
              </p>
            </div>
            <div className="">
              <button
                className="w-full rounded-full bg-[#ece7e3] py-4"
                onClick={() => {
                  navigate("../" + path.AQUARIUMPRICE);
                }}
              >
                <p className="uppercase text-2xl  hover:font-bold hover:underline pv:max-sm:text-xl md:max-lg:text-lg">
                  AQUARIUM
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Aqua Cafe */}
      <div className="w-full mx-auto flex flex-col gap-6 p-10 bg-[#adc9c8]">
        {handlefilter_Cafe(dataLessonChoose).map((item, index) => {
          return (
            <div className="flex flex-col gap-10 items-center  pv:max-md:pb-4">
              <div className="flex w-[85%] ">
                <p className="text-[#1b4235] text-6xl uppercase">
                  {item.description ?? ""}
                </p>
              </div>
              <div className="w-[85%] pv:max-md:w-[90%] md:max-lg:w-[80%] pv:max-lg:gap-4 grid grid-cols-3 gap-10">
                <div className="col-span-2 pv:max-lg:col-span-3 flex flex-col gap-6">
                  <img className="w-full  " src={aquapic_01 ?? ""} />
                  <div className="text-primary flex flex-col ">
                    <p className="text-3xl text-[#391b0b] pv:max-md:text-xl">
                      {item.detail ?? ""}
                    </p>
                  </div>
                </div>
                <div className="relative col-span-1 h-full pv:max-lg:col-span-3">
                  <img
                    className="h-full mx-auto"
                    style={{ aspectRatio: "9/14" }}
                    src={aquapic_02}
                  />
                  <img
                    className="aspect-square w-[50%] max-w-[180px] opacity-40 absolute_center_horizontal bottom-0"
                    src="https://aquaticshub.com/images/5d8e495e67f0d410399bafd2944dcaa9.png"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Freaky */}

      <div className={` py-24 pv:max-lg:py-4`}>
        {handlefilter_Freaky(dataLessonChoose).map((item, index) => {
          return (
            <div
              className={`w-[65%]  mx-auto gap-10  grid grid-cols-3 pv:max-sm:w-[95%] pv:max-lg:grid-cols-1 pv:max-sm:gap-6 sm:max-md:w-[95%] sm:max-md:mx-auto lg:max-dv:w-[90%] lg:max-dv:mx-auto `}
            >
              <div
                className={`col-span-2 pv:max-ph:col-span-1 w-[100%]  md:max-lg:mx-auto  `}
              >
                <img
                  className="w-full lg:max-dv:h-[400px] h-[450px] mx-auto   object-cover pv:max-md:h-[55vw] md:max-lg:h-[350px]"
                  src={item.pictureUrl}
                ></img>
              </div>
              <div
                className={`col-span-1 flex flex-col justify-around pv:max-sm:gap-4 pv:max-sm:w-[95%] pv:max-sm:mx-auto sm:max-lg:w-[60%] sm:max-lg:mx-auto sm:max-md:gap-4
         
          `}
              >
                <div className="pv:max-lg:w-[70%]  ">
                  <p className="uppercase text-[#1b4235] font-medium text-5xl pv:max-sm:text-5xl sm:max-md:text-5xl md:max-lg:text-2xl lg:max-dv:text-[3.2vw]">
                    FREAKY FISH SWIM SHOP
                  </p>
                </div>
                <div className="">
                  <p className="text-[#391b0b] text-2xl leading-10 pv:max-sm:text-[20px] pv:max-sm:leading-8 md:max-lg:text-lg lg:max-dv:text-[2vw]">
                    Freaky Fish, a Vietnamese based ​swim brand, offers swimming
                    ​goggles, swimwear, and training ​equipment. We also provide
                    ​customized swimsuits for swim ​clubs to represent their
                    team ​identity.
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center py-4">
          <button
            className="bg-[#4e858b] hover:bg-secondary1 rounded-full flex justify-center items-center py-4 px-32 pv:max-md:px-14"
            onClick={() => (window.location = "mailto:contact@aquaticshub.vn")}
          >
            <p className="text-3xl underline font-roboto_regular text-white pv:max-md:text-2xl">
              {t("HomePage_Contact")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ChooseLesson);
